import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { ButtonModule } from 'primeng/button';
import { get } from 'lodash-es';

@Component({
  selector: 'inf-search-system',
  standalone: true,
  imports: [CommonModule, DropdownModule, FormsModule, InputTextModule, ButtonModule],
  templateUrl: './search-system.component.html',
})
export class SearchSystemComponent<T> implements OnChanges {
  @Input() data: T[] = [];
  @Input() paths: string[];
  @Input() preset: string = '';
  @Output() dataChange = new EventEmitter<T[]>();
  @Output() input = new EventEmitter<string>();
  query: string = '';

  ngOnInit(): void {
    // Optionally State from the dashboard via router
    this.query = this.preset;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.filterData();
  }

  filterData(): void {
    const lowerCaseQuery = this.query.trim().toLowerCase();
    const filteredData =
      lowerCaseQuery.length > 0
        ? this.data.filter(item => this.filter(item, lowerCaseQuery))
        : this.data.filter(item => true);
    this.dataChange.emit(filteredData);
    this.input.emit(this.query);
  }

  /**
   * Predicate to filter items
   *
   * @param item
   * @param query
   * @returns
   */
  private filter(item: T, query: string): boolean {
    return this.getStringValues(item, this.paths).some(value => value.includes(query));
  }

  /**
   * Return all the values to be tested for the given item
   *
   * @param obj
   * @param paths
   * @returns
   */
  private getStringValues(obj: T, paths: string[]): string[] {
    return paths.map(path => this.getStringValue(obj, path)).filter(value => value.length > 0);
  }

  /**
   * Return a string, or empty string, for the given path
   *
   * @param obj
   * @param path
   * @returns
   */
  private getStringValue(obj: T, path: string): string {
    if (!obj) {
      return '';
    }
    const value = get(obj, path);
    if (value === undefined || value === null) {
      return '';
    }
    switch (typeof value) {
      case 'string':
        return (value as string).toLowerCase();
      case 'number':
      case 'boolean':
        return String(value as number | boolean).toLowerCase();
      case 'object':
        return value.toString().toLowerCase();
      default:
        return '';
    }
  }

  clearQuery(): void {
    this.query = '';
    this.dataChange.emit(this.data);
  }
}
