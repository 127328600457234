<p-table [columns]="selectedColumns" [value]="rows">
  <ng-template pTemplate="caption" *ngIf="columns.length > 1">
    <p-multiSelect
      [options]="columns"
      [(ngModel)]="selectedColumns"
      (ngModelChange)="selectionChanged()"
      optionLabel="label"
      selectedItemsLabel="{0} columns selected"
      [style]="{ 'min-width': '200px' }"
      placeholder="Choose Columns">
    </p-multiSelect>
  </ng-template>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th>Name</th>
      <th *ngFor="let col of columns">
        {{ col.label }}
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-row let-columns="columns">
    <tr>
      <td>{{ row.label }}</td>
      <td *ngFor="let col of columns">
        {{ row[col.field] }}
      </td>
    </tr>
  </ng-template>
</p-table>
