<div class="fill-v">
  <div class="align-items-center flex justify-content-between">
    <h2 class="font-medium mb-2">Orders</h2>
    <inf-sync-orders [inProgress]="inProgress"></inf-sync-orders>
  </div>
  <div class="font-medium subtitle mb-6">FROM SALES TO PRODUCTION</div>
  <!-- <div class="mb-4" *ngIf="orders">
    <button
      class="p-button-sm"
      icon="pi pi-sliders-v"
      iconPos="left"
      label="Workflow"
      pButton
      pTooltip="View or Edit Order Workflow"
      tooltipPosition="bottom"
      [routerLink]="['/workflow']"></button>
    <button
      class="p-button-sm ml-2"
      icon="pi pi-users"
      iconPos="left"
      label="Customers"
      pButton
      pTooltip="View activity by customers"
      tooltipPosition="bottom"
      [routerLink]="['/customers']"></button>
    <button
      class="p-button-sm ml-2"
      icon="pi pi-cog"
      iconPos="left"
      label="Production"
      pButton
      pTooltip="View production data"
      tooltipPosition="bottom"
      [routerLink]="['/production']"></button>
  </div> -->

  <inf-orders-status [dashboard]="false" class="mb-4"></inf-orders-status>

  <inf-orders-overview
    [presetSearch]="presetSearch"
    [orders]="orders"
    [orderWorkBatches]="orderWorkBatches"
    class="fill-v"
    [rowsNumber]="10"></inf-orders-overview>
  <ng-template #loadingNow> Loading... </ng-template>
</div>

<!-- <pre>{{ orders | json }}</pre> -->
