import { CommonModule } from '@angular/common';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';

// Services
import { AlertService, PatchworkService, SincePipe } from '@harmanpa/ng-cae';
import { OrderService } from '../orders.service';

// Components
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { InputTextModule } from 'primeng/inputtext';
import { Table, TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { map, Observable } from 'rxjs';

// Types
import { Title } from '@angular/platform-browser';
import {
  Artefact,
  DefaultService,
  DocumentSummary,
  ExternalProductData,
  LineItem,
  ProductionParts,
  ProductOrder,
  ReferenceProductConfiguration,
  VersionedArtefact,
  VersionedOrder,
  VersionedProduct,
  VersionedWorkBatch,
} from 'generated-src';
import { ordersStatuses } from './../orders.component';
import { TreeNode } from 'primeng/api';

// Components
import { RouterLink } from '@angular/router';
import { ChipModule } from 'primeng/chip';
import { ContextMenuModule } from 'primeng/contextmenu';
import { MenuModule } from 'primeng/menu';
import { SyncOrdersComponent } from '../sync-orders/sync-orders.component';
import { ToolbarBatchOrderEditorComponent } from '../orders-overview/toolbar-batch-order-editor/toolbar-batch-order-editor.component';
import { EmptyStateComponent } from '../../shared/components/empty-state/empty-state.component';

import { TreeTableModule } from 'primeng/treetable';
import { InplaceModule } from 'primeng/inplace';
import { TruncatePipe } from '../../shared/pipes/truncate.pipe';
import { OrderProductCardComponent } from './order-product-card/order-product-card.component';
import { ConfigurationService } from '../../shared/services/configuration.service';

/**
 *To Do
 * Get Status and Requires Action from the APIs
 */
////////////////////////////
// Temporary Type
////////////////////////////

export interface TemporaryExternalProductData extends ExternalProductData {
  inventory_quantity: number;
}

export interface TemporaryLineItem extends LineItem {
  cogSpin: boolean;
  image: string;
  madeToOrder?: boolean;
  module: string;
  quantity: number;
  properties: TemporaryExternalProductData;
  title?: string;
  quantityOnHand?: string;
  quantityToBeMade?: string;
  quantityToBeOrdered?: string;
  moduleName: string;
}

export interface TemporaryShopifyProps {
  id: string;
  status: string;
  total_price: number;
  line_items: LineItem[];
}

export interface OrderTableDropdownNode extends TreeNode {
  name: string;
  module: string;
  quantity: number;
  title?: string;
  madeToOrder?: string;

  data: {
    name: string;
    module: string;

    [key: string]: string | number;
  };
}

////////////////////////////
// End Temporary Type
////////////////////////////

@Component({
  selector: 'inf-order',
  templateUrl: './order.component.html',
  standalone: true,
  imports: [
    ButtonModule,
    CommonModule,
    DividerModule,
    FormsModule,
    InputTextModule,
    TooltipModule,
    RouterModule,
    ChipModule,
    ContextMenuModule,
    MenuModule,
    RouterLink,
    TableModule,
    ToolbarBatchOrderEditorComponent,
    SyncOrdersComponent,
    EmptyStateComponent,
    TreeTableModule,
    InplaceModule,
    SincePipe,
    TruncatePipe,
    OrderProductCardComponent,
  ],
})
export class OrderComponent implements OnInit {
  // Order Data
  vo: VersionedOrder;
  summary: DocumentSummary;
  vwbs: VersionedWorkBatch[] = [];

  // TreeTable Data
  tto: any;
  permissions: any;
  customerName: string = '';
  orderVersion: string;
  dateOrderCreated: Date | undefined;
  orderStatus: string = '';
  products: any = [];
  providers: any = [];
  requiresAction: boolean = false;
  orderItems: any = [];

  // Loading
  inProgress: boolean;

  // State
  cogSpin: boolean = false;

  // Provider Data
  shopifyProvider: TemporaryShopifyProps;
  customerId: String | undefined;
  totalPrice: Number | undefined = 0;

  // Table
  rowsNumber: number = 10;
  filteredProducts: LineItem[] | TemporaryLineItem[] = [];
  initialisedFilteredProducts: TemporaryLineItem[] = [];
  treeTableFilteredProducts: TemporaryLineItem[] = [];
  selectedProducts: LineItem[] | TemporaryLineItem[] = [];
  @Output() selection = new EventEmitter<LineItem[]>();
  first: number = 0;
  totalCount: LineItem[] = [];
  @ViewChild('dtorder') table: Table;

  //
  cols: any;
  files: [];

  constructor(
    private api: DefaultService,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private orderService: OrderService,
    private patchworkService: PatchworkService,
    private configurationService: ConfigurationService
  ) {}

  ngOnInit(): void {
    this.inProgress = true;
    // Get the order
    this.vo = this.route.snapshot.data['order'] as VersionedOrder;
    this.patchworkService.setVersionQuery(this.route, this.vo);
    // Get the summary for owner
    this.api.summariseOrder(this.vo.id as string).subscribe({
      next: summary => {
        this.summary = summary;
        // Find out if it has been sent to production
        this.orderService.getOrderWorkBatches(this.vo.id as string).subscribe({
          next: batches => {
            this.vwbs = batches;
            this.requiresAction = this.vwbs.length === 0;
          },
          error: err => {
            this.alertService.error(err);
            this.inProgress = false;
          },
        });
      },
      error: err => {
        this.alertService.error(err);
        this.inProgress = false;
      },
    });

    // ////////////////////////////
    // // Gather order data - Not formatted
    // ////////////////////////////
    //
    // const combinedOrderData$ = from(this.api.findOrders()).pipe(
    //   map(array => {
    //     const findOrderObject = array.find(item => item.id === orderId);
    //     if (!findOrderObject) throw new Error('Order not found in findOrders API');
    //     return findOrderObject;
    //   }),
    //
    //   switchMap(orderObject =>
    //     this.http.get<any>(`/api/orders/${orderId}`).pipe(
    //       switchMap(documentSummaryObject => {
    //         const lineItems = documentSummaryObject.document.lineItems;
    //
    //         const lineItemWithModules$ = lineItems.map((lineItem: any) =>
    //           this.api.getModule(lineItem.module?.split(':')[0], lineItem.module?.split(':')[1]).pipe(
    //             map(moduleResponse => ({
    //               ...lineItem,
    //               ////////////////////////////
    //               // Added Product Module Info
    //               ////////////////////////////
    //               productModuleInfo: moduleResponse,
    //               moduleName: moduleResponse.document
    //                 ? `${moduleResponse.document?.name ?? ''} (${moduleResponse.document?.description ?? ''})`
    //                 : (lineItem.name ?? lineItem.title ?? ''),
    //               /**
    //                *To Do
    //                * Image Auto Magic Generated
    //                */
    //               image: lineItem.module
    //                 ? `api/image/modules/${lineItem.module?.split(':')[0]}/v/${
    //                     lineItem.module?.split(':')[1]
    //                   }/source/image.172.png`
    //                 : 'assets/images/logos/infinitive.png',
    //               /**
    //                *To Do
    //                * UI to show Requires Action
    //                */
    //               requiresAction: Math.random() < 0.5,
    //               /**
    //                *To Do
    //                * Use to API to generate quantity logic based on Made To Order
    //                */
    //
    //               madeToOrder: Math.random() < 0.5,
    //               quantityToBeMade: lineItem.madeToOrder ? lineItem.quantity : 0,
    //               quantityOnHand: lineItem.properties?.inventory_quantity ?? 0,
    //               quantityToBeOrdered: lineItem.madeToOrder
    //                 ? 0
    //                 : lineItem.properties?.inventory_quantity &&
    //                     lineItem.properties?.inventory_quantity > lineItem.quantity
    //                   ? lineItem.quantity
    //                   : 0,
    //               quantity: lineItem.quantity ?? 0,
    //               cogSpin: false,
    //             })),
    //             catchError(error => {
    //               console.error('Error fetching module data:', error.message);
    //               return of({
    //                 ...lineItem,
    //                 moduleData: 'Failed to fetch Module data',
    //               });
    //             })
    //           )
    //         );
    //
    //         return forkJoin(lineItemWithModules$).pipe(
    //           map(extraLineItems => ({
    //             /**
    //              *To Do
    //              * Use to API to generate Order Status / Show Order Status
    //              */
    //
    //             orderStatus: ordersStatuses[Math.floor(Math.random() * ordersStatuses.length)],
    //             ...orderObject,
    //             ...documentSummaryObject,
    //             orderLineItems: extraLineItems,
    //           }))
    //         );
    //       }),
    //       catchError(error => {
    //         console.error('Error VersionedOrder API call:', error.message);
    //         return of({ ...orderObject, orderLineItems: [] });
    //       })
    //     )
    //   ),
    //   catchError(error => {
    //     console.error('Error during DocumentSummary API call or processing:', error.message);
    //     return of({ error: true, message: error.message });
    //   })
    // );
    //
    // ////////////////////////////
    // // Populate the tables
    // ////////////////////////////
    //
    // combinedOrderData$.subscribe({
    //   next: combinedOrder => {
    //     ////////////////////////////
    //     // General Data
    //     ////////////////////////////
    //     this.vo = combinedOrder;
    //
    //     console.log(this.vo, 'combined data');
    //
    //     this.orderId = this.vo.id ?? '';
    //     this.orderVersion = this.vo.version ?? '';
    //     this.customerName = this.vo.owner ?? this.orderId ?? '';
    //     this.orderStatus = this.vo.orderStatus ?? 'Received';
    //
    //     this.products = this.vo.document.products ?? [];
    //     this.providers = this.vo.document.providers ?? [];
    //
    //     ////////////////////////////
    //     // Configurator Link
    //     ////////////////////////////
    //     /**
    //      *To Do
    //      * Ensure correct configurator link is generated
    //      */
    //
    //     this.configuratorLinkRoot = this.customerName ? this.customerName?.split('@')[1] : 'infinitive.io';
    //
    //     this.dateOrderCreated = this.vo.versionDate;
    //
    //     this.productConfiguratorId = this.products.length > 0 ? this.products[0].productConfiguration?.id : '';
    //     this.productConfiguratorVersion =
    //       this.products.length > 0 ? this.products[0].productConfiguration?.version : '';
    //
    //     this.configuratorLink =
    //       this.configuratorLinkRoot.length > 0 &&
    //       this.productConfiguratorId.length > 0 &&
    //       this.productConfiguratorVersion.length
    //         ? `https://${this.configuratorLinkRoot}/c/${this.productConfiguratorId}?version=${this.productConfiguratorVersion}`
    //         : '';
    //
    //     ////////////////////////////
    //     // Table Data on init
    //     ////////////////////////////
    //
    //     ////////////////////////////
    //     // Provider
    //     ////////////////////////////
    //
    //     this.totalPrice = 20;
    //
    //     ////////////////////////////
    //     // Products
    //     ////////////////////////////
    //
    //     ////////////////////////////
    //     // Load Order
    //     ////////////////////////////
    //     this.loadOrder();
    //   },
    //   error: error => console.error('Order Load error:', error),
    //   complete: () => console.log('Order Load Completed'),
    // });

    ////////////////////////////
    // Tree Table (Testing)
    ////////////////////////////

    this.cols = [
      { field: 'name', header: 'Product Name' },
      { field: 'quantity', header: 'Quantity' },
      { field: 'quantityOnHand', header: 'Quantity On Hand' },
      { field: 'quantityToBeMade', header: 'Quantity To Be Made' },
      { field: 'quantityToBeOrdered', header: 'Quantity To Be Ordered' },

      // { field: 'type', header: 'Type' },
    ];

    // ////////////////////////////
    // // Gather order data - Not formatted
    // ////////////////////////////
    //
    // const combinedTreeOrderData$ = from(this.api.findOrders()).pipe(
    //   map(array => {
    //     const findOrderObject: DocumentSummary | undefined = array.find(item => item.id === orderId);
    //     if (!findOrderObject) throw new Error('Order not found in findOrders API');
    //     return findOrderObject;
    //   }),
    //
    //   switchMap(orderObject =>
    //     this.http.get<any>(`/api/orders/${orderId}`).pipe(
    //       switchMap(documentSummaryObject => {
    //         const lineItems = documentSummaryObject.document.lineItems;
    //
    //         const lineItemWithModules$ = lineItems.map((lineItem: TemporaryLineItem) =>
    //           this.api.getModule(lineItem.module?.split(':')[0], lineItem.module?.split(':')[1]).pipe(
    //             map(moduleResponse => ({
    //               data: {
    //                 ...lineItem,
    //                 ////////////////////////////
    //                 // Added Product Module Info
    //                 ////////////////////////////
    //                 productModuleInfo: moduleResponse,
    //                 moduleName: moduleResponse.document
    //                   ? `${moduleResponse.document?.name ?? ''} (${moduleResponse.document?.description ?? ''})`
    //                   : (lineItem.name ?? lineItem.title ?? ''),
    //                 /**
    //                  *To Do
    //                  * Image Auto Magic Generated
    //                  */
    //                 image: lineItem.module
    //                   ? `api/image/modules/${lineItem.module?.split(':')[0]}/v/${
    //                       lineItem.module?.split(':')[1]
    //                     }/source/image.172.png`
    //                   : 'assets/images/logos/infinitive.png',
    //                 /**
    //                  *To Do
    //                  * UI to show Requires Action
    //                  */
    //                 requiresAction: Math.random() < 0.5,
    //                 /**
    //                  *To Do
    //                  * Use to API to generate quantity logic based on Made To Order
    //                  */
    //
    //                 madeToOrder: Math.random() < 0.5,
    //                 quantityToBeMade: lineItem.madeToOrder ? lineItem.quantity : 0,
    //                 quantityOnHand: lineItem.properties?.inventory_quantity ?? 0,
    //                 quantityToBeOrdered: lineItem.madeToOrder
    //                   ? 0
    //                   : lineItem.properties?.inventory_quantity &&
    //                       lineItem.properties?.inventory_quantity > lineItem.quantity
    //                     ? lineItem.quantity
    //                     : 0,
    //                 quantity: lineItem.quantity ?? 0,
    //                 cogSpin: false,
    //               },
    //
    //               /**
    //                *To Do
    //                * Add Data / Children.... info
    //                */
    //               children: [
    //                 {
    //                   data: {
    //                     moduleName: moduleResponse.document
    //                       ? `${moduleResponse.document?.name ?? ''} (${moduleResponse.document?.description ?? ''})`
    //                       : (lineItem.name ?? lineItem.title ?? ''),
    //                     name: lineItem.name,
    //                     image: lineItem.module
    //                       ? `api/image/modules/${lineItem.module?.split(':')[0]}/v/${
    //                           lineItem.module?.split(':')[1]
    //                         }/source/image.172.png`
    //                       : 'assets/images/logos/infinitive.png',
    //                     module: lineItem.module,
    //                     quantity: lineItem.quantity ?? 1,
    //                     quantityOnHand: lineItem.quantityOnHand ?? 2,
    //                     quantityToBeMade: lineItem.quantityToBeMade ?? 2,
    //                     quantityToBeOrdered: lineItem.quantityToBeOrdered ?? 2,
    //                   },
    //                   children: [
    //                     {
    //                       data: {
    //                         moduleName: lineItem.moduleName,
    //                         name: lineItem.name,
    //                         image: lineItem.module
    //                           ? `api/image/modules/${lineItem.module?.split(':')[0]}/v/${
    //                               lineItem.module?.split(':')[1]
    //                             }/source/image.172.png`
    //                           : 'assets/images/logos/infinitive.png',
    //                         module: lineItem.module,
    //                         quantity: lineItem.quantity ?? 1,
    //                         quantityOnHand: lineItem.quantityOnHand ?? 2,
    //                         quantityToBeMade: lineItem.quantityToBeMade ?? 2,
    //                         quantityToBeOrdered: lineItem.quantityToBeOrdered ?? 2,
    //                       },
    //                     },
    //                   ],
    //                 },
    //               ],
    //             })),
    //             catchError(error => {
    //               console.error('Error fetching module data:', error.message);
    //               return of({
    //                 ...lineItem,
    //                 moduleData: 'Failed to fetch Module data',
    //               });
    //             })
    //           )
    //         );
    //
    //         return forkJoin(lineItemWithModules$).pipe(
    //           map(extraLineItems => ({
    //             /**
    //              *To Do
    //              * Use to API to generate Order Status / Show Order Status
    //              */
    //
    //             orderStatus: ordersStatuses[Math.floor(Math.random() * ordersStatuses.length)],
    //             ...orderObject,
    //             ...documentSummaryObject,
    //             orderLineItems: extraLineItems,
    //           }))
    //         );
    //       }),
    //       catchError(error => {
    //         console.error('Error VersionedOrder API call:', error.message);
    //         return of({ ...orderObject, orderLineItems: [] });
    //       })
    //     )
    //   ),
    //   catchError(error => {
    //     console.error('Error during DocumentSummary API call or processing:', error.message);
    //     return of({ error: true, message: error.message });
    //   })
    // );
    //
    // ////////////////////////////
    // // Gather order data - Tree Table
    // ////////////////////////////
    //
    // combinedTreeOrderData$.subscribe({
    //   next: combinedTreeOrder => {
    //     this.tto = combinedTreeOrder;
    //
    //     this.treeTableFilteredProducts = this.tto.orderLineItems ?? [];
    //   },
    // });
  }

  makeTableData(lineItems: LineItem[]): void {}

  getModuleImage(module: string): Observable<string> {
    return this.api.getModuleSourceImage(module.split(':')[0], module.split(':')[1]).pipe(
      map((vi: VersionedArtefact) => {
        if (vi.document && (vi.document as Artefact).uri) {
          return '/api/modules/' + module.split(':')[0] + '/v/' + module.split(':')[1] + '/source/image.png';
        } else {
          return '/api/modules/' + module.split(':')[0] + '/v/' + module.split(':')[1] + '/source/render.png';
        }
      })
    );
  }

  ////////////////////////////
  // Get Order
  ////////////////////////////

  loadOrder(): void {
    //this.inProgress = true;
    ////////////////////////////
    // Load LineItems
    ////////////////////////////
    // this.orderItems = this.vo.orderLineItems;
    // this.filteredProducts = this.orderItems ?? [];
    //
    // this.initialisedFilteredProducts = this.orderItems ?? [];
  }

  ////////////////////////////
  // Calculate Total Price - If not in the provider information
  ////////////////////////////

  calculateTotalPrice(): number {
    return this.orderItems.reduce((total: any, item: any) => total + item.quantity * item.properties.price, 0);
  }

  ////////////////////////////
  // External Link to Configurator
  ////////////////////////////
  openPublishConfig(item: ProductOrder): void {
    this.configurationService.openConfiguration(item.productConfiguration as ReferenceProductConfiguration);
  }

  ////////////////////////////
  // Additional Table Functions
  ////////////////////////////

  filterData(data: TemporaryLineItem[]): void {
    if (this.table) {
      //On filter change, go to first page
      this.table.first = 0;
    }
    this.initialisedFilteredProducts = data;
  }

  onSelectionChange(): void {
    this.selection.emit(this.selectedProducts);
  }

  /**
   *To Do
   * Setup to facilitate send to production
   */
  sendBatchToProduction(item: LineItem): void {
    console.log('send batches to production', item);
    //console.log('sendToProduction', item);

    // const ids =
  }

  /**
   *To Do
   * Question? Is there an action to add product here or does it just link to configurator to add products there?
   */
  addToProduct(item: DocumentSummary[]): void {
    console.log('send batches to production', item);
    //console.log('sendToProduction', item);

    //this.api.addProduct()
  }

  /**
   *To Do
   * Need to add Order Batch Delete to API / Use Product Configuration Id - productConfiguration.id
   */
  deleteBatchProducts(items: TemporaryLineItem[]): void {
    console.log('delete batches', items);

    //const batchOrderIds = [];
    // = items.find(item => {
    //   return item.name;
    // });

    //console.log(batchOrderIds, 'iidssss');

    // this.confirmService.onConfirmed(
    //   // this.api.deleteOrderBatch(batchOrderIds).pipe(
    //   //   //  Delete order
    //   //   map((res: any) => {
    //   //     console.log('order deleted.');
    //   //     this.router.navigate(['/orders']);
    //   //     return true;
    //   //   }),
    //   //   catchError((err: any) => {
    //   //     console.log('Error deleting: ', err);
    //   //     return of(false);
    //   //   })
    //   // ),
    //   this.api.deleteOrderBatch(batchProductId: string[])
    //   ,
    //   "Are you sure you want to delete this order? Changes can't be undone.",
    //   'Delete order',
    //   'pi pi-exclamation-triangle',
    //   ['Order deleted successfully.', 'Delete order'],
    //   true,
    //   true,
    //   'Yes',
    //   'No',
    //   '',
    //   ''
    // );
  }

  /**
   *To Do
   * Setup to facilitate send to production
   */
  sendToProduction(item: TemporaryLineItem): void {
    item.cogSpin = true;
    console.log('send single product', item);

    // Reset spinning state after 1 second
    setTimeout(() => {
      item.cogSpin = false;
    }, 1000);
  }
}
