<div class="card flex flex-column">
  <div class="pb-4 m-0 rounded-xl" *ngIf="isOverview">
    <div class="flex align-items-center justify-content-between mb-3">
      <p class="label-normal font-bold">
        ORDERS :
        <span class="label-normal font-regular"> {{ totalCount }} orders</span>
      </p>
      <p-button
        icon="pi pi-arrow-right"
        iconPos="right"
        label="See all"
        [routerLink]="['/orders']"
        class="primary"
        styleClass="p-button-text p-button-sm"></p-button>
    </div>
    <p-divider></p-divider>
  </div>

  <ng-container>
    <p-table
      #dtorders
      class="mb-5 flex-1 border-round-xl"
      [value]="filteredOrders"
      [rows]="rowsNumber"
      [showCurrentPageReport]="true"
      [paginator]="!isOverview"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      [rowsPerPageOptions]="[10, 25, 50]"
      scrollable="false"
      selectionMode="multiple"
      [(selection)]="selectedOrders"
      (selectionChange)="onSelectionChange()"
      [selectionPageOnly]="true">
      <ng-template pTemplate="caption" *ngIf="includeSearch">
        <inf-search-system
          [preset]="presetSearch"
          class="mb-4 flex-1"
          [data]="orders"
          [paths]="['id', 'owner']"
          (dataChange)="filterData($event)">
        </inf-search-system>
        <inf-toolbar-batch-order-editor [selectedOrders]="selectedOrders"> </inf-toolbar-batch-order-editor>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 4rem" *ngIf="!isOverview">
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </th>
          <th [pSortableColumn]="isOverview ? undefined : 'owner'">
            Customer<span *ngIf="!isOverview"> <p-sortIcon field="owner" /></span>
          </th>
          <th>Created</th>
          <th *ngIf="!isOverview" pSortableColumn="lastEdited">
            Last Edited<span> <p-sortIcon field="lastEdited" /></span>
          </th>
          <th *ngIf="!isOverview">Order ID</th>
          <th *ngIf="!isOverview">Production Status</th>
          <!-- <th>Delivery Status</th> -->
          <th>Actions</th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-value let-rowIndex="rowIndex">
        <tr [pContextMenuRow]="value">
          <td *ngIf="!isOverview">
            <p-tableCheckbox [value]="value"></p-tableCheckbox>
          </td>
          <td>
            <inf-avatar
              [user]="value.owner"
              [size]="'small'"
              [pTooltip]="getOwnerName(value.owner)"
              tooltipPosition="top"></inf-avatar>
          </td>
          <td>
            {{ value.created | date: 'dd MMMM yyyy' }}
          </td>
          <td *ngIf="!isOverview">
            {{ value.lastEdited | date: 'dd MMMM yyyy' }}
          </td>
          <td *ngIf="!isOverview">{{ value.id }}</td>
          <td *ngIf="!isOverview" class="flex align-items-center">
            <div class="flex align-items-center text-center gap-2">
              <!-- TODO: this button gives an error! -->
              <!-- <p-button
                *ngIf="!orderWorkBatches[value.id]"
                styleClass="p-button-text border-none bg-transparent text-primary"
                [routerLink]="['/orders', value.id]">
                {{ value.orderStatus | titlecase }}
              </p-button> -->
              <p-button
                #actionTriangle
                *ngIf="orderWorkBatches[value.id]"
                pTooltip="Requires Actions"
                tooltipPosition="top"
                styleClass="p-button-text border-none bg-transparent text-primary"
                [routerLink]="['/orders', value.id]"
                >Requires Action
                <i class="ml-2 pi pi-exclamation-triangle text-red-500"></i>
              </p-button>
            </div>
          </td>
          <td>
            <div class="flex gap-2">
              <button
                class="p-button-text p-button-rounded"
                icon="pi pi-pencil"
                pButton
                [routerLink]="['/orders', value.id]"></button>

              <p-button
                *ngIf="!isOverview"
                pTooltip="Delete"
                tooltipPosition="top"
                icon="pi pi-trash"
                styleClass="p-button-text p-button-rounded"
                (click)="deleteProduct(value)" />

              <p-button
                *ngIf="!isOverview"
                pTooltip="Send To Production"
                tooltipPosition="top"
                icon="pi pi-cog"
                styleClass="p-button-text p-button-rounded"
                (click)="sendToProduction(value)" />

              <!-- <p-button
                *ngIf="!isOverview"
                (onClick)="menu.toggle($event)"
                styleClass="flex-shrink-0 p-button-text p-button-rounded"
                icon="pi pi-ellipsis-v"></p-button> -->
            </div>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="loadingbody" let-columns="columns">
        <tr style="height: 46px">
          <td *ngFor="let col of columns; let even = even">
            <p-skeleton />
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr style="height: 400px" *ngIf="!isOverview">
          <td colspan="100%">
            <inf-empty-state
              *ngIf="orders.length === 0"
              src="/assets/images/empty-states/orders.svg"
              subtitle="No Active Orders"
              title="Active orders linked to your account will be listed here"></inf-empty-state>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </ng-container>
</div>
