import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';

// Services
import { OrderService } from '../orders.service';

// Types
import { DocumentSummary } from 'generated-src';

// Components
import { FormsModule } from '@angular/forms';
import { RouterLink, Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { InputTextModule } from 'primeng/inputtext';
import { MenuModule } from 'primeng/menu';
import { SkeletonModule } from 'primeng/skeleton';
import { Table, TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { EmptyStateComponent } from '../../shared/components/empty-state/empty-state.component';
import { SearchSystemComponent } from '../../shared/components/search-system/search-system.component';
import { ToolbarBatchOrderEditorComponent } from './toolbar-batch-order-editor/toolbar-batch-order-editor.component';
import { OrderWorkBatches } from '../orders.component';
import { TruncatePipe } from '../../shared/pipes/truncate.pipe';
import { AvatarComponent } from '../../shared/components/avatar/avatar.component';

@Component({
  selector: 'inf-orders-overview',
  standalone: true,
  imports: [
    ButtonModule,
    DividerModule,
    RouterLink,
    CommonModule,
    InputTextModule,
    TableModule,
    TooltipModule,
    EmptyStateComponent,
    SearchSystemComponent,
    SkeletonModule,
    MenuModule,
    ToolbarBatchOrderEditorComponent,
    TruncatePipe,
    AvatarComponent,
  ],
  templateUrl: './orders-overview.component.html',
})
export class OrdersOverviewComponent implements OnInit {
  @Input() orders: DocumentSummary[] = [];
  @Input() orderWorkBatches: OrderWorkBatches = {};
  @Input() presetSearch: string = '';

  @Input() onOrderClick!: (order: any) => void;
  @Input() isOverview: boolean = false;
  @Input() includeSearch: boolean = true;
  @Input() rowsNumber: number = 10;
  @Input() showBatchAction = true;

  filteredOrders: DocumentSummary[] = [];

  @Output() selection = new EventEmitter<DocumentSummary[]>();
  @Output() orderClicked = new EventEmitter<any>();

  // Table
  first: number = 0;
  selectedOrders: DocumentSummary[] = [];
  totalCount: number;
  @ViewChild('dtorders') table: Table;

  popUpItems: MenuItem[] | undefined = [];

  constructor(
    private orderService: OrderService,
    private router: Router
  ) {}

  ngOnInit(): void {
    ////////////////////////////
    // Table Logic
    ////////////////////////////

    this.totalCount = this.orders.length;

    // this.popUpItems = [
    //   {
    //     //label: 'Status',
    //     items: [
    //       { label: 'Change Status', icon: 'fa-plus' },
    //       { label: 'Add Products', icon: 'fa-download' },
    //     ],
    //   },
    // ];

    if (this.isOverview) {
      this.orders = this.orders.slice(0, this.rowsNumber);
    }

    this.filteredOrders = this.orders;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['orders'] && changes['orders'].currentValue) {
      this.filteredOrders = this.orders;
    }
  }

  filterData(data: DocumentSummary[]): void {
    if (this.table) {
      //On filter change, go to first page
      this.table.first = 0;
    }

    this.filteredOrders = data;
  }

  handleRowClick(order: any) {
    this.orderClicked.emit(order);
  }

  deleteProduct(item: DocumentSummary): void {
    console.log('delete product', item);
  }

  sendToProduction(item: DocumentSummary): void {
    // Put individual order into array for work batch
    const data = [item];
    this.orderService.sendOrdersToProduction(data.map(ds => ({ id: ds.id, version: ds.head }))).subscribe(workBatch => {
      this.router.navigate(['/production', workBatch.id]);
    });
  }

  // loadOrdersLazy(data: DocumentSummary[]): void {}

  onSelectionChange(): void {
    this.selection.emit(this.selectedOrders);
  }

  getOwnerName(email: string): string {
    return email.split('@')[0];
  }
}
