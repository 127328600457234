import { Component, OnInit, ViewChild } from '@angular/core';
import { OrderService } from '../orders.service';
import { DefaultService, VersionedWorkBatch } from 'generated-src';
import { Table, TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { DatePipe, NgIf } from '@angular/common';
import { OrdersOverviewComponent } from '../orders-overview/orders-overview.component';
import { OrdersStatusComponent } from '../orders-status/orders-status.component';
import { SyncOrdersComponent } from '../sync-orders/sync-orders.component';
import { TooltipModule } from 'primeng/tooltip';
import { RouterLink } from '@angular/router';
import { DocumentSummary } from '@harmanpa/ng-cae';
import { AvatarComponent } from '../../shared/components/avatar/avatar.component';
import { SearchSystemComponent } from '../../shared/components/search-system/search-system.component';

@Component({
  selector: 'inf-production',
  templateUrl: './production.component.html',
  styles: [],
  standalone: true,
  imports: [
    TableModule,
    ButtonModule,
    NgIf,
    OrdersOverviewComponent,
    OrdersStatusComponent,
    SyncOrdersComponent,
    TooltipModule,
    RouterLink,
    AvatarComponent,
    DatePipe,
    SearchSystemComponent,
  ],
})
export class ProductionComponent implements OnInit {
  isOverview: boolean = false;
  includeSearch: boolean = true;
  rowsNumber: number = 10;
  showBatchAction = true;
  first: number = 0;
  workBatches: DocumentSummary[] = [];
  filteredWorkBatches: DocumentSummary[] = [];

  @ViewChild('dt1') table: Table;

  constructor(
    private api: DefaultService,
    private orderService: OrderService
  ) {}

  ngOnInit(): void {
    this.api.findWorkBatches().subscribe(wbs => {
      this.workBatches = wbs;
      this.filteredWorkBatches = this.workBatches;
      console.log('WORK', this.workBatches);
    });
  }

  filterData(data: DocumentSummary[]): void {
    if (this.table) {
      //On filter change, go to first page
      this.table.first = 0;
    }
    this.filteredWorkBatches = data;
  }

  getOwnerName(email: string): string {
    return email.split('@')[0];
  }
}
