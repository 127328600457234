import { Injectable } from '@angular/core';
import {
  DefaultService,
  DocumentSummary,
  ReferenceProduct,
  ReferenceProductConfiguration,
  VersionedProduct,
  VersionedProductConfiguration,
} from '../../../../generated-src';
import { AlertService } from '@harmanpa/ng-cae';
import { map, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
  deps: [DefaultService],
})
export class ConfigurationService {
  constructor(
    private http: HttpClient,
    private alertService: AlertService
  ) {}

  findConfiguratorDomain(ref: VersionedProduct | ReferenceProduct, exact = false): Observable<string> {
    return this.http
      .get<{
        domain: string;
      }>(exact ? `/api/published/configurator/${ref.id}/v/${ref.version}` : `/api/published/configurator/${ref.id}`)
      .pipe(map(response => response.domain));
  }

  findConfigurationDomain(
    ref: VersionedProductConfiguration | ReferenceProductConfiguration | DocumentSummary
  ): Observable<string> {
    return this.http
      .get<{
        domain: string;
      }>(`/api/published/configuration/${ref.id}/v/${'version' in ref ? ref.version : (ref as DocumentSummary).head}`)
      .pipe(map(response => response.domain));
  }

  openConfiguration(ref: VersionedProductConfiguration | ReferenceProductConfiguration | DocumentSummary): void {
    this.findConfigurationDomain(ref).subscribe({
      next: domain => {
        const url = `https://${domain}/c/${ref.id}?version=${'version' in ref ? ref.version : (ref as DocumentSummary).head}`;
        window.open(url, '_blank');
      },
      error: err => this.alertService.error('Configurator not published'),
    });
  }
}
