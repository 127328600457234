<div class="p-2 order-product-card preview">
  <div class="flex justify-content-between">
    <div class="flex justify-content-between flex-1 w-full">
      <img
        *ngIf="url"
        [alt]="lineItem.name"
        class="mr-2 align-self-center img-object-contain product-image"
        [ngSrc]="url"
        [loaderParams]="{ fallback: '' }"
        height="86"
        width="86" />
      <div class="flex flex-column justify-content-between product-description flex-1">
        <!-- NAME AND TRASH -->
        <div class="flex flex-row justify-content-between">
          <div class="body-small font-bold overflow-hidden text-overflow-ellipsis">
            {{ lineItem.name }}
            <a class="documentation-link" [href]="'/products/' + id + '?version=' + version" target="_blank">
              <i class="pi pi-external-link ml-1"></i>
            </a>
          </div>
        </div>
        <!-- SKU AND price -->
        <div class="flex flex-row align-items-center">
          <!--          <div>{{ module.description }}</div>-->
          <div class="ml-auto">
            £ 9.99
            <!-- {{ price | currency }} -->
          </div>
        </div>
        <!-- DOCUMENTATION -->
        <div class="flex align-items-end justify-content-between flex-1">
          {{ nProductionParts }} Production Parts
          <!--          <a-->
          <!--            class="documentation-link"-->
          <!--            [href]="docLink"-->
          <!--            target="_blank">-->
          <!--            <i class="fas fa-file-export mr-1"></i>-->
          <!--            <small class="body-xtra-small">Documentation</small>-->
          <!--          </a>-->
        </div>
      </div>
    </div>
  </div>
  <!--  <ng-container *ngIf="lineItem.parameters?.length">-->
  <!--    <div-->
  <!--      class="flex flex-column mt-2"-->
  <!--      (click)="stopP($event)"-->
  <!--      #params>-->
  <!--      <div-->
  <!--        *ngFor="-->
  <!--          let parameter of lineItem.parameters;-->
  <!--          index as i;-->
  <!--          trackBy: parameterTrackBy-->
  <!--        "-->
  <!--        class="flex align-items-center justify-content-between mb-2">-->
  <!--        <label class="mr-1 text-color-secondary">{{ parameter | json }}:</label>-->
  <!--        &lt;!&ndash; <p-dropdown class="align-items-center" [pTooltip]="parameter.description" [tooltipPosition]="'left'"-->
  <!--          [appendTo]="'body'" [disabled]="disabled || !synchronised && !selected" [options]="parameterOptions[parameter.name]"-->
  <!--          [(ngModel)]="parameterValues[parameter.name]" (onChange)="setParameter(parameter, $event)"-->
  <!--          optionLabel="value" optionValue="value" optionDisabled="disabled">-->
  <!--        </p-dropdown> &ndash;&gt;-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </ng-container>-->
</div>
