import { Component, Input, OnInit } from '@angular/core';
import {
  DefaultService,
  NestedSheets,
  PickingList,
  VersionedWorkBatch,
  VersionedWorkBatchGroup,
  WorkBatchGroup,
  WorkBatchGroupData,
} from '../../../../../generated-src';
import { AlertService } from '@harmanpa/ng-cae';
import { CommonModule } from '@angular/common';
import { MetricButtonComponent } from '../../../shared/components/metric-button/metric-button.component';
import { AccordionModule } from 'primeng/accordion';
import { SharedModule } from 'primeng/api';
import { AvatarComponent } from '../../../shared/components/avatar/avatar.component';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';

@Component({
  selector: 'inf-work-batch-group',
  templateUrl: './work-batch-group.component.html',
  standalone: true,
  imports: [
    CommonModule,
    MetricButtonComponent,
    AccordionModule,
    SharedModule,
    AvatarComponent,
    ButtonModule,
    TableModule,
  ],
})
export class WorkBatchGroupComponent implements OnInit {
  @Input() workBatch: VersionedWorkBatch;
  @Input() group: WorkBatchGroup;
  @Input() index: number;
  count = 0;
  type: string;
  data: PickingList | NestedSheets;

  constructor(
    private api: DefaultService,
    private alertService: AlertService
  ) {}

  ngOnInit(): void {
    this.count = (this.group.items || []).length;
    this.type = (this.group.key || {})['type'] || 'unknown';
    this.api
      .getWorkBatchGroupsData(this.workBatch.id as string, this.workBatch.version as string, String(this.index))
      .subscribe({
        next: data => {
          this.data = data.document as PickingList | NestedSheets;
        },
        error: err => {},
      });
  }
}
