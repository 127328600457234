<button
  class="ml-auto p-button-sm p-button-outlined"
  loadingIcon="pi pi-spin pi-sync"
  iconPos="left"
  [icon]="syncSpin ? 'pi pi-sync pi-spin' : 'pi pi-sync '"
  animationDuration="2s"
  label="Sync External"
  pTooltip="Synchronise order data from external integrations"
  tooltipPosition="bottom"
  pButton
  [loading]="ordersSyncing"
  (click)="syncOrders()"></button>
