<div class="toolbar-batch-editor">
  <ng-container>
    <div class="batch-actions flex align-items-center mt-2">
      <ng-container *ngIf="selectedOrders.length > 0">
        <p class="body-small">
          {{ selectedOrders.length }} Order{{ selectedOrders.length !== 1 ? 's' : '' }}
          Selected
        </p>
        <p-button
          label="Send to Production"
          pTooltip="Send Selected To Production"
          tooltipPosition="top"
          icon="pi pi-cog"
          styleClass="p-button-text p-button-rounded p-button-sm"
          class="mr-2"
          (click)="sendToProduction(selectedOrders)"></p-button>
        <!--        <div class="vertical-line surface-secondary-dark align-self-stretch my-1"></div>-->
      </ng-container>
    </div>
  </ng-container>
</div>
