import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BadgeModule } from 'primeng/badge';
import { FormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import {
  AlertService,
  LogService,
  Theme,
  ThemeService,
  VersionedTheme,
  Workspace,
  WorkspaceService,
} from '@harmanpa/ng-cae';
import { cloneDeep } from 'lodash-es';
import {
  argbFromHex,
  hexFromArgb,
  rgbaFromArgb,
  TonalPalette,
  SchemeTonalSpot,
  Hct,
  SchemeContent,
} from '@material/material-color-utilities';
import { DialogModule } from 'primeng/dialog';
import _ from 'lodash';
import { ThemeKeycolorsComponent } from './theme-keycolors/theme-keycolors.component';
import { defaultTheme } from 'src/app/configurators/settings/custom-themes/defaultTheme';
import { ThemePresetComponent } from './theme-preset/theme-preset.component';
import { of, switchMap } from 'rxjs';
import { CheckboxModule } from 'primeng/checkbox';
import { InfColorPickerModule } from 'src/app/shared/components/color-picker/color-picker.component';

@Component({
  selector: 'inf-theme-generator',
  standalone: true,
  templateUrl: './theme-generator.component.html',
  imports: [
    BadgeModule,
    ButtonModule,
    CheckboxModule,
    CommonModule,
    DialogModule,
    FormsModule,
    InputTextModule,
    InfColorPickerModule,
    ThemePresetComponent,
    ThemeKeycolorsComponent,
  ],
})
export class ThemeGenerator {
  @Input() management: boolean = false;
  themes: VersionedTheme[] = [];
  selectedTheme: Theme;
  selectedPreset: number = -1;
  seed: string = '#006EB8';
  loading: boolean = false;
  surfaces: { [key: string]: { [key: string]: string } };
  keyMainColours: { primary: string; secondary: string; tertiary: string };
  togglePrimary: boolean = false;
  toggleSecondary: boolean = false;
  toggleTertiary: boolean = false;
  trueColors: boolean = false;

  hexregex = /^#?([A-Fa-f0-9]{6})$/;

  visible: boolean = false;
  themeName: string;

  constructor(
    private alertService: AlertService,
    private themeService: ThemeService,
    public workspaceService: WorkspaceService,
    public log: LogService
  ) {}

  ngOnInit(): void {
    //get current theme from workspace
    this.loading = true;
    this.setThemeValues(defaultTheme.seed);

    this.themeService.getActiveTheme(defaultTheme).subscribe((theme: Theme) => {
      this.selectedTheme = cloneDeep(theme);
      this.setThemeValues(this.selectedTheme.seed ?? (this.selectedTheme.properties?.light?.['primary'] as string));
      this.loading = false;
    });

    //get public themes from theme service
    this.themeService.getAllThemes().subscribe(themes => {
      //TODO: get all themes should filter themes from different workspaces/accounts, currently all of them are public
      this.themes = themes.filter(
        theme => (theme.permissions as unknown as { publicDocument: boolean })?.publicDocument === true
      );
      console.log('themes', this.themes);
    });
  }
  ////////////////////////////////////////////
  //REGISTER NEW PUBLIC THEMES
  showDialog() {
    this.visible = true;
  }
  addNewTheme(): void {
    this.visible = false;
    //   if (this.hexregex.test(this.seed)) {
    //     this.createThemeFromHex(this.seed);
    //     this.createLightAndDarkSchemes();
    //     this.createPaletteFromTheme(this.theme.palettes);
    //     this.selectedTheme = this.createConfiguratorTheme();
    //     //reset preset
    //     this.selectedPreset = -1;
    //   }
    //
    // CHANGE registeredTheme for the theme you want to register if there is a file. Otherwise change Seed in UI.
    //
    const registeredTheme = Object.assign({}, this.selectedTheme, {
      name: this.themeName,
    });
    this.themeService.registerTheme(registeredTheme, true).subscribe(res => this.log.debug(res));
  }
  ///////////////////////////////////////////

  /***
   * Generate Surface Values for light and dark theme
   */
  getSurfaces(seed: string, scheme: SchemeTonalSpot): { [key: string]: { [key: string]: string } } {
    let usedScheme = scheme;

    //If true colors, use scheme content instead to have surface colors that standout more
    if (this.trueColors) {
      usedScheme = new SchemeContent(Hct.fromInt(argbFromHex(seed)), false, 0.0);
    }

    return {
      light: {
        surfaceDim: hexFromArgb(usedScheme.neutralPalette.tone(87)),
        surface: hexFromArgb(usedScheme.neutralPalette.tone(98)),
        surfaceBright: hexFromArgb(usedScheme.neutralPalette.tone(98)),
        surfaceContainerLowest: hexFromArgb(usedScheme.neutralPalette.tone(100)),
        surfaceContainerLow: hexFromArgb(usedScheme.neutralPalette.tone(96)),
        surfaceContainer: hexFromArgb(usedScheme.neutralPalette.tone(94)),
        surfaceContainerHigh: hexFromArgb(usedScheme.neutralPalette.tone(92)),
        surfaceContainerHighest: hexFromArgb(usedScheme.neutralPalette.tone(90)),
        onSurface: hexFromArgb(usedScheme.neutralPalette.tone(10)),
        onSurfaceVariant: hexFromArgb(usedScheme.neutralVariantPalette.tone(30)),
        outline: hexFromArgb(usedScheme.neutralVariantPalette.tone(50)),
        outlineVariant: hexFromArgb(usedScheme.neutralVariantPalette.tone(80)),
        inverseSurface: hexFromArgb(usedScheme.neutralPalette.tone(20)),
        inverseOnSurface: hexFromArgb(usedScheme.neutralPalette.tone(95)),
        scrim: hexFromArgb(usedScheme.neutralPalette.tone(0)),
        shadow: hexFromArgb(usedScheme.neutralPalette.tone(0)),
      },
      dark: {
        surfaceDim: hexFromArgb(usedScheme.neutralPalette.tone(6)),
        surface: hexFromArgb(usedScheme.neutralPalette.tone(6)),
        surfaceBright: hexFromArgb(usedScheme.neutralPalette.tone(24)),
        surfaceContainerLowest: hexFromArgb(usedScheme.neutralPalette.tone(4)),
        surfaceContainerLow: hexFromArgb(usedScheme.neutralPalette.tone(10)),
        surfaceContainer: hexFromArgb(usedScheme.neutralPalette.tone(12)),
        surfaceContainerHigh: hexFromArgb(usedScheme.neutralPalette.tone(17)),
        surfaceContainerHighest: hexFromArgb(usedScheme.neutralPalette.tone(24)),
        onSurface: hexFromArgb(usedScheme.neutralPalette.tone(90)),
        onSurfaceVariant: hexFromArgb(usedScheme.neutralVariantPalette.tone(90)),
        outline: hexFromArgb(usedScheme.neutralVariantPalette.tone(60)),
        outlineVariant: hexFromArgb(usedScheme.neutralVariantPalette.tone(30)),
        inverseSurface: hexFromArgb(usedScheme.neutralPalette.tone(90)),
        inverseOnSurface: hexFromArgb(usedScheme.neutralPalette.tone(20)),
        scrim: hexFromArgb(usedScheme.neutralPalette.tone(0)),
        shadow: hexFromArgb(usedScheme.neutralPalette.tone(0)),
      },
    };
  }

  /***
   * Saves the selected Theme for the current workspace to DB
   */
  changeSelectedTheme(): void {
    this.log.debug('selectedTheme', this.selectedTheme);
    this.workspaceService
      .getWorkspace()
      .pipe(
        switchMap((ws: Workspace) => {
          if (!ws.themeId) {
            this.themeService.registerTheme(this.selectedTheme, false).subscribe(id => {
              this.themeService.setTheme(id);
            });
            return of(ws);
          } else {
            //TODO: If themeId is empty, we need to create a theme, either this or create one on signup, otherwise it throws an error
            //TODO: new configurator doesnt work since the color isnt set
            return this.themeService.getTheme(ws.themeId as string).pipe(
              switchMap(theme => {
                const updatedTheme = {
                  ...theme,
                  name: this.selectedTheme.name,
                  seed: this.selectedTheme.seed,
                  properties: this.selectedTheme.properties,
                };

                // Return the updated observable from the updateTheme call
                return this.themeService.updateTheme(ws.themeId as string, updatedTheme);
              })
            );
          }
        })
      )
      .subscribe(
        result => {
          this.log.info('Theme updated successfully:', result);
        },
        error => {
          this.log.error('Error updating theme:', error);
        }
      );
  }

  /**
   * Gets the palette tones from a color
   * @param argb - the color to generate the palette tones
   * @param palette - if its primary, secondary or tertiary
   * @param useSeed - If the palette tone is being created by the seed
   * @returns - the palette tone
   */
  getPaletteTones(argb: number, palette: string, useSeed = false): { [key: string]: number } {
    let tonePalette: { [key: string]: number };
    if (this.trueColors) {
      //If true color, create palette directly from color, and apply changes to specific tones, used for the primary, secondary, tertiary values
      const color = TonalPalette.fromInt(argb);
      tonePalette = {
        tone100: color.tone(100),
        tone099: color.tone(99),
        tone098: color.tone(98),
        tone096: color.tone(96),
        tone095: color.tone(95),
        tone094: color.tone(94),
        tone092: color.tone(92),
        tone090: this.getPrimaryContainerColor(Hct.fromInt(argb)).toInt(),
        tone080: color.tone(80),
        tone070: color.tone(70),
        tone060: color.tone(60),
        tone050: color.tone(50),
        tone040: this.getPrimaryColor(Hct.fromInt(argb)).toInt(),
        tone035: color.tone(35),
        tone030: color.tone(30),
        tone025: color.tone(25),
        tone022: color.tone(22),
        tone020: color.tone(20),
        tone017: color.tone(17),
        tone015: color.tone(15),
        tone012: color.tone(12),
        tone010: this.getOnPrimaryContainerColor(Hct.fromInt(argb)).toInt(),
        tone005: color.tone(5),
        tone004: color.tone(4),
        tone000: color.tone(0),
      };
    } else {
      //If not true colors, simply use scheme tonal spot.
      let color: TonalPalette;
      if (useSeed) {
        //If its to use the seed, base the color from the seed
        color = new SchemeTonalSpot(Hct.fromInt(argbFromHex(this.seed)), false, 0.0)[
          (palette + 'Palette') as 'primaryPalette'
        ];
      } else {
        //If not, get the palette from the color
        color = new SchemeTonalSpot(Hct.fromInt(argb), false, 0.0).primaryPalette;
      }
      tonePalette = {
        tone100: color.tone(100),
        tone099: color.tone(99),
        tone098: color.tone(98),
        tone096: color.tone(96),
        tone095: color.tone(95),
        tone094: color.tone(94),
        tone092: color.tone(92),
        tone090: color.tone(90),
        tone080: color.tone(80),
        tone070: color.tone(70),
        tone060: color.tone(60),
        tone050: color.tone(50),
        tone040: color.tone(40),
        tone035: color.tone(35),
        tone030: color.tone(30),
        tone025: color.tone(25),
        tone022: color.tone(22),
        tone020: color.tone(20),
        tone017: color.tone(17),
        tone015: color.tone(15),
        tone012: color.tone(12),
        tone010: color.tone(10),
        tone005: color.tone(5),
        tone004: color.tone(4),
        tone000: color.tone(0),
      };
    }

    return this.transformArgbToHex(tonePalette);
  }

  /**
   * Applies changes to color to work as the true color from https://material-foundation.github.io/material-theme-builder/
   * For primary/secondary/tertiary color
   * @param color - the color to change
   * @returns - the color converted to true color
   */
  getPrimaryColor(color: Hct): Hct {
    if (color.tone <= 43) {
      return Hct.from(color.hue, color.chroma, color.tone - 9);
    } else if (color.tone <= 48) {
      return Hct.from(color.hue, color.chroma, color.tone - 15);
    } else if (color.tone <= 59) {
      return Hct.from(color.hue, color.chroma, 34);
    } else {
      return Hct.from(color.hue, color.chroma, 40);
    }
  }

  /**
   * Applies changes to color to work as the true color from https://material-foundation.github.io/material-theme-builder/
   * For primary/secondary/tertiary container color
   * @param color - the color to change
   * @returns - the color converted to true color
   */
  getPrimaryContainerColor(color: Hct): Hct {
    if (color.tone <= 43) {
      return Hct.from(color.hue, color.chroma, color.tone + 6);
    } else if (color.tone <= 48) {
      return Hct.from(color.hue, color.chroma, color.tone);
    } else if (color.tone <= 59) {
      return Hct.from(color.hue, color.chroma, 49);
    } else if (color.tone <= 70) {
      return Hct.from(color.hue, color.chroma, color.tone + 4);
    } else if (color.tone <= 82) {
      return Hct.from(color.hue, color.chroma, color.tone + 3);
    } else if (color.tone <= 92) {
      return Hct.from(color.hue, color.chroma, color.tone + 2);
    } else if (color.tone <= 99) {
      return Hct.from(color.hue, color.chroma, color.tone + 1);
    } else {
      return Hct.from(color.hue, color.chroma, 100);
    }
  }

  /**
   * Applies changes to color to work as the true color from https://material-foundation.github.io/material-theme-builder/
   * For on primary/secondary/tertiary container color
   * @param color - the color to change
   * @returns - the color converted to true color
   */
  getOnPrimaryContainerColor(color: Hct): Hct {
    if (color.tone <= 9) {
      return Hct.from(color.hue, color.chroma, 72);
    } else if (color.tone <= 21) {
      return Hct.from(color.hue, color.chroma, color.tone + 64);
    } else if (color.tone <= 25) {
      return Hct.from(color.hue, color.chroma, color.tone + 66);
    } else if (color.tone <= 29) {
      return Hct.from(color.hue, color.chroma, color.tone + 67);
    } else if (color.tone <= 59) {
      return Hct.from(color.hue, color.chroma, 100);
    } else if (color.tone <= 79) {
      return Hct.from(color.hue, color.chroma, color.tone - 54);
    } else {
      const raw = 25 + 0.6 * (color.tone - 80);
      return Hct.from(color.hue, color.chroma, Math.floor(raw + 0.5));
    }
  }

  /**
   * Checks if a number is an argb
   * @param value - the value to check
   */
  isArgb(value: number): boolean {
    return typeof value === 'number' && value.toString().length === 10;
  }

  /***
   * To help the UI update the selected theme -> apply the border around the preset theme
   * Save the index of the preset theme
   */
  selectPreset(index: number): void {
    this.selectedPreset = index;
  }

  /***
   * Sets the selected theme preset as current and call changeSelectedTheme to save it to DB.
   */
  setPreset(): void {
    if (this.selectedPreset !== -1) {
      this.selectedTheme = cloneDeep(this.themes[this.selectedPreset].document as Theme);

      this.setNewThemeName();
      this.setThemeValues(this.selectedTheme.seed ?? this.selectedTheme.properties?.light?.['primary']);

      this.changeSelectedTheme();
    } else {
      this.alertService.warning('Select a theme from the list.', 'No theme selected');
    }
  }

  /***
   * Handle changes on input value for seed and generate theme.
   * @param seed hex value from which generate theme
   */
  themeModelChanged(seed: string): void {
    if (this.hexregex.test(seed)) {
      this.setThemeValues(seed);

      //Apply changes from primary
      this.advancedGenerator(this.keyMainColours.primary, 'primary', true);
      //Apply changes from secondary
      this.advancedGenerator(this.keyMainColours.secondary, 'secondary', true);
      //Apply changes from tertiary
      this.advancedGenerator(this.keyMainColours.tertiary, 'tertiary', true);

      //Set new name for theme, and update seed
      this.setNewThemeName();
      this.selectedTheme.seed = this.seed;

      //reset preset
      this.selectedPreset = -1;
    }
  }

  /**
   * Converts the argb tone list into a list with those tones + rgb ones
   * @param obj - the argb tone list
   * @returns the list with argb tone list + rgb tone list
   */
  transformArgbToHex(obj: any): any {
    for (const key in obj) {
      if (typeof obj[key] === 'object') {
        // Recursively process nested objects
        this.transformArgbToHex(obj[key]);
      } else if (this.isArgb(obj[key])) {
        // Convert number to hex
        obj[key + 'RGB'] = rgbaFromArgb(obj[key]).r + ',' + rgbaFromArgb(obj[key]).g + ',' + rgbaFromArgb(obj[key]).b;
        obj[key] = hexFromArgb(obj[key]);
      }
    }
    return obj;
  }

  /**
   * Toggles editing for primary/secondary/tertiary values
   * @param keycolour - which are we toggling
   */
  toggleAdvanced(keycolour: string): void {
    switch (keycolour) {
      case 'primary':
        this.togglePrimary = !this.togglePrimary;
        break;
      case 'secondary':
        this.toggleSecondary = !this.toggleSecondary;
        break;
      case 'tertiary':
        this.toggleTertiary = !this.toggleTertiary;
        break;
    }
  }

  /**
   * Updates selected theme according to change on primary/secondary/tertiary
   * @param colour - the color from it
   * @param coreColour - on which is the change
   * @param useSeed - if it is to use seed
   */
  advancedGenerator(colour: string, coreColour: string, useSeed = false): void {
    //If change is from seed, dont update it
    if (coreColour === 'primary' && !useSeed) {
      this.keyMainColours.primary = colour;
      this.seed = colour;
    }

    if (this.hexregex.test(colour)) {
      // Dont update surface if change is from seed (since it already does it on themeModelChanged)
      if (coreColour === 'primary' && !useSeed) {
        const scheme = this.getScheme(colour);
        this.surfaces = this.getSurfaces(colour, scheme);
      }

      //Get palette tones
      const tonal_palette = this.getPaletteTones(argbFromHex(colour), coreColour, useSeed);
      //Create light and dark themes from the palette
      const colourCap = coreColour.replace(/^\w/, c => c.toUpperCase());
      const lightprops = Object.fromEntries([
        [coreColour, tonal_palette['tone040']],
        [coreColour + 'RGB', tonal_palette['tone040RGB']],
        ['on' + colourCap, tonal_palette['tone100']],
        ['on' + colourCap + 'RGB', tonal_palette['tone100RGB']],
        [coreColour + 'Container', tonal_palette['tone090']],
        [coreColour + 'ContainerRGB', tonal_palette['tone090RGB']],
        ['on' + colourCap + 'Container', tonal_palette['tone010']],
        ['on' + colourCap + 'ContainerRGB', tonal_palette['tone010RGB']],
        ['inverse' + colourCap, tonal_palette['tone080']],
        ['inverse' + colourCap + 'RGB', tonal_palette['tone080RGB']],
      ]);
      const darkprops = Object.fromEntries([
        [coreColour, tonal_palette['tone080']],
        [coreColour + 'RGB', tonal_palette['tone080RGB']],
        ['on' + colourCap, tonal_palette['tone020']],
        ['on' + colourCap + 'RGB', tonal_palette['tone020RGB']],
        [coreColour + 'Container', tonal_palette[this.trueColors ? 'tone090' : 'tone030']],
        [coreColour + 'ContainerRGB', tonal_palette[this.trueColors ? 'tone090RGB' : 'tone030RGB']],
        ['on' + colourCap + 'Container', tonal_palette[this.trueColors ? 'tone010' : 'tone090']],
        ['on' + colourCap + 'ContainerRGB', tonal_palette[this.trueColors ? 'tone010RGB' : 'tone090RGB']],
        ['inverse' + colourCap, tonal_palette['tone040']],
        ['inverse' + colourCap + 'RGB', tonal_palette['tone040RGB']],
      ]);

      //Apply changes to selected theme
      this.selectedTheme.properties = {
        light: Object.assign({}, this.selectedTheme.properties?.light, lightprops, this.surfaces['light']),
        dark: Object.assign({}, this.selectedTheme.properties?.dark, darkprops, this.surfaces['dark']),
      };

      if (!useSeed) {
        //Update theme name and seed
        this.setNewThemeName();
        this.selectedTheme.seed = this.seed;
      }
    } else {
      this.log.info('This string is not a valid HEX');
    }
  }

  /**
   * Updates theme values according to seed
   * @param seed - the seed
   */
  setThemeValues(seed: string): void {
    this.seed = seed;
    const scheme = this.getScheme(seed);
    this.setKeyMainColors(this.seed, scheme);
    this.surfaces = this.getSurfaces(this.seed, scheme);
  }

  /**
   * Sets a new theme name
   */
  setNewThemeName(): void {
    //CHANGE HERE
    this.selectedTheme.name = this.themeName ? this.themeName : Date.now() + 'configuratorTheme';
    // this.selectedTheme.name = this.themeName ? (Date.now()+this.themeName) : Date.now() + `configuratorTheme`;
  }

  /**
   * Sets the key main colors
   * @param seed - the seed
   * @param scheme - the scheme
   */
  setKeyMainColors(seed: string, scheme: SchemeTonalSpot): void {
    this.keyMainColours = {
      primary: seed,
      secondary: hexFromArgb(scheme.secondaryPaletteKeyColor),
      tertiary: hexFromArgb(scheme.tertiaryPaletteKeyColor),
    };
  }

  /**
   * Gets the scheme based on the seed
   * @param seed - the seed
   * @returns - the scheme
   */
  getScheme(seed: string): SchemeTonalSpot {
    if (this.trueColors) {
      return new SchemeContent(Hct.fromInt(argbFromHex(seed)), false, 0.0);
    } else {
      return new SchemeTonalSpot(Hct.fromInt(argbFromHex(seed)), false, 0.0);
    }
  }
}
