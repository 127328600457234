<div class="grid m-0">
  <div class="col">
    <div
      class="p-2 body-xtra-small text-overflow-ellipsis overflow-hidden white-space-nowrap"
      [pTooltip]="theme?.properties?.light.primary"
      [style.background-color]="theme?.properties?.light.primary"
      [style.color]="theme?.properties?.light.onPrimary"
      title="Primary">
      Primary
    </div>
    <div
      class="p-2 body-xtra-small text-overflow-ellipsis overflow-hidden white-space-nowrap"
      [pTooltip]="theme?.properties?.light.onPrimary"
      [style.background-color]="theme?.properties?.light.onPrimary"
      [style.color]="theme?.properties?.light.primary"
      title="On Primary">
      On Primary
    </div>
  </div>
  <div class="col">
    <div
      class="p-2 body-xtra-small text-overflow-ellipsis overflow-hidden white-space-nowrap"
      [pTooltip]="theme?.properties?.light.secondary"
      [style.background-color]="theme?.properties?.light.secondary"
      [style.color]="theme?.properties?.light.onSecondary"
      title="Secondary">
      Secondary
    </div>
    <div
      class="p-2 body-xtra-small text-overflow-ellipsis overflow-hidden white-space-nowrap"
      [pTooltip]="theme?.properties?.light.onSecondary"
      [style.background-color]="theme?.properties?.light.onSecondary"
      [style.color]="theme?.properties?.light.secondary"
      title="On Secondary">
      On Secondary
    </div>
  </div>
  <div class="col">
    <div
      class="p-2 body-xtra-small text-overflow-ellipsis overflow-hidden white-space-nowrap"
      [pTooltip]="theme?.properties?.light.tertiary"
      [style.background-color]="theme?.properties?.light.tertiary"
      [style.color]="theme?.properties?.light.onTertiary"
      title="Tertiary">
      Tertiary
    </div>
    <div
      class="p-2 body-xtra-small text-overflow-ellipsis overflow-hidden white-space-nowrap"
      [pTooltip]="theme?.properties?.light.onTertiary"
      [style.background-color]="theme?.properties?.light.onTertiary"
      [style.color]="theme?.properties?.light.tertiary"
      title="On tertiary">
      On Tertiary
    </div>
  </div>
  <div class="col pl-2">
    <div
      class="p-2 body-xtra-small text-overflow-ellipsis overflow-hidden white-space-nowrap"
      [pTooltip]="theme?.properties?.light.error"
      [style.background-color]="theme?.properties?.light.error"
      [style.color]="theme?.properties?.light.onError"
      title="Error">
      Error
    </div>
    <div
      class="p-2 body-xtra-small text-overflow-ellipsis overflow-hidden white-space-nowrap"
      [pTooltip]="theme?.properties?.light.onError"
      [style.background-color]="theme?.properties?.light.onError"
      [style.error]="theme?.properties?.light.error"
      title="On Error">
      On Error
    </div>
  </div>
</div>
<div class="grid m-0">
  <div class="col">
    <div
      class="p-2 body-xtra-small text-overflow-ellipsis overflow-hidden white-space-nowrap"
      [pTooltip]="theme?.properties?.light.primaryContainer"
      [style.background-color]="theme?.properties?.light.primaryContainer"
      [style.color]="theme?.properties?.light.onPrimaryContainer"
      title="Primary container">
      Primary Cont.
    </div>
    <div
      class="p-2 body-xtra-small text-overflow-ellipsis overflow-hidden white-space-nowrap"
      [pTooltip]="theme?.properties?.light.onPrimaryContainer"
      [style.background-color]="theme?.properties?.light.onPrimaryContainer"
      [style.color]="theme?.properties?.light.primaryContainer"
      title="On primary container">
      On Primary Cont.
    </div>
  </div>
  <div class="col">
    <div
      class="p-2 body-xtra-small text-overflow-ellipsis overflow-hidden white-space-nowrap"
      [pTooltip]="theme?.properties?.light.secondaryContainer"
      [style.background-color]="theme?.properties?.light.secondaryContainer"
      [style.color]="theme?.properties?.light.onSecondaryContainer"
      title="Secondary container">
      Secondary Cont.
    </div>
    <div
      class="p-2 body-xtra-small text-overflow-ellipsis overflow-hidden white-space-nowrap"
      [pTooltip]="theme?.properties?.light.onSecondaryContainer"
      [style.background-color]="theme?.properties?.light.onSecondaryContainer"
      [style.color]="theme?.properties?.light.secondaryContainer"
      title="On secondary container">
      On Secondary Cont.
    </div>
  </div>
  <div class="col">
    <div
      class="p-2 body-xtra-small text-overflow-ellipsis overflow-hidden white-space-nowrap"
      [pTooltip]="theme?.properties?.light.tertiaryContainer"
      [style.background-color]="theme?.properties?.light.tertiaryContainer"
      [style.color]="theme?.properties?.light.onTertiaryContainer"
      title="Tertiary container">
      Tertiary Cont.
    </div>
    <div
      class="p-2 body-xtra-small text-overflow-ellipsis overflow-hidden white-space-nowrap"
      [pTooltip]="theme?.properties?.light.onTertiaryContainer"
      [style.background-color]="theme?.properties?.light.onTertiaryContainer"
      [style.color]="theme?.properties?.light.tertiaryContainer"
      title="On tertiary container">
      On Tertiary Cont.
    </div>
  </div>
  <div class="col pl-2">
    <div
      class="p-2 body-xtra-small text-overflow-ellipsis overflow-hidden white-space-nowrap"
      [pTooltip]="theme?.properties?.light.errorContainer"
      [style.background-color]="theme?.properties?.light.errorContainer"
      [style.color]="theme?.properties?.light.onErrorContainer"
      title="Error container">
      Error Cont.
    </div>
    <div
      class="p-2 body-xtra-small text-overflow-ellipsis overflow-hidden white-space-nowrap"
      [pTooltip]="theme?.properties?.light.onErrorContainer"
      [style.background-color]="theme?.properties?.light.onErrorContainer"
      [style.color]="theme?.properties?.light.errorContainer"
      title="On error container">
      On Error Cont.
    </div>
  </div>
</div>
<div class="grid m-0 pt-2">
  <div class="col-9 pr-1">
    <div class="grid m-0">
      <div class="col-4">
        <div
          class="p-2 body-xtra-small text-overflow-ellipsis overflow-hidden white-space-nowrap"
          [style.background-color]="theme?.properties?.light.surfaceDim"
          [style.color]="theme?.properties?.light.inverseSurface"
          title="Surface Dim">
          Surface Dim
        </div>
      </div>
      <div class="col-4">
        <div
          class="p-2 body-xtra-small text-overflow-ellipsis overflow-hidden white-space-nowrap"
          [style.background-color]="theme?.properties?.light.surface"
          [style.color]="theme?.properties?.light.inverseSurface"
          title="Surface">
          Surface
        </div>
      </div>
      <div class="col-4">
        <div
          class="p-2 body-xtra-small text-overflow-ellipsis overflow-hidden white-space-nowrap"
          [style.background-color]="theme?.properties?.light.surfaceBright"
          [style.color]="theme?.properties?.light.inverseSurface"
          title="Surface Bright">
          Surface Bright
        </div>
      </div>

      <div class="col-2">
        <div
          class="p-2 body-xtra-small text-overflow-ellipsis overflow-hidden white-space-nowrap"
          [style.background-color]="theme?.properties?.light.surfaceContainerLowest"
          [style.color]="theme?.properties?.light.inverseSurface"
          title="Surface container lowest">
          S. Cont. Lowest
        </div>
      </div>
      <div class="col-2">
        <div
          class="p-2 body-xtra-small text-overflow-ellipsis overflow-hidden white-space-nowrap"
          [style.background-color]="theme?.properties?.light.surfaceContainerLow"
          [style.color]="theme?.properties?.light.inverseSurface"
          title="Surface container low">
          S. Cont. Low
        </div>
      </div>
      <div class="col-2">
        <div
          class="p-2 body-xtra-small text-overflow-ellipsis overflow-hidden white-space-nowrap"
          [style.background-color]="theme?.properties?.light.surfaceContainer"
          [style.color]="theme?.properties?.light.inverseSurface"
          title="Surface container">
          S. Cont.
        </div>
      </div>
      <div class="col-2">
        <div
          class="p-2 body-xtra-small text-overflow-ellipsis overflow-hidden white-space-nowrap"
          [style.background-color]="theme?.properties?.light.surfaceContainerHigh"
          [style.color]="theme?.properties?.light.inverseSurface"
          title="Surface container high">
          S. Cont. High
        </div>
      </div>
      <div class="col-4">
        <div
          class="p-2 body-xtra-small text-overflow-ellipsis overflow-hidden white-space-nowrap"
          [style.background-color]="theme?.properties?.light.surfaceContainerHighest"
          [style.color]="theme?.properties?.light.inverseSurface"
          title="Surface container highest">
          S. Cont. Highest
        </div>
      </div>

      <div class="col-3">
        <div
          class="p-2 body-xtra-small text-overflow-ellipsis overflow-hidden white-space-nowrap"
          [style.background-color]="theme?.properties?.light.onSurface"
          [style.color]="theme?.properties?.light.surface"
          title="On surface">
          On Surface
        </div>
      </div>
      <div class="col-3">
        <div
          class="p-2 body-xtra-small text-overflow-ellipsis overflow-hidden white-space-nowrap"
          [style.background-color]="theme?.properties?.light.onSurfaceVariant"
          [style.color]="theme?.properties?.light.surfaceDim"
          title="On surface variant">
          On Surf. Variant
        </div>
      </div>
      <div class="col-3">
        <div
          class="p-2 body-xtra-small text-overflow-ellipsis overflow-hidden white-space-nowrap"
          [style.background-color]="theme?.properties?.light.outline"
          [style.color]="theme?.properties?.light.surface"
          title="Outline">
          Outline
        </div>
      </div>
      <div class="col-3">
        <div
          class="p-2 body-xtra-small text-overflow-ellipsis overflow-hidden white-space-nowrap"
          [style.background-color]="theme?.properties?.light.outlineVariant"
          [style.color]="theme?.properties?.light.onSurface"
          title="Outline variant">
          Outline Variant
        </div>
      </div>
    </div>
  </div>

  <div class="col-3">
    <div class="grid m-0">
      <div class="col-12">
        <div
          class="p-2 body-xtra-small text-overflow-ellipsis overflow-hidden white-space-nowrap"
          [style.background-color]="theme?.properties?.light.inverseSurface"
          [style.color]="theme?.properties?.light.surface"
          title="Inverse surface">
          inverseSurface
        </div>
      </div>
      <div class="col-12">
        <div
          class="p-2 body-xtra-small text-overflow-ellipsis overflow-hidden white-space-nowrap"
          [style.background-color]="theme?.properties?.light.inverseOnSurface"
          [style.color]="theme?.properties?.light.onSurface"
          title="Inverse on surface">
          inverseOnSurface
        </div>
      </div>
      <div class="col-12">
        <div
          class="p-2 body-xtra-small text-overflow-ellipsis overflow-hidden white-space-nowrap"
          [style.background-color]="theme?.properties?.light.inversePrimary"
          [style.color]="theme?.properties?.light.primary"
          title="Inverse primary">
          inversePrimary
        </div>
      </div>
      <div class="col-6">
        <div
          class="p-2 body-xtra-small text-overflow-ellipsis overflow-hidden white-space-nowrap text-white"
          [style.background-color]="theme?.properties?.light.scrim"
          title="Scrim">
          scrim
        </div>
      </div>
      <div class="col-6">
        <div
          class="p-2 body-xtra-small text-overflow-ellipsis overflow-hidden white-space-nowrap text-white"
          [style.background-color]="theme?.properties?.light.shadow"
          title="Shadow">
          shadow
        </div>
      </div>
    </div>
  </div>
</div>
