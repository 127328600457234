import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StatisticsService {
  constructor(private http: HttpClient) {}

  getProductUsageStatistics(workspace: string): Observable<{ [key: string]: number }> {
    return this.http.get<{ [key: string]: number }>(`/api/statistics/productusagestatistics/${workspace}`);
  }

  getFunctionsCount(): Observable<{ [key: string]: number }> {
    return of({});
  }

  getConfigurationCount(): Observable<CountStatistics> {
    return this.http.get<CountStatistics>('/api/statistics/productconfigurations?unit=weeks&n=8');
  }

  getOrderCount(status: string): Observable<CountStatistics> {
    // TODO: Make query from status
    return this.http.get<CountStatistics>('/api/statistics/orders?unit=weeks&n=4');
  }

  getOrderWeekOnWeekCount(status: string): Observable<CountStatistics> {
    // TODO: Make query from status
    return this.http.get<CountStatistics>('/api/statistics/orders?unit=weeks&n=8');
  }

  // getOrderStatusCount(status: string): Observable<CountStatistics> {
  //   // TODO: Make query from status
  //   return this.http.get<CountStatistics>(
  //     '/api/statistics/orders?unit=weeks&n=4'
  //   );
  // }
}

export interface CountStatistics {
  total: number;
  byDate: DateRangeCount[];
}

export interface DateRangeCount {
  since: Date;
  before: Date;
  count: number;
}
