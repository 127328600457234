import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MermaidComponent } from '@harmanpa/ng-cae';
import { VersionedWorkflow } from 'generated-src';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'inf-workflow',
  standalone: true,
  templateUrl: './workflow.component.html',
  imports: [ButtonModule, CommonModule, MermaidComponent, TooltipModule, RouterLink],
})
export class WorkflowComponent {
  spec =
    'stateDiagram-v2\n' +
    '  direction LR\n' +
    '  [*] --> Draft : Configurator\n' +
    '  Draft --> Abandoned\n' +
    '  Abandoned --> [*]\n' +
    '  Draft --> Paid\n' +
    '  Paid --> Picked\n' +
    '  Picked --> Fulfilled\n' +
    '  Fulfilled --> [*]';

  private workflow: VersionedWorkflow;
}
