import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject, Observable, map, forkJoin } from 'rxjs';
import { TemporaryDocumentSummary } from './orders.component';
import {
  DefaultService,
  DocumentSummary,
  LineItem,
  ProduceOrdersPayload,
  ReferenceOrder,
  VersionedWorkBatch,
} from 'generated-src';
import { TemporaryLineItem } from './order/order.component';
import { HttpClient } from '@angular/common/http';
import { TreeNode } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  private selectedOrder: TemporaryDocumentSummary;
  private selectedOrderSubject = new Subject<TemporaryDocumentSummary>();

  // Add / Subtract Quantity
  private lineItemSubject = new BehaviorSubject<Map<TemporaryLineItem | LineItem, number>>(new Map());

  constructor(private http: HttpClient) {}

  ////////////////////////////
  // Get Order Data
  ////////////////////////////

  selectedOrder$ = this.selectedOrderSubject.asObservable();

  setSelectedOrder(order: TemporaryDocumentSummary) {
    this.selectedOrder = order;
    this.selectedOrderSubject.next(order);
  }

  getSelectedOrder(): TemporaryDocumentSummary {
    return this.selectedOrder;
  }

  ////////////////////////////
  // Table States
  ////////////////////////////

  lineItemState$ = this.lineItemSubject.asObservable();

  initialiseState(rows: Array<{ item: object; quantity: number }>): void {
    const initialState = new Map<object, number>();
    rows.forEach(({ item, quantity }) => initialState.set(item, quantity));
    this.lineItemSubject.next(initialState);
  }

  // Increment the value for a specific item
  increment(item: object): void {
    this.updateState(item, 1);
  }

  // Decrement the value for a specific item
  decrement(item: object): void {
    this.updateState(item, -1);
  }

  // Update state for a specific item
  private updateState(item: object, index: number): void {
    const currentState = this.lineItemSubject.value;
    const currentValue = currentState.get(item) || 0;

    // Clone the map and update the state
    const updatedState = new Map(currentState);
    updatedState.set(item, currentValue + index);
    this.lineItemSubject.next(updatedState);
  }

  ////////////////////////////
  // Filter States
  ////////////////////////////

  private filterOrderStatusSubject = new BehaviorSubject<string>('');
  filterOrderStatus$ = this.filterOrderStatusSubject.asObservable();

  filterOrderStatusData(status: string) {
    this.filterOrderStatusSubject.next(status);
  }

  ////////////////////////////
  // Send Orders To Production
  ////////////////////////////

  getOrderWorkBatches(id: string): Observable<VersionedWorkBatch[]> {
    return this.http.get<VersionedWorkBatch[]>(`/api/production/orders/${id}`);
  }

  sendOrderToProduction(id: string, version: string): Observable<VersionedWorkBatch> {
    return this.sendOrdersToProduction([{ id, version }]);
  }

  sendOrdersToProduction(orders: ReferenceOrder[]): Observable<VersionedWorkBatch> {
    const payload: ProduceOrdersPayload = { orders };
    return this.http.post<VersionedWorkBatch>('/api/production', payload);
  }
}
