/**
 * InfinitivePlatform API
 * Modular Configurator
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { Activity } from '../model/activity';
import { AppVersion } from '../model/appVersion';
import { Appearance } from '../model/appearance';
import { Artefact } from '../model/artefact';
import { Company } from '../model/company';
import { ConfigurationParameter } from '../model/configurationParameter';
import { ConnectorInstance } from '../model/connectorInstance';
import { ConnectorType } from '../model/connectorType';
import { DefaultAppearance } from '../model/defaultAppearance';
import { DocumentSummary } from '../model/documentSummary';
import { GeometryNodeSettings } from '../model/geometryNodeSettings';
import { HotspotAnnotation } from '../model/hotspotAnnotation';
import { LargeObject } from '../model/largeObject';
import { MaterialStyling } from '../model/materialStyling';
import { Module } from '../model/module';
import { OfflineAction } from '../model/offlineAction';
import { Order } from '../model/order';
import { ParameterDefinition } from '../model/parameterDefinition';
import { ParametricAppearance } from '../model/parametricAppearance';
import { PartGeometry } from '../model/partGeometry';
import { Patch } from '../model/patch';
import { Population } from '../model/population';
import { Product } from '../model/product';
import { ProductConfiguration } from '../model/productConfiguration';
import { ProductionMaterial } from '../model/productionMaterial';
import { PublishedConfigurator } from '../model/publishedConfigurator';
import { Reference } from '../model/reference';
import { Sharing } from '../model/sharing';
import { ShortCode } from '../model/shortCode';
import { ShortCodeResponse } from '../model/shortCodeResponse';
import { SkuMapping } from '../model/skuMapping';
import { Theme } from '../model/theme';
import { User } from '../model/user';
import { UserPermissions } from '../model/userPermissions';
import { VersionGraph } from '../model/versionGraph';
import { VersionLog } from '../model/versionLog';
import { VersionLogVersion } from '../model/versionLogVersion';
import { VersionedAppearance } from '../model/versionedAppearance';
import { VersionedAppearanceDiff } from '../model/versionedAppearanceDiff';
import { VersionedAppearancePatch } from '../model/versionedAppearancePatch';
import { VersionedArtefact } from '../model/versionedArtefact';
import { VersionedArtefactPatch } from '../model/versionedArtefactPatch';
import { VersionedAssembly } from '../model/versionedAssembly';
import { VersionedCategoryModules } from '../model/versionedCategoryModules';
import { VersionedCategoryModulesArray } from '../model/versionedCategoryModulesArray';
import { VersionedCompany } from '../model/versionedCompany';
import { VersionedCompanyDiff } from '../model/versionedCompanyDiff';
import { VersionedCompanyPatch } from '../model/versionedCompanyPatch';
import { VersionedConfigurationScore } from '../model/versionedConfigurationScore';
import { VersionedConfigurationState } from '../model/versionedConfigurationState';
import { VersionedConfiguratorProductionData } from '../model/versionedConfiguratorProductionData';
import { VersionedConnectorType } from '../model/versionedConnectorType';
import { VersionedConnectorTypeDiff } from '../model/versionedConnectorTypeDiff';
import { VersionedConnectorTypePatch } from '../model/versionedConnectorTypePatch';
import { VersionedLargeObject } from '../model/versionedLargeObject';
import { VersionedLargeObjectPatch } from '../model/versionedLargeObjectPatch';
import { VersionedMaterialStyling } from '../model/versionedMaterialStyling';
import { VersionedMaterialStylingPatch } from '../model/versionedMaterialStylingPatch';
import { VersionedModule } from '../model/versionedModule';
import { VersionedModuleDiff } from '../model/versionedModuleDiff';
import { VersionedModulePatch } from '../model/versionedModulePatch';
import { VersionedOfflineAction } from '../model/versionedOfflineAction';
import { VersionedOfflineActionDiff } from '../model/versionedOfflineActionDiff';
import { VersionedOfflineActionPatch } from '../model/versionedOfflineActionPatch';
import { VersionedOrder } from '../model/versionedOrder';
import { VersionedOrderDiff } from '../model/versionedOrderDiff';
import { VersionedOrderPatch } from '../model/versionedOrderPatch';
import { VersionedPartGeometry } from '../model/versionedPartGeometry';
import { VersionedPartGeometryPatch } from '../model/versionedPartGeometryPatch';
import { VersionedPopulation } from '../model/versionedPopulation';
import { VersionedPopulationDiff } from '../model/versionedPopulationDiff';
import { VersionedPopulationPatch } from '../model/versionedPopulationPatch';
import { VersionedPopulationState } from '../model/versionedPopulationState';
import { VersionedProduct } from '../model/versionedProduct';
import { VersionedProductConfiguration } from '../model/versionedProductConfiguration';
import { VersionedProductConfigurationDiff } from '../model/versionedProductConfigurationDiff';
import { VersionedProductConfigurationPatch } from '../model/versionedProductConfigurationPatch';
import { VersionedProductDiff } from '../model/versionedProductDiff';
import { VersionedProductPatch } from '../model/versionedProductPatch';
import { VersionedProductionMaterial } from '../model/versionedProductionMaterial';
import { VersionedProductionMaterialDiff } from '../model/versionedProductionMaterialDiff';
import { VersionedProductionMaterialPatch } from '../model/versionedProductionMaterialPatch';
import { VersionedProductionParts } from '../model/versionedProductionParts';
import { VersionedPublishedConfigurator } from '../model/versionedPublishedConfigurator';
import { VersionedPublishedConfiguratorDiff } from '../model/versionedPublishedConfiguratorDiff';
import { VersionedPublishedConfiguratorPatch } from '../model/versionedPublishedConfiguratorPatch';
import { VersionedStoredModuleGraph } from '../model/versionedStoredModuleGraph';
import { VersionedTheme } from '../model/versionedTheme';
import { VersionedThemeDiff } from '../model/versionedThemeDiff';
import { VersionedThemePatch } from '../model/versionedThemePatch';
import { VersionedTransitionHistory } from '../model/versionedTransitionHistory';
import { VersionedWorkBatch } from '../model/versionedWorkBatch';
import { VersionedWorkBatchDiff } from '../model/versionedWorkBatchDiff';
import { VersionedWorkBatchGroup } from '../model/versionedWorkBatchGroup';
import { VersionedWorkBatchGroupArray } from '../model/versionedWorkBatchGroupArray';
import { VersionedWorkBatchGroupData } from '../model/versionedWorkBatchGroupData';
import { VersionedWorkBatchPatch } from '../model/versionedWorkBatchPatch';
import { VersionedWorkBatchProvider } from '../model/versionedWorkBatchProvider';
import { VersionedWorkBatchProviderMap } from '../model/versionedWorkBatchProviderMap';
import { VersionedWorkflow } from '../model/versionedWorkflow';
import { VersionedWorkflowDiff } from '../model/versionedWorkflowDiff';
import { VersionedWorkflowPatch } from '../model/versionedWorkflowPatch';
import { WorkBatch } from '../model/workBatch';
import { Workflow } from '../model/workflow';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class DefaultService {

    protected basePath = '/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * Add a new Appearance
     * @param body 
     * @param duplicateId ID of Appearance to duplicate
     * @param duplicateVersion Version of Appearance to duplicate
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addAppearance(body?: Appearance, duplicateId?: string, duplicateVersion?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedAppearance>;
    public addAppearance(body?: Appearance, duplicateId?: string, duplicateVersion?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedAppearance>>;
    public addAppearance(body?: Appearance, duplicateId?: string, duplicateVersion?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedAppearance>>;
    public addAppearance(body?: Appearance, duplicateId?: string, duplicateVersion?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (duplicateId !== undefined && duplicateId !== null) {
            queryParameters = queryParameters.set('duplicate_id', <any>duplicateId);
        }
        if (duplicateVersion !== undefined && duplicateVersion !== null) {
            queryParameters = queryParameters.set('duplicate_version', <any>duplicateVersion);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedAppearance>('post',`${this.basePath}/appearances`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Add a new Company
     * @param body 
     * @param duplicateId ID of Company to duplicate
     * @param duplicateVersion Version of Company to duplicate
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addCompany(body?: Company, duplicateId?: string, duplicateVersion?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedCompany>;
    public addCompany(body?: Company, duplicateId?: string, duplicateVersion?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedCompany>>;
    public addCompany(body?: Company, duplicateId?: string, duplicateVersion?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedCompany>>;
    public addCompany(body?: Company, duplicateId?: string, duplicateVersion?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (duplicateId !== undefined && duplicateId !== null) {
            queryParameters = queryParameters.set('duplicate_id', <any>duplicateId);
        }
        if (duplicateVersion !== undefined && duplicateVersion !== null) {
            queryParameters = queryParameters.set('duplicate_version', <any>duplicateVersion);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedCompany>('post',`${this.basePath}/companies`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Add a new ConnectorType
     * @param body 
     * @param duplicateId ID of ConnectorType to duplicate
     * @param duplicateVersion Version of ConnectorType to duplicate
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addConnectorType(body?: ConnectorType, duplicateId?: string, duplicateVersion?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedConnectorType>;
    public addConnectorType(body?: ConnectorType, duplicateId?: string, duplicateVersion?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedConnectorType>>;
    public addConnectorType(body?: ConnectorType, duplicateId?: string, duplicateVersion?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedConnectorType>>;
    public addConnectorType(body?: ConnectorType, duplicateId?: string, duplicateVersion?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (duplicateId !== undefined && duplicateId !== null) {
            queryParameters = queryParameters.set('duplicate_id', <any>duplicateId);
        }
        if (duplicateVersion !== undefined && duplicateVersion !== null) {
            queryParameters = queryParameters.set('duplicate_version', <any>duplicateVersion);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedConnectorType>('post',`${this.basePath}/connectortypes`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Add a new Module
     * @param body 
     * @param duplicateId ID of Module to duplicate
     * @param duplicateVersion Version of Module to duplicate
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addModule(body?: Module, duplicateId?: string, duplicateVersion?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedModule>;
    public addModule(body?: Module, duplicateId?: string, duplicateVersion?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedModule>>;
    public addModule(body?: Module, duplicateId?: string, duplicateVersion?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedModule>>;
    public addModule(body?: Module, duplicateId?: string, duplicateVersion?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (duplicateId !== undefined && duplicateId !== null) {
            queryParameters = queryParameters.set('duplicate_id', <any>duplicateId);
        }
        if (duplicateVersion !== undefined && duplicateVersion !== null) {
            queryParameters = queryParameters.set('duplicate_version', <any>duplicateVersion);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedModule>('post',`${this.basePath}/modules`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Add a new OfflineAction
     * @param body 
     * @param duplicateId ID of OfflineAction to duplicate
     * @param duplicateVersion Version of OfflineAction to duplicate
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addOfflineAction(body?: OfflineAction, duplicateId?: string, duplicateVersion?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedOfflineAction>;
    public addOfflineAction(body?: OfflineAction, duplicateId?: string, duplicateVersion?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedOfflineAction>>;
    public addOfflineAction(body?: OfflineAction, duplicateId?: string, duplicateVersion?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedOfflineAction>>;
    public addOfflineAction(body?: OfflineAction, duplicateId?: string, duplicateVersion?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (duplicateId !== undefined && duplicateId !== null) {
            queryParameters = queryParameters.set('duplicate_id', <any>duplicateId);
        }
        if (duplicateVersion !== undefined && duplicateVersion !== null) {
            queryParameters = queryParameters.set('duplicate_version', <any>duplicateVersion);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedOfflineAction>('post',`${this.basePath}/offlineactions`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Add a new Order
     * @param body 
     * @param duplicateId ID of Order to duplicate
     * @param duplicateVersion Version of Order to duplicate
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addOrder(body?: Order, duplicateId?: string, duplicateVersion?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedOrder>;
    public addOrder(body?: Order, duplicateId?: string, duplicateVersion?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedOrder>>;
    public addOrder(body?: Order, duplicateId?: string, duplicateVersion?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedOrder>>;
    public addOrder(body?: Order, duplicateId?: string, duplicateVersion?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (duplicateId !== undefined && duplicateId !== null) {
            queryParameters = queryParameters.set('duplicate_id', <any>duplicateId);
        }
        if (duplicateVersion !== undefined && duplicateVersion !== null) {
            queryParameters = queryParameters.set('duplicate_version', <any>duplicateVersion);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedOrder>('post',`${this.basePath}/orders`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Add a new Population
     * @param body 
     * @param duplicateId ID of Population to duplicate
     * @param duplicateVersion Version of Population to duplicate
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addPopulation(body?: Population, duplicateId?: string, duplicateVersion?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedPopulation>;
    public addPopulation(body?: Population, duplicateId?: string, duplicateVersion?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedPopulation>>;
    public addPopulation(body?: Population, duplicateId?: string, duplicateVersion?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedPopulation>>;
    public addPopulation(body?: Population, duplicateId?: string, duplicateVersion?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (duplicateId !== undefined && duplicateId !== null) {
            queryParameters = queryParameters.set('duplicate_id', <any>duplicateId);
        }
        if (duplicateVersion !== undefined && duplicateVersion !== null) {
            queryParameters = queryParameters.set('duplicate_version', <any>duplicateVersion);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedPopulation>('post',`${this.basePath}/populations`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Add a new Product
     * @param body 
     * @param duplicateId ID of Product to duplicate
     * @param duplicateVersion Version of Product to duplicate
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addProduct(body?: Product, duplicateId?: string, duplicateVersion?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedProduct>;
    public addProduct(body?: Product, duplicateId?: string, duplicateVersion?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedProduct>>;
    public addProduct(body?: Product, duplicateId?: string, duplicateVersion?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedProduct>>;
    public addProduct(body?: Product, duplicateId?: string, duplicateVersion?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (duplicateId !== undefined && duplicateId !== null) {
            queryParameters = queryParameters.set('duplicate_id', <any>duplicateId);
        }
        if (duplicateVersion !== undefined && duplicateVersion !== null) {
            queryParameters = queryParameters.set('duplicate_version', <any>duplicateVersion);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedProduct>('post',`${this.basePath}/products`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Add a new ProductConfiguration
     * @param body 
     * @param duplicateId ID of ProductConfiguration to duplicate
     * @param duplicateVersion Version of ProductConfiguration to duplicate
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addProductConfiguration(body?: ProductConfiguration, duplicateId?: string, duplicateVersion?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedProductConfiguration>;
    public addProductConfiguration(body?: ProductConfiguration, duplicateId?: string, duplicateVersion?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedProductConfiguration>>;
    public addProductConfiguration(body?: ProductConfiguration, duplicateId?: string, duplicateVersion?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedProductConfiguration>>;
    public addProductConfiguration(body?: ProductConfiguration, duplicateId?: string, duplicateVersion?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (duplicateId !== undefined && duplicateId !== null) {
            queryParameters = queryParameters.set('duplicate_id', <any>duplicateId);
        }
        if (duplicateVersion !== undefined && duplicateVersion !== null) {
            queryParameters = queryParameters.set('duplicate_version', <any>duplicateVersion);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedProductConfiguration>('post',`${this.basePath}/productconfigurations`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Add a new ProductionMaterial
     * @param body 
     * @param duplicateId ID of ProductionMaterial to duplicate
     * @param duplicateVersion Version of ProductionMaterial to duplicate
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addProductionMaterial(body?: ProductionMaterial, duplicateId?: string, duplicateVersion?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedProductionMaterial>;
    public addProductionMaterial(body?: ProductionMaterial, duplicateId?: string, duplicateVersion?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedProductionMaterial>>;
    public addProductionMaterial(body?: ProductionMaterial, duplicateId?: string, duplicateVersion?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedProductionMaterial>>;
    public addProductionMaterial(body?: ProductionMaterial, duplicateId?: string, duplicateVersion?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (duplicateId !== undefined && duplicateId !== null) {
            queryParameters = queryParameters.set('duplicate_id', <any>duplicateId);
        }
        if (duplicateVersion !== undefined && duplicateVersion !== null) {
            queryParameters = queryParameters.set('duplicate_version', <any>duplicateVersion);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedProductionMaterial>('post',`${this.basePath}/productionmaterials`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Add a new PublishedConfigurator
     * @param body 
     * @param duplicateId ID of PublishedConfigurator to duplicate
     * @param duplicateVersion Version of PublishedConfigurator to duplicate
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addPublishedConfigurator(body?: PublishedConfigurator, duplicateId?: string, duplicateVersion?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedPublishedConfigurator>;
    public addPublishedConfigurator(body?: PublishedConfigurator, duplicateId?: string, duplicateVersion?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedPublishedConfigurator>>;
    public addPublishedConfigurator(body?: PublishedConfigurator, duplicateId?: string, duplicateVersion?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedPublishedConfigurator>>;
    public addPublishedConfigurator(body?: PublishedConfigurator, duplicateId?: string, duplicateVersion?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (duplicateId !== undefined && duplicateId !== null) {
            queryParameters = queryParameters.set('duplicate_id', <any>duplicateId);
        }
        if (duplicateVersion !== undefined && duplicateVersion !== null) {
            queryParameters = queryParameters.set('duplicate_version', <any>duplicateVersion);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedPublishedConfigurator>('post',`${this.basePath}/publishedconfigurators`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Add a new Theme
     * @param body 
     * @param duplicateId ID of Theme to duplicate
     * @param duplicateVersion Version of Theme to duplicate
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addTheme(body?: Theme, duplicateId?: string, duplicateVersion?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedTheme>;
    public addTheme(body?: Theme, duplicateId?: string, duplicateVersion?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedTheme>>;
    public addTheme(body?: Theme, duplicateId?: string, duplicateVersion?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedTheme>>;
    public addTheme(body?: Theme, duplicateId?: string, duplicateVersion?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (duplicateId !== undefined && duplicateId !== null) {
            queryParameters = queryParameters.set('duplicate_id', <any>duplicateId);
        }
        if (duplicateVersion !== undefined && duplicateVersion !== null) {
            queryParameters = queryParameters.set('duplicate_version', <any>duplicateVersion);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedTheme>('post',`${this.basePath}/themes`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Add a new WorkBatch
     * @param body 
     * @param duplicateId ID of WorkBatch to duplicate
     * @param duplicateVersion Version of WorkBatch to duplicate
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addWorkBatch(body?: WorkBatch, duplicateId?: string, duplicateVersion?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedWorkBatch>;
    public addWorkBatch(body?: WorkBatch, duplicateId?: string, duplicateVersion?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedWorkBatch>>;
    public addWorkBatch(body?: WorkBatch, duplicateId?: string, duplicateVersion?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedWorkBatch>>;
    public addWorkBatch(body?: WorkBatch, duplicateId?: string, duplicateVersion?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (duplicateId !== undefined && duplicateId !== null) {
            queryParameters = queryParameters.set('duplicate_id', <any>duplicateId);
        }
        if (duplicateVersion !== undefined && duplicateVersion !== null) {
            queryParameters = queryParameters.set('duplicate_version', <any>duplicateVersion);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedWorkBatch>('post',`${this.basePath}/workbatches`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Add a new Workflow
     * @param body 
     * @param duplicateId ID of Workflow to duplicate
     * @param duplicateVersion Version of Workflow to duplicate
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addWorkflow(body?: Workflow, duplicateId?: string, duplicateVersion?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedWorkflow>;
    public addWorkflow(body?: Workflow, duplicateId?: string, duplicateVersion?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedWorkflow>>;
    public addWorkflow(body?: Workflow, duplicateId?: string, duplicateVersion?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedWorkflow>>;
    public addWorkflow(body?: Workflow, duplicateId?: string, duplicateVersion?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (duplicateId !== undefined && duplicateId !== null) {
            queryParameters = queryParameters.set('duplicate_id', <any>duplicateId);
        }
        if (duplicateVersion !== undefined && duplicateVersion !== null) {
            queryParameters = queryParameters.set('duplicate_version', <any>duplicateVersion);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedWorkflow>('post',`${this.basePath}/workflows`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Use short code to access a shared resource
     * @param code Short code being used
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public applyShortCode(code: string, observe?: 'body', reportProgress?: boolean): Observable<ShortCodeResponse>;
    public applyShortCode(code: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ShortCodeResponse>>;
    public applyShortCode(code: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ShortCodeResponse>>;
    public applyShortCode(code: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (code === null || code === undefined) {
            throw new Error('Required parameter code was null or undefined when calling applyShortCode.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ShortCodeResponse>('get',`${this.basePath}/s/${encodeURIComponent(String(code))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Check if user can read specified Appearance
     * @param appearanceId ID of Appearance to check
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public canReadAppearance(appearanceId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public canReadAppearance(appearanceId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public canReadAppearance(appearanceId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public canReadAppearance(appearanceId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (appearanceId === null || appearanceId === undefined) {
            throw new Error('Required parameter appearanceId was null or undefined when calling canReadAppearance.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('head',`${this.basePath}/appearances/${encodeURIComponent(String(appearanceId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Check if user can read specified Company
     * @param companyId ID of Company to check
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public canReadCompany(companyId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public canReadCompany(companyId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public canReadCompany(companyId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public canReadCompany(companyId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling canReadCompany.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('head',`${this.basePath}/companies/${encodeURIComponent(String(companyId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Check if user can read specified ConnectorType
     * @param connectortypeId ID of ConnectorType to check
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public canReadConnectorType(connectortypeId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public canReadConnectorType(connectortypeId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public canReadConnectorType(connectortypeId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public canReadConnectorType(connectortypeId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (connectortypeId === null || connectortypeId === undefined) {
            throw new Error('Required parameter connectortypeId was null or undefined when calling canReadConnectorType.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('head',`${this.basePath}/connectortypes/${encodeURIComponent(String(connectortypeId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Check if user can read specified Module
     * @param moduleId ID of Module to check
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public canReadModule(moduleId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public canReadModule(moduleId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public canReadModule(moduleId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public canReadModule(moduleId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (moduleId === null || moduleId === undefined) {
            throw new Error('Required parameter moduleId was null or undefined when calling canReadModule.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('head',`${this.basePath}/modules/${encodeURIComponent(String(moduleId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Check if user can read specified OfflineAction
     * @param offlineactionId ID of OfflineAction to check
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public canReadOfflineAction(offlineactionId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public canReadOfflineAction(offlineactionId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public canReadOfflineAction(offlineactionId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public canReadOfflineAction(offlineactionId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (offlineactionId === null || offlineactionId === undefined) {
            throw new Error('Required parameter offlineactionId was null or undefined when calling canReadOfflineAction.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('head',`${this.basePath}/offlineactions/${encodeURIComponent(String(offlineactionId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Check if user can read specified Order
     * @param orderId ID of Order to check
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public canReadOrder(orderId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public canReadOrder(orderId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public canReadOrder(orderId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public canReadOrder(orderId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (orderId === null || orderId === undefined) {
            throw new Error('Required parameter orderId was null or undefined when calling canReadOrder.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('head',`${this.basePath}/orders/${encodeURIComponent(String(orderId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Check if user can read specified Population
     * @param populationId ID of Population to check
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public canReadPopulation(populationId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public canReadPopulation(populationId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public canReadPopulation(populationId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public canReadPopulation(populationId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (populationId === null || populationId === undefined) {
            throw new Error('Required parameter populationId was null or undefined when calling canReadPopulation.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('head',`${this.basePath}/populations/${encodeURIComponent(String(populationId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Check if user can read specified Product
     * @param productId ID of Product to check
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public canReadProduct(productId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public canReadProduct(productId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public canReadProduct(productId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public canReadProduct(productId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productId === null || productId === undefined) {
            throw new Error('Required parameter productId was null or undefined when calling canReadProduct.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('head',`${this.basePath}/products/${encodeURIComponent(String(productId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Check if user can read specified ProductConfiguration
     * @param productconfigurationId ID of ProductConfiguration to check
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public canReadProductConfiguration(productconfigurationId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public canReadProductConfiguration(productconfigurationId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public canReadProductConfiguration(productconfigurationId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public canReadProductConfiguration(productconfigurationId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productconfigurationId === null || productconfigurationId === undefined) {
            throw new Error('Required parameter productconfigurationId was null or undefined when calling canReadProductConfiguration.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('head',`${this.basePath}/productconfigurations/${encodeURIComponent(String(productconfigurationId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Check if user can read specified ProductionMaterial
     * @param productionmaterialId ID of ProductionMaterial to check
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public canReadProductionMaterial(productionmaterialId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public canReadProductionMaterial(productionmaterialId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public canReadProductionMaterial(productionmaterialId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public canReadProductionMaterial(productionmaterialId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productionmaterialId === null || productionmaterialId === undefined) {
            throw new Error('Required parameter productionmaterialId was null or undefined when calling canReadProductionMaterial.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('head',`${this.basePath}/productionmaterials/${encodeURIComponent(String(productionmaterialId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Check if user can read specified PublishedConfigurator
     * @param publishedconfiguratorId ID of PublishedConfigurator to check
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public canReadPublishedConfigurator(publishedconfiguratorId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public canReadPublishedConfigurator(publishedconfiguratorId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public canReadPublishedConfigurator(publishedconfiguratorId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public canReadPublishedConfigurator(publishedconfiguratorId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (publishedconfiguratorId === null || publishedconfiguratorId === undefined) {
            throw new Error('Required parameter publishedconfiguratorId was null or undefined when calling canReadPublishedConfigurator.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('head',`${this.basePath}/publishedconfigurators/${encodeURIComponent(String(publishedconfiguratorId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Check if user can read specified Theme
     * @param themeId ID of Theme to check
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public canReadTheme(themeId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public canReadTheme(themeId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public canReadTheme(themeId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public canReadTheme(themeId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (themeId === null || themeId === undefined) {
            throw new Error('Required parameter themeId was null or undefined when calling canReadTheme.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('head',`${this.basePath}/themes/${encodeURIComponent(String(themeId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Check if user can read specified WorkBatch
     * @param workbatchId ID of WorkBatch to check
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public canReadWorkBatch(workbatchId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public canReadWorkBatch(workbatchId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public canReadWorkBatch(workbatchId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public canReadWorkBatch(workbatchId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workbatchId === null || workbatchId === undefined) {
            throw new Error('Required parameter workbatchId was null or undefined when calling canReadWorkBatch.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('head',`${this.basePath}/workbatches/${encodeURIComponent(String(workbatchId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Check if user can read specified Workflow
     * @param workflowId ID of Workflow to check
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public canReadWorkflow(workflowId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public canReadWorkflow(workflowId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public canReadWorkflow(workflowId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public canReadWorkflow(workflowId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workflowId === null || workflowId === undefined) {
            throw new Error('Required parameter workflowId was null or undefined when calling canReadWorkflow.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('head',`${this.basePath}/workflows/${encodeURIComponent(String(workflowId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Delete Appearance
     * @param appearanceId ID of Appearance to return
     * @param archive Archive, rather than remove, data
     * @param failIfUsed Fail if the document is referenced by another undeleted document
     * @param updateUsed Update affected documents
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteAppearance(appearanceId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedAppearancePatch>;
    public deleteAppearance(appearanceId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedAppearancePatch>>;
    public deleteAppearance(appearanceId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedAppearancePatch>>;
    public deleteAppearance(appearanceId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (appearanceId === null || appearanceId === undefined) {
            throw new Error('Required parameter appearanceId was null or undefined when calling deleteAppearance.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedAppearancePatch>('delete',`${this.basePath}/appearances/${encodeURIComponent(String(appearanceId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param appearanceId ID of Appearance
     * @param versionId Version of Appearance
     * @param archive Archive, rather than remove, data
     * @param failIfUsed Fail if the document is referenced by another undeleted document
     * @param updateUsed Update affected documents
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteAppearanceDisplacementMapImage(appearanceId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedArtefactPatch>;
    public deleteAppearanceDisplacementMapImage(appearanceId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedArtefactPatch>>;
    public deleteAppearanceDisplacementMapImage(appearanceId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedArtefactPatch>>;
    public deleteAppearanceDisplacementMapImage(appearanceId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (appearanceId === null || appearanceId === undefined) {
            throw new Error('Required parameter appearanceId was null or undefined when calling deleteAppearanceDisplacementMapImage.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling deleteAppearanceDisplacementMapImage.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedArtefactPatch>('delete',`${this.basePath}/appearances/${encodeURIComponent(String(appearanceId))}/v/${encodeURIComponent(String(versionId))}/displacementMap/image`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param appearanceId ID of Appearance
     * @param archive Archive, rather than remove, data
     * @param failIfUsed Fail if the document is referenced by another undeleted document
     * @param updateUsed Update affected documents
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteAppearanceHeadDisplacementMapImage(appearanceId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedArtefactPatch>;
    public deleteAppearanceHeadDisplacementMapImage(appearanceId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedArtefactPatch>>;
    public deleteAppearanceHeadDisplacementMapImage(appearanceId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedArtefactPatch>>;
    public deleteAppearanceHeadDisplacementMapImage(appearanceId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (appearanceId === null || appearanceId === undefined) {
            throw new Error('Required parameter appearanceId was null or undefined when calling deleteAppearanceHeadDisplacementMapImage.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedArtefactPatch>('delete',`${this.basePath}/appearances/${encodeURIComponent(String(appearanceId))}/displacementMap/image`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param appearanceId ID of Appearance
     * @param archive Archive, rather than remove, data
     * @param failIfUsed Fail if the document is referenced by another undeleted document
     * @param updateUsed Update affected documents
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteAppearanceHeadMapImage(appearanceId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedArtefactPatch>;
    public deleteAppearanceHeadMapImage(appearanceId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedArtefactPatch>>;
    public deleteAppearanceHeadMapImage(appearanceId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedArtefactPatch>>;
    public deleteAppearanceHeadMapImage(appearanceId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (appearanceId === null || appearanceId === undefined) {
            throw new Error('Required parameter appearanceId was null or undefined when calling deleteAppearanceHeadMapImage.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedArtefactPatch>('delete',`${this.basePath}/appearances/${encodeURIComponent(String(appearanceId))}/map/image`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param appearanceId ID of Appearance
     * @param versionId Version of Appearance
     * @param archive Archive, rather than remove, data
     * @param failIfUsed Fail if the document is referenced by another undeleted document
     * @param updateUsed Update affected documents
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteAppearanceMapImage(appearanceId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedArtefactPatch>;
    public deleteAppearanceMapImage(appearanceId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedArtefactPatch>>;
    public deleteAppearanceMapImage(appearanceId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedArtefactPatch>>;
    public deleteAppearanceMapImage(appearanceId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (appearanceId === null || appearanceId === undefined) {
            throw new Error('Required parameter appearanceId was null or undefined when calling deleteAppearanceMapImage.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling deleteAppearanceMapImage.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedArtefactPatch>('delete',`${this.basePath}/appearances/${encodeURIComponent(String(appearanceId))}/v/${encodeURIComponent(String(versionId))}/map/image`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Delete specific version of a Appearance
     * @param appearanceId ID of Appearance to delete version
     * @param versionId Version of Appearance to delete
     * @param archive Archive, rather than remove, data
     * @param failIfUsed Fail if the document is referenced by another undeleted document
     * @param updateUsed Update affected documents
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteAppearanceVersion(appearanceId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionGraph>;
    public deleteAppearanceVersion(appearanceId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionGraph>>;
    public deleteAppearanceVersion(appearanceId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionGraph>>;
    public deleteAppearanceVersion(appearanceId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (appearanceId === null || appearanceId === undefined) {
            throw new Error('Required parameter appearanceId was null or undefined when calling deleteAppearanceVersion.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling deleteAppearanceVersion.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionGraph>('delete',`${this.basePath}/appearances/${encodeURIComponent(String(appearanceId))}/v/${encodeURIComponent(String(versionId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Delete Company
     * @param companyId ID of Company to return
     * @param archive Archive, rather than remove, data
     * @param failIfUsed Fail if the document is referenced by another undeleted document
     * @param updateUsed Update affected documents
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteCompany(companyId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedCompanyPatch>;
    public deleteCompany(companyId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedCompanyPatch>>;
    public deleteCompany(companyId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedCompanyPatch>>;
    public deleteCompany(companyId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling deleteCompany.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedCompanyPatch>('delete',`${this.basePath}/companies/${encodeURIComponent(String(companyId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param companyId ID of Company
     * @param versionId Version of Company
     * @param archive Archive, rather than remove, data
     * @param failIfUsed Fail if the document is referenced by another undeleted document
     * @param updateUsed Update affected documents
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteCompanyAvatar(companyId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedArtefactPatch>;
    public deleteCompanyAvatar(companyId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedArtefactPatch>>;
    public deleteCompanyAvatar(companyId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedArtefactPatch>>;
    public deleteCompanyAvatar(companyId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling deleteCompanyAvatar.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling deleteCompanyAvatar.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedArtefactPatch>('delete',`${this.basePath}/companies/${encodeURIComponent(String(companyId))}/v/${encodeURIComponent(String(versionId))}/avatar`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param companyId ID of Company
     * @param archive Archive, rather than remove, data
     * @param failIfUsed Fail if the document is referenced by another undeleted document
     * @param updateUsed Update affected documents
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteCompanyHeadAvatar(companyId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedArtefactPatch>;
    public deleteCompanyHeadAvatar(companyId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedArtefactPatch>>;
    public deleteCompanyHeadAvatar(companyId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedArtefactPatch>>;
    public deleteCompanyHeadAvatar(companyId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling deleteCompanyHeadAvatar.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedArtefactPatch>('delete',`${this.basePath}/companies/${encodeURIComponent(String(companyId))}/avatar`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Delete specific version of a Company
     * @param companyId ID of Company to delete version
     * @param versionId Version of Company to delete
     * @param archive Archive, rather than remove, data
     * @param failIfUsed Fail if the document is referenced by another undeleted document
     * @param updateUsed Update affected documents
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteCompanyVersion(companyId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionGraph>;
    public deleteCompanyVersion(companyId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionGraph>>;
    public deleteCompanyVersion(companyId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionGraph>>;
    public deleteCompanyVersion(companyId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling deleteCompanyVersion.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling deleteCompanyVersion.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionGraph>('delete',`${this.basePath}/companies/${encodeURIComponent(String(companyId))}/v/${encodeURIComponent(String(versionId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Delete ConnectorType
     * @param connectortypeId ID of ConnectorType to return
     * @param archive Archive, rather than remove, data
     * @param failIfUsed Fail if the document is referenced by another undeleted document
     * @param updateUsed Update affected documents
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteConnectorType(connectortypeId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedConnectorTypePatch>;
    public deleteConnectorType(connectortypeId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedConnectorTypePatch>>;
    public deleteConnectorType(connectortypeId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedConnectorTypePatch>>;
    public deleteConnectorType(connectortypeId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (connectortypeId === null || connectortypeId === undefined) {
            throw new Error('Required parameter connectortypeId was null or undefined when calling deleteConnectorType.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedConnectorTypePatch>('delete',`${this.basePath}/connectortypes/${encodeURIComponent(String(connectortypeId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param connectortypeId ID of ConnectorType
     * @param versionId Version of ConnectorType
     * @param connectorsId 
     * @param partsId 
     * @param archive Archive, rather than remove, data
     * @param failIfUsed Fail if the document is referenced by another undeleted document
     * @param updateUsed Update affected documents
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteConnectorTypeConnectorsSourceAssemblyPartsGeometry(connectortypeId: string, versionId: string, connectorsId: string, partsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedPartGeometryPatch>;
    public deleteConnectorTypeConnectorsSourceAssemblyPartsGeometry(connectortypeId: string, versionId: string, connectorsId: string, partsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedPartGeometryPatch>>;
    public deleteConnectorTypeConnectorsSourceAssemblyPartsGeometry(connectortypeId: string, versionId: string, connectorsId: string, partsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedPartGeometryPatch>>;
    public deleteConnectorTypeConnectorsSourceAssemblyPartsGeometry(connectortypeId: string, versionId: string, connectorsId: string, partsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (connectortypeId === null || connectortypeId === undefined) {
            throw new Error('Required parameter connectortypeId was null or undefined when calling deleteConnectorTypeConnectorsSourceAssemblyPartsGeometry.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling deleteConnectorTypeConnectorsSourceAssemblyPartsGeometry.');
        }

        if (connectorsId === null || connectorsId === undefined) {
            throw new Error('Required parameter connectorsId was null or undefined when calling deleteConnectorTypeConnectorsSourceAssemblyPartsGeometry.');
        }

        if (partsId === null || partsId === undefined) {
            throw new Error('Required parameter partsId was null or undefined when calling deleteConnectorTypeConnectorsSourceAssemblyPartsGeometry.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedPartGeometryPatch>('delete',`${this.basePath}/connectortypes/${encodeURIComponent(String(connectortypeId))}/v/${encodeURIComponent(String(versionId))}/connectors/${encodeURIComponent(String(connectorsId))}/source/assembly/parts/${encodeURIComponent(String(partsId))}/geometry`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param connectortypeId ID of ConnectorType
     * @param versionId Version of ConnectorType
     * @param connectorsId 
     * @param subassembliesId 
     * @param partsId 
     * @param archive Archive, rather than remove, data
     * @param failIfUsed Fail if the document is referenced by another undeleted document
     * @param updateUsed Update affected documents
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteConnectorTypeConnectorsSourceAssemblySubassembliesPartsGeometry(connectortypeId: string, versionId: string, connectorsId: string, subassembliesId: string, partsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedPartGeometryPatch>;
    public deleteConnectorTypeConnectorsSourceAssemblySubassembliesPartsGeometry(connectortypeId: string, versionId: string, connectorsId: string, subassembliesId: string, partsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedPartGeometryPatch>>;
    public deleteConnectorTypeConnectorsSourceAssemblySubassembliesPartsGeometry(connectortypeId: string, versionId: string, connectorsId: string, subassembliesId: string, partsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedPartGeometryPatch>>;
    public deleteConnectorTypeConnectorsSourceAssemblySubassembliesPartsGeometry(connectortypeId: string, versionId: string, connectorsId: string, subassembliesId: string, partsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (connectortypeId === null || connectortypeId === undefined) {
            throw new Error('Required parameter connectortypeId was null or undefined when calling deleteConnectorTypeConnectorsSourceAssemblySubassembliesPartsGeometry.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling deleteConnectorTypeConnectorsSourceAssemblySubassembliesPartsGeometry.');
        }

        if (connectorsId === null || connectorsId === undefined) {
            throw new Error('Required parameter connectorsId was null or undefined when calling deleteConnectorTypeConnectorsSourceAssemblySubassembliesPartsGeometry.');
        }

        if (subassembliesId === null || subassembliesId === undefined) {
            throw new Error('Required parameter subassembliesId was null or undefined when calling deleteConnectorTypeConnectorsSourceAssemblySubassembliesPartsGeometry.');
        }

        if (partsId === null || partsId === undefined) {
            throw new Error('Required parameter partsId was null or undefined when calling deleteConnectorTypeConnectorsSourceAssemblySubassembliesPartsGeometry.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedPartGeometryPatch>('delete',`${this.basePath}/connectortypes/${encodeURIComponent(String(connectortypeId))}/v/${encodeURIComponent(String(versionId))}/connectors/${encodeURIComponent(String(connectorsId))}/source/assembly/subassemblies/${encodeURIComponent(String(subassembliesId))}/parts/${encodeURIComponent(String(partsId))}/geometry`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param connectortypeId ID of ConnectorType
     * @param versionId Version of ConnectorType
     * @param connectorsId 
     * @param partsId 
     * @param archive Archive, rather than remove, data
     * @param failIfUsed Fail if the document is referenced by another undeleted document
     * @param updateUsed Update affected documents
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteConnectorTypeConnectorsSourceFileAssemblyPartsGeometry(connectortypeId: string, versionId: string, connectorsId: string, partsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedPartGeometryPatch>;
    public deleteConnectorTypeConnectorsSourceFileAssemblyPartsGeometry(connectortypeId: string, versionId: string, connectorsId: string, partsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedPartGeometryPatch>>;
    public deleteConnectorTypeConnectorsSourceFileAssemblyPartsGeometry(connectortypeId: string, versionId: string, connectorsId: string, partsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedPartGeometryPatch>>;
    public deleteConnectorTypeConnectorsSourceFileAssemblyPartsGeometry(connectortypeId: string, versionId: string, connectorsId: string, partsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (connectortypeId === null || connectortypeId === undefined) {
            throw new Error('Required parameter connectortypeId was null or undefined when calling deleteConnectorTypeConnectorsSourceFileAssemblyPartsGeometry.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling deleteConnectorTypeConnectorsSourceFileAssemblyPartsGeometry.');
        }

        if (connectorsId === null || connectorsId === undefined) {
            throw new Error('Required parameter connectorsId was null or undefined when calling deleteConnectorTypeConnectorsSourceFileAssemblyPartsGeometry.');
        }

        if (partsId === null || partsId === undefined) {
            throw new Error('Required parameter partsId was null or undefined when calling deleteConnectorTypeConnectorsSourceFileAssemblyPartsGeometry.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedPartGeometryPatch>('delete',`${this.basePath}/connectortypes/${encodeURIComponent(String(connectortypeId))}/v/${encodeURIComponent(String(versionId))}/connectors/${encodeURIComponent(String(connectorsId))}/source/file/assembly/parts/${encodeURIComponent(String(partsId))}/geometry`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param connectortypeId ID of ConnectorType
     * @param versionId Version of ConnectorType
     * @param connectorsId 
     * @param subassembliesId 
     * @param partsId 
     * @param archive Archive, rather than remove, data
     * @param failIfUsed Fail if the document is referenced by another undeleted document
     * @param updateUsed Update affected documents
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteConnectorTypeConnectorsSourceFileAssemblySubassembliesPartsGeometry(connectortypeId: string, versionId: string, connectorsId: string, subassembliesId: string, partsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedPartGeometryPatch>;
    public deleteConnectorTypeConnectorsSourceFileAssemblySubassembliesPartsGeometry(connectortypeId: string, versionId: string, connectorsId: string, subassembliesId: string, partsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedPartGeometryPatch>>;
    public deleteConnectorTypeConnectorsSourceFileAssemblySubassembliesPartsGeometry(connectortypeId: string, versionId: string, connectorsId: string, subassembliesId: string, partsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedPartGeometryPatch>>;
    public deleteConnectorTypeConnectorsSourceFileAssemblySubassembliesPartsGeometry(connectortypeId: string, versionId: string, connectorsId: string, subassembliesId: string, partsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (connectortypeId === null || connectortypeId === undefined) {
            throw new Error('Required parameter connectortypeId was null or undefined when calling deleteConnectorTypeConnectorsSourceFileAssemblySubassembliesPartsGeometry.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling deleteConnectorTypeConnectorsSourceFileAssemblySubassembliesPartsGeometry.');
        }

        if (connectorsId === null || connectorsId === undefined) {
            throw new Error('Required parameter connectorsId was null or undefined when calling deleteConnectorTypeConnectorsSourceFileAssemblySubassembliesPartsGeometry.');
        }

        if (subassembliesId === null || subassembliesId === undefined) {
            throw new Error('Required parameter subassembliesId was null or undefined when calling deleteConnectorTypeConnectorsSourceFileAssemblySubassembliesPartsGeometry.');
        }

        if (partsId === null || partsId === undefined) {
            throw new Error('Required parameter partsId was null or undefined when calling deleteConnectorTypeConnectorsSourceFileAssemblySubassembliesPartsGeometry.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedPartGeometryPatch>('delete',`${this.basePath}/connectortypes/${encodeURIComponent(String(connectortypeId))}/v/${encodeURIComponent(String(versionId))}/connectors/${encodeURIComponent(String(connectorsId))}/source/file/assembly/subassemblies/${encodeURIComponent(String(subassembliesId))}/parts/${encodeURIComponent(String(partsId))}/geometry`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param connectortypeId ID of ConnectorType
     * @param versionId Version of ConnectorType
     * @param connectorsId 
     * @param archive Archive, rather than remove, data
     * @param failIfUsed Fail if the document is referenced by another undeleted document
     * @param updateUsed Update affected documents
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteConnectorTypeConnectorsSourceFileFile(connectortypeId: string, versionId: string, connectorsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedArtefactPatch>;
    public deleteConnectorTypeConnectorsSourceFileFile(connectortypeId: string, versionId: string, connectorsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedArtefactPatch>>;
    public deleteConnectorTypeConnectorsSourceFileFile(connectortypeId: string, versionId: string, connectorsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedArtefactPatch>>;
    public deleteConnectorTypeConnectorsSourceFileFile(connectortypeId: string, versionId: string, connectorsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (connectortypeId === null || connectortypeId === undefined) {
            throw new Error('Required parameter connectortypeId was null or undefined when calling deleteConnectorTypeConnectorsSourceFileFile.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling deleteConnectorTypeConnectorsSourceFileFile.');
        }

        if (connectorsId === null || connectorsId === undefined) {
            throw new Error('Required parameter connectorsId was null or undefined when calling deleteConnectorTypeConnectorsSourceFileFile.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedArtefactPatch>('delete',`${this.basePath}/connectortypes/${encodeURIComponent(String(connectortypeId))}/v/${encodeURIComponent(String(versionId))}/connectors/${encodeURIComponent(String(connectorsId))}/source/file/file`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param connectortypeId ID of ConnectorType
     * @param versionId Version of ConnectorType
     * @param connectorsId 
     * @param archive Archive, rather than remove, data
     * @param failIfUsed Fail if the document is referenced by another undeleted document
     * @param updateUsed Update affected documents
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteConnectorTypeConnectorsSourceImage(connectortypeId: string, versionId: string, connectorsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedArtefactPatch>;
    public deleteConnectorTypeConnectorsSourceImage(connectortypeId: string, versionId: string, connectorsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedArtefactPatch>>;
    public deleteConnectorTypeConnectorsSourceImage(connectortypeId: string, versionId: string, connectorsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedArtefactPatch>>;
    public deleteConnectorTypeConnectorsSourceImage(connectortypeId: string, versionId: string, connectorsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (connectortypeId === null || connectortypeId === undefined) {
            throw new Error('Required parameter connectortypeId was null or undefined when calling deleteConnectorTypeConnectorsSourceImage.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling deleteConnectorTypeConnectorsSourceImage.');
        }

        if (connectorsId === null || connectorsId === undefined) {
            throw new Error('Required parameter connectorsId was null or undefined when calling deleteConnectorTypeConnectorsSourceImage.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedArtefactPatch>('delete',`${this.basePath}/connectortypes/${encodeURIComponent(String(connectortypeId))}/v/${encodeURIComponent(String(versionId))}/connectors/${encodeURIComponent(String(connectorsId))}/source/image`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param connectortypeId ID of ConnectorType
     * @param versionId Version of ConnectorType
     * @param connectorsId 
     * @param archive Archive, rather than remove, data
     * @param failIfUsed Fail if the document is referenced by another undeleted document
     * @param updateUsed Update affected documents
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteConnectorTypeConnectorsSourceMaterial(connectortypeId: string, versionId: string, connectorsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedMaterialStylingPatch>;
    public deleteConnectorTypeConnectorsSourceMaterial(connectortypeId: string, versionId: string, connectorsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedMaterialStylingPatch>>;
    public deleteConnectorTypeConnectorsSourceMaterial(connectortypeId: string, versionId: string, connectorsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedMaterialStylingPatch>>;
    public deleteConnectorTypeConnectorsSourceMaterial(connectortypeId: string, versionId: string, connectorsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (connectortypeId === null || connectortypeId === undefined) {
            throw new Error('Required parameter connectortypeId was null or undefined when calling deleteConnectorTypeConnectorsSourceMaterial.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling deleteConnectorTypeConnectorsSourceMaterial.');
        }

        if (connectorsId === null || connectorsId === undefined) {
            throw new Error('Required parameter connectorsId was null or undefined when calling deleteConnectorTypeConnectorsSourceMaterial.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedMaterialStylingPatch>('delete',`${this.basePath}/connectortypes/${encodeURIComponent(String(connectortypeId))}/v/${encodeURIComponent(String(versionId))}/connectors/${encodeURIComponent(String(connectorsId))}/source/material`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param connectortypeId ID of ConnectorType
     * @param connectorsId 
     * @param partsId 
     * @param archive Archive, rather than remove, data
     * @param failIfUsed Fail if the document is referenced by another undeleted document
     * @param updateUsed Update affected documents
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteConnectorTypeHeadConnectorsSourceAssemblyPartsGeometry(connectortypeId: string, connectorsId: string, partsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedPartGeometryPatch>;
    public deleteConnectorTypeHeadConnectorsSourceAssemblyPartsGeometry(connectortypeId: string, connectorsId: string, partsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedPartGeometryPatch>>;
    public deleteConnectorTypeHeadConnectorsSourceAssemblyPartsGeometry(connectortypeId: string, connectorsId: string, partsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedPartGeometryPatch>>;
    public deleteConnectorTypeHeadConnectorsSourceAssemblyPartsGeometry(connectortypeId: string, connectorsId: string, partsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (connectortypeId === null || connectortypeId === undefined) {
            throw new Error('Required parameter connectortypeId was null or undefined when calling deleteConnectorTypeHeadConnectorsSourceAssemblyPartsGeometry.');
        }

        if (connectorsId === null || connectorsId === undefined) {
            throw new Error('Required parameter connectorsId was null or undefined when calling deleteConnectorTypeHeadConnectorsSourceAssemblyPartsGeometry.');
        }

        if (partsId === null || partsId === undefined) {
            throw new Error('Required parameter partsId was null or undefined when calling deleteConnectorTypeHeadConnectorsSourceAssemblyPartsGeometry.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedPartGeometryPatch>('delete',`${this.basePath}/connectortypes/${encodeURIComponent(String(connectortypeId))}/connectors/${encodeURIComponent(String(connectorsId))}/source/assembly/parts/${encodeURIComponent(String(partsId))}/geometry`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param connectortypeId ID of ConnectorType
     * @param connectorsId 
     * @param subassembliesId 
     * @param partsId 
     * @param archive Archive, rather than remove, data
     * @param failIfUsed Fail if the document is referenced by another undeleted document
     * @param updateUsed Update affected documents
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteConnectorTypeHeadConnectorsSourceAssemblySubassembliesPartsGeometry(connectortypeId: string, connectorsId: string, subassembliesId: string, partsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedPartGeometryPatch>;
    public deleteConnectorTypeHeadConnectorsSourceAssemblySubassembliesPartsGeometry(connectortypeId: string, connectorsId: string, subassembliesId: string, partsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedPartGeometryPatch>>;
    public deleteConnectorTypeHeadConnectorsSourceAssemblySubassembliesPartsGeometry(connectortypeId: string, connectorsId: string, subassembliesId: string, partsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedPartGeometryPatch>>;
    public deleteConnectorTypeHeadConnectorsSourceAssemblySubassembliesPartsGeometry(connectortypeId: string, connectorsId: string, subassembliesId: string, partsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (connectortypeId === null || connectortypeId === undefined) {
            throw new Error('Required parameter connectortypeId was null or undefined when calling deleteConnectorTypeHeadConnectorsSourceAssemblySubassembliesPartsGeometry.');
        }

        if (connectorsId === null || connectorsId === undefined) {
            throw new Error('Required parameter connectorsId was null or undefined when calling deleteConnectorTypeHeadConnectorsSourceAssemblySubassembliesPartsGeometry.');
        }

        if (subassembliesId === null || subassembliesId === undefined) {
            throw new Error('Required parameter subassembliesId was null or undefined when calling deleteConnectorTypeHeadConnectorsSourceAssemblySubassembliesPartsGeometry.');
        }

        if (partsId === null || partsId === undefined) {
            throw new Error('Required parameter partsId was null or undefined when calling deleteConnectorTypeHeadConnectorsSourceAssemblySubassembliesPartsGeometry.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedPartGeometryPatch>('delete',`${this.basePath}/connectortypes/${encodeURIComponent(String(connectortypeId))}/connectors/${encodeURIComponent(String(connectorsId))}/source/assembly/subassemblies/${encodeURIComponent(String(subassembliesId))}/parts/${encodeURIComponent(String(partsId))}/geometry`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param connectortypeId ID of ConnectorType
     * @param connectorsId 
     * @param partsId 
     * @param archive Archive, rather than remove, data
     * @param failIfUsed Fail if the document is referenced by another undeleted document
     * @param updateUsed Update affected documents
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteConnectorTypeHeadConnectorsSourceFileAssemblyPartsGeometry(connectortypeId: string, connectorsId: string, partsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedPartGeometryPatch>;
    public deleteConnectorTypeHeadConnectorsSourceFileAssemblyPartsGeometry(connectortypeId: string, connectorsId: string, partsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedPartGeometryPatch>>;
    public deleteConnectorTypeHeadConnectorsSourceFileAssemblyPartsGeometry(connectortypeId: string, connectorsId: string, partsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedPartGeometryPatch>>;
    public deleteConnectorTypeHeadConnectorsSourceFileAssemblyPartsGeometry(connectortypeId: string, connectorsId: string, partsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (connectortypeId === null || connectortypeId === undefined) {
            throw new Error('Required parameter connectortypeId was null or undefined when calling deleteConnectorTypeHeadConnectorsSourceFileAssemblyPartsGeometry.');
        }

        if (connectorsId === null || connectorsId === undefined) {
            throw new Error('Required parameter connectorsId was null or undefined when calling deleteConnectorTypeHeadConnectorsSourceFileAssemblyPartsGeometry.');
        }

        if (partsId === null || partsId === undefined) {
            throw new Error('Required parameter partsId was null or undefined when calling deleteConnectorTypeHeadConnectorsSourceFileAssemblyPartsGeometry.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedPartGeometryPatch>('delete',`${this.basePath}/connectortypes/${encodeURIComponent(String(connectortypeId))}/connectors/${encodeURIComponent(String(connectorsId))}/source/file/assembly/parts/${encodeURIComponent(String(partsId))}/geometry`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param connectortypeId ID of ConnectorType
     * @param connectorsId 
     * @param subassembliesId 
     * @param partsId 
     * @param archive Archive, rather than remove, data
     * @param failIfUsed Fail if the document is referenced by another undeleted document
     * @param updateUsed Update affected documents
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteConnectorTypeHeadConnectorsSourceFileAssemblySubassembliesPartsGeometry(connectortypeId: string, connectorsId: string, subassembliesId: string, partsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedPartGeometryPatch>;
    public deleteConnectorTypeHeadConnectorsSourceFileAssemblySubassembliesPartsGeometry(connectortypeId: string, connectorsId: string, subassembliesId: string, partsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedPartGeometryPatch>>;
    public deleteConnectorTypeHeadConnectorsSourceFileAssemblySubassembliesPartsGeometry(connectortypeId: string, connectorsId: string, subassembliesId: string, partsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedPartGeometryPatch>>;
    public deleteConnectorTypeHeadConnectorsSourceFileAssemblySubassembliesPartsGeometry(connectortypeId: string, connectorsId: string, subassembliesId: string, partsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (connectortypeId === null || connectortypeId === undefined) {
            throw new Error('Required parameter connectortypeId was null or undefined when calling deleteConnectorTypeHeadConnectorsSourceFileAssemblySubassembliesPartsGeometry.');
        }

        if (connectorsId === null || connectorsId === undefined) {
            throw new Error('Required parameter connectorsId was null or undefined when calling deleteConnectorTypeHeadConnectorsSourceFileAssemblySubassembliesPartsGeometry.');
        }

        if (subassembliesId === null || subassembliesId === undefined) {
            throw new Error('Required parameter subassembliesId was null or undefined when calling deleteConnectorTypeHeadConnectorsSourceFileAssemblySubassembliesPartsGeometry.');
        }

        if (partsId === null || partsId === undefined) {
            throw new Error('Required parameter partsId was null or undefined when calling deleteConnectorTypeHeadConnectorsSourceFileAssemblySubassembliesPartsGeometry.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedPartGeometryPatch>('delete',`${this.basePath}/connectortypes/${encodeURIComponent(String(connectortypeId))}/connectors/${encodeURIComponent(String(connectorsId))}/source/file/assembly/subassemblies/${encodeURIComponent(String(subassembliesId))}/parts/${encodeURIComponent(String(partsId))}/geometry`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param connectortypeId ID of ConnectorType
     * @param connectorsId 
     * @param archive Archive, rather than remove, data
     * @param failIfUsed Fail if the document is referenced by another undeleted document
     * @param updateUsed Update affected documents
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteConnectorTypeHeadConnectorsSourceFileFile(connectortypeId: string, connectorsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedArtefactPatch>;
    public deleteConnectorTypeHeadConnectorsSourceFileFile(connectortypeId: string, connectorsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedArtefactPatch>>;
    public deleteConnectorTypeHeadConnectorsSourceFileFile(connectortypeId: string, connectorsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedArtefactPatch>>;
    public deleteConnectorTypeHeadConnectorsSourceFileFile(connectortypeId: string, connectorsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (connectortypeId === null || connectortypeId === undefined) {
            throw new Error('Required parameter connectortypeId was null or undefined when calling deleteConnectorTypeHeadConnectorsSourceFileFile.');
        }

        if (connectorsId === null || connectorsId === undefined) {
            throw new Error('Required parameter connectorsId was null or undefined when calling deleteConnectorTypeHeadConnectorsSourceFileFile.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedArtefactPatch>('delete',`${this.basePath}/connectortypes/${encodeURIComponent(String(connectortypeId))}/connectors/${encodeURIComponent(String(connectorsId))}/source/file/file`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param connectortypeId ID of ConnectorType
     * @param connectorsId 
     * @param archive Archive, rather than remove, data
     * @param failIfUsed Fail if the document is referenced by another undeleted document
     * @param updateUsed Update affected documents
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteConnectorTypeHeadConnectorsSourceImage(connectortypeId: string, connectorsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedArtefactPatch>;
    public deleteConnectorTypeHeadConnectorsSourceImage(connectortypeId: string, connectorsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedArtefactPatch>>;
    public deleteConnectorTypeHeadConnectorsSourceImage(connectortypeId: string, connectorsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedArtefactPatch>>;
    public deleteConnectorTypeHeadConnectorsSourceImage(connectortypeId: string, connectorsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (connectortypeId === null || connectortypeId === undefined) {
            throw new Error('Required parameter connectortypeId was null or undefined when calling deleteConnectorTypeHeadConnectorsSourceImage.');
        }

        if (connectorsId === null || connectorsId === undefined) {
            throw new Error('Required parameter connectorsId was null or undefined when calling deleteConnectorTypeHeadConnectorsSourceImage.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedArtefactPatch>('delete',`${this.basePath}/connectortypes/${encodeURIComponent(String(connectortypeId))}/connectors/${encodeURIComponent(String(connectorsId))}/source/image`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param connectortypeId ID of ConnectorType
     * @param connectorsId 
     * @param archive Archive, rather than remove, data
     * @param failIfUsed Fail if the document is referenced by another undeleted document
     * @param updateUsed Update affected documents
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteConnectorTypeHeadConnectorsSourceMaterial(connectortypeId: string, connectorsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedMaterialStylingPatch>;
    public deleteConnectorTypeHeadConnectorsSourceMaterial(connectortypeId: string, connectorsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedMaterialStylingPatch>>;
    public deleteConnectorTypeHeadConnectorsSourceMaterial(connectortypeId: string, connectorsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedMaterialStylingPatch>>;
    public deleteConnectorTypeHeadConnectorsSourceMaterial(connectortypeId: string, connectorsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (connectortypeId === null || connectortypeId === undefined) {
            throw new Error('Required parameter connectortypeId was null or undefined when calling deleteConnectorTypeHeadConnectorsSourceMaterial.');
        }

        if (connectorsId === null || connectorsId === undefined) {
            throw new Error('Required parameter connectorsId was null or undefined when calling deleteConnectorTypeHeadConnectorsSourceMaterial.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedMaterialStylingPatch>('delete',`${this.basePath}/connectortypes/${encodeURIComponent(String(connectortypeId))}/connectors/${encodeURIComponent(String(connectorsId))}/source/material`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Delete specific version of a ConnectorType
     * @param connectortypeId ID of ConnectorType to delete version
     * @param versionId Version of ConnectorType to delete
     * @param archive Archive, rather than remove, data
     * @param failIfUsed Fail if the document is referenced by another undeleted document
     * @param updateUsed Update affected documents
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteConnectorTypeVersion(connectortypeId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionGraph>;
    public deleteConnectorTypeVersion(connectortypeId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionGraph>>;
    public deleteConnectorTypeVersion(connectortypeId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionGraph>>;
    public deleteConnectorTypeVersion(connectortypeId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (connectortypeId === null || connectortypeId === undefined) {
            throw new Error('Required parameter connectortypeId was null or undefined when calling deleteConnectorTypeVersion.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling deleteConnectorTypeVersion.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionGraph>('delete',`${this.basePath}/connectortypes/${encodeURIComponent(String(connectortypeId))}/v/${encodeURIComponent(String(versionId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Delete Module
     * @param moduleId ID of Module to return
     * @param archive Archive, rather than remove, data
     * @param failIfUsed Fail if the document is referenced by another undeleted document
     * @param updateUsed Update affected documents
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteModule(moduleId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedModulePatch>;
    public deleteModule(moduleId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedModulePatch>>;
    public deleteModule(moduleId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedModulePatch>>;
    public deleteModule(moduleId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (moduleId === null || moduleId === undefined) {
            throw new Error('Required parameter moduleId was null or undefined when calling deleteModule.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedModulePatch>('delete',`${this.basePath}/modules/${encodeURIComponent(String(moduleId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param moduleId ID of Module
     * @param partsId 
     * @param archive Archive, rather than remove, data
     * @param failIfUsed Fail if the document is referenced by another undeleted document
     * @param updateUsed Update affected documents
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteModuleHeadSourceAssemblyPartsGeometry(moduleId: string, partsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedPartGeometryPatch>;
    public deleteModuleHeadSourceAssemblyPartsGeometry(moduleId: string, partsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedPartGeometryPatch>>;
    public deleteModuleHeadSourceAssemblyPartsGeometry(moduleId: string, partsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedPartGeometryPatch>>;
    public deleteModuleHeadSourceAssemblyPartsGeometry(moduleId: string, partsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (moduleId === null || moduleId === undefined) {
            throw new Error('Required parameter moduleId was null or undefined when calling deleteModuleHeadSourceAssemblyPartsGeometry.');
        }

        if (partsId === null || partsId === undefined) {
            throw new Error('Required parameter partsId was null or undefined when calling deleteModuleHeadSourceAssemblyPartsGeometry.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedPartGeometryPatch>('delete',`${this.basePath}/modules/${encodeURIComponent(String(moduleId))}/source/assembly/parts/${encodeURIComponent(String(partsId))}/geometry`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param moduleId ID of Module
     * @param subassembliesId 
     * @param partsId 
     * @param archive Archive, rather than remove, data
     * @param failIfUsed Fail if the document is referenced by another undeleted document
     * @param updateUsed Update affected documents
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteModuleHeadSourceAssemblySubassembliesPartsGeometry(moduleId: string, subassembliesId: string, partsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedPartGeometryPatch>;
    public deleteModuleHeadSourceAssemblySubassembliesPartsGeometry(moduleId: string, subassembliesId: string, partsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedPartGeometryPatch>>;
    public deleteModuleHeadSourceAssemblySubassembliesPartsGeometry(moduleId: string, subassembliesId: string, partsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedPartGeometryPatch>>;
    public deleteModuleHeadSourceAssemblySubassembliesPartsGeometry(moduleId: string, subassembliesId: string, partsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (moduleId === null || moduleId === undefined) {
            throw new Error('Required parameter moduleId was null or undefined when calling deleteModuleHeadSourceAssemblySubassembliesPartsGeometry.');
        }

        if (subassembliesId === null || subassembliesId === undefined) {
            throw new Error('Required parameter subassembliesId was null or undefined when calling deleteModuleHeadSourceAssemblySubassembliesPartsGeometry.');
        }

        if (partsId === null || partsId === undefined) {
            throw new Error('Required parameter partsId was null or undefined when calling deleteModuleHeadSourceAssemblySubassembliesPartsGeometry.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedPartGeometryPatch>('delete',`${this.basePath}/modules/${encodeURIComponent(String(moduleId))}/source/assembly/subassemblies/${encodeURIComponent(String(subassembliesId))}/parts/${encodeURIComponent(String(partsId))}/geometry`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param moduleId ID of Module
     * @param partsId 
     * @param archive Archive, rather than remove, data
     * @param failIfUsed Fail if the document is referenced by another undeleted document
     * @param updateUsed Update affected documents
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteModuleHeadSourceFileAssemblyPartsGeometry(moduleId: string, partsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedPartGeometryPatch>;
    public deleteModuleHeadSourceFileAssemblyPartsGeometry(moduleId: string, partsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedPartGeometryPatch>>;
    public deleteModuleHeadSourceFileAssemblyPartsGeometry(moduleId: string, partsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedPartGeometryPatch>>;
    public deleteModuleHeadSourceFileAssemblyPartsGeometry(moduleId: string, partsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (moduleId === null || moduleId === undefined) {
            throw new Error('Required parameter moduleId was null or undefined when calling deleteModuleHeadSourceFileAssemblyPartsGeometry.');
        }

        if (partsId === null || partsId === undefined) {
            throw new Error('Required parameter partsId was null or undefined when calling deleteModuleHeadSourceFileAssemblyPartsGeometry.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedPartGeometryPatch>('delete',`${this.basePath}/modules/${encodeURIComponent(String(moduleId))}/source/file/assembly/parts/${encodeURIComponent(String(partsId))}/geometry`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param moduleId ID of Module
     * @param subassembliesId 
     * @param partsId 
     * @param archive Archive, rather than remove, data
     * @param failIfUsed Fail if the document is referenced by another undeleted document
     * @param updateUsed Update affected documents
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteModuleHeadSourceFileAssemblySubassembliesPartsGeometry(moduleId: string, subassembliesId: string, partsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedPartGeometryPatch>;
    public deleteModuleHeadSourceFileAssemblySubassembliesPartsGeometry(moduleId: string, subassembliesId: string, partsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedPartGeometryPatch>>;
    public deleteModuleHeadSourceFileAssemblySubassembliesPartsGeometry(moduleId: string, subassembliesId: string, partsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedPartGeometryPatch>>;
    public deleteModuleHeadSourceFileAssemblySubassembliesPartsGeometry(moduleId: string, subassembliesId: string, partsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (moduleId === null || moduleId === undefined) {
            throw new Error('Required parameter moduleId was null or undefined when calling deleteModuleHeadSourceFileAssemblySubassembliesPartsGeometry.');
        }

        if (subassembliesId === null || subassembliesId === undefined) {
            throw new Error('Required parameter subassembliesId was null or undefined when calling deleteModuleHeadSourceFileAssemblySubassembliesPartsGeometry.');
        }

        if (partsId === null || partsId === undefined) {
            throw new Error('Required parameter partsId was null or undefined when calling deleteModuleHeadSourceFileAssemblySubassembliesPartsGeometry.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedPartGeometryPatch>('delete',`${this.basePath}/modules/${encodeURIComponent(String(moduleId))}/source/file/assembly/subassemblies/${encodeURIComponent(String(subassembliesId))}/parts/${encodeURIComponent(String(partsId))}/geometry`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param moduleId ID of Module
     * @param archive Archive, rather than remove, data
     * @param failIfUsed Fail if the document is referenced by another undeleted document
     * @param updateUsed Update affected documents
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteModuleHeadSourceFileFile(moduleId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedArtefactPatch>;
    public deleteModuleHeadSourceFileFile(moduleId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedArtefactPatch>>;
    public deleteModuleHeadSourceFileFile(moduleId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedArtefactPatch>>;
    public deleteModuleHeadSourceFileFile(moduleId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (moduleId === null || moduleId === undefined) {
            throw new Error('Required parameter moduleId was null or undefined when calling deleteModuleHeadSourceFileFile.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedArtefactPatch>('delete',`${this.basePath}/modules/${encodeURIComponent(String(moduleId))}/source/file/file`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param moduleId ID of Module
     * @param archive Archive, rather than remove, data
     * @param failIfUsed Fail if the document is referenced by another undeleted document
     * @param updateUsed Update affected documents
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteModuleHeadSourceImage(moduleId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedArtefactPatch>;
    public deleteModuleHeadSourceImage(moduleId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedArtefactPatch>>;
    public deleteModuleHeadSourceImage(moduleId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedArtefactPatch>>;
    public deleteModuleHeadSourceImage(moduleId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (moduleId === null || moduleId === undefined) {
            throw new Error('Required parameter moduleId was null or undefined when calling deleteModuleHeadSourceImage.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedArtefactPatch>('delete',`${this.basePath}/modules/${encodeURIComponent(String(moduleId))}/source/image`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param moduleId ID of Module
     * @param archive Archive, rather than remove, data
     * @param failIfUsed Fail if the document is referenced by another undeleted document
     * @param updateUsed Update affected documents
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteModuleHeadSourceMaterial(moduleId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedMaterialStylingPatch>;
    public deleteModuleHeadSourceMaterial(moduleId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedMaterialStylingPatch>>;
    public deleteModuleHeadSourceMaterial(moduleId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedMaterialStylingPatch>>;
    public deleteModuleHeadSourceMaterial(moduleId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (moduleId === null || moduleId === undefined) {
            throw new Error('Required parameter moduleId was null or undefined when calling deleteModuleHeadSourceMaterial.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedMaterialStylingPatch>('delete',`${this.basePath}/modules/${encodeURIComponent(String(moduleId))}/source/material`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param moduleId ID of Module
     * @param versionId Version of Module
     * @param partsId 
     * @param archive Archive, rather than remove, data
     * @param failIfUsed Fail if the document is referenced by another undeleted document
     * @param updateUsed Update affected documents
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteModuleSourceAssemblyPartsGeometry(moduleId: string, versionId: string, partsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedPartGeometryPatch>;
    public deleteModuleSourceAssemblyPartsGeometry(moduleId: string, versionId: string, partsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedPartGeometryPatch>>;
    public deleteModuleSourceAssemblyPartsGeometry(moduleId: string, versionId: string, partsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedPartGeometryPatch>>;
    public deleteModuleSourceAssemblyPartsGeometry(moduleId: string, versionId: string, partsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (moduleId === null || moduleId === undefined) {
            throw new Error('Required parameter moduleId was null or undefined when calling deleteModuleSourceAssemblyPartsGeometry.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling deleteModuleSourceAssemblyPartsGeometry.');
        }

        if (partsId === null || partsId === undefined) {
            throw new Error('Required parameter partsId was null or undefined when calling deleteModuleSourceAssemblyPartsGeometry.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedPartGeometryPatch>('delete',`${this.basePath}/modules/${encodeURIComponent(String(moduleId))}/v/${encodeURIComponent(String(versionId))}/source/assembly/parts/${encodeURIComponent(String(partsId))}/geometry`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param moduleId ID of Module
     * @param versionId Version of Module
     * @param subassembliesId 
     * @param partsId 
     * @param archive Archive, rather than remove, data
     * @param failIfUsed Fail if the document is referenced by another undeleted document
     * @param updateUsed Update affected documents
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteModuleSourceAssemblySubassembliesPartsGeometry(moduleId: string, versionId: string, subassembliesId: string, partsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedPartGeometryPatch>;
    public deleteModuleSourceAssemblySubassembliesPartsGeometry(moduleId: string, versionId: string, subassembliesId: string, partsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedPartGeometryPatch>>;
    public deleteModuleSourceAssemblySubassembliesPartsGeometry(moduleId: string, versionId: string, subassembliesId: string, partsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedPartGeometryPatch>>;
    public deleteModuleSourceAssemblySubassembliesPartsGeometry(moduleId: string, versionId: string, subassembliesId: string, partsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (moduleId === null || moduleId === undefined) {
            throw new Error('Required parameter moduleId was null or undefined when calling deleteModuleSourceAssemblySubassembliesPartsGeometry.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling deleteModuleSourceAssemblySubassembliesPartsGeometry.');
        }

        if (subassembliesId === null || subassembliesId === undefined) {
            throw new Error('Required parameter subassembliesId was null or undefined when calling deleteModuleSourceAssemblySubassembliesPartsGeometry.');
        }

        if (partsId === null || partsId === undefined) {
            throw new Error('Required parameter partsId was null or undefined when calling deleteModuleSourceAssemblySubassembliesPartsGeometry.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedPartGeometryPatch>('delete',`${this.basePath}/modules/${encodeURIComponent(String(moduleId))}/v/${encodeURIComponent(String(versionId))}/source/assembly/subassemblies/${encodeURIComponent(String(subassembliesId))}/parts/${encodeURIComponent(String(partsId))}/geometry`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param moduleId ID of Module
     * @param versionId Version of Module
     * @param partsId 
     * @param archive Archive, rather than remove, data
     * @param failIfUsed Fail if the document is referenced by another undeleted document
     * @param updateUsed Update affected documents
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteModuleSourceFileAssemblyPartsGeometry(moduleId: string, versionId: string, partsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedPartGeometryPatch>;
    public deleteModuleSourceFileAssemblyPartsGeometry(moduleId: string, versionId: string, partsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedPartGeometryPatch>>;
    public deleteModuleSourceFileAssemblyPartsGeometry(moduleId: string, versionId: string, partsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedPartGeometryPatch>>;
    public deleteModuleSourceFileAssemblyPartsGeometry(moduleId: string, versionId: string, partsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (moduleId === null || moduleId === undefined) {
            throw new Error('Required parameter moduleId was null or undefined when calling deleteModuleSourceFileAssemblyPartsGeometry.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling deleteModuleSourceFileAssemblyPartsGeometry.');
        }

        if (partsId === null || partsId === undefined) {
            throw new Error('Required parameter partsId was null or undefined when calling deleteModuleSourceFileAssemblyPartsGeometry.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedPartGeometryPatch>('delete',`${this.basePath}/modules/${encodeURIComponent(String(moduleId))}/v/${encodeURIComponent(String(versionId))}/source/file/assembly/parts/${encodeURIComponent(String(partsId))}/geometry`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param moduleId ID of Module
     * @param versionId Version of Module
     * @param subassembliesId 
     * @param partsId 
     * @param archive Archive, rather than remove, data
     * @param failIfUsed Fail if the document is referenced by another undeleted document
     * @param updateUsed Update affected documents
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteModuleSourceFileAssemblySubassembliesPartsGeometry(moduleId: string, versionId: string, subassembliesId: string, partsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedPartGeometryPatch>;
    public deleteModuleSourceFileAssemblySubassembliesPartsGeometry(moduleId: string, versionId: string, subassembliesId: string, partsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedPartGeometryPatch>>;
    public deleteModuleSourceFileAssemblySubassembliesPartsGeometry(moduleId: string, versionId: string, subassembliesId: string, partsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedPartGeometryPatch>>;
    public deleteModuleSourceFileAssemblySubassembliesPartsGeometry(moduleId: string, versionId: string, subassembliesId: string, partsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (moduleId === null || moduleId === undefined) {
            throw new Error('Required parameter moduleId was null or undefined when calling deleteModuleSourceFileAssemblySubassembliesPartsGeometry.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling deleteModuleSourceFileAssemblySubassembliesPartsGeometry.');
        }

        if (subassembliesId === null || subassembliesId === undefined) {
            throw new Error('Required parameter subassembliesId was null or undefined when calling deleteModuleSourceFileAssemblySubassembliesPartsGeometry.');
        }

        if (partsId === null || partsId === undefined) {
            throw new Error('Required parameter partsId was null or undefined when calling deleteModuleSourceFileAssemblySubassembliesPartsGeometry.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedPartGeometryPatch>('delete',`${this.basePath}/modules/${encodeURIComponent(String(moduleId))}/v/${encodeURIComponent(String(versionId))}/source/file/assembly/subassemblies/${encodeURIComponent(String(subassembliesId))}/parts/${encodeURIComponent(String(partsId))}/geometry`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param moduleId ID of Module
     * @param versionId Version of Module
     * @param archive Archive, rather than remove, data
     * @param failIfUsed Fail if the document is referenced by another undeleted document
     * @param updateUsed Update affected documents
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteModuleSourceFileFile(moduleId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedArtefactPatch>;
    public deleteModuleSourceFileFile(moduleId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedArtefactPatch>>;
    public deleteModuleSourceFileFile(moduleId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedArtefactPatch>>;
    public deleteModuleSourceFileFile(moduleId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (moduleId === null || moduleId === undefined) {
            throw new Error('Required parameter moduleId was null or undefined when calling deleteModuleSourceFileFile.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling deleteModuleSourceFileFile.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedArtefactPatch>('delete',`${this.basePath}/modules/${encodeURIComponent(String(moduleId))}/v/${encodeURIComponent(String(versionId))}/source/file/file`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param moduleId ID of Module
     * @param versionId Version of Module
     * @param archive Archive, rather than remove, data
     * @param failIfUsed Fail if the document is referenced by another undeleted document
     * @param updateUsed Update affected documents
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteModuleSourceImage(moduleId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedArtefactPatch>;
    public deleteModuleSourceImage(moduleId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedArtefactPatch>>;
    public deleteModuleSourceImage(moduleId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedArtefactPatch>>;
    public deleteModuleSourceImage(moduleId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (moduleId === null || moduleId === undefined) {
            throw new Error('Required parameter moduleId was null or undefined when calling deleteModuleSourceImage.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling deleteModuleSourceImage.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedArtefactPatch>('delete',`${this.basePath}/modules/${encodeURIComponent(String(moduleId))}/v/${encodeURIComponent(String(versionId))}/source/image`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param moduleId ID of Module
     * @param versionId Version of Module
     * @param archive Archive, rather than remove, data
     * @param failIfUsed Fail if the document is referenced by another undeleted document
     * @param updateUsed Update affected documents
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteModuleSourceMaterial(moduleId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedMaterialStylingPatch>;
    public deleteModuleSourceMaterial(moduleId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedMaterialStylingPatch>>;
    public deleteModuleSourceMaterial(moduleId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedMaterialStylingPatch>>;
    public deleteModuleSourceMaterial(moduleId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (moduleId === null || moduleId === undefined) {
            throw new Error('Required parameter moduleId was null or undefined when calling deleteModuleSourceMaterial.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling deleteModuleSourceMaterial.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedMaterialStylingPatch>('delete',`${this.basePath}/modules/${encodeURIComponent(String(moduleId))}/v/${encodeURIComponent(String(versionId))}/source/material`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Delete specific version of a Module
     * @param moduleId ID of Module to delete version
     * @param versionId Version of Module to delete
     * @param archive Archive, rather than remove, data
     * @param failIfUsed Fail if the document is referenced by another undeleted document
     * @param updateUsed Update affected documents
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteModuleVersion(moduleId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionGraph>;
    public deleteModuleVersion(moduleId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionGraph>>;
    public deleteModuleVersion(moduleId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionGraph>>;
    public deleteModuleVersion(moduleId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (moduleId === null || moduleId === undefined) {
            throw new Error('Required parameter moduleId was null or undefined when calling deleteModuleVersion.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling deleteModuleVersion.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionGraph>('delete',`${this.basePath}/modules/${encodeURIComponent(String(moduleId))}/v/${encodeURIComponent(String(versionId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Delete OfflineAction
     * @param offlineactionId ID of OfflineAction to return
     * @param archive Archive, rather than remove, data
     * @param failIfUsed Fail if the document is referenced by another undeleted document
     * @param updateUsed Update affected documents
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteOfflineAction(offlineactionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedOfflineActionPatch>;
    public deleteOfflineAction(offlineactionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedOfflineActionPatch>>;
    public deleteOfflineAction(offlineactionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedOfflineActionPatch>>;
    public deleteOfflineAction(offlineactionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (offlineactionId === null || offlineactionId === undefined) {
            throw new Error('Required parameter offlineactionId was null or undefined when calling deleteOfflineAction.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedOfflineActionPatch>('delete',`${this.basePath}/offlineactions/${encodeURIComponent(String(offlineactionId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Delete specific version of a OfflineAction
     * @param offlineactionId ID of OfflineAction to delete version
     * @param versionId Version of OfflineAction to delete
     * @param archive Archive, rather than remove, data
     * @param failIfUsed Fail if the document is referenced by another undeleted document
     * @param updateUsed Update affected documents
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteOfflineActionVersion(offlineactionId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionGraph>;
    public deleteOfflineActionVersion(offlineactionId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionGraph>>;
    public deleteOfflineActionVersion(offlineactionId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionGraph>>;
    public deleteOfflineActionVersion(offlineactionId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (offlineactionId === null || offlineactionId === undefined) {
            throw new Error('Required parameter offlineactionId was null or undefined when calling deleteOfflineActionVersion.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling deleteOfflineActionVersion.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionGraph>('delete',`${this.basePath}/offlineactions/${encodeURIComponent(String(offlineactionId))}/v/${encodeURIComponent(String(versionId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Delete Order
     * @param orderId ID of Order to return
     * @param archive Archive, rather than remove, data
     * @param failIfUsed Fail if the document is referenced by another undeleted document
     * @param updateUsed Update affected documents
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteOrder(orderId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedOrderPatch>;
    public deleteOrder(orderId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedOrderPatch>>;
    public deleteOrder(orderId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedOrderPatch>>;
    public deleteOrder(orderId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (orderId === null || orderId === undefined) {
            throw new Error('Required parameter orderId was null or undefined when calling deleteOrder.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedOrderPatch>('delete',`${this.basePath}/orders/${encodeURIComponent(String(orderId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Delete specific version of a Order
     * @param orderId ID of Order to delete version
     * @param versionId Version of Order to delete
     * @param archive Archive, rather than remove, data
     * @param failIfUsed Fail if the document is referenced by another undeleted document
     * @param updateUsed Update affected documents
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteOrderVersion(orderId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionGraph>;
    public deleteOrderVersion(orderId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionGraph>>;
    public deleteOrderVersion(orderId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionGraph>>;
    public deleteOrderVersion(orderId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (orderId === null || orderId === undefined) {
            throw new Error('Required parameter orderId was null or undefined when calling deleteOrderVersion.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling deleteOrderVersion.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionGraph>('delete',`${this.basePath}/orders/${encodeURIComponent(String(orderId))}/v/${encodeURIComponent(String(versionId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Delete Population
     * @param populationId ID of Population to return
     * @param archive Archive, rather than remove, data
     * @param failIfUsed Fail if the document is referenced by another undeleted document
     * @param updateUsed Update affected documents
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deletePopulation(populationId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedPopulationPatch>;
    public deletePopulation(populationId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedPopulationPatch>>;
    public deletePopulation(populationId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedPopulationPatch>>;
    public deletePopulation(populationId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (populationId === null || populationId === undefined) {
            throw new Error('Required parameter populationId was null or undefined when calling deletePopulation.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedPopulationPatch>('delete',`${this.basePath}/populations/${encodeURIComponent(String(populationId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Delete specific version of a Population
     * @param populationId ID of Population to delete version
     * @param versionId Version of Population to delete
     * @param archive Archive, rather than remove, data
     * @param failIfUsed Fail if the document is referenced by another undeleted document
     * @param updateUsed Update affected documents
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deletePopulationVersion(populationId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionGraph>;
    public deletePopulationVersion(populationId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionGraph>>;
    public deletePopulationVersion(populationId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionGraph>>;
    public deletePopulationVersion(populationId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (populationId === null || populationId === undefined) {
            throw new Error('Required parameter populationId was null or undefined when calling deletePopulationVersion.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling deletePopulationVersion.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionGraph>('delete',`${this.basePath}/populations/${encodeURIComponent(String(populationId))}/v/${encodeURIComponent(String(versionId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Delete Product
     * @param productId ID of Product to return
     * @param archive Archive, rather than remove, data
     * @param failIfUsed Fail if the document is referenced by another undeleted document
     * @param updateUsed Update affected documents
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteProduct(productId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedProductPatch>;
    public deleteProduct(productId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedProductPatch>>;
    public deleteProduct(productId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedProductPatch>>;
    public deleteProduct(productId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productId === null || productId === undefined) {
            throw new Error('Required parameter productId was null or undefined when calling deleteProduct.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedProductPatch>('delete',`${this.basePath}/products/${encodeURIComponent(String(productId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Delete ProductConfiguration
     * @param productconfigurationId ID of ProductConfiguration to return
     * @param archive Archive, rather than remove, data
     * @param failIfUsed Fail if the document is referenced by another undeleted document
     * @param updateUsed Update affected documents
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteProductConfiguration(productconfigurationId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedProductConfigurationPatch>;
    public deleteProductConfiguration(productconfigurationId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedProductConfigurationPatch>>;
    public deleteProductConfiguration(productconfigurationId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedProductConfigurationPatch>>;
    public deleteProductConfiguration(productconfigurationId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productconfigurationId === null || productconfigurationId === undefined) {
            throw new Error('Required parameter productconfigurationId was null or undefined when calling deleteProductConfiguration.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedProductConfigurationPatch>('delete',`${this.basePath}/productconfigurations/${encodeURIComponent(String(productconfigurationId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Delete specific version of a ProductConfiguration
     * @param productconfigurationId ID of ProductConfiguration to delete version
     * @param versionId Version of ProductConfiguration to delete
     * @param archive Archive, rather than remove, data
     * @param failIfUsed Fail if the document is referenced by another undeleted document
     * @param updateUsed Update affected documents
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteProductConfigurationVersion(productconfigurationId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionGraph>;
    public deleteProductConfigurationVersion(productconfigurationId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionGraph>>;
    public deleteProductConfigurationVersion(productconfigurationId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionGraph>>;
    public deleteProductConfigurationVersion(productconfigurationId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productconfigurationId === null || productconfigurationId === undefined) {
            throw new Error('Required parameter productconfigurationId was null or undefined when calling deleteProductConfigurationVersion.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling deleteProductConfigurationVersion.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionGraph>('delete',`${this.basePath}/productconfigurations/${encodeURIComponent(String(productconfigurationId))}/v/${encodeURIComponent(String(versionId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param productId ID of Product
     * @param groupsId 
     * @param archive Archive, rather than remove, data
     * @param failIfUsed Fail if the document is referenced by another undeleted document
     * @param updateUsed Update affected documents
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteProductHeadProductionDataGroupsSheetResultPlacementCandidates(productId: string, groupsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedLargeObjectPatch>;
    public deleteProductHeadProductionDataGroupsSheetResultPlacementCandidates(productId: string, groupsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedLargeObjectPatch>>;
    public deleteProductHeadProductionDataGroupsSheetResultPlacementCandidates(productId: string, groupsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedLargeObjectPatch>>;
    public deleteProductHeadProductionDataGroupsSheetResultPlacementCandidates(productId: string, groupsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productId === null || productId === undefined) {
            throw new Error('Required parameter productId was null or undefined when calling deleteProductHeadProductionDataGroupsSheetResultPlacementCandidates.');
        }

        if (groupsId === null || groupsId === undefined) {
            throw new Error('Required parameter groupsId was null or undefined when calling deleteProductHeadProductionDataGroupsSheetResultPlacementCandidates.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedLargeObjectPatch>('delete',`${this.basePath}/products/${encodeURIComponent(String(productId))}/productionData/groups/${encodeURIComponent(String(groupsId))}/sheetResult/placementCandidates`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param productId ID of Product
     * @param versionId Version of Product
     * @param groupsId 
     * @param archive Archive, rather than remove, data
     * @param failIfUsed Fail if the document is referenced by another undeleted document
     * @param updateUsed Update affected documents
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteProductProductionDataGroupsSheetResultPlacementCandidates(productId: string, versionId: string, groupsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedLargeObjectPatch>;
    public deleteProductProductionDataGroupsSheetResultPlacementCandidates(productId: string, versionId: string, groupsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedLargeObjectPatch>>;
    public deleteProductProductionDataGroupsSheetResultPlacementCandidates(productId: string, versionId: string, groupsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedLargeObjectPatch>>;
    public deleteProductProductionDataGroupsSheetResultPlacementCandidates(productId: string, versionId: string, groupsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productId === null || productId === undefined) {
            throw new Error('Required parameter productId was null or undefined when calling deleteProductProductionDataGroupsSheetResultPlacementCandidates.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling deleteProductProductionDataGroupsSheetResultPlacementCandidates.');
        }

        if (groupsId === null || groupsId === undefined) {
            throw new Error('Required parameter groupsId was null or undefined when calling deleteProductProductionDataGroupsSheetResultPlacementCandidates.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedLargeObjectPatch>('delete',`${this.basePath}/products/${encodeURIComponent(String(productId))}/v/${encodeURIComponent(String(versionId))}/productionData/groups/${encodeURIComponent(String(groupsId))}/sheetResult/placementCandidates`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Delete specific version of a Product
     * @param productId ID of Product to delete version
     * @param versionId Version of Product to delete
     * @param archive Archive, rather than remove, data
     * @param failIfUsed Fail if the document is referenced by another undeleted document
     * @param updateUsed Update affected documents
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteProductVersion(productId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionGraph>;
    public deleteProductVersion(productId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionGraph>>;
    public deleteProductVersion(productId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionGraph>>;
    public deleteProductVersion(productId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productId === null || productId === undefined) {
            throw new Error('Required parameter productId was null or undefined when calling deleteProductVersion.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling deleteProductVersion.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionGraph>('delete',`${this.basePath}/products/${encodeURIComponent(String(productId))}/v/${encodeURIComponent(String(versionId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Delete ProductionMaterial
     * @param productionmaterialId ID of ProductionMaterial to return
     * @param archive Archive, rather than remove, data
     * @param failIfUsed Fail if the document is referenced by another undeleted document
     * @param updateUsed Update affected documents
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteProductionMaterial(productionmaterialId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedProductionMaterialPatch>;
    public deleteProductionMaterial(productionmaterialId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedProductionMaterialPatch>>;
    public deleteProductionMaterial(productionmaterialId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedProductionMaterialPatch>>;
    public deleteProductionMaterial(productionmaterialId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productionmaterialId === null || productionmaterialId === undefined) {
            throw new Error('Required parameter productionmaterialId was null or undefined when calling deleteProductionMaterial.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedProductionMaterialPatch>('delete',`${this.basePath}/productionmaterials/${encodeURIComponent(String(productionmaterialId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Delete specific version of a ProductionMaterial
     * @param productionmaterialId ID of ProductionMaterial to delete version
     * @param versionId Version of ProductionMaterial to delete
     * @param archive Archive, rather than remove, data
     * @param failIfUsed Fail if the document is referenced by another undeleted document
     * @param updateUsed Update affected documents
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteProductionMaterialVersion(productionmaterialId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionGraph>;
    public deleteProductionMaterialVersion(productionmaterialId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionGraph>>;
    public deleteProductionMaterialVersion(productionmaterialId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionGraph>>;
    public deleteProductionMaterialVersion(productionmaterialId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productionmaterialId === null || productionmaterialId === undefined) {
            throw new Error('Required parameter productionmaterialId was null or undefined when calling deleteProductionMaterialVersion.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling deleteProductionMaterialVersion.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionGraph>('delete',`${this.basePath}/productionmaterials/${encodeURIComponent(String(productionmaterialId))}/v/${encodeURIComponent(String(versionId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Delete PublishedConfigurator
     * @param publishedconfiguratorId ID of PublishedConfigurator to return
     * @param archive Archive, rather than remove, data
     * @param failIfUsed Fail if the document is referenced by another undeleted document
     * @param updateUsed Update affected documents
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deletePublishedConfigurator(publishedconfiguratorId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedPublishedConfiguratorPatch>;
    public deletePublishedConfigurator(publishedconfiguratorId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedPublishedConfiguratorPatch>>;
    public deletePublishedConfigurator(publishedconfiguratorId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedPublishedConfiguratorPatch>>;
    public deletePublishedConfigurator(publishedconfiguratorId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (publishedconfiguratorId === null || publishedconfiguratorId === undefined) {
            throw new Error('Required parameter publishedconfiguratorId was null or undefined when calling deletePublishedConfigurator.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedPublishedConfiguratorPatch>('delete',`${this.basePath}/publishedconfigurators/${encodeURIComponent(String(publishedconfiguratorId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Delete specific version of a PublishedConfigurator
     * @param publishedconfiguratorId ID of PublishedConfigurator to delete version
     * @param versionId Version of PublishedConfigurator to delete
     * @param archive Archive, rather than remove, data
     * @param failIfUsed Fail if the document is referenced by another undeleted document
     * @param updateUsed Update affected documents
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deletePublishedConfiguratorVersion(publishedconfiguratorId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionGraph>;
    public deletePublishedConfiguratorVersion(publishedconfiguratorId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionGraph>>;
    public deletePublishedConfiguratorVersion(publishedconfiguratorId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionGraph>>;
    public deletePublishedConfiguratorVersion(publishedconfiguratorId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (publishedconfiguratorId === null || publishedconfiguratorId === undefined) {
            throw new Error('Required parameter publishedconfiguratorId was null or undefined when calling deletePublishedConfiguratorVersion.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling deletePublishedConfiguratorVersion.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionGraph>('delete',`${this.basePath}/publishedconfigurators/${encodeURIComponent(String(publishedconfiguratorId))}/v/${encodeURIComponent(String(versionId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Delete Theme
     * @param themeId ID of Theme to return
     * @param archive Archive, rather than remove, data
     * @param failIfUsed Fail if the document is referenced by another undeleted document
     * @param updateUsed Update affected documents
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteTheme(themeId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedThemePatch>;
    public deleteTheme(themeId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedThemePatch>>;
    public deleteTheme(themeId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedThemePatch>>;
    public deleteTheme(themeId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (themeId === null || themeId === undefined) {
            throw new Error('Required parameter themeId was null or undefined when calling deleteTheme.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedThemePatch>('delete',`${this.basePath}/themes/${encodeURIComponent(String(themeId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Delete specific version of a Theme
     * @param themeId ID of Theme to delete version
     * @param versionId Version of Theme to delete
     * @param archive Archive, rather than remove, data
     * @param failIfUsed Fail if the document is referenced by another undeleted document
     * @param updateUsed Update affected documents
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteThemeVersion(themeId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionGraph>;
    public deleteThemeVersion(themeId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionGraph>>;
    public deleteThemeVersion(themeId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionGraph>>;
    public deleteThemeVersion(themeId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (themeId === null || themeId === undefined) {
            throw new Error('Required parameter themeId was null or undefined when calling deleteThemeVersion.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling deleteThemeVersion.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionGraph>('delete',`${this.basePath}/themes/${encodeURIComponent(String(themeId))}/v/${encodeURIComponent(String(versionId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Delete specific user
     * @param email Email of user
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteUser(email: string, observe?: 'body', reportProgress?: boolean): Observable<User>;
    public deleteUser(email: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<User>>;
    public deleteUser(email: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<User>>;
    public deleteUser(email: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (email === null || email === undefined) {
            throw new Error('Required parameter email was null or undefined when calling deleteUser.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<User>('delete',`${this.basePath}/users/${encodeURIComponent(String(email))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Delete WorkBatch
     * @param workbatchId ID of WorkBatch to return
     * @param archive Archive, rather than remove, data
     * @param failIfUsed Fail if the document is referenced by another undeleted document
     * @param updateUsed Update affected documents
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteWorkBatch(workbatchId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedWorkBatchPatch>;
    public deleteWorkBatch(workbatchId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedWorkBatchPatch>>;
    public deleteWorkBatch(workbatchId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedWorkBatchPatch>>;
    public deleteWorkBatch(workbatchId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workbatchId === null || workbatchId === undefined) {
            throw new Error('Required parameter workbatchId was null or undefined when calling deleteWorkBatch.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedWorkBatchPatch>('delete',`${this.basePath}/workbatches/${encodeURIComponent(String(workbatchId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Delete specific version of a WorkBatch
     * @param workbatchId ID of WorkBatch to delete version
     * @param versionId Version of WorkBatch to delete
     * @param archive Archive, rather than remove, data
     * @param failIfUsed Fail if the document is referenced by another undeleted document
     * @param updateUsed Update affected documents
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteWorkBatchVersion(workbatchId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionGraph>;
    public deleteWorkBatchVersion(workbatchId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionGraph>>;
    public deleteWorkBatchVersion(workbatchId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionGraph>>;
    public deleteWorkBatchVersion(workbatchId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workbatchId === null || workbatchId === undefined) {
            throw new Error('Required parameter workbatchId was null or undefined when calling deleteWorkBatchVersion.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling deleteWorkBatchVersion.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionGraph>('delete',`${this.basePath}/workbatches/${encodeURIComponent(String(workbatchId))}/v/${encodeURIComponent(String(versionId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Delete Workflow
     * @param workflowId ID of Workflow to return
     * @param archive Archive, rather than remove, data
     * @param failIfUsed Fail if the document is referenced by another undeleted document
     * @param updateUsed Update affected documents
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteWorkflow(workflowId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedWorkflowPatch>;
    public deleteWorkflow(workflowId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedWorkflowPatch>>;
    public deleteWorkflow(workflowId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedWorkflowPatch>>;
    public deleteWorkflow(workflowId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workflowId === null || workflowId === undefined) {
            throw new Error('Required parameter workflowId was null or undefined when calling deleteWorkflow.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedWorkflowPatch>('delete',`${this.basePath}/workflows/${encodeURIComponent(String(workflowId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Delete specific version of a Workflow
     * @param workflowId ID of Workflow to delete version
     * @param versionId Version of Workflow to delete
     * @param archive Archive, rather than remove, data
     * @param failIfUsed Fail if the document is referenced by another undeleted document
     * @param updateUsed Update affected documents
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteWorkflowVersion(workflowId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionGraph>;
    public deleteWorkflowVersion(workflowId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionGraph>>;
    public deleteWorkflowVersion(workflowId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionGraph>>;
    public deleteWorkflowVersion(workflowId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workflowId === null || workflowId === undefined) {
            throw new Error('Required parameter workflowId was null or undefined when calling deleteWorkflowVersion.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling deleteWorkflowVersion.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionGraph>('delete',`${this.basePath}/workflows/${encodeURIComponent(String(workflowId))}/v/${encodeURIComponent(String(versionId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get complete documents for all Appearances
     * @param mine Request owned by current user
     * @param shared Request shared with current user
     * @param includeDeleted Include deleted
     * @param canWrite Limit to documents with write-access
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param _public Request shared with public
     * @param baseType Object type being referenced
     * @param baseId Unique identifier of object
     * @param baseVersion Version of object
     * @param name Name of appearance
     * @param category Category of appearance
     * @param description Description
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findAndGetAppearances(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, baseType?: string, baseId?: string, baseVersion?: string, name?: string, category?: string, description?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<VersionedAppearance>>;
    public findAndGetAppearances(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, baseType?: string, baseId?: string, baseVersion?: string, name?: string, category?: string, description?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<VersionedAppearance>>>;
    public findAndGetAppearances(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, baseType?: string, baseId?: string, baseVersion?: string, name?: string, category?: string, description?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<VersionedAppearance>>>;
    public findAndGetAppearances(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, baseType?: string, baseId?: string, baseVersion?: string, name?: string, category?: string, description?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {













        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (mine !== undefined && mine !== null) {
            queryParameters = queryParameters.set('mine', <any>mine);
        }
        if (shared !== undefined && shared !== null) {
            queryParameters = queryParameters.set('shared', <any>shared);
        }
        if (includeDeleted !== undefined && includeDeleted !== null) {
            queryParameters = queryParameters.set('includeDeleted', <any>includeDeleted);
        }
        if (canWrite !== undefined && canWrite !== null) {
            queryParameters = queryParameters.set('canWrite', <any>canWrite);
        }
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (_public !== undefined && _public !== null) {
            queryParameters = queryParameters.set('public', <any>_public);
        }
        if (baseType !== undefined && baseType !== null) {
            queryParameters = queryParameters.set('base.type', <any>baseType);
        }
        if (baseId !== undefined && baseId !== null) {
            queryParameters = queryParameters.set('base.id', <any>baseId);
        }
        if (baseVersion !== undefined && baseVersion !== null) {
            queryParameters = queryParameters.set('base.version', <any>baseVersion);
        }
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }
        if (category !== undefined && category !== null) {
            queryParameters = queryParameters.set('category', <any>category);
        }
        if (description !== undefined && description !== null) {
            queryParameters = queryParameters.set('description', <any>description);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<VersionedAppearance>>('get',`${this.basePath}/appearances/all`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get complete documents for all Companies
     * @param mine Request owned by current user
     * @param shared Request shared with current user
     * @param includeDeleted Include deleted
     * @param canWrite Limit to documents with write-access
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param _public Request shared with public
     * @param name Company name
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findAndGetCompanies(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, name?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<VersionedCompany>>;
    public findAndGetCompanies(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<VersionedCompany>>>;
    public findAndGetCompanies(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<VersionedCompany>>>;
    public findAndGetCompanies(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {








        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (mine !== undefined && mine !== null) {
            queryParameters = queryParameters.set('mine', <any>mine);
        }
        if (shared !== undefined && shared !== null) {
            queryParameters = queryParameters.set('shared', <any>shared);
        }
        if (includeDeleted !== undefined && includeDeleted !== null) {
            queryParameters = queryParameters.set('includeDeleted', <any>includeDeleted);
        }
        if (canWrite !== undefined && canWrite !== null) {
            queryParameters = queryParameters.set('canWrite', <any>canWrite);
        }
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (_public !== undefined && _public !== null) {
            queryParameters = queryParameters.set('public', <any>_public);
        }
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<VersionedCompany>>('get',`${this.basePath}/companies/all`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get complete documents for all ConnectorTypes
     * @param mine Request owned by current user
     * @param shared Request shared with current user
     * @param includeDeleted Include deleted
     * @param canWrite Limit to documents with write-access
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param _public Request shared with public
     * @param name Name of connector type
     * @param description Description of connector type
     * @param domain Domain that this connector type applies to
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findAndGetConnectorTypes(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, name?: string, description?: string, domain?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<VersionedConnectorType>>;
    public findAndGetConnectorTypes(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, name?: string, description?: string, domain?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<VersionedConnectorType>>>;
    public findAndGetConnectorTypes(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, name?: string, description?: string, domain?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<VersionedConnectorType>>>;
    public findAndGetConnectorTypes(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, name?: string, description?: string, domain?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {










        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (mine !== undefined && mine !== null) {
            queryParameters = queryParameters.set('mine', <any>mine);
        }
        if (shared !== undefined && shared !== null) {
            queryParameters = queryParameters.set('shared', <any>shared);
        }
        if (includeDeleted !== undefined && includeDeleted !== null) {
            queryParameters = queryParameters.set('includeDeleted', <any>includeDeleted);
        }
        if (canWrite !== undefined && canWrite !== null) {
            queryParameters = queryParameters.set('canWrite', <any>canWrite);
        }
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (_public !== undefined && _public !== null) {
            queryParameters = queryParameters.set('public', <any>_public);
        }
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }
        if (description !== undefined && description !== null) {
            queryParameters = queryParameters.set('description', <any>description);
        }
        if (domain !== undefined && domain !== null) {
            queryParameters = queryParameters.set('domain', <any>domain);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<VersionedConnectorType>>('get',`${this.basePath}/connectortypes/all`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get complete documents for all Modules
     * @param mine Request owned by current user
     * @param shared Request shared with current user
     * @param includeDeleted Include deleted
     * @param canWrite Limit to documents with write-access
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param _public Request shared with public
     * @param name Name of module
     * @param description Description
     * @param skus SKU (Stock Keeping Unit)
     * @param sourceImageName File name
     * @param sourceImageContentType File content-type
     * @param sourceImageUri URI pointing to content
     * @param sourceImageHashString SHA256 hash of content
     * @param sourceFileFileName File name
     * @param sourceFileFileContentType File content-type
     * @param sourceFileFileUri URI pointing to content
     * @param sourceFileFileHashString SHA256 hash of content
     * @param sourceOnshapeDocumentId 
     * @param sourceOnshapeWvmType 
     * @param sourceOnshapeWvmId 
     * @param sourceOnshapeElementId 
     * @param sourceOnshapeParameters 
     * @param sourceOnshapeDocumentName 
     * @param sourceOnshapeElementName 
     * @param sourceOnshapeElementType 
     * @param sourceGeometrySettingsCadOrientation Coordinate system of CAD model
     * @param sourceGeometrySettingsUvMap Create UV map for applying textures
     * @param sourceGeometrySettingsAutoExtrude Convert geometry to be extrudable with attributes
     * @param sourceGeometrySettingsCollapseDepth Maximum depth of assembly
     * @param sourceGeometrySettingsRootNode Path to root node in assembly
     * @param sourceGeometrySettingsNodes Settings for child nodes
     * @param sourceAppearanceDefaultAppearances Parametric appearances to apply to all parts in assembly
     * @param sourceAppearanceParametricAppearances Parametric appearances to apply to all parts in assembly
     * @param sourceHotspots Hotspot Annotation
     * @param connectors Connectors placed on this module
     * @param categories Categories this module is part of
     * @param functions Global functionalities provided by this module
     * @param submodules Names of submodules
     * @param bundled Names of bundled modules
     * @param submodulesComposition Number of submodules that must be selected if this is selected
     * @param parameters Parameters available for this module
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findAndGetModules(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, name?: string, description?: string, skus?: Array<SkuMapping>, sourceImageName?: string, sourceImageContentType?: string, sourceImageUri?: string, sourceImageHashString?: string, sourceFileFileName?: string, sourceFileFileContentType?: string, sourceFileFileUri?: string, sourceFileFileHashString?: string, sourceOnshapeDocumentId?: string, sourceOnshapeWvmType?: string, sourceOnshapeWvmId?: string, sourceOnshapeElementId?: string, sourceOnshapeParameters?: Array<ConfigurationParameter>, sourceOnshapeDocumentName?: string, sourceOnshapeElementName?: string, sourceOnshapeElementType?: string, sourceGeometrySettingsCadOrientation?: string, sourceGeometrySettingsUvMap?: boolean, sourceGeometrySettingsAutoExtrude?: boolean, sourceGeometrySettingsCollapseDepth?: number, sourceGeometrySettingsRootNode?: Array<string>, sourceGeometrySettingsNodes?: { [key: string]: GeometryNodeSettings; }, sourceAppearanceDefaultAppearances?: Array<DefaultAppearance>, sourceAppearanceParametricAppearances?: Array<ParametricAppearance>, sourceHotspots?: Array<HotspotAnnotation>, connectors?: Array<ConnectorInstance>, categories?: Array<string>, functions?: Array<string>, submodules?: Array<string>, bundled?: Array<string>, submodulesComposition?: string, parameters?: Array<ParameterDefinition>, observe?: 'body', reportProgress?: boolean): Observable<Array<VersionedModule>>;
    public findAndGetModules(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, name?: string, description?: string, skus?: Array<SkuMapping>, sourceImageName?: string, sourceImageContentType?: string, sourceImageUri?: string, sourceImageHashString?: string, sourceFileFileName?: string, sourceFileFileContentType?: string, sourceFileFileUri?: string, sourceFileFileHashString?: string, sourceOnshapeDocumentId?: string, sourceOnshapeWvmType?: string, sourceOnshapeWvmId?: string, sourceOnshapeElementId?: string, sourceOnshapeParameters?: Array<ConfigurationParameter>, sourceOnshapeDocumentName?: string, sourceOnshapeElementName?: string, sourceOnshapeElementType?: string, sourceGeometrySettingsCadOrientation?: string, sourceGeometrySettingsUvMap?: boolean, sourceGeometrySettingsAutoExtrude?: boolean, sourceGeometrySettingsCollapseDepth?: number, sourceGeometrySettingsRootNode?: Array<string>, sourceGeometrySettingsNodes?: { [key: string]: GeometryNodeSettings; }, sourceAppearanceDefaultAppearances?: Array<DefaultAppearance>, sourceAppearanceParametricAppearances?: Array<ParametricAppearance>, sourceHotspots?: Array<HotspotAnnotation>, connectors?: Array<ConnectorInstance>, categories?: Array<string>, functions?: Array<string>, submodules?: Array<string>, bundled?: Array<string>, submodulesComposition?: string, parameters?: Array<ParameterDefinition>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<VersionedModule>>>;
    public findAndGetModules(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, name?: string, description?: string, skus?: Array<SkuMapping>, sourceImageName?: string, sourceImageContentType?: string, sourceImageUri?: string, sourceImageHashString?: string, sourceFileFileName?: string, sourceFileFileContentType?: string, sourceFileFileUri?: string, sourceFileFileHashString?: string, sourceOnshapeDocumentId?: string, sourceOnshapeWvmType?: string, sourceOnshapeWvmId?: string, sourceOnshapeElementId?: string, sourceOnshapeParameters?: Array<ConfigurationParameter>, sourceOnshapeDocumentName?: string, sourceOnshapeElementName?: string, sourceOnshapeElementType?: string, sourceGeometrySettingsCadOrientation?: string, sourceGeometrySettingsUvMap?: boolean, sourceGeometrySettingsAutoExtrude?: boolean, sourceGeometrySettingsCollapseDepth?: number, sourceGeometrySettingsRootNode?: Array<string>, sourceGeometrySettingsNodes?: { [key: string]: GeometryNodeSettings; }, sourceAppearanceDefaultAppearances?: Array<DefaultAppearance>, sourceAppearanceParametricAppearances?: Array<ParametricAppearance>, sourceHotspots?: Array<HotspotAnnotation>, connectors?: Array<ConnectorInstance>, categories?: Array<string>, functions?: Array<string>, submodules?: Array<string>, bundled?: Array<string>, submodulesComposition?: string, parameters?: Array<ParameterDefinition>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<VersionedModule>>>;
    public findAndGetModules(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, name?: string, description?: string, skus?: Array<SkuMapping>, sourceImageName?: string, sourceImageContentType?: string, sourceImageUri?: string, sourceImageHashString?: string, sourceFileFileName?: string, sourceFileFileContentType?: string, sourceFileFileUri?: string, sourceFileFileHashString?: string, sourceOnshapeDocumentId?: string, sourceOnshapeWvmType?: string, sourceOnshapeWvmId?: string, sourceOnshapeElementId?: string, sourceOnshapeParameters?: Array<ConfigurationParameter>, sourceOnshapeDocumentName?: string, sourceOnshapeElementName?: string, sourceOnshapeElementType?: string, sourceGeometrySettingsCadOrientation?: string, sourceGeometrySettingsUvMap?: boolean, sourceGeometrySettingsAutoExtrude?: boolean, sourceGeometrySettingsCollapseDepth?: number, sourceGeometrySettingsRootNode?: Array<string>, sourceGeometrySettingsNodes?: { [key: string]: GeometryNodeSettings; }, sourceAppearanceDefaultAppearances?: Array<DefaultAppearance>, sourceAppearanceParametricAppearances?: Array<ParametricAppearance>, sourceHotspots?: Array<HotspotAnnotation>, connectors?: Array<ConnectorInstance>, categories?: Array<string>, functions?: Array<string>, submodules?: Array<string>, bundled?: Array<string>, submodulesComposition?: string, parameters?: Array<ParameterDefinition>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {










































        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (mine !== undefined && mine !== null) {
            queryParameters = queryParameters.set('mine', <any>mine);
        }
        if (shared !== undefined && shared !== null) {
            queryParameters = queryParameters.set('shared', <any>shared);
        }
        if (includeDeleted !== undefined && includeDeleted !== null) {
            queryParameters = queryParameters.set('includeDeleted', <any>includeDeleted);
        }
        if (canWrite !== undefined && canWrite !== null) {
            queryParameters = queryParameters.set('canWrite', <any>canWrite);
        }
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (_public !== undefined && _public !== null) {
            queryParameters = queryParameters.set('public', <any>_public);
        }
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }
        if (description !== undefined && description !== null) {
            queryParameters = queryParameters.set('description', <any>description);
        }
        if (skus) {
            skus.forEach((element) => {
                queryParameters = queryParameters.append('skus', <any>element);
            })
        }
        if (sourceImageName !== undefined && sourceImageName !== null) {
            queryParameters = queryParameters.set('source.image.name', <any>sourceImageName);
        }
        if (sourceImageContentType !== undefined && sourceImageContentType !== null) {
            queryParameters = queryParameters.set('source.image.contentType', <any>sourceImageContentType);
        }
        if (sourceImageUri !== undefined && sourceImageUri !== null) {
            queryParameters = queryParameters.set('source.image.uri', <any>sourceImageUri);
        }
        if (sourceImageHashString !== undefined && sourceImageHashString !== null) {
            queryParameters = queryParameters.set('source.image.hashString', <any>sourceImageHashString);
        }
        if (sourceFileFileName !== undefined && sourceFileFileName !== null) {
            queryParameters = queryParameters.set('source.file.file.name', <any>sourceFileFileName);
        }
        if (sourceFileFileContentType !== undefined && sourceFileFileContentType !== null) {
            queryParameters = queryParameters.set('source.file.file.contentType', <any>sourceFileFileContentType);
        }
        if (sourceFileFileUri !== undefined && sourceFileFileUri !== null) {
            queryParameters = queryParameters.set('source.file.file.uri', <any>sourceFileFileUri);
        }
        if (sourceFileFileHashString !== undefined && sourceFileFileHashString !== null) {
            queryParameters = queryParameters.set('source.file.file.hashString', <any>sourceFileFileHashString);
        }
        if (sourceOnshapeDocumentId !== undefined && sourceOnshapeDocumentId !== null) {
            queryParameters = queryParameters.set('source.onshape.documentId', <any>sourceOnshapeDocumentId);
        }
        if (sourceOnshapeWvmType !== undefined && sourceOnshapeWvmType !== null) {
            queryParameters = queryParameters.set('source.onshape.wvmType', <any>sourceOnshapeWvmType);
        }
        if (sourceOnshapeWvmId !== undefined && sourceOnshapeWvmId !== null) {
            queryParameters = queryParameters.set('source.onshape.wvmId', <any>sourceOnshapeWvmId);
        }
        if (sourceOnshapeElementId !== undefined && sourceOnshapeElementId !== null) {
            queryParameters = queryParameters.set('source.onshape.elementId', <any>sourceOnshapeElementId);
        }
        if (sourceOnshapeParameters) {
            sourceOnshapeParameters.forEach((element) => {
                queryParameters = queryParameters.append('source.onshape.parameters', <any>element);
            })
        }
        if (sourceOnshapeDocumentName !== undefined && sourceOnshapeDocumentName !== null) {
            queryParameters = queryParameters.set('source.onshape.documentName', <any>sourceOnshapeDocumentName);
        }
        if (sourceOnshapeElementName !== undefined && sourceOnshapeElementName !== null) {
            queryParameters = queryParameters.set('source.onshape.elementName', <any>sourceOnshapeElementName);
        }
        if (sourceOnshapeElementType !== undefined && sourceOnshapeElementType !== null) {
            queryParameters = queryParameters.set('source.onshape.elementType', <any>sourceOnshapeElementType);
        }
        if (sourceGeometrySettingsCadOrientation !== undefined && sourceGeometrySettingsCadOrientation !== null) {
            queryParameters = queryParameters.set('source.geometrySettings.cadOrientation', <any>sourceGeometrySettingsCadOrientation);
        }
        if (sourceGeometrySettingsUvMap !== undefined && sourceGeometrySettingsUvMap !== null) {
            queryParameters = queryParameters.set('source.geometrySettings.uvMap', <any>sourceGeometrySettingsUvMap);
        }
        if (sourceGeometrySettingsAutoExtrude !== undefined && sourceGeometrySettingsAutoExtrude !== null) {
            queryParameters = queryParameters.set('source.geometrySettings.autoExtrude', <any>sourceGeometrySettingsAutoExtrude);
        }
        if (sourceGeometrySettingsCollapseDepth !== undefined && sourceGeometrySettingsCollapseDepth !== null) {
            queryParameters = queryParameters.set('source.geometrySettings.collapseDepth', <any>sourceGeometrySettingsCollapseDepth);
        }
        if (sourceGeometrySettingsRootNode) {
            sourceGeometrySettingsRootNode.forEach((element) => {
                queryParameters = queryParameters.append('source.geometrySettings.rootNode', <any>element);
            })
        }
        if (sourceGeometrySettingsNodes !== undefined && sourceGeometrySettingsNodes !== null) {
            queryParameters = queryParameters.set('source.geometrySettings.nodes', <any>sourceGeometrySettingsNodes);
        }
        if (sourceAppearanceDefaultAppearances) {
            sourceAppearanceDefaultAppearances.forEach((element) => {
                queryParameters = queryParameters.append('source.appearance.defaultAppearances', <any>element);
            })
        }
        if (sourceAppearanceParametricAppearances) {
            sourceAppearanceParametricAppearances.forEach((element) => {
                queryParameters = queryParameters.append('source.appearance.parametricAppearances', <any>element);
            })
        }
        if (sourceHotspots) {
            sourceHotspots.forEach((element) => {
                queryParameters = queryParameters.append('source.hotspots', <any>element);
            })
        }
        if (connectors) {
            connectors.forEach((element) => {
                queryParameters = queryParameters.append('connectors', <any>element);
            })
        }
        if (categories) {
            categories.forEach((element) => {
                queryParameters = queryParameters.append('categories', <any>element);
            })
        }
        if (functions) {
            functions.forEach((element) => {
                queryParameters = queryParameters.append('functions', <any>element);
            })
        }
        if (submodules) {
            submodules.forEach((element) => {
                queryParameters = queryParameters.append('submodules', <any>element);
            })
        }
        if (bundled) {
            bundled.forEach((element) => {
                queryParameters = queryParameters.append('bundled', <any>element);
            })
        }
        if (submodulesComposition !== undefined && submodulesComposition !== null) {
            queryParameters = queryParameters.set('submodulesComposition', <any>submodulesComposition);
        }
        if (parameters) {
            parameters.forEach((element) => {
                queryParameters = queryParameters.append('parameters', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<VersionedModule>>('get',`${this.basePath}/modules/all`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get complete documents for all OfflineActions
     * @param mine Request owned by current user
     * @param shared Request shared with current user
     * @param includeDeleted Include deleted
     * @param canWrite Limit to documents with write-access
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param _public Request shared with public
     * @param url 
     * @param action 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findAndGetOfflineActions(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, url?: string, action?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<VersionedOfflineAction>>;
    public findAndGetOfflineActions(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, url?: string, action?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<VersionedOfflineAction>>>;
    public findAndGetOfflineActions(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, url?: string, action?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<VersionedOfflineAction>>>;
    public findAndGetOfflineActions(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, url?: string, action?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {









        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (mine !== undefined && mine !== null) {
            queryParameters = queryParameters.set('mine', <any>mine);
        }
        if (shared !== undefined && shared !== null) {
            queryParameters = queryParameters.set('shared', <any>shared);
        }
        if (includeDeleted !== undefined && includeDeleted !== null) {
            queryParameters = queryParameters.set('includeDeleted', <any>includeDeleted);
        }
        if (canWrite !== undefined && canWrite !== null) {
            queryParameters = queryParameters.set('canWrite', <any>canWrite);
        }
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (_public !== undefined && _public !== null) {
            queryParameters = queryParameters.set('public', <any>_public);
        }
        if (url !== undefined && url !== null) {
            queryParameters = queryParameters.set('url', <any>url);
        }
        if (action !== undefined && action !== null) {
            queryParameters = queryParameters.set('action', <any>action);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<VersionedOfflineAction>>('get',`${this.basePath}/offlineactions/all`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get complete documents for all Orders
     * @param mine Request owned by current user
     * @param shared Request shared with current user
     * @param includeDeleted Include deleted
     * @param canWrite Limit to documents with write-access
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param _public Request shared with public
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findAndGetOrders(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Array<VersionedOrder>>;
    public findAndGetOrders(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<VersionedOrder>>>;
    public findAndGetOrders(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<VersionedOrder>>>;
    public findAndGetOrders(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (mine !== undefined && mine !== null) {
            queryParameters = queryParameters.set('mine', <any>mine);
        }
        if (shared !== undefined && shared !== null) {
            queryParameters = queryParameters.set('shared', <any>shared);
        }
        if (includeDeleted !== undefined && includeDeleted !== null) {
            queryParameters = queryParameters.set('includeDeleted', <any>includeDeleted);
        }
        if (canWrite !== undefined && canWrite !== null) {
            queryParameters = queryParameters.set('canWrite', <any>canWrite);
        }
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (_public !== undefined && _public !== null) {
            queryParameters = queryParameters.set('public', <any>_public);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<VersionedOrder>>('get',`${this.basePath}/orders/all`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get complete documents for all Populations
     * @param mine Request owned by current user
     * @param shared Request shared with current user
     * @param includeDeleted Include deleted
     * @param canWrite Limit to documents with write-access
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param _public Request shared with public
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findAndGetPopulations(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Array<VersionedPopulation>>;
    public findAndGetPopulations(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<VersionedPopulation>>>;
    public findAndGetPopulations(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<VersionedPopulation>>>;
    public findAndGetPopulations(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (mine !== undefined && mine !== null) {
            queryParameters = queryParameters.set('mine', <any>mine);
        }
        if (shared !== undefined && shared !== null) {
            queryParameters = queryParameters.set('shared', <any>shared);
        }
        if (includeDeleted !== undefined && includeDeleted !== null) {
            queryParameters = queryParameters.set('includeDeleted', <any>includeDeleted);
        }
        if (canWrite !== undefined && canWrite !== null) {
            queryParameters = queryParameters.set('canWrite', <any>canWrite);
        }
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (_public !== undefined && _public !== null) {
            queryParameters = queryParameters.set('public', <any>_public);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<VersionedPopulation>>('get',`${this.basePath}/populations/all`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get complete documents for all ProductConfigurations
     * @param mine Request owned by current user
     * @param shared Request shared with current user
     * @param includeDeleted Include deleted
     * @param canWrite Limit to documents with write-access
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param _public Request shared with public
     * @param name Name of product configuration
     * @param description Description of product configuration
     * @param productType Object type being referenced
     * @param productId Unique identifier of object
     * @param productVersion Version of object
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findAndGetProductConfigurations(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, name?: string, description?: string, productType?: string, productId?: string, productVersion?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<VersionedProductConfiguration>>;
    public findAndGetProductConfigurations(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, name?: string, description?: string, productType?: string, productId?: string, productVersion?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<VersionedProductConfiguration>>>;
    public findAndGetProductConfigurations(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, name?: string, description?: string, productType?: string, productId?: string, productVersion?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<VersionedProductConfiguration>>>;
    public findAndGetProductConfigurations(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, name?: string, description?: string, productType?: string, productId?: string, productVersion?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {












        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (mine !== undefined && mine !== null) {
            queryParameters = queryParameters.set('mine', <any>mine);
        }
        if (shared !== undefined && shared !== null) {
            queryParameters = queryParameters.set('shared', <any>shared);
        }
        if (includeDeleted !== undefined && includeDeleted !== null) {
            queryParameters = queryParameters.set('includeDeleted', <any>includeDeleted);
        }
        if (canWrite !== undefined && canWrite !== null) {
            queryParameters = queryParameters.set('canWrite', <any>canWrite);
        }
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (_public !== undefined && _public !== null) {
            queryParameters = queryParameters.set('public', <any>_public);
        }
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }
        if (description !== undefined && description !== null) {
            queryParameters = queryParameters.set('description', <any>description);
        }
        if (productType !== undefined && productType !== null) {
            queryParameters = queryParameters.set('product.type', <any>productType);
        }
        if (productId !== undefined && productId !== null) {
            queryParameters = queryParameters.set('product.id', <any>productId);
        }
        if (productVersion !== undefined && productVersion !== null) {
            queryParameters = queryParameters.set('product.version', <any>productVersion);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<VersionedProductConfiguration>>('get',`${this.basePath}/productconfigurations/all`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get complete documents for all ProductionMaterials
     * @param mine Request owned by current user
     * @param shared Request shared with current user
     * @param includeDeleted Include deleted
     * @param canWrite Limit to documents with write-access
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param _public Request shared with public
     * @param category Category of appearance
     * @param name Name of material
     * @param description Description
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findAndGetProductionMaterials(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, category?: string, name?: string, description?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<VersionedProductionMaterial>>;
    public findAndGetProductionMaterials(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, category?: string, name?: string, description?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<VersionedProductionMaterial>>>;
    public findAndGetProductionMaterials(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, category?: string, name?: string, description?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<VersionedProductionMaterial>>>;
    public findAndGetProductionMaterials(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, category?: string, name?: string, description?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {










        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (mine !== undefined && mine !== null) {
            queryParameters = queryParameters.set('mine', <any>mine);
        }
        if (shared !== undefined && shared !== null) {
            queryParameters = queryParameters.set('shared', <any>shared);
        }
        if (includeDeleted !== undefined && includeDeleted !== null) {
            queryParameters = queryParameters.set('includeDeleted', <any>includeDeleted);
        }
        if (canWrite !== undefined && canWrite !== null) {
            queryParameters = queryParameters.set('canWrite', <any>canWrite);
        }
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (_public !== undefined && _public !== null) {
            queryParameters = queryParameters.set('public', <any>_public);
        }
        if (category !== undefined && category !== null) {
            queryParameters = queryParameters.set('category', <any>category);
        }
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }
        if (description !== undefined && description !== null) {
            queryParameters = queryParameters.set('description', <any>description);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<VersionedProductionMaterial>>('get',`${this.basePath}/productionmaterials/all`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get complete documents for all Products
     * @param mine Request owned by current user
     * @param shared Request shared with current user
     * @param includeDeleted Include deleted
     * @param canWrite Limit to documents with write-access
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param _public Request shared with public
     * @param name Name of configurator
     * @param description Description
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findAndGetProducts(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, name?: string, description?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<VersionedProduct>>;
    public findAndGetProducts(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, name?: string, description?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<VersionedProduct>>>;
    public findAndGetProducts(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, name?: string, description?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<VersionedProduct>>>;
    public findAndGetProducts(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, name?: string, description?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {









        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (mine !== undefined && mine !== null) {
            queryParameters = queryParameters.set('mine', <any>mine);
        }
        if (shared !== undefined && shared !== null) {
            queryParameters = queryParameters.set('shared', <any>shared);
        }
        if (includeDeleted !== undefined && includeDeleted !== null) {
            queryParameters = queryParameters.set('includeDeleted', <any>includeDeleted);
        }
        if (canWrite !== undefined && canWrite !== null) {
            queryParameters = queryParameters.set('canWrite', <any>canWrite);
        }
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (_public !== undefined && _public !== null) {
            queryParameters = queryParameters.set('public', <any>_public);
        }
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }
        if (description !== undefined && description !== null) {
            queryParameters = queryParameters.set('description', <any>description);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<VersionedProduct>>('get',`${this.basePath}/products/all`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get complete documents for all PublishedConfigurators
     * @param mine Request owned by current user
     * @param shared Request shared with current user
     * @param includeDeleted Include deleted
     * @param canWrite Limit to documents with write-access
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param _public Request shared with public
     * @param infinitiveDomain 
     * @param customerDomain 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findAndGetPublishedConfigurators(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, infinitiveDomain?: string, customerDomain?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<VersionedPublishedConfigurator>>;
    public findAndGetPublishedConfigurators(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, infinitiveDomain?: string, customerDomain?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<VersionedPublishedConfigurator>>>;
    public findAndGetPublishedConfigurators(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, infinitiveDomain?: string, customerDomain?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<VersionedPublishedConfigurator>>>;
    public findAndGetPublishedConfigurators(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, infinitiveDomain?: string, customerDomain?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {









        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (mine !== undefined && mine !== null) {
            queryParameters = queryParameters.set('mine', <any>mine);
        }
        if (shared !== undefined && shared !== null) {
            queryParameters = queryParameters.set('shared', <any>shared);
        }
        if (includeDeleted !== undefined && includeDeleted !== null) {
            queryParameters = queryParameters.set('includeDeleted', <any>includeDeleted);
        }
        if (canWrite !== undefined && canWrite !== null) {
            queryParameters = queryParameters.set('canWrite', <any>canWrite);
        }
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (_public !== undefined && _public !== null) {
            queryParameters = queryParameters.set('public', <any>_public);
        }
        if (infinitiveDomain !== undefined && infinitiveDomain !== null) {
            queryParameters = queryParameters.set('infinitiveDomain', <any>infinitiveDomain);
        }
        if (customerDomain !== undefined && customerDomain !== null) {
            queryParameters = queryParameters.set('customerDomain', <any>customerDomain);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<VersionedPublishedConfigurator>>('get',`${this.basePath}/publishedconfigurators/all`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get complete documents for all Themes
     * @param mine Request owned by current user
     * @param shared Request shared with current user
     * @param includeDeleted Include deleted
     * @param canWrite Limit to documents with write-access
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param _public Request shared with public
     * @param name Name
     * @param seed Seed
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findAndGetThemes(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, name?: string, seed?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<VersionedTheme>>;
    public findAndGetThemes(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, name?: string, seed?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<VersionedTheme>>>;
    public findAndGetThemes(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, name?: string, seed?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<VersionedTheme>>>;
    public findAndGetThemes(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, name?: string, seed?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {









        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (mine !== undefined && mine !== null) {
            queryParameters = queryParameters.set('mine', <any>mine);
        }
        if (shared !== undefined && shared !== null) {
            queryParameters = queryParameters.set('shared', <any>shared);
        }
        if (includeDeleted !== undefined && includeDeleted !== null) {
            queryParameters = queryParameters.set('includeDeleted', <any>includeDeleted);
        }
        if (canWrite !== undefined && canWrite !== null) {
            queryParameters = queryParameters.set('canWrite', <any>canWrite);
        }
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (_public !== undefined && _public !== null) {
            queryParameters = queryParameters.set('public', <any>_public);
        }
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }
        if (seed !== undefined && seed !== null) {
            queryParameters = queryParameters.set('seed', <any>seed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<VersionedTheme>>('get',`${this.basePath}/themes/all`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get complete documents for all WorkBatches
     * @param mine Request owned by current user
     * @param shared Request shared with current user
     * @param includeDeleted Include deleted
     * @param canWrite Limit to documents with write-access
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param _public Request shared with public
     * @param ordersEmpty 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findAndGetWorkBatches(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, ordersEmpty?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Array<VersionedWorkBatch>>;
    public findAndGetWorkBatches(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, ordersEmpty?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<VersionedWorkBatch>>>;
    public findAndGetWorkBatches(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, ordersEmpty?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<VersionedWorkBatch>>>;
    public findAndGetWorkBatches(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, ordersEmpty?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {








        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (mine !== undefined && mine !== null) {
            queryParameters = queryParameters.set('mine', <any>mine);
        }
        if (shared !== undefined && shared !== null) {
            queryParameters = queryParameters.set('shared', <any>shared);
        }
        if (includeDeleted !== undefined && includeDeleted !== null) {
            queryParameters = queryParameters.set('includeDeleted', <any>includeDeleted);
        }
        if (canWrite !== undefined && canWrite !== null) {
            queryParameters = queryParameters.set('canWrite', <any>canWrite);
        }
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (_public !== undefined && _public !== null) {
            queryParameters = queryParameters.set('public', <any>_public);
        }
        if (ordersEmpty !== undefined && ordersEmpty !== null) {
            queryParameters = queryParameters.set('orders.empty', <any>ordersEmpty);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<VersionedWorkBatch>>('get',`${this.basePath}/workbatches/all`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get complete documents for all Workflows
     * @param mine Request owned by current user
     * @param shared Request shared with current user
     * @param includeDeleted Include deleted
     * @param canWrite Limit to documents with write-access
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param _public Request shared with public
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findAndGetWorkflows(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Array<VersionedWorkflow>>;
    public findAndGetWorkflows(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<VersionedWorkflow>>>;
    public findAndGetWorkflows(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<VersionedWorkflow>>>;
    public findAndGetWorkflows(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (mine !== undefined && mine !== null) {
            queryParameters = queryParameters.set('mine', <any>mine);
        }
        if (shared !== undefined && shared !== null) {
            queryParameters = queryParameters.set('shared', <any>shared);
        }
        if (includeDeleted !== undefined && includeDeleted !== null) {
            queryParameters = queryParameters.set('includeDeleted', <any>includeDeleted);
        }
        if (canWrite !== undefined && canWrite !== null) {
            queryParameters = queryParameters.set('canWrite', <any>canWrite);
        }
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (_public !== undefined && _public !== null) {
            queryParameters = queryParameters.set('public', <any>_public);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<VersionedWorkflow>>('get',`${this.basePath}/workflows/all`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get all Appearances
     * @param mine Request owned by current user
     * @param shared Request shared with current user
     * @param includeDeleted Include deleted
     * @param canWrite Limit to documents with write-access
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param _public Request shared with public
     * @param baseType Object type being referenced
     * @param baseId Unique identifier of object
     * @param baseVersion Version of object
     * @param name Name of appearance
     * @param category Category of appearance
     * @param description Description
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findAppearances(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, baseType?: string, baseId?: string, baseVersion?: string, name?: string, category?: string, description?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<DocumentSummary>>;
    public findAppearances(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, baseType?: string, baseId?: string, baseVersion?: string, name?: string, category?: string, description?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DocumentSummary>>>;
    public findAppearances(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, baseType?: string, baseId?: string, baseVersion?: string, name?: string, category?: string, description?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DocumentSummary>>>;
    public findAppearances(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, baseType?: string, baseId?: string, baseVersion?: string, name?: string, category?: string, description?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {













        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (mine !== undefined && mine !== null) {
            queryParameters = queryParameters.set('mine', <any>mine);
        }
        if (shared !== undefined && shared !== null) {
            queryParameters = queryParameters.set('shared', <any>shared);
        }
        if (includeDeleted !== undefined && includeDeleted !== null) {
            queryParameters = queryParameters.set('includeDeleted', <any>includeDeleted);
        }
        if (canWrite !== undefined && canWrite !== null) {
            queryParameters = queryParameters.set('canWrite', <any>canWrite);
        }
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (_public !== undefined && _public !== null) {
            queryParameters = queryParameters.set('public', <any>_public);
        }
        if (baseType !== undefined && baseType !== null) {
            queryParameters = queryParameters.set('base.type', <any>baseType);
        }
        if (baseId !== undefined && baseId !== null) {
            queryParameters = queryParameters.set('base.id', <any>baseId);
        }
        if (baseVersion !== undefined && baseVersion !== null) {
            queryParameters = queryParameters.set('base.version', <any>baseVersion);
        }
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }
        if (category !== undefined && category !== null) {
            queryParameters = queryParameters.set('category', <any>category);
        }
        if (description !== undefined && description !== null) {
            queryParameters = queryParameters.set('description', <any>description);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<DocumentSummary>>('get',`${this.basePath}/appearances`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get all Companies
     * @param mine Request owned by current user
     * @param shared Request shared with current user
     * @param includeDeleted Include deleted
     * @param canWrite Limit to documents with write-access
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param _public Request shared with public
     * @param name Company name
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findCompanies(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, name?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<DocumentSummary>>;
    public findCompanies(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DocumentSummary>>>;
    public findCompanies(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DocumentSummary>>>;
    public findCompanies(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {








        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (mine !== undefined && mine !== null) {
            queryParameters = queryParameters.set('mine', <any>mine);
        }
        if (shared !== undefined && shared !== null) {
            queryParameters = queryParameters.set('shared', <any>shared);
        }
        if (includeDeleted !== undefined && includeDeleted !== null) {
            queryParameters = queryParameters.set('includeDeleted', <any>includeDeleted);
        }
        if (canWrite !== undefined && canWrite !== null) {
            queryParameters = queryParameters.set('canWrite', <any>canWrite);
        }
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (_public !== undefined && _public !== null) {
            queryParameters = queryParameters.set('public', <any>_public);
        }
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<DocumentSummary>>('get',`${this.basePath}/companies`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get all ConnectorTypes
     * @param mine Request owned by current user
     * @param shared Request shared with current user
     * @param includeDeleted Include deleted
     * @param canWrite Limit to documents with write-access
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param _public Request shared with public
     * @param name Name of connector type
     * @param description Description of connector type
     * @param domain Domain that this connector type applies to
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findConnectorTypes(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, name?: string, description?: string, domain?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<DocumentSummary>>;
    public findConnectorTypes(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, name?: string, description?: string, domain?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DocumentSummary>>>;
    public findConnectorTypes(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, name?: string, description?: string, domain?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DocumentSummary>>>;
    public findConnectorTypes(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, name?: string, description?: string, domain?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {










        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (mine !== undefined && mine !== null) {
            queryParameters = queryParameters.set('mine', <any>mine);
        }
        if (shared !== undefined && shared !== null) {
            queryParameters = queryParameters.set('shared', <any>shared);
        }
        if (includeDeleted !== undefined && includeDeleted !== null) {
            queryParameters = queryParameters.set('includeDeleted', <any>includeDeleted);
        }
        if (canWrite !== undefined && canWrite !== null) {
            queryParameters = queryParameters.set('canWrite', <any>canWrite);
        }
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (_public !== undefined && _public !== null) {
            queryParameters = queryParameters.set('public', <any>_public);
        }
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }
        if (description !== undefined && description !== null) {
            queryParameters = queryParameters.set('description', <any>description);
        }
        if (domain !== undefined && domain !== null) {
            queryParameters = queryParameters.set('domain', <any>domain);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<DocumentSummary>>('get',`${this.basePath}/connectortypes`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get all Modules
     * @param mine Request owned by current user
     * @param shared Request shared with current user
     * @param includeDeleted Include deleted
     * @param canWrite Limit to documents with write-access
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param _public Request shared with public
     * @param name Name of module
     * @param description Description
     * @param skus SKU (Stock Keeping Unit)
     * @param sourceImageName File name
     * @param sourceImageContentType File content-type
     * @param sourceImageUri URI pointing to content
     * @param sourceImageHashString SHA256 hash of content
     * @param sourceFileFileName File name
     * @param sourceFileFileContentType File content-type
     * @param sourceFileFileUri URI pointing to content
     * @param sourceFileFileHashString SHA256 hash of content
     * @param sourceOnshapeDocumentId 
     * @param sourceOnshapeWvmType 
     * @param sourceOnshapeWvmId 
     * @param sourceOnshapeElementId 
     * @param sourceOnshapeParameters 
     * @param sourceOnshapeDocumentName 
     * @param sourceOnshapeElementName 
     * @param sourceOnshapeElementType 
     * @param sourceGeometrySettingsCadOrientation Coordinate system of CAD model
     * @param sourceGeometrySettingsUvMap Create UV map for applying textures
     * @param sourceGeometrySettingsAutoExtrude Convert geometry to be extrudable with attributes
     * @param sourceGeometrySettingsCollapseDepth Maximum depth of assembly
     * @param sourceGeometrySettingsRootNode Path to root node in assembly
     * @param sourceGeometrySettingsNodes Settings for child nodes
     * @param sourceAppearanceDefaultAppearances Parametric appearances to apply to all parts in assembly
     * @param sourceAppearanceParametricAppearances Parametric appearances to apply to all parts in assembly
     * @param sourceHotspots Hotspot Annotation
     * @param connectors Connectors placed on this module
     * @param categories Categories this module is part of
     * @param functions Global functionalities provided by this module
     * @param submodules Names of submodules
     * @param bundled Names of bundled modules
     * @param submodulesComposition Number of submodules that must be selected if this is selected
     * @param parameters Parameters available for this module
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findModules(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, name?: string, description?: string, skus?: Array<SkuMapping>, sourceImageName?: string, sourceImageContentType?: string, sourceImageUri?: string, sourceImageHashString?: string, sourceFileFileName?: string, sourceFileFileContentType?: string, sourceFileFileUri?: string, sourceFileFileHashString?: string, sourceOnshapeDocumentId?: string, sourceOnshapeWvmType?: string, sourceOnshapeWvmId?: string, sourceOnshapeElementId?: string, sourceOnshapeParameters?: Array<ConfigurationParameter>, sourceOnshapeDocumentName?: string, sourceOnshapeElementName?: string, sourceOnshapeElementType?: string, sourceGeometrySettingsCadOrientation?: string, sourceGeometrySettingsUvMap?: boolean, sourceGeometrySettingsAutoExtrude?: boolean, sourceGeometrySettingsCollapseDepth?: number, sourceGeometrySettingsRootNode?: Array<string>, sourceGeometrySettingsNodes?: { [key: string]: GeometryNodeSettings; }, sourceAppearanceDefaultAppearances?: Array<DefaultAppearance>, sourceAppearanceParametricAppearances?: Array<ParametricAppearance>, sourceHotspots?: Array<HotspotAnnotation>, connectors?: Array<ConnectorInstance>, categories?: Array<string>, functions?: Array<string>, submodules?: Array<string>, bundled?: Array<string>, submodulesComposition?: string, parameters?: Array<ParameterDefinition>, observe?: 'body', reportProgress?: boolean): Observable<Array<DocumentSummary>>;
    public findModules(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, name?: string, description?: string, skus?: Array<SkuMapping>, sourceImageName?: string, sourceImageContentType?: string, sourceImageUri?: string, sourceImageHashString?: string, sourceFileFileName?: string, sourceFileFileContentType?: string, sourceFileFileUri?: string, sourceFileFileHashString?: string, sourceOnshapeDocumentId?: string, sourceOnshapeWvmType?: string, sourceOnshapeWvmId?: string, sourceOnshapeElementId?: string, sourceOnshapeParameters?: Array<ConfigurationParameter>, sourceOnshapeDocumentName?: string, sourceOnshapeElementName?: string, sourceOnshapeElementType?: string, sourceGeometrySettingsCadOrientation?: string, sourceGeometrySettingsUvMap?: boolean, sourceGeometrySettingsAutoExtrude?: boolean, sourceGeometrySettingsCollapseDepth?: number, sourceGeometrySettingsRootNode?: Array<string>, sourceGeometrySettingsNodes?: { [key: string]: GeometryNodeSettings; }, sourceAppearanceDefaultAppearances?: Array<DefaultAppearance>, sourceAppearanceParametricAppearances?: Array<ParametricAppearance>, sourceHotspots?: Array<HotspotAnnotation>, connectors?: Array<ConnectorInstance>, categories?: Array<string>, functions?: Array<string>, submodules?: Array<string>, bundled?: Array<string>, submodulesComposition?: string, parameters?: Array<ParameterDefinition>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DocumentSummary>>>;
    public findModules(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, name?: string, description?: string, skus?: Array<SkuMapping>, sourceImageName?: string, sourceImageContentType?: string, sourceImageUri?: string, sourceImageHashString?: string, sourceFileFileName?: string, sourceFileFileContentType?: string, sourceFileFileUri?: string, sourceFileFileHashString?: string, sourceOnshapeDocumentId?: string, sourceOnshapeWvmType?: string, sourceOnshapeWvmId?: string, sourceOnshapeElementId?: string, sourceOnshapeParameters?: Array<ConfigurationParameter>, sourceOnshapeDocumentName?: string, sourceOnshapeElementName?: string, sourceOnshapeElementType?: string, sourceGeometrySettingsCadOrientation?: string, sourceGeometrySettingsUvMap?: boolean, sourceGeometrySettingsAutoExtrude?: boolean, sourceGeometrySettingsCollapseDepth?: number, sourceGeometrySettingsRootNode?: Array<string>, sourceGeometrySettingsNodes?: { [key: string]: GeometryNodeSettings; }, sourceAppearanceDefaultAppearances?: Array<DefaultAppearance>, sourceAppearanceParametricAppearances?: Array<ParametricAppearance>, sourceHotspots?: Array<HotspotAnnotation>, connectors?: Array<ConnectorInstance>, categories?: Array<string>, functions?: Array<string>, submodules?: Array<string>, bundled?: Array<string>, submodulesComposition?: string, parameters?: Array<ParameterDefinition>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DocumentSummary>>>;
    public findModules(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, name?: string, description?: string, skus?: Array<SkuMapping>, sourceImageName?: string, sourceImageContentType?: string, sourceImageUri?: string, sourceImageHashString?: string, sourceFileFileName?: string, sourceFileFileContentType?: string, sourceFileFileUri?: string, sourceFileFileHashString?: string, sourceOnshapeDocumentId?: string, sourceOnshapeWvmType?: string, sourceOnshapeWvmId?: string, sourceOnshapeElementId?: string, sourceOnshapeParameters?: Array<ConfigurationParameter>, sourceOnshapeDocumentName?: string, sourceOnshapeElementName?: string, sourceOnshapeElementType?: string, sourceGeometrySettingsCadOrientation?: string, sourceGeometrySettingsUvMap?: boolean, sourceGeometrySettingsAutoExtrude?: boolean, sourceGeometrySettingsCollapseDepth?: number, sourceGeometrySettingsRootNode?: Array<string>, sourceGeometrySettingsNodes?: { [key: string]: GeometryNodeSettings; }, sourceAppearanceDefaultAppearances?: Array<DefaultAppearance>, sourceAppearanceParametricAppearances?: Array<ParametricAppearance>, sourceHotspots?: Array<HotspotAnnotation>, connectors?: Array<ConnectorInstance>, categories?: Array<string>, functions?: Array<string>, submodules?: Array<string>, bundled?: Array<string>, submodulesComposition?: string, parameters?: Array<ParameterDefinition>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {










































        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (mine !== undefined && mine !== null) {
            queryParameters = queryParameters.set('mine', <any>mine);
        }
        if (shared !== undefined && shared !== null) {
            queryParameters = queryParameters.set('shared', <any>shared);
        }
        if (includeDeleted !== undefined && includeDeleted !== null) {
            queryParameters = queryParameters.set('includeDeleted', <any>includeDeleted);
        }
        if (canWrite !== undefined && canWrite !== null) {
            queryParameters = queryParameters.set('canWrite', <any>canWrite);
        }
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (_public !== undefined && _public !== null) {
            queryParameters = queryParameters.set('public', <any>_public);
        }
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }
        if (description !== undefined && description !== null) {
            queryParameters = queryParameters.set('description', <any>description);
        }
        if (skus) {
            skus.forEach((element) => {
                queryParameters = queryParameters.append('skus', <any>element);
            })
        }
        if (sourceImageName !== undefined && sourceImageName !== null) {
            queryParameters = queryParameters.set('source.image.name', <any>sourceImageName);
        }
        if (sourceImageContentType !== undefined && sourceImageContentType !== null) {
            queryParameters = queryParameters.set('source.image.contentType', <any>sourceImageContentType);
        }
        if (sourceImageUri !== undefined && sourceImageUri !== null) {
            queryParameters = queryParameters.set('source.image.uri', <any>sourceImageUri);
        }
        if (sourceImageHashString !== undefined && sourceImageHashString !== null) {
            queryParameters = queryParameters.set('source.image.hashString', <any>sourceImageHashString);
        }
        if (sourceFileFileName !== undefined && sourceFileFileName !== null) {
            queryParameters = queryParameters.set('source.file.file.name', <any>sourceFileFileName);
        }
        if (sourceFileFileContentType !== undefined && sourceFileFileContentType !== null) {
            queryParameters = queryParameters.set('source.file.file.contentType', <any>sourceFileFileContentType);
        }
        if (sourceFileFileUri !== undefined && sourceFileFileUri !== null) {
            queryParameters = queryParameters.set('source.file.file.uri', <any>sourceFileFileUri);
        }
        if (sourceFileFileHashString !== undefined && sourceFileFileHashString !== null) {
            queryParameters = queryParameters.set('source.file.file.hashString', <any>sourceFileFileHashString);
        }
        if (sourceOnshapeDocumentId !== undefined && sourceOnshapeDocumentId !== null) {
            queryParameters = queryParameters.set('source.onshape.documentId', <any>sourceOnshapeDocumentId);
        }
        if (sourceOnshapeWvmType !== undefined && sourceOnshapeWvmType !== null) {
            queryParameters = queryParameters.set('source.onshape.wvmType', <any>sourceOnshapeWvmType);
        }
        if (sourceOnshapeWvmId !== undefined && sourceOnshapeWvmId !== null) {
            queryParameters = queryParameters.set('source.onshape.wvmId', <any>sourceOnshapeWvmId);
        }
        if (sourceOnshapeElementId !== undefined && sourceOnshapeElementId !== null) {
            queryParameters = queryParameters.set('source.onshape.elementId', <any>sourceOnshapeElementId);
        }
        if (sourceOnshapeParameters) {
            sourceOnshapeParameters.forEach((element) => {
                queryParameters = queryParameters.append('source.onshape.parameters', <any>element);
            })
        }
        if (sourceOnshapeDocumentName !== undefined && sourceOnshapeDocumentName !== null) {
            queryParameters = queryParameters.set('source.onshape.documentName', <any>sourceOnshapeDocumentName);
        }
        if (sourceOnshapeElementName !== undefined && sourceOnshapeElementName !== null) {
            queryParameters = queryParameters.set('source.onshape.elementName', <any>sourceOnshapeElementName);
        }
        if (sourceOnshapeElementType !== undefined && sourceOnshapeElementType !== null) {
            queryParameters = queryParameters.set('source.onshape.elementType', <any>sourceOnshapeElementType);
        }
        if (sourceGeometrySettingsCadOrientation !== undefined && sourceGeometrySettingsCadOrientation !== null) {
            queryParameters = queryParameters.set('source.geometrySettings.cadOrientation', <any>sourceGeometrySettingsCadOrientation);
        }
        if (sourceGeometrySettingsUvMap !== undefined && sourceGeometrySettingsUvMap !== null) {
            queryParameters = queryParameters.set('source.geometrySettings.uvMap', <any>sourceGeometrySettingsUvMap);
        }
        if (sourceGeometrySettingsAutoExtrude !== undefined && sourceGeometrySettingsAutoExtrude !== null) {
            queryParameters = queryParameters.set('source.geometrySettings.autoExtrude', <any>sourceGeometrySettingsAutoExtrude);
        }
        if (sourceGeometrySettingsCollapseDepth !== undefined && sourceGeometrySettingsCollapseDepth !== null) {
            queryParameters = queryParameters.set('source.geometrySettings.collapseDepth', <any>sourceGeometrySettingsCollapseDepth);
        }
        if (sourceGeometrySettingsRootNode) {
            sourceGeometrySettingsRootNode.forEach((element) => {
                queryParameters = queryParameters.append('source.geometrySettings.rootNode', <any>element);
            })
        }
        if (sourceGeometrySettingsNodes !== undefined && sourceGeometrySettingsNodes !== null) {
            queryParameters = queryParameters.set('source.geometrySettings.nodes', <any>sourceGeometrySettingsNodes);
        }
        if (sourceAppearanceDefaultAppearances) {
            sourceAppearanceDefaultAppearances.forEach((element) => {
                queryParameters = queryParameters.append('source.appearance.defaultAppearances', <any>element);
            })
        }
        if (sourceAppearanceParametricAppearances) {
            sourceAppearanceParametricAppearances.forEach((element) => {
                queryParameters = queryParameters.append('source.appearance.parametricAppearances', <any>element);
            })
        }
        if (sourceHotspots) {
            sourceHotspots.forEach((element) => {
                queryParameters = queryParameters.append('source.hotspots', <any>element);
            })
        }
        if (connectors) {
            connectors.forEach((element) => {
                queryParameters = queryParameters.append('connectors', <any>element);
            })
        }
        if (categories) {
            categories.forEach((element) => {
                queryParameters = queryParameters.append('categories', <any>element);
            })
        }
        if (functions) {
            functions.forEach((element) => {
                queryParameters = queryParameters.append('functions', <any>element);
            })
        }
        if (submodules) {
            submodules.forEach((element) => {
                queryParameters = queryParameters.append('submodules', <any>element);
            })
        }
        if (bundled) {
            bundled.forEach((element) => {
                queryParameters = queryParameters.append('bundled', <any>element);
            })
        }
        if (submodulesComposition !== undefined && submodulesComposition !== null) {
            queryParameters = queryParameters.set('submodulesComposition', <any>submodulesComposition);
        }
        if (parameters) {
            parameters.forEach((element) => {
                queryParameters = queryParameters.append('parameters', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<DocumentSummary>>('get',`${this.basePath}/modules`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get all OfflineActions
     * @param mine Request owned by current user
     * @param shared Request shared with current user
     * @param includeDeleted Include deleted
     * @param canWrite Limit to documents with write-access
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param _public Request shared with public
     * @param url 
     * @param action 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findOfflineActions(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, url?: string, action?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<DocumentSummary>>;
    public findOfflineActions(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, url?: string, action?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DocumentSummary>>>;
    public findOfflineActions(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, url?: string, action?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DocumentSummary>>>;
    public findOfflineActions(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, url?: string, action?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {









        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (mine !== undefined && mine !== null) {
            queryParameters = queryParameters.set('mine', <any>mine);
        }
        if (shared !== undefined && shared !== null) {
            queryParameters = queryParameters.set('shared', <any>shared);
        }
        if (includeDeleted !== undefined && includeDeleted !== null) {
            queryParameters = queryParameters.set('includeDeleted', <any>includeDeleted);
        }
        if (canWrite !== undefined && canWrite !== null) {
            queryParameters = queryParameters.set('canWrite', <any>canWrite);
        }
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (_public !== undefined && _public !== null) {
            queryParameters = queryParameters.set('public', <any>_public);
        }
        if (url !== undefined && url !== null) {
            queryParameters = queryParameters.set('url', <any>url);
        }
        if (action !== undefined && action !== null) {
            queryParameters = queryParameters.set('action', <any>action);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<DocumentSummary>>('get',`${this.basePath}/offlineactions`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get all Orders
     * @param mine Request owned by current user
     * @param shared Request shared with current user
     * @param includeDeleted Include deleted
     * @param canWrite Limit to documents with write-access
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param _public Request shared with public
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findOrders(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Array<DocumentSummary>>;
    public findOrders(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DocumentSummary>>>;
    public findOrders(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DocumentSummary>>>;
    public findOrders(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (mine !== undefined && mine !== null) {
            queryParameters = queryParameters.set('mine', <any>mine);
        }
        if (shared !== undefined && shared !== null) {
            queryParameters = queryParameters.set('shared', <any>shared);
        }
        if (includeDeleted !== undefined && includeDeleted !== null) {
            queryParameters = queryParameters.set('includeDeleted', <any>includeDeleted);
        }
        if (canWrite !== undefined && canWrite !== null) {
            queryParameters = queryParameters.set('canWrite', <any>canWrite);
        }
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (_public !== undefined && _public !== null) {
            queryParameters = queryParameters.set('public', <any>_public);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<DocumentSummary>>('get',`${this.basePath}/orders`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get all Populations
     * @param mine Request owned by current user
     * @param shared Request shared with current user
     * @param includeDeleted Include deleted
     * @param canWrite Limit to documents with write-access
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param _public Request shared with public
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findPopulations(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Array<DocumentSummary>>;
    public findPopulations(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DocumentSummary>>>;
    public findPopulations(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DocumentSummary>>>;
    public findPopulations(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (mine !== undefined && mine !== null) {
            queryParameters = queryParameters.set('mine', <any>mine);
        }
        if (shared !== undefined && shared !== null) {
            queryParameters = queryParameters.set('shared', <any>shared);
        }
        if (includeDeleted !== undefined && includeDeleted !== null) {
            queryParameters = queryParameters.set('includeDeleted', <any>includeDeleted);
        }
        if (canWrite !== undefined && canWrite !== null) {
            queryParameters = queryParameters.set('canWrite', <any>canWrite);
        }
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (_public !== undefined && _public !== null) {
            queryParameters = queryParameters.set('public', <any>_public);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<DocumentSummary>>('get',`${this.basePath}/populations`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get all ProductConfigurations
     * @param mine Request owned by current user
     * @param shared Request shared with current user
     * @param includeDeleted Include deleted
     * @param canWrite Limit to documents with write-access
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param _public Request shared with public
     * @param name Name of product configuration
     * @param description Description of product configuration
     * @param productType Object type being referenced
     * @param productId Unique identifier of object
     * @param productVersion Version of object
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findProductConfigurations(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, name?: string, description?: string, productType?: string, productId?: string, productVersion?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<DocumentSummary>>;
    public findProductConfigurations(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, name?: string, description?: string, productType?: string, productId?: string, productVersion?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DocumentSummary>>>;
    public findProductConfigurations(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, name?: string, description?: string, productType?: string, productId?: string, productVersion?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DocumentSummary>>>;
    public findProductConfigurations(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, name?: string, description?: string, productType?: string, productId?: string, productVersion?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {












        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (mine !== undefined && mine !== null) {
            queryParameters = queryParameters.set('mine', <any>mine);
        }
        if (shared !== undefined && shared !== null) {
            queryParameters = queryParameters.set('shared', <any>shared);
        }
        if (includeDeleted !== undefined && includeDeleted !== null) {
            queryParameters = queryParameters.set('includeDeleted', <any>includeDeleted);
        }
        if (canWrite !== undefined && canWrite !== null) {
            queryParameters = queryParameters.set('canWrite', <any>canWrite);
        }
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (_public !== undefined && _public !== null) {
            queryParameters = queryParameters.set('public', <any>_public);
        }
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }
        if (description !== undefined && description !== null) {
            queryParameters = queryParameters.set('description', <any>description);
        }
        if (productType !== undefined && productType !== null) {
            queryParameters = queryParameters.set('product.type', <any>productType);
        }
        if (productId !== undefined && productId !== null) {
            queryParameters = queryParameters.set('product.id', <any>productId);
        }
        if (productVersion !== undefined && productVersion !== null) {
            queryParameters = queryParameters.set('product.version', <any>productVersion);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<DocumentSummary>>('get',`${this.basePath}/productconfigurations`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get all ProductionMaterials
     * @param mine Request owned by current user
     * @param shared Request shared with current user
     * @param includeDeleted Include deleted
     * @param canWrite Limit to documents with write-access
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param _public Request shared with public
     * @param category Category of appearance
     * @param name Name of material
     * @param description Description
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findProductionMaterials(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, category?: string, name?: string, description?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<DocumentSummary>>;
    public findProductionMaterials(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, category?: string, name?: string, description?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DocumentSummary>>>;
    public findProductionMaterials(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, category?: string, name?: string, description?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DocumentSummary>>>;
    public findProductionMaterials(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, category?: string, name?: string, description?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {










        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (mine !== undefined && mine !== null) {
            queryParameters = queryParameters.set('mine', <any>mine);
        }
        if (shared !== undefined && shared !== null) {
            queryParameters = queryParameters.set('shared', <any>shared);
        }
        if (includeDeleted !== undefined && includeDeleted !== null) {
            queryParameters = queryParameters.set('includeDeleted', <any>includeDeleted);
        }
        if (canWrite !== undefined && canWrite !== null) {
            queryParameters = queryParameters.set('canWrite', <any>canWrite);
        }
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (_public !== undefined && _public !== null) {
            queryParameters = queryParameters.set('public', <any>_public);
        }
        if (category !== undefined && category !== null) {
            queryParameters = queryParameters.set('category', <any>category);
        }
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }
        if (description !== undefined && description !== null) {
            queryParameters = queryParameters.set('description', <any>description);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<DocumentSummary>>('get',`${this.basePath}/productionmaterials`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get all Products
     * @param mine Request owned by current user
     * @param shared Request shared with current user
     * @param includeDeleted Include deleted
     * @param canWrite Limit to documents with write-access
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param _public Request shared with public
     * @param name Name of configurator
     * @param description Description
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findProducts(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, name?: string, description?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<DocumentSummary>>;
    public findProducts(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, name?: string, description?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DocumentSummary>>>;
    public findProducts(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, name?: string, description?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DocumentSummary>>>;
    public findProducts(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, name?: string, description?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {









        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (mine !== undefined && mine !== null) {
            queryParameters = queryParameters.set('mine', <any>mine);
        }
        if (shared !== undefined && shared !== null) {
            queryParameters = queryParameters.set('shared', <any>shared);
        }
        if (includeDeleted !== undefined && includeDeleted !== null) {
            queryParameters = queryParameters.set('includeDeleted', <any>includeDeleted);
        }
        if (canWrite !== undefined && canWrite !== null) {
            queryParameters = queryParameters.set('canWrite', <any>canWrite);
        }
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (_public !== undefined && _public !== null) {
            queryParameters = queryParameters.set('public', <any>_public);
        }
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }
        if (description !== undefined && description !== null) {
            queryParameters = queryParameters.set('description', <any>description);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<DocumentSummary>>('get',`${this.basePath}/products`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get all PublishedConfigurators
     * @param mine Request owned by current user
     * @param shared Request shared with current user
     * @param includeDeleted Include deleted
     * @param canWrite Limit to documents with write-access
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param _public Request shared with public
     * @param infinitiveDomain 
     * @param customerDomain 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findPublishedConfigurators(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, infinitiveDomain?: string, customerDomain?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<DocumentSummary>>;
    public findPublishedConfigurators(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, infinitiveDomain?: string, customerDomain?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DocumentSummary>>>;
    public findPublishedConfigurators(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, infinitiveDomain?: string, customerDomain?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DocumentSummary>>>;
    public findPublishedConfigurators(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, infinitiveDomain?: string, customerDomain?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {









        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (mine !== undefined && mine !== null) {
            queryParameters = queryParameters.set('mine', <any>mine);
        }
        if (shared !== undefined && shared !== null) {
            queryParameters = queryParameters.set('shared', <any>shared);
        }
        if (includeDeleted !== undefined && includeDeleted !== null) {
            queryParameters = queryParameters.set('includeDeleted', <any>includeDeleted);
        }
        if (canWrite !== undefined && canWrite !== null) {
            queryParameters = queryParameters.set('canWrite', <any>canWrite);
        }
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (_public !== undefined && _public !== null) {
            queryParameters = queryParameters.set('public', <any>_public);
        }
        if (infinitiveDomain !== undefined && infinitiveDomain !== null) {
            queryParameters = queryParameters.set('infinitiveDomain', <any>infinitiveDomain);
        }
        if (customerDomain !== undefined && customerDomain !== null) {
            queryParameters = queryParameters.set('customerDomain', <any>customerDomain);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<DocumentSummary>>('get',`${this.basePath}/publishedconfigurators`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get all Themes
     * @param mine Request owned by current user
     * @param shared Request shared with current user
     * @param includeDeleted Include deleted
     * @param canWrite Limit to documents with write-access
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param _public Request shared with public
     * @param name Name
     * @param seed Seed
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findThemes(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, name?: string, seed?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<DocumentSummary>>;
    public findThemes(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, name?: string, seed?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DocumentSummary>>>;
    public findThemes(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, name?: string, seed?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DocumentSummary>>>;
    public findThemes(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, name?: string, seed?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {









        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (mine !== undefined && mine !== null) {
            queryParameters = queryParameters.set('mine', <any>mine);
        }
        if (shared !== undefined && shared !== null) {
            queryParameters = queryParameters.set('shared', <any>shared);
        }
        if (includeDeleted !== undefined && includeDeleted !== null) {
            queryParameters = queryParameters.set('includeDeleted', <any>includeDeleted);
        }
        if (canWrite !== undefined && canWrite !== null) {
            queryParameters = queryParameters.set('canWrite', <any>canWrite);
        }
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (_public !== undefined && _public !== null) {
            queryParameters = queryParameters.set('public', <any>_public);
        }
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }
        if (seed !== undefined && seed !== null) {
            queryParameters = queryParameters.set('seed', <any>seed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<DocumentSummary>>('get',`${this.basePath}/themes`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get all WorkBatches
     * @param mine Request owned by current user
     * @param shared Request shared with current user
     * @param includeDeleted Include deleted
     * @param canWrite Limit to documents with write-access
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param _public Request shared with public
     * @param ordersEmpty 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findWorkBatches(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, ordersEmpty?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Array<DocumentSummary>>;
    public findWorkBatches(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, ordersEmpty?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DocumentSummary>>>;
    public findWorkBatches(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, ordersEmpty?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DocumentSummary>>>;
    public findWorkBatches(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, ordersEmpty?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {








        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (mine !== undefined && mine !== null) {
            queryParameters = queryParameters.set('mine', <any>mine);
        }
        if (shared !== undefined && shared !== null) {
            queryParameters = queryParameters.set('shared', <any>shared);
        }
        if (includeDeleted !== undefined && includeDeleted !== null) {
            queryParameters = queryParameters.set('includeDeleted', <any>includeDeleted);
        }
        if (canWrite !== undefined && canWrite !== null) {
            queryParameters = queryParameters.set('canWrite', <any>canWrite);
        }
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (_public !== undefined && _public !== null) {
            queryParameters = queryParameters.set('public', <any>_public);
        }
        if (ordersEmpty !== undefined && ordersEmpty !== null) {
            queryParameters = queryParameters.set('orders.empty', <any>ordersEmpty);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<DocumentSummary>>('get',`${this.basePath}/workbatches`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get all Workflows
     * @param mine Request owned by current user
     * @param shared Request shared with current user
     * @param includeDeleted Include deleted
     * @param canWrite Limit to documents with write-access
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param _public Request shared with public
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findWorkflows(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Array<DocumentSummary>>;
    public findWorkflows(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DocumentSummary>>>;
    public findWorkflows(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DocumentSummary>>>;
    public findWorkflows(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (mine !== undefined && mine !== null) {
            queryParameters = queryParameters.set('mine', <any>mine);
        }
        if (shared !== undefined && shared !== null) {
            queryParameters = queryParameters.set('shared', <any>shared);
        }
        if (includeDeleted !== undefined && includeDeleted !== null) {
            queryParameters = queryParameters.set('includeDeleted', <any>includeDeleted);
        }
        if (canWrite !== undefined && canWrite !== null) {
            queryParameters = queryParameters.set('canWrite', <any>canWrite);
        }
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (_public !== undefined && _public !== null) {
            queryParameters = queryParameters.set('public', <any>_public);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<DocumentSummary>>('get',`${this.basePath}/workflows`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get the current version of the application
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAppVersion(observe?: 'body', reportProgress?: boolean): Observable<Array<AppVersion>>;
    public getAppVersion(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<AppVersion>>>;
    public getAppVersion(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<AppVersion>>>;
    public getAppVersion(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<AppVersion>>('get',`${this.basePath}/version`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get specified version of specified Appearance
     * @param appearanceId ID of Appearance to return
     * @param versionId Version of Appearance to return
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param ifNoneMatch Version of Appearance already received, if no change to Appearance since version then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAppearance(appearanceId: string, versionId: string, multiWorkspace?: boolean, complete?: boolean, ifNoneMatch?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedAppearance>;
    public getAppearance(appearanceId: string, versionId: string, multiWorkspace?: boolean, complete?: boolean, ifNoneMatch?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedAppearance>>;
    public getAppearance(appearanceId: string, versionId: string, multiWorkspace?: boolean, complete?: boolean, ifNoneMatch?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedAppearance>>;
    public getAppearance(appearanceId: string, versionId: string, multiWorkspace?: boolean, complete?: boolean, ifNoneMatch?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (appearanceId === null || appearanceId === undefined) {
            throw new Error('Required parameter appearanceId was null or undefined when calling getAppearance.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getAppearance.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;
        if (ifNoneMatch !== undefined && ifNoneMatch !== null) {
            headers = headers.set('If-None-Match', String(ifNoneMatch));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedAppearance>('get',`${this.basePath}/appearances/${encodeURIComponent(String(appearanceId))}/v/${encodeURIComponent(String(versionId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get diff between versions of Appearances
     * @param appearanceId1 ID of first Appearance to compare
     * @param versionId1 Version of first Appearance to compare
     * @param appearanceId2 ID of second Appearance to compare
     * @param versionId2 Version of second Appearance to compare
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAppearanceDiff(appearanceId1: string, versionId1: string, appearanceId2: string, versionId2: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedAppearanceDiff>;
    public getAppearanceDiff(appearanceId1: string, versionId1: string, appearanceId2: string, versionId2: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedAppearanceDiff>>;
    public getAppearanceDiff(appearanceId1: string, versionId1: string, appearanceId2: string, versionId2: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedAppearanceDiff>>;
    public getAppearanceDiff(appearanceId1: string, versionId1: string, appearanceId2: string, versionId2: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (appearanceId1 === null || appearanceId1 === undefined) {
            throw new Error('Required parameter appearanceId1 was null or undefined when calling getAppearanceDiff.');
        }

        if (versionId1 === null || versionId1 === undefined) {
            throw new Error('Required parameter versionId1 was null or undefined when calling getAppearanceDiff.');
        }

        if (appearanceId2 === null || appearanceId2 === undefined) {
            throw new Error('Required parameter appearanceId2 was null or undefined when calling getAppearanceDiff.');
        }

        if (versionId2 === null || versionId2 === undefined) {
            throw new Error('Required parameter versionId2 was null or undefined when calling getAppearanceDiff.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedAppearanceDiff>('get',`${this.basePath}/appearances/${encodeURIComponent(String(appearanceId1))}/v/${encodeURIComponent(String(versionId1))}/diff/${encodeURIComponent(String(appearanceId2))}/v/${encodeURIComponent(String(versionId2))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param appearanceId ID of Appearance
     * @param versionId Version of Appearance
     * @param ifNoneMatch Version of Artefact already received, if no change to Artefact since version then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAppearanceDisplacementMapImage(appearanceId: string, versionId: string, ifNoneMatch?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedArtefact>;
    public getAppearanceDisplacementMapImage(appearanceId: string, versionId: string, ifNoneMatch?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedArtefact>>;
    public getAppearanceDisplacementMapImage(appearanceId: string, versionId: string, ifNoneMatch?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedArtefact>>;
    public getAppearanceDisplacementMapImage(appearanceId: string, versionId: string, ifNoneMatch?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (appearanceId === null || appearanceId === undefined) {
            throw new Error('Required parameter appearanceId was null or undefined when calling getAppearanceDisplacementMapImage.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getAppearanceDisplacementMapImage.');
        }


        let headers = this.defaultHeaders;
        if (ifNoneMatch !== undefined && ifNoneMatch !== null) {
            headers = headers.set('If-None-Match', String(ifNoneMatch));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedArtefact>('get',`${this.basePath}/appearances/${encodeURIComponent(String(appearanceId))}/v/${encodeURIComponent(String(versionId))}/displacementMap/image`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param appearanceId ID of Appearance to return
     * @param versionId Version of Appearance
     * @param extension File extension requested
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAppearanceDisplacementMapImageContent(appearanceId: string, versionId: string, extension: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public getAppearanceDisplacementMapImageContent(appearanceId: string, versionId: string, extension: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public getAppearanceDisplacementMapImageContent(appearanceId: string, versionId: string, extension: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public getAppearanceDisplacementMapImageContent(appearanceId: string, versionId: string, extension: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (appearanceId === null || appearanceId === undefined) {
            throw new Error('Required parameter appearanceId was null or undefined when calling getAppearanceDisplacementMapImageContent.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getAppearanceDisplacementMapImageContent.');
        }

        if (extension === null || extension === undefined) {
            throw new Error('Required parameter extension was null or undefined when calling getAppearanceDisplacementMapImageContent.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'binary/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<string>('get',`${this.basePath}/appearances/${encodeURIComponent(String(appearanceId))}/v/${encodeURIComponent(String(versionId))}/displacementMap/image.${encodeURIComponent(String(extension))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get latest version of specified Appearance
     * @param appearanceId ID of Appearance to return
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param eTag Version of Appearance considered to be HEAD, if no newer Appearance is found then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAppearanceHead(appearanceId: string, multiWorkspace?: boolean, complete?: boolean, eTag?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedAppearance>;
    public getAppearanceHead(appearanceId: string, multiWorkspace?: boolean, complete?: boolean, eTag?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedAppearance>>;
    public getAppearanceHead(appearanceId: string, multiWorkspace?: boolean, complete?: boolean, eTag?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedAppearance>>;
    public getAppearanceHead(appearanceId: string, multiWorkspace?: boolean, complete?: boolean, eTag?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (appearanceId === null || appearanceId === undefined) {
            throw new Error('Required parameter appearanceId was null or undefined when calling getAppearanceHead.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;
        if (eTag !== undefined && eTag !== null) {
            headers = headers.set('ETag', String(eTag));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedAppearance>('get',`${this.basePath}/appearances/${encodeURIComponent(String(appearanceId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param appearanceId ID of Appearance
     * @param eTag Version of Artefact considered to be HEAD, if no newer Artefact is found then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAppearanceHeadDisplacementMapImage(appearanceId: string, eTag?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedArtefact>;
    public getAppearanceHeadDisplacementMapImage(appearanceId: string, eTag?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedArtefact>>;
    public getAppearanceHeadDisplacementMapImage(appearanceId: string, eTag?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedArtefact>>;
    public getAppearanceHeadDisplacementMapImage(appearanceId: string, eTag?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (appearanceId === null || appearanceId === undefined) {
            throw new Error('Required parameter appearanceId was null or undefined when calling getAppearanceHeadDisplacementMapImage.');
        }


        let headers = this.defaultHeaders;
        if (eTag !== undefined && eTag !== null) {
            headers = headers.set('ETag', String(eTag));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedArtefact>('get',`${this.basePath}/appearances/${encodeURIComponent(String(appearanceId))}/displacementMap/image`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param appearanceId ID of Appearance to return
     * @param extension File extension requested
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAppearanceHeadDisplacementMapImageContent(appearanceId: string, extension: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public getAppearanceHeadDisplacementMapImageContent(appearanceId: string, extension: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public getAppearanceHeadDisplacementMapImageContent(appearanceId: string, extension: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public getAppearanceHeadDisplacementMapImageContent(appearanceId: string, extension: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (appearanceId === null || appearanceId === undefined) {
            throw new Error('Required parameter appearanceId was null or undefined when calling getAppearanceHeadDisplacementMapImageContent.');
        }

        if (extension === null || extension === undefined) {
            throw new Error('Required parameter extension was null or undefined when calling getAppearanceHeadDisplacementMapImageContent.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'binary/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<string>('get',`${this.basePath}/appearances/${encodeURIComponent(String(appearanceId))}/displacementMap/image.${encodeURIComponent(String(extension))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param appearanceId ID of Appearance to return
     * @param from First version to consider in log
     * @param to Last version to consider in log
     * @param limit Maximum number of versions in log
     * @param tag Tag of version to find
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAppearanceHeadDisplacementMapImageLog(appearanceId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionLog>;
    public getAppearanceHeadDisplacementMapImageLog(appearanceId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLog>>;
    public getAppearanceHeadDisplacementMapImageLog(appearanceId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLog>>;
    public getAppearanceHeadDisplacementMapImageLog(appearanceId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (appearanceId === null || appearanceId === undefined) {
            throw new Error('Required parameter appearanceId was null or undefined when calling getAppearanceHeadDisplacementMapImageLog.');
        }







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from);
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('to', <any>to);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (tag !== undefined && tag !== null) {
            queryParameters = queryParameters.set('tag', <any>tag);
        }
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLog>('get',`${this.basePath}/appearances/${encodeURIComponent(String(appearanceId))}/displacementMap/image/log`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param appearanceId ID of Appearance
     * @param eTag Version of Artefact considered to be HEAD, if no newer Artefact is found then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAppearanceHeadMapImage(appearanceId: string, eTag?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedArtefact>;
    public getAppearanceHeadMapImage(appearanceId: string, eTag?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedArtefact>>;
    public getAppearanceHeadMapImage(appearanceId: string, eTag?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedArtefact>>;
    public getAppearanceHeadMapImage(appearanceId: string, eTag?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (appearanceId === null || appearanceId === undefined) {
            throw new Error('Required parameter appearanceId was null or undefined when calling getAppearanceHeadMapImage.');
        }


        let headers = this.defaultHeaders;
        if (eTag !== undefined && eTag !== null) {
            headers = headers.set('ETag', String(eTag));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedArtefact>('get',`${this.basePath}/appearances/${encodeURIComponent(String(appearanceId))}/map/image`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param appearanceId ID of Appearance to return
     * @param extension File extension requested
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAppearanceHeadMapImageContent(appearanceId: string, extension: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public getAppearanceHeadMapImageContent(appearanceId: string, extension: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public getAppearanceHeadMapImageContent(appearanceId: string, extension: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public getAppearanceHeadMapImageContent(appearanceId: string, extension: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (appearanceId === null || appearanceId === undefined) {
            throw new Error('Required parameter appearanceId was null or undefined when calling getAppearanceHeadMapImageContent.');
        }

        if (extension === null || extension === undefined) {
            throw new Error('Required parameter extension was null or undefined when calling getAppearanceHeadMapImageContent.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'binary/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<string>('get',`${this.basePath}/appearances/${encodeURIComponent(String(appearanceId))}/map/image.${encodeURIComponent(String(extension))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param appearanceId ID of Appearance to return
     * @param from First version to consider in log
     * @param to Last version to consider in log
     * @param limit Maximum number of versions in log
     * @param tag Tag of version to find
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAppearanceHeadMapImageLog(appearanceId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionLog>;
    public getAppearanceHeadMapImageLog(appearanceId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLog>>;
    public getAppearanceHeadMapImageLog(appearanceId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLog>>;
    public getAppearanceHeadMapImageLog(appearanceId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (appearanceId === null || appearanceId === undefined) {
            throw new Error('Required parameter appearanceId was null or undefined when calling getAppearanceHeadMapImageLog.');
        }







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from);
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('to', <any>to);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (tag !== undefined && tag !== null) {
            queryParameters = queryParameters.set('tag', <any>tag);
        }
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLog>('get',`${this.basePath}/appearances/${encodeURIComponent(String(appearanceId))}/map/image/log`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get history of specified Appearance
     * @param appearanceId ID of Appearance to return
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAppearanceHistory(appearanceId: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionGraph>;
    public getAppearanceHistory(appearanceId: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionGraph>>;
    public getAppearanceHistory(appearanceId: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionGraph>>;
    public getAppearanceHistory(appearanceId: string, multiWorkspace?: boolean, complete?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (appearanceId === null || appearanceId === undefined) {
            throw new Error('Required parameter appearanceId was null or undefined when calling getAppearanceHistory.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionGraph>('get',`${this.basePath}/appearances/${encodeURIComponent(String(appearanceId))}/history`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get log of specified Appearance
     * @param appearanceId ID of Appearance to return
     * @param from First version to consider in log
     * @param to Last version to consider in log
     * @param limit Maximum number of versions in log
     * @param tag Tag of version to find
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAppearanceLog(appearanceId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionLog>;
    public getAppearanceLog(appearanceId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLog>>;
    public getAppearanceLog(appearanceId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLog>>;
    public getAppearanceLog(appearanceId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (appearanceId === null || appearanceId === undefined) {
            throw new Error('Required parameter appearanceId was null or undefined when calling getAppearanceLog.');
        }







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from);
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('to', <any>to);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (tag !== undefined && tag !== null) {
            queryParameters = queryParameters.set('tag', <any>tag);
        }
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLog>('get',`${this.basePath}/appearances/${encodeURIComponent(String(appearanceId))}/log`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param appearanceId ID of Appearance
     * @param versionId Version of Appearance
     * @param ifNoneMatch Version of Artefact already received, if no change to Artefact since version then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAppearanceMapImage(appearanceId: string, versionId: string, ifNoneMatch?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedArtefact>;
    public getAppearanceMapImage(appearanceId: string, versionId: string, ifNoneMatch?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedArtefact>>;
    public getAppearanceMapImage(appearanceId: string, versionId: string, ifNoneMatch?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedArtefact>>;
    public getAppearanceMapImage(appearanceId: string, versionId: string, ifNoneMatch?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (appearanceId === null || appearanceId === undefined) {
            throw new Error('Required parameter appearanceId was null or undefined when calling getAppearanceMapImage.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getAppearanceMapImage.');
        }


        let headers = this.defaultHeaders;
        if (ifNoneMatch !== undefined && ifNoneMatch !== null) {
            headers = headers.set('If-None-Match', String(ifNoneMatch));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedArtefact>('get',`${this.basePath}/appearances/${encodeURIComponent(String(appearanceId))}/v/${encodeURIComponent(String(versionId))}/map/image`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param appearanceId ID of Appearance to return
     * @param versionId Version of Appearance
     * @param extension File extension requested
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAppearanceMapImageContent(appearanceId: string, versionId: string, extension: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public getAppearanceMapImageContent(appearanceId: string, versionId: string, extension: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public getAppearanceMapImageContent(appearanceId: string, versionId: string, extension: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public getAppearanceMapImageContent(appearanceId: string, versionId: string, extension: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (appearanceId === null || appearanceId === undefined) {
            throw new Error('Required parameter appearanceId was null or undefined when calling getAppearanceMapImageContent.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getAppearanceMapImageContent.');
        }

        if (extension === null || extension === undefined) {
            throw new Error('Required parameter extension was null or undefined when calling getAppearanceMapImageContent.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'binary/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<string>('get',`${this.basePath}/appearances/${encodeURIComponent(String(appearanceId))}/v/${encodeURIComponent(String(versionId))}/map/image.${encodeURIComponent(String(extension))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get Appearance permissions
     * @param appearanceId ID of Appearance to check
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAppearancePermissions(appearanceId: string, observe?: 'body', reportProgress?: boolean): Observable<UserPermissions>;
    public getAppearancePermissions(appearanceId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserPermissions>>;
    public getAppearancePermissions(appearanceId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserPermissions>>;
    public getAppearancePermissions(appearanceId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (appearanceId === null || appearanceId === undefined) {
            throw new Error('Required parameter appearanceId was null or undefined when calling getAppearancePermissions.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<UserPermissions>('get',`${this.basePath}/appearances/${encodeURIComponent(String(appearanceId))}/permissions`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get sharing details of specified Appearance
     * @param appearanceId ID of Appearance to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAppearanceSharing(appearanceId: string, observe?: 'body', reportProgress?: boolean): Observable<Sharing>;
    public getAppearanceSharing(appearanceId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Sharing>>;
    public getAppearanceSharing(appearanceId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Sharing>>;
    public getAppearanceSharing(appearanceId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (appearanceId === null || appearanceId === undefined) {
            throw new Error('Required parameter appearanceId was null or undefined when calling getAppearanceSharing.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Sharing>('get',`${this.basePath}/appearances/${encodeURIComponent(String(appearanceId))}/sharing`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get short code to share the specified Appearance
     * @param appearanceId ID of Appearance to share
     * @param mode Mode: copy or view or edit
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAppearanceShortCode(appearanceId: string, mode: string, observe?: 'body', reportProgress?: boolean): Observable<ShortCode>;
    public getAppearanceShortCode(appearanceId: string, mode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ShortCode>>;
    public getAppearanceShortCode(appearanceId: string, mode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ShortCode>>;
    public getAppearanceShortCode(appearanceId: string, mode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (appearanceId === null || appearanceId === undefined) {
            throw new Error('Required parameter appearanceId was null or undefined when calling getAppearanceShortCode.');
        }

        if (mode === null || mode === undefined) {
            throw new Error('Required parameter mode was null or undefined when calling getAppearanceShortCode.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ShortCode>('get',`${this.basePath}/s/appearances/${encodeURIComponent(String(appearanceId))}/${encodeURIComponent(String(mode))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Find usages of specified Appearance
     * @param appearanceId ID of Appearance to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAppearanceUsages(appearanceId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Reference>>;
    public getAppearanceUsages(appearanceId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Reference>>>;
    public getAppearanceUsages(appearanceId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Reference>>>;
    public getAppearanceUsages(appearanceId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (appearanceId === null || appearanceId === undefined) {
            throw new Error('Required parameter appearanceId was null or undefined when calling getAppearanceUsages.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Reference>>('get',`${this.basePath}/appearances/${encodeURIComponent(String(appearanceId))}/usages`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get version summary of specified Appearance
     * @param appearanceId ID of Appearance to return
     * @param versionId Version to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAppearanceVersionSummary(appearanceId: string, versionId: string, observe?: 'body', reportProgress?: boolean): Observable<VersionLogVersion>;
    public getAppearanceVersionSummary(appearanceId: string, versionId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLogVersion>>;
    public getAppearanceVersionSummary(appearanceId: string, versionId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLogVersion>>;
    public getAppearanceVersionSummary(appearanceId: string, versionId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (appearanceId === null || appearanceId === undefined) {
            throw new Error('Required parameter appearanceId was null or undefined when calling getAppearanceVersionSummary.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getAppearanceVersionSummary.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLogVersion>('get',`${this.basePath}/appearances/${encodeURIComponent(String(appearanceId))}/log/${encodeURIComponent(String(versionId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Find usages of specified Appearance version
     * @param appearanceId ID of Appearance to return
     * @param versionId Version of Appearance to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAppearanceVersionUsages(appearanceId: string, versionId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Reference>>;
    public getAppearanceVersionUsages(appearanceId: string, versionId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Reference>>>;
    public getAppearanceVersionUsages(appearanceId: string, versionId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Reference>>>;
    public getAppearanceVersionUsages(appearanceId: string, versionId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (appearanceId === null || appearanceId === undefined) {
            throw new Error('Required parameter appearanceId was null or undefined when calling getAppearanceVersionUsages.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getAppearanceVersionUsages.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Reference>>('get',`${this.basePath}/appearances/${encodeURIComponent(String(appearanceId))}/v/${encodeURIComponent(String(versionId))}/usages`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get specified version of specified Company
     * @param companyId ID of Company to return
     * @param versionId Version of Company to return
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param ifNoneMatch Version of Company already received, if no change to Company since version then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCompany(companyId: string, versionId: string, multiWorkspace?: boolean, complete?: boolean, ifNoneMatch?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedCompany>;
    public getCompany(companyId: string, versionId: string, multiWorkspace?: boolean, complete?: boolean, ifNoneMatch?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedCompany>>;
    public getCompany(companyId: string, versionId: string, multiWorkspace?: boolean, complete?: boolean, ifNoneMatch?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedCompany>>;
    public getCompany(companyId: string, versionId: string, multiWorkspace?: boolean, complete?: boolean, ifNoneMatch?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling getCompany.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getCompany.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;
        if (ifNoneMatch !== undefined && ifNoneMatch !== null) {
            headers = headers.set('If-None-Match', String(ifNoneMatch));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedCompany>('get',`${this.basePath}/companies/${encodeURIComponent(String(companyId))}/v/${encodeURIComponent(String(versionId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param companyId ID of Company
     * @param versionId Version of Company
     * @param ifNoneMatch Version of Artefact already received, if no change to Artefact since version then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCompanyAvatar(companyId: string, versionId: string, ifNoneMatch?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedArtefact>;
    public getCompanyAvatar(companyId: string, versionId: string, ifNoneMatch?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedArtefact>>;
    public getCompanyAvatar(companyId: string, versionId: string, ifNoneMatch?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedArtefact>>;
    public getCompanyAvatar(companyId: string, versionId: string, ifNoneMatch?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling getCompanyAvatar.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getCompanyAvatar.');
        }


        let headers = this.defaultHeaders;
        if (ifNoneMatch !== undefined && ifNoneMatch !== null) {
            headers = headers.set('If-None-Match', String(ifNoneMatch));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedArtefact>('get',`${this.basePath}/companies/${encodeURIComponent(String(companyId))}/v/${encodeURIComponent(String(versionId))}/avatar`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param companyId ID of Company to return
     * @param versionId Version of Company
     * @param extension File extension requested
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCompanyAvatarContent(companyId: string, versionId: string, extension: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public getCompanyAvatarContent(companyId: string, versionId: string, extension: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public getCompanyAvatarContent(companyId: string, versionId: string, extension: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public getCompanyAvatarContent(companyId: string, versionId: string, extension: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling getCompanyAvatarContent.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getCompanyAvatarContent.');
        }

        if (extension === null || extension === undefined) {
            throw new Error('Required parameter extension was null or undefined when calling getCompanyAvatarContent.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'binary/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<string>('get',`${this.basePath}/companies/${encodeURIComponent(String(companyId))}/v/${encodeURIComponent(String(versionId))}/avatar.${encodeURIComponent(String(extension))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get diff between versions of Companies
     * @param companyId1 ID of first Company to compare
     * @param versionId1 Version of first Company to compare
     * @param companyId2 ID of second Company to compare
     * @param versionId2 Version of second Company to compare
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCompanyDiff(companyId1: string, versionId1: string, companyId2: string, versionId2: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedCompanyDiff>;
    public getCompanyDiff(companyId1: string, versionId1: string, companyId2: string, versionId2: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedCompanyDiff>>;
    public getCompanyDiff(companyId1: string, versionId1: string, companyId2: string, versionId2: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedCompanyDiff>>;
    public getCompanyDiff(companyId1: string, versionId1: string, companyId2: string, versionId2: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (companyId1 === null || companyId1 === undefined) {
            throw new Error('Required parameter companyId1 was null or undefined when calling getCompanyDiff.');
        }

        if (versionId1 === null || versionId1 === undefined) {
            throw new Error('Required parameter versionId1 was null or undefined when calling getCompanyDiff.');
        }

        if (companyId2 === null || companyId2 === undefined) {
            throw new Error('Required parameter companyId2 was null or undefined when calling getCompanyDiff.');
        }

        if (versionId2 === null || versionId2 === undefined) {
            throw new Error('Required parameter versionId2 was null or undefined when calling getCompanyDiff.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedCompanyDiff>('get',`${this.basePath}/companies/${encodeURIComponent(String(companyId1))}/v/${encodeURIComponent(String(versionId1))}/diff/${encodeURIComponent(String(companyId2))}/v/${encodeURIComponent(String(versionId2))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get latest version of specified Company
     * @param companyId ID of Company to return
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param eTag Version of Company considered to be HEAD, if no newer Company is found then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCompanyHead(companyId: string, multiWorkspace?: boolean, complete?: boolean, eTag?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedCompany>;
    public getCompanyHead(companyId: string, multiWorkspace?: boolean, complete?: boolean, eTag?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedCompany>>;
    public getCompanyHead(companyId: string, multiWorkspace?: boolean, complete?: boolean, eTag?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedCompany>>;
    public getCompanyHead(companyId: string, multiWorkspace?: boolean, complete?: boolean, eTag?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling getCompanyHead.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;
        if (eTag !== undefined && eTag !== null) {
            headers = headers.set('ETag', String(eTag));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedCompany>('get',`${this.basePath}/companies/${encodeURIComponent(String(companyId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param companyId ID of Company
     * @param eTag Version of Artefact considered to be HEAD, if no newer Artefact is found then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCompanyHeadAvatar(companyId: string, eTag?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedArtefact>;
    public getCompanyHeadAvatar(companyId: string, eTag?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedArtefact>>;
    public getCompanyHeadAvatar(companyId: string, eTag?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedArtefact>>;
    public getCompanyHeadAvatar(companyId: string, eTag?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling getCompanyHeadAvatar.');
        }


        let headers = this.defaultHeaders;
        if (eTag !== undefined && eTag !== null) {
            headers = headers.set('ETag', String(eTag));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedArtefact>('get',`${this.basePath}/companies/${encodeURIComponent(String(companyId))}/avatar`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param companyId ID of Company to return
     * @param extension File extension requested
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCompanyHeadAvatarContent(companyId: string, extension: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public getCompanyHeadAvatarContent(companyId: string, extension: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public getCompanyHeadAvatarContent(companyId: string, extension: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public getCompanyHeadAvatarContent(companyId: string, extension: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling getCompanyHeadAvatarContent.');
        }

        if (extension === null || extension === undefined) {
            throw new Error('Required parameter extension was null or undefined when calling getCompanyHeadAvatarContent.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'binary/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<string>('get',`${this.basePath}/companies/${encodeURIComponent(String(companyId))}/avatar.${encodeURIComponent(String(extension))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param companyId ID of Company to return
     * @param from First version to consider in log
     * @param to Last version to consider in log
     * @param limit Maximum number of versions in log
     * @param tag Tag of version to find
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCompanyHeadAvatarLog(companyId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionLog>;
    public getCompanyHeadAvatarLog(companyId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLog>>;
    public getCompanyHeadAvatarLog(companyId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLog>>;
    public getCompanyHeadAvatarLog(companyId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling getCompanyHeadAvatarLog.');
        }







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from);
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('to', <any>to);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (tag !== undefined && tag !== null) {
            queryParameters = queryParameters.set('tag', <any>tag);
        }
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLog>('get',`${this.basePath}/companies/${encodeURIComponent(String(companyId))}/avatar/log`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get history of specified Company
     * @param companyId ID of Company to return
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCompanyHistory(companyId: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionGraph>;
    public getCompanyHistory(companyId: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionGraph>>;
    public getCompanyHistory(companyId: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionGraph>>;
    public getCompanyHistory(companyId: string, multiWorkspace?: boolean, complete?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling getCompanyHistory.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionGraph>('get',`${this.basePath}/companies/${encodeURIComponent(String(companyId))}/history`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get log of specified Company
     * @param companyId ID of Company to return
     * @param from First version to consider in log
     * @param to Last version to consider in log
     * @param limit Maximum number of versions in log
     * @param tag Tag of version to find
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCompanyLog(companyId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionLog>;
    public getCompanyLog(companyId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLog>>;
    public getCompanyLog(companyId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLog>>;
    public getCompanyLog(companyId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling getCompanyLog.');
        }







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from);
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('to', <any>to);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (tag !== undefined && tag !== null) {
            queryParameters = queryParameters.set('tag', <any>tag);
        }
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLog>('get',`${this.basePath}/companies/${encodeURIComponent(String(companyId))}/log`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get Company permissions
     * @param companyId ID of Company to check
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCompanyPermissions(companyId: string, observe?: 'body', reportProgress?: boolean): Observable<UserPermissions>;
    public getCompanyPermissions(companyId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserPermissions>>;
    public getCompanyPermissions(companyId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserPermissions>>;
    public getCompanyPermissions(companyId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling getCompanyPermissions.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<UserPermissions>('get',`${this.basePath}/companies/${encodeURIComponent(String(companyId))}/permissions`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get sharing details of specified Company
     * @param companyId ID of Company to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCompanySharing(companyId: string, observe?: 'body', reportProgress?: boolean): Observable<Sharing>;
    public getCompanySharing(companyId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Sharing>>;
    public getCompanySharing(companyId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Sharing>>;
    public getCompanySharing(companyId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling getCompanySharing.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Sharing>('get',`${this.basePath}/companies/${encodeURIComponent(String(companyId))}/sharing`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get short code to share the specified Company
     * @param companyId ID of Company to share
     * @param mode Mode: copy or view or edit
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCompanyShortCode(companyId: string, mode: string, observe?: 'body', reportProgress?: boolean): Observable<ShortCode>;
    public getCompanyShortCode(companyId: string, mode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ShortCode>>;
    public getCompanyShortCode(companyId: string, mode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ShortCode>>;
    public getCompanyShortCode(companyId: string, mode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling getCompanyShortCode.');
        }

        if (mode === null || mode === undefined) {
            throw new Error('Required parameter mode was null or undefined when calling getCompanyShortCode.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ShortCode>('get',`${this.basePath}/s/companies/${encodeURIComponent(String(companyId))}/${encodeURIComponent(String(mode))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Find usages of specified Company
     * @param companyId ID of Company to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCompanyUsages(companyId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Reference>>;
    public getCompanyUsages(companyId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Reference>>>;
    public getCompanyUsages(companyId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Reference>>>;
    public getCompanyUsages(companyId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling getCompanyUsages.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Reference>>('get',`${this.basePath}/companies/${encodeURIComponent(String(companyId))}/usages`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get version summary of specified Company
     * @param companyId ID of Company to return
     * @param versionId Version to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCompanyVersionSummary(companyId: string, versionId: string, observe?: 'body', reportProgress?: boolean): Observable<VersionLogVersion>;
    public getCompanyVersionSummary(companyId: string, versionId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLogVersion>>;
    public getCompanyVersionSummary(companyId: string, versionId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLogVersion>>;
    public getCompanyVersionSummary(companyId: string, versionId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling getCompanyVersionSummary.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getCompanyVersionSummary.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLogVersion>('get',`${this.basePath}/companies/${encodeURIComponent(String(companyId))}/log/${encodeURIComponent(String(versionId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Find usages of specified Company version
     * @param companyId ID of Company to return
     * @param versionId Version of Company to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCompanyVersionUsages(companyId: string, versionId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Reference>>;
    public getCompanyVersionUsages(companyId: string, versionId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Reference>>>;
    public getCompanyVersionUsages(companyId: string, versionId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Reference>>>;
    public getCompanyVersionUsages(companyId: string, versionId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling getCompanyVersionUsages.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getCompanyVersionUsages.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Reference>>('get',`${this.basePath}/companies/${encodeURIComponent(String(companyId))}/v/${encodeURIComponent(String(versionId))}/usages`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get specified version of specified ConnectorType
     * @param connectortypeId ID of ConnectorType to return
     * @param versionId Version of ConnectorType to return
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param ifNoneMatch Version of ConnectorType already received, if no change to ConnectorType since version then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConnectorType(connectortypeId: string, versionId: string, multiWorkspace?: boolean, complete?: boolean, ifNoneMatch?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedConnectorType>;
    public getConnectorType(connectortypeId: string, versionId: string, multiWorkspace?: boolean, complete?: boolean, ifNoneMatch?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedConnectorType>>;
    public getConnectorType(connectortypeId: string, versionId: string, multiWorkspace?: boolean, complete?: boolean, ifNoneMatch?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedConnectorType>>;
    public getConnectorType(connectortypeId: string, versionId: string, multiWorkspace?: boolean, complete?: boolean, ifNoneMatch?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (connectortypeId === null || connectortypeId === undefined) {
            throw new Error('Required parameter connectortypeId was null or undefined when calling getConnectorType.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getConnectorType.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;
        if (ifNoneMatch !== undefined && ifNoneMatch !== null) {
            headers = headers.set('If-None-Match', String(ifNoneMatch));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedConnectorType>('get',`${this.basePath}/connectortypes/${encodeURIComponent(String(connectortypeId))}/v/${encodeURIComponent(String(versionId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param connectortypeId ID of ConnectorType
     * @param versionId Version of ConnectorType
     * @param connectorsId 
     * @param ifNoneMatch Version of Assembly already received, if no change to Assembly since version then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConnectorTypeConnectorsSourceAssembly(connectortypeId: string, versionId: string, connectorsId: string, ifNoneMatch?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedAssembly>;
    public getConnectorTypeConnectorsSourceAssembly(connectortypeId: string, versionId: string, connectorsId: string, ifNoneMatch?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedAssembly>>;
    public getConnectorTypeConnectorsSourceAssembly(connectortypeId: string, versionId: string, connectorsId: string, ifNoneMatch?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedAssembly>>;
    public getConnectorTypeConnectorsSourceAssembly(connectortypeId: string, versionId: string, connectorsId: string, ifNoneMatch?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (connectortypeId === null || connectortypeId === undefined) {
            throw new Error('Required parameter connectortypeId was null or undefined when calling getConnectorTypeConnectorsSourceAssembly.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getConnectorTypeConnectorsSourceAssembly.');
        }

        if (connectorsId === null || connectorsId === undefined) {
            throw new Error('Required parameter connectorsId was null or undefined when calling getConnectorTypeConnectorsSourceAssembly.');
        }


        let headers = this.defaultHeaders;
        if (ifNoneMatch !== undefined && ifNoneMatch !== null) {
            headers = headers.set('If-None-Match', String(ifNoneMatch));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedAssembly>('get',`${this.basePath}/connectortypes/${encodeURIComponent(String(connectortypeId))}/v/${encodeURIComponent(String(versionId))}/connectors/${encodeURIComponent(String(connectorsId))}/source/assembly`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param connectortypeId ID of ConnectorType
     * @param versionId Version of ConnectorType
     * @param connectorsId 
     * @param partsId 
     * @param ifNoneMatch Version of PartGeometry already received, if no change to PartGeometry since version then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConnectorTypeConnectorsSourceAssemblyPartsGeometry(connectortypeId: string, versionId: string, connectorsId: string, partsId: string, ifNoneMatch?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedPartGeometry>;
    public getConnectorTypeConnectorsSourceAssemblyPartsGeometry(connectortypeId: string, versionId: string, connectorsId: string, partsId: string, ifNoneMatch?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedPartGeometry>>;
    public getConnectorTypeConnectorsSourceAssemblyPartsGeometry(connectortypeId: string, versionId: string, connectorsId: string, partsId: string, ifNoneMatch?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedPartGeometry>>;
    public getConnectorTypeConnectorsSourceAssemblyPartsGeometry(connectortypeId: string, versionId: string, connectorsId: string, partsId: string, ifNoneMatch?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (connectortypeId === null || connectortypeId === undefined) {
            throw new Error('Required parameter connectortypeId was null or undefined when calling getConnectorTypeConnectorsSourceAssemblyPartsGeometry.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getConnectorTypeConnectorsSourceAssemblyPartsGeometry.');
        }

        if (connectorsId === null || connectorsId === undefined) {
            throw new Error('Required parameter connectorsId was null or undefined when calling getConnectorTypeConnectorsSourceAssemblyPartsGeometry.');
        }

        if (partsId === null || partsId === undefined) {
            throw new Error('Required parameter partsId was null or undefined when calling getConnectorTypeConnectorsSourceAssemblyPartsGeometry.');
        }


        let headers = this.defaultHeaders;
        if (ifNoneMatch !== undefined && ifNoneMatch !== null) {
            headers = headers.set('If-None-Match', String(ifNoneMatch));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedPartGeometry>('get',`${this.basePath}/connectortypes/${encodeURIComponent(String(connectortypeId))}/v/${encodeURIComponent(String(versionId))}/connectors/${encodeURIComponent(String(connectorsId))}/source/assembly/parts/${encodeURIComponent(String(partsId))}/geometry`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param connectortypeId ID of ConnectorType
     * @param versionId Version of ConnectorType
     * @param connectorsId 
     * @param subassembliesId 
     * @param partsId 
     * @param ifNoneMatch Version of PartGeometry already received, if no change to PartGeometry since version then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConnectorTypeConnectorsSourceAssemblySubassembliesPartsGeometry(connectortypeId: string, versionId: string, connectorsId: string, subassembliesId: string, partsId: string, ifNoneMatch?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedPartGeometry>;
    public getConnectorTypeConnectorsSourceAssemblySubassembliesPartsGeometry(connectortypeId: string, versionId: string, connectorsId: string, subassembliesId: string, partsId: string, ifNoneMatch?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedPartGeometry>>;
    public getConnectorTypeConnectorsSourceAssemblySubassembliesPartsGeometry(connectortypeId: string, versionId: string, connectorsId: string, subassembliesId: string, partsId: string, ifNoneMatch?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedPartGeometry>>;
    public getConnectorTypeConnectorsSourceAssemblySubassembliesPartsGeometry(connectortypeId: string, versionId: string, connectorsId: string, subassembliesId: string, partsId: string, ifNoneMatch?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (connectortypeId === null || connectortypeId === undefined) {
            throw new Error('Required parameter connectortypeId was null or undefined when calling getConnectorTypeConnectorsSourceAssemblySubassembliesPartsGeometry.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getConnectorTypeConnectorsSourceAssemblySubassembliesPartsGeometry.');
        }

        if (connectorsId === null || connectorsId === undefined) {
            throw new Error('Required parameter connectorsId was null or undefined when calling getConnectorTypeConnectorsSourceAssemblySubassembliesPartsGeometry.');
        }

        if (subassembliesId === null || subassembliesId === undefined) {
            throw new Error('Required parameter subassembliesId was null or undefined when calling getConnectorTypeConnectorsSourceAssemblySubassembliesPartsGeometry.');
        }

        if (partsId === null || partsId === undefined) {
            throw new Error('Required parameter partsId was null or undefined when calling getConnectorTypeConnectorsSourceAssemblySubassembliesPartsGeometry.');
        }


        let headers = this.defaultHeaders;
        if (ifNoneMatch !== undefined && ifNoneMatch !== null) {
            headers = headers.set('If-None-Match', String(ifNoneMatch));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedPartGeometry>('get',`${this.basePath}/connectortypes/${encodeURIComponent(String(connectortypeId))}/v/${encodeURIComponent(String(versionId))}/connectors/${encodeURIComponent(String(connectorsId))}/source/assembly/subassemblies/${encodeURIComponent(String(subassembliesId))}/parts/${encodeURIComponent(String(partsId))}/geometry`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param connectortypeId ID of ConnectorType
     * @param versionId Version of ConnectorType
     * @param connectorsId 
     * @param ifNoneMatch Version of Assembly already received, if no change to Assembly since version then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConnectorTypeConnectorsSourceFileAssembly(connectortypeId: string, versionId: string, connectorsId: string, ifNoneMatch?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedAssembly>;
    public getConnectorTypeConnectorsSourceFileAssembly(connectortypeId: string, versionId: string, connectorsId: string, ifNoneMatch?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedAssembly>>;
    public getConnectorTypeConnectorsSourceFileAssembly(connectortypeId: string, versionId: string, connectorsId: string, ifNoneMatch?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedAssembly>>;
    public getConnectorTypeConnectorsSourceFileAssembly(connectortypeId: string, versionId: string, connectorsId: string, ifNoneMatch?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (connectortypeId === null || connectortypeId === undefined) {
            throw new Error('Required parameter connectortypeId was null or undefined when calling getConnectorTypeConnectorsSourceFileAssembly.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getConnectorTypeConnectorsSourceFileAssembly.');
        }

        if (connectorsId === null || connectorsId === undefined) {
            throw new Error('Required parameter connectorsId was null or undefined when calling getConnectorTypeConnectorsSourceFileAssembly.');
        }


        let headers = this.defaultHeaders;
        if (ifNoneMatch !== undefined && ifNoneMatch !== null) {
            headers = headers.set('If-None-Match', String(ifNoneMatch));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedAssembly>('get',`${this.basePath}/connectortypes/${encodeURIComponent(String(connectortypeId))}/v/${encodeURIComponent(String(versionId))}/connectors/${encodeURIComponent(String(connectorsId))}/source/file/assembly`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param connectortypeId ID of ConnectorType
     * @param versionId Version of ConnectorType
     * @param connectorsId 
     * @param partsId 
     * @param ifNoneMatch Version of PartGeometry already received, if no change to PartGeometry since version then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConnectorTypeConnectorsSourceFileAssemblyPartsGeometry(connectortypeId: string, versionId: string, connectorsId: string, partsId: string, ifNoneMatch?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedPartGeometry>;
    public getConnectorTypeConnectorsSourceFileAssemblyPartsGeometry(connectortypeId: string, versionId: string, connectorsId: string, partsId: string, ifNoneMatch?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedPartGeometry>>;
    public getConnectorTypeConnectorsSourceFileAssemblyPartsGeometry(connectortypeId: string, versionId: string, connectorsId: string, partsId: string, ifNoneMatch?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedPartGeometry>>;
    public getConnectorTypeConnectorsSourceFileAssemblyPartsGeometry(connectortypeId: string, versionId: string, connectorsId: string, partsId: string, ifNoneMatch?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (connectortypeId === null || connectortypeId === undefined) {
            throw new Error('Required parameter connectortypeId was null or undefined when calling getConnectorTypeConnectorsSourceFileAssemblyPartsGeometry.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getConnectorTypeConnectorsSourceFileAssemblyPartsGeometry.');
        }

        if (connectorsId === null || connectorsId === undefined) {
            throw new Error('Required parameter connectorsId was null or undefined when calling getConnectorTypeConnectorsSourceFileAssemblyPartsGeometry.');
        }

        if (partsId === null || partsId === undefined) {
            throw new Error('Required parameter partsId was null or undefined when calling getConnectorTypeConnectorsSourceFileAssemblyPartsGeometry.');
        }


        let headers = this.defaultHeaders;
        if (ifNoneMatch !== undefined && ifNoneMatch !== null) {
            headers = headers.set('If-None-Match', String(ifNoneMatch));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedPartGeometry>('get',`${this.basePath}/connectortypes/${encodeURIComponent(String(connectortypeId))}/v/${encodeURIComponent(String(versionId))}/connectors/${encodeURIComponent(String(connectorsId))}/source/file/assembly/parts/${encodeURIComponent(String(partsId))}/geometry`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param connectortypeId ID of ConnectorType
     * @param versionId Version of ConnectorType
     * @param connectorsId 
     * @param subassembliesId 
     * @param partsId 
     * @param ifNoneMatch Version of PartGeometry already received, if no change to PartGeometry since version then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConnectorTypeConnectorsSourceFileAssemblySubassembliesPartsGeometry(connectortypeId: string, versionId: string, connectorsId: string, subassembliesId: string, partsId: string, ifNoneMatch?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedPartGeometry>;
    public getConnectorTypeConnectorsSourceFileAssemblySubassembliesPartsGeometry(connectortypeId: string, versionId: string, connectorsId: string, subassembliesId: string, partsId: string, ifNoneMatch?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedPartGeometry>>;
    public getConnectorTypeConnectorsSourceFileAssemblySubassembliesPartsGeometry(connectortypeId: string, versionId: string, connectorsId: string, subassembliesId: string, partsId: string, ifNoneMatch?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedPartGeometry>>;
    public getConnectorTypeConnectorsSourceFileAssemblySubassembliesPartsGeometry(connectortypeId: string, versionId: string, connectorsId: string, subassembliesId: string, partsId: string, ifNoneMatch?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (connectortypeId === null || connectortypeId === undefined) {
            throw new Error('Required parameter connectortypeId was null or undefined when calling getConnectorTypeConnectorsSourceFileAssemblySubassembliesPartsGeometry.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getConnectorTypeConnectorsSourceFileAssemblySubassembliesPartsGeometry.');
        }

        if (connectorsId === null || connectorsId === undefined) {
            throw new Error('Required parameter connectorsId was null or undefined when calling getConnectorTypeConnectorsSourceFileAssemblySubassembliesPartsGeometry.');
        }

        if (subassembliesId === null || subassembliesId === undefined) {
            throw new Error('Required parameter subassembliesId was null or undefined when calling getConnectorTypeConnectorsSourceFileAssemblySubassembliesPartsGeometry.');
        }

        if (partsId === null || partsId === undefined) {
            throw new Error('Required parameter partsId was null or undefined when calling getConnectorTypeConnectorsSourceFileAssemblySubassembliesPartsGeometry.');
        }


        let headers = this.defaultHeaders;
        if (ifNoneMatch !== undefined && ifNoneMatch !== null) {
            headers = headers.set('If-None-Match', String(ifNoneMatch));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedPartGeometry>('get',`${this.basePath}/connectortypes/${encodeURIComponent(String(connectortypeId))}/v/${encodeURIComponent(String(versionId))}/connectors/${encodeURIComponent(String(connectorsId))}/source/file/assembly/subassemblies/${encodeURIComponent(String(subassembliesId))}/parts/${encodeURIComponent(String(partsId))}/geometry`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param connectortypeId ID of ConnectorType
     * @param versionId Version of ConnectorType
     * @param connectorsId 
     * @param ifNoneMatch Version of Artefact already received, if no change to Artefact since version then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConnectorTypeConnectorsSourceFileFile(connectortypeId: string, versionId: string, connectorsId: string, ifNoneMatch?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedArtefact>;
    public getConnectorTypeConnectorsSourceFileFile(connectortypeId: string, versionId: string, connectorsId: string, ifNoneMatch?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedArtefact>>;
    public getConnectorTypeConnectorsSourceFileFile(connectortypeId: string, versionId: string, connectorsId: string, ifNoneMatch?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedArtefact>>;
    public getConnectorTypeConnectorsSourceFileFile(connectortypeId: string, versionId: string, connectorsId: string, ifNoneMatch?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (connectortypeId === null || connectortypeId === undefined) {
            throw new Error('Required parameter connectortypeId was null or undefined when calling getConnectorTypeConnectorsSourceFileFile.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getConnectorTypeConnectorsSourceFileFile.');
        }

        if (connectorsId === null || connectorsId === undefined) {
            throw new Error('Required parameter connectorsId was null or undefined when calling getConnectorTypeConnectorsSourceFileFile.');
        }


        let headers = this.defaultHeaders;
        if (ifNoneMatch !== undefined && ifNoneMatch !== null) {
            headers = headers.set('If-None-Match', String(ifNoneMatch));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedArtefact>('get',`${this.basePath}/connectortypes/${encodeURIComponent(String(connectortypeId))}/v/${encodeURIComponent(String(versionId))}/connectors/${encodeURIComponent(String(connectorsId))}/source/file/file`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param connectortypeId ID of ConnectorType to return
     * @param versionId Version of ConnectorType
     * @param connectorsId 
     * @param extension File extension requested
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConnectorTypeConnectorsSourceFileFileContent(connectortypeId: string, versionId: string, connectorsId: string, extension: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public getConnectorTypeConnectorsSourceFileFileContent(connectortypeId: string, versionId: string, connectorsId: string, extension: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public getConnectorTypeConnectorsSourceFileFileContent(connectortypeId: string, versionId: string, connectorsId: string, extension: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public getConnectorTypeConnectorsSourceFileFileContent(connectortypeId: string, versionId: string, connectorsId: string, extension: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (connectortypeId === null || connectortypeId === undefined) {
            throw new Error('Required parameter connectortypeId was null or undefined when calling getConnectorTypeConnectorsSourceFileFileContent.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getConnectorTypeConnectorsSourceFileFileContent.');
        }

        if (connectorsId === null || connectorsId === undefined) {
            throw new Error('Required parameter connectorsId was null or undefined when calling getConnectorTypeConnectorsSourceFileFileContent.');
        }

        if (extension === null || extension === undefined) {
            throw new Error('Required parameter extension was null or undefined when calling getConnectorTypeConnectorsSourceFileFileContent.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'binary/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<string>('get',`${this.basePath}/connectortypes/${encodeURIComponent(String(connectortypeId))}/v/${encodeURIComponent(String(versionId))}/connectors/${encodeURIComponent(String(connectorsId))}/source/file/file.${encodeURIComponent(String(extension))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param connectortypeId ID of ConnectorType
     * @param versionId Version of ConnectorType
     * @param connectorsId 
     * @param ifNoneMatch Version of Artefact already received, if no change to Artefact since version then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConnectorTypeConnectorsSourceImage(connectortypeId: string, versionId: string, connectorsId: string, ifNoneMatch?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedArtefact>;
    public getConnectorTypeConnectorsSourceImage(connectortypeId: string, versionId: string, connectorsId: string, ifNoneMatch?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedArtefact>>;
    public getConnectorTypeConnectorsSourceImage(connectortypeId: string, versionId: string, connectorsId: string, ifNoneMatch?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedArtefact>>;
    public getConnectorTypeConnectorsSourceImage(connectortypeId: string, versionId: string, connectorsId: string, ifNoneMatch?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (connectortypeId === null || connectortypeId === undefined) {
            throw new Error('Required parameter connectortypeId was null or undefined when calling getConnectorTypeConnectorsSourceImage.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getConnectorTypeConnectorsSourceImage.');
        }

        if (connectorsId === null || connectorsId === undefined) {
            throw new Error('Required parameter connectorsId was null or undefined when calling getConnectorTypeConnectorsSourceImage.');
        }


        let headers = this.defaultHeaders;
        if (ifNoneMatch !== undefined && ifNoneMatch !== null) {
            headers = headers.set('If-None-Match', String(ifNoneMatch));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedArtefact>('get',`${this.basePath}/connectortypes/${encodeURIComponent(String(connectortypeId))}/v/${encodeURIComponent(String(versionId))}/connectors/${encodeURIComponent(String(connectorsId))}/source/image`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param connectortypeId ID of ConnectorType to return
     * @param versionId Version of ConnectorType
     * @param connectorsId 
     * @param extension File extension requested
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConnectorTypeConnectorsSourceImageContent(connectortypeId: string, versionId: string, connectorsId: string, extension: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public getConnectorTypeConnectorsSourceImageContent(connectortypeId: string, versionId: string, connectorsId: string, extension: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public getConnectorTypeConnectorsSourceImageContent(connectortypeId: string, versionId: string, connectorsId: string, extension: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public getConnectorTypeConnectorsSourceImageContent(connectortypeId: string, versionId: string, connectorsId: string, extension: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (connectortypeId === null || connectortypeId === undefined) {
            throw new Error('Required parameter connectortypeId was null or undefined when calling getConnectorTypeConnectorsSourceImageContent.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getConnectorTypeConnectorsSourceImageContent.');
        }

        if (connectorsId === null || connectorsId === undefined) {
            throw new Error('Required parameter connectorsId was null or undefined when calling getConnectorTypeConnectorsSourceImageContent.');
        }

        if (extension === null || extension === undefined) {
            throw new Error('Required parameter extension was null or undefined when calling getConnectorTypeConnectorsSourceImageContent.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'binary/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<string>('get',`${this.basePath}/connectortypes/${encodeURIComponent(String(connectortypeId))}/v/${encodeURIComponent(String(versionId))}/connectors/${encodeURIComponent(String(connectorsId))}/source/image.${encodeURIComponent(String(extension))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param connectortypeId ID of ConnectorType
     * @param versionId Version of ConnectorType
     * @param connectorsId 
     * @param ifNoneMatch Version of MaterialStyling already received, if no change to MaterialStyling since version then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConnectorTypeConnectorsSourceMaterial(connectortypeId: string, versionId: string, connectorsId: string, ifNoneMatch?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedMaterialStyling>;
    public getConnectorTypeConnectorsSourceMaterial(connectortypeId: string, versionId: string, connectorsId: string, ifNoneMatch?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedMaterialStyling>>;
    public getConnectorTypeConnectorsSourceMaterial(connectortypeId: string, versionId: string, connectorsId: string, ifNoneMatch?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedMaterialStyling>>;
    public getConnectorTypeConnectorsSourceMaterial(connectortypeId: string, versionId: string, connectorsId: string, ifNoneMatch?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (connectortypeId === null || connectortypeId === undefined) {
            throw new Error('Required parameter connectortypeId was null or undefined when calling getConnectorTypeConnectorsSourceMaterial.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getConnectorTypeConnectorsSourceMaterial.');
        }

        if (connectorsId === null || connectorsId === undefined) {
            throw new Error('Required parameter connectorsId was null or undefined when calling getConnectorTypeConnectorsSourceMaterial.');
        }


        let headers = this.defaultHeaders;
        if (ifNoneMatch !== undefined && ifNoneMatch !== null) {
            headers = headers.set('If-None-Match', String(ifNoneMatch));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedMaterialStyling>('get',`${this.basePath}/connectortypes/${encodeURIComponent(String(connectortypeId))}/v/${encodeURIComponent(String(versionId))}/connectors/${encodeURIComponent(String(connectorsId))}/source/material`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param connectortypeId ID of ConnectorType
     * @param versionId Version of ConnectorType
     * @param connectorsId 
     * @param ifNoneMatch Version of Artefact already received, if no change to Artefact since version then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConnectorTypeConnectorsSourceRender(connectortypeId: string, versionId: string, connectorsId: string, ifNoneMatch?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedArtefact>;
    public getConnectorTypeConnectorsSourceRender(connectortypeId: string, versionId: string, connectorsId: string, ifNoneMatch?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedArtefact>>;
    public getConnectorTypeConnectorsSourceRender(connectortypeId: string, versionId: string, connectorsId: string, ifNoneMatch?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedArtefact>>;
    public getConnectorTypeConnectorsSourceRender(connectortypeId: string, versionId: string, connectorsId: string, ifNoneMatch?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (connectortypeId === null || connectortypeId === undefined) {
            throw new Error('Required parameter connectortypeId was null or undefined when calling getConnectorTypeConnectorsSourceRender.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getConnectorTypeConnectorsSourceRender.');
        }

        if (connectorsId === null || connectorsId === undefined) {
            throw new Error('Required parameter connectorsId was null or undefined when calling getConnectorTypeConnectorsSourceRender.');
        }


        let headers = this.defaultHeaders;
        if (ifNoneMatch !== undefined && ifNoneMatch !== null) {
            headers = headers.set('If-None-Match', String(ifNoneMatch));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedArtefact>('get',`${this.basePath}/connectortypes/${encodeURIComponent(String(connectortypeId))}/v/${encodeURIComponent(String(versionId))}/connectors/${encodeURIComponent(String(connectorsId))}/source/render`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param connectortypeId ID of ConnectorType to return
     * @param versionId Version of ConnectorType
     * @param connectorsId 
     * @param extension File extension requested
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConnectorTypeConnectorsSourceRenderContent(connectortypeId: string, versionId: string, connectorsId: string, extension: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public getConnectorTypeConnectorsSourceRenderContent(connectortypeId: string, versionId: string, connectorsId: string, extension: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public getConnectorTypeConnectorsSourceRenderContent(connectortypeId: string, versionId: string, connectorsId: string, extension: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public getConnectorTypeConnectorsSourceRenderContent(connectortypeId: string, versionId: string, connectorsId: string, extension: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (connectortypeId === null || connectortypeId === undefined) {
            throw new Error('Required parameter connectortypeId was null or undefined when calling getConnectorTypeConnectorsSourceRenderContent.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getConnectorTypeConnectorsSourceRenderContent.');
        }

        if (connectorsId === null || connectorsId === undefined) {
            throw new Error('Required parameter connectorsId was null or undefined when calling getConnectorTypeConnectorsSourceRenderContent.');
        }

        if (extension === null || extension === undefined) {
            throw new Error('Required parameter extension was null or undefined when calling getConnectorTypeConnectorsSourceRenderContent.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'binary/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<string>('get',`${this.basePath}/connectortypes/${encodeURIComponent(String(connectortypeId))}/v/${encodeURIComponent(String(versionId))}/connectors/${encodeURIComponent(String(connectorsId))}/source/render.${encodeURIComponent(String(extension))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get diff between versions of ConnectorTypes
     * @param connectortypeId1 ID of first ConnectorType to compare
     * @param versionId1 Version of first ConnectorType to compare
     * @param connectortypeId2 ID of second ConnectorType to compare
     * @param versionId2 Version of second ConnectorType to compare
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConnectorTypeDiff(connectortypeId1: string, versionId1: string, connectortypeId2: string, versionId2: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedConnectorTypeDiff>;
    public getConnectorTypeDiff(connectortypeId1: string, versionId1: string, connectortypeId2: string, versionId2: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedConnectorTypeDiff>>;
    public getConnectorTypeDiff(connectortypeId1: string, versionId1: string, connectortypeId2: string, versionId2: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedConnectorTypeDiff>>;
    public getConnectorTypeDiff(connectortypeId1: string, versionId1: string, connectortypeId2: string, versionId2: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (connectortypeId1 === null || connectortypeId1 === undefined) {
            throw new Error('Required parameter connectortypeId1 was null or undefined when calling getConnectorTypeDiff.');
        }

        if (versionId1 === null || versionId1 === undefined) {
            throw new Error('Required parameter versionId1 was null or undefined when calling getConnectorTypeDiff.');
        }

        if (connectortypeId2 === null || connectortypeId2 === undefined) {
            throw new Error('Required parameter connectortypeId2 was null or undefined when calling getConnectorTypeDiff.');
        }

        if (versionId2 === null || versionId2 === undefined) {
            throw new Error('Required parameter versionId2 was null or undefined when calling getConnectorTypeDiff.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedConnectorTypeDiff>('get',`${this.basePath}/connectortypes/${encodeURIComponent(String(connectortypeId1))}/v/${encodeURIComponent(String(versionId1))}/diff/${encodeURIComponent(String(connectortypeId2))}/v/${encodeURIComponent(String(versionId2))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get latest version of specified ConnectorType
     * @param connectortypeId ID of ConnectorType to return
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param eTag Version of ConnectorType considered to be HEAD, if no newer ConnectorType is found then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConnectorTypeHead(connectortypeId: string, multiWorkspace?: boolean, complete?: boolean, eTag?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedConnectorType>;
    public getConnectorTypeHead(connectortypeId: string, multiWorkspace?: boolean, complete?: boolean, eTag?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedConnectorType>>;
    public getConnectorTypeHead(connectortypeId: string, multiWorkspace?: boolean, complete?: boolean, eTag?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedConnectorType>>;
    public getConnectorTypeHead(connectortypeId: string, multiWorkspace?: boolean, complete?: boolean, eTag?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (connectortypeId === null || connectortypeId === undefined) {
            throw new Error('Required parameter connectortypeId was null or undefined when calling getConnectorTypeHead.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;
        if (eTag !== undefined && eTag !== null) {
            headers = headers.set('ETag', String(eTag));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedConnectorType>('get',`${this.basePath}/connectortypes/${encodeURIComponent(String(connectortypeId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param connectortypeId ID of ConnectorType
     * @param connectorsId 
     * @param eTag Version of Assembly considered to be HEAD, if no newer Assembly is found then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConnectorTypeHeadConnectorsSourceAssembly(connectortypeId: string, connectorsId: string, eTag?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedAssembly>;
    public getConnectorTypeHeadConnectorsSourceAssembly(connectortypeId: string, connectorsId: string, eTag?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedAssembly>>;
    public getConnectorTypeHeadConnectorsSourceAssembly(connectortypeId: string, connectorsId: string, eTag?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedAssembly>>;
    public getConnectorTypeHeadConnectorsSourceAssembly(connectortypeId: string, connectorsId: string, eTag?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (connectortypeId === null || connectortypeId === undefined) {
            throw new Error('Required parameter connectortypeId was null or undefined when calling getConnectorTypeHeadConnectorsSourceAssembly.');
        }

        if (connectorsId === null || connectorsId === undefined) {
            throw new Error('Required parameter connectorsId was null or undefined when calling getConnectorTypeHeadConnectorsSourceAssembly.');
        }


        let headers = this.defaultHeaders;
        if (eTag !== undefined && eTag !== null) {
            headers = headers.set('ETag', String(eTag));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedAssembly>('get',`${this.basePath}/connectortypes/${encodeURIComponent(String(connectortypeId))}/connectors/${encodeURIComponent(String(connectorsId))}/source/assembly`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param connectortypeId ID of ConnectorType to return
     * @param connectorsId 
     * @param from First version to consider in log
     * @param to Last version to consider in log
     * @param limit Maximum number of versions in log
     * @param tag Tag of version to find
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConnectorTypeHeadConnectorsSourceAssemblyLog(connectortypeId: string, connectorsId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionLog>;
    public getConnectorTypeHeadConnectorsSourceAssemblyLog(connectortypeId: string, connectorsId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLog>>;
    public getConnectorTypeHeadConnectorsSourceAssemblyLog(connectortypeId: string, connectorsId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLog>>;
    public getConnectorTypeHeadConnectorsSourceAssemblyLog(connectortypeId: string, connectorsId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (connectortypeId === null || connectortypeId === undefined) {
            throw new Error('Required parameter connectortypeId was null or undefined when calling getConnectorTypeHeadConnectorsSourceAssemblyLog.');
        }

        if (connectorsId === null || connectorsId === undefined) {
            throw new Error('Required parameter connectorsId was null or undefined when calling getConnectorTypeHeadConnectorsSourceAssemblyLog.');
        }







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from);
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('to', <any>to);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (tag !== undefined && tag !== null) {
            queryParameters = queryParameters.set('tag', <any>tag);
        }
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLog>('get',`${this.basePath}/connectortypes/${encodeURIComponent(String(connectortypeId))}/connectors/${encodeURIComponent(String(connectorsId))}/source/assembly/log`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param connectortypeId ID of ConnectorType
     * @param connectorsId 
     * @param partsId 
     * @param eTag Version of PartGeometry considered to be HEAD, if no newer PartGeometry is found then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConnectorTypeHeadConnectorsSourceAssemblyPartsGeometry(connectortypeId: string, connectorsId: string, partsId: string, eTag?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedPartGeometry>;
    public getConnectorTypeHeadConnectorsSourceAssemblyPartsGeometry(connectortypeId: string, connectorsId: string, partsId: string, eTag?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedPartGeometry>>;
    public getConnectorTypeHeadConnectorsSourceAssemblyPartsGeometry(connectortypeId: string, connectorsId: string, partsId: string, eTag?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedPartGeometry>>;
    public getConnectorTypeHeadConnectorsSourceAssemblyPartsGeometry(connectortypeId: string, connectorsId: string, partsId: string, eTag?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (connectortypeId === null || connectortypeId === undefined) {
            throw new Error('Required parameter connectortypeId was null or undefined when calling getConnectorTypeHeadConnectorsSourceAssemblyPartsGeometry.');
        }

        if (connectorsId === null || connectorsId === undefined) {
            throw new Error('Required parameter connectorsId was null or undefined when calling getConnectorTypeHeadConnectorsSourceAssemblyPartsGeometry.');
        }

        if (partsId === null || partsId === undefined) {
            throw new Error('Required parameter partsId was null or undefined when calling getConnectorTypeHeadConnectorsSourceAssemblyPartsGeometry.');
        }


        let headers = this.defaultHeaders;
        if (eTag !== undefined && eTag !== null) {
            headers = headers.set('ETag', String(eTag));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedPartGeometry>('get',`${this.basePath}/connectortypes/${encodeURIComponent(String(connectortypeId))}/connectors/${encodeURIComponent(String(connectorsId))}/source/assembly/parts/${encodeURIComponent(String(partsId))}/geometry`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param connectortypeId ID of ConnectorType to return
     * @param connectorsId 
     * @param partsId 
     * @param from First version to consider in log
     * @param to Last version to consider in log
     * @param limit Maximum number of versions in log
     * @param tag Tag of version to find
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConnectorTypeHeadConnectorsSourceAssemblyPartsGeometryLog(connectortypeId: string, connectorsId: string, partsId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionLog>;
    public getConnectorTypeHeadConnectorsSourceAssemblyPartsGeometryLog(connectortypeId: string, connectorsId: string, partsId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLog>>;
    public getConnectorTypeHeadConnectorsSourceAssemblyPartsGeometryLog(connectortypeId: string, connectorsId: string, partsId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLog>>;
    public getConnectorTypeHeadConnectorsSourceAssemblyPartsGeometryLog(connectortypeId: string, connectorsId: string, partsId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (connectortypeId === null || connectortypeId === undefined) {
            throw new Error('Required parameter connectortypeId was null or undefined when calling getConnectorTypeHeadConnectorsSourceAssemblyPartsGeometryLog.');
        }

        if (connectorsId === null || connectorsId === undefined) {
            throw new Error('Required parameter connectorsId was null or undefined when calling getConnectorTypeHeadConnectorsSourceAssemblyPartsGeometryLog.');
        }

        if (partsId === null || partsId === undefined) {
            throw new Error('Required parameter partsId was null or undefined when calling getConnectorTypeHeadConnectorsSourceAssemblyPartsGeometryLog.');
        }







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from);
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('to', <any>to);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (tag !== undefined && tag !== null) {
            queryParameters = queryParameters.set('tag', <any>tag);
        }
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLog>('get',`${this.basePath}/connectortypes/${encodeURIComponent(String(connectortypeId))}/connectors/${encodeURIComponent(String(connectorsId))}/source/assembly/parts/${encodeURIComponent(String(partsId))}/geometry/log`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param connectortypeId ID of ConnectorType
     * @param connectorsId 
     * @param subassembliesId 
     * @param partsId 
     * @param eTag Version of PartGeometry considered to be HEAD, if no newer PartGeometry is found then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConnectorTypeHeadConnectorsSourceAssemblySubassembliesPartsGeometry(connectortypeId: string, connectorsId: string, subassembliesId: string, partsId: string, eTag?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedPartGeometry>;
    public getConnectorTypeHeadConnectorsSourceAssemblySubassembliesPartsGeometry(connectortypeId: string, connectorsId: string, subassembliesId: string, partsId: string, eTag?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedPartGeometry>>;
    public getConnectorTypeHeadConnectorsSourceAssemblySubassembliesPartsGeometry(connectortypeId: string, connectorsId: string, subassembliesId: string, partsId: string, eTag?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedPartGeometry>>;
    public getConnectorTypeHeadConnectorsSourceAssemblySubassembliesPartsGeometry(connectortypeId: string, connectorsId: string, subassembliesId: string, partsId: string, eTag?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (connectortypeId === null || connectortypeId === undefined) {
            throw new Error('Required parameter connectortypeId was null or undefined when calling getConnectorTypeHeadConnectorsSourceAssemblySubassembliesPartsGeometry.');
        }

        if (connectorsId === null || connectorsId === undefined) {
            throw new Error('Required parameter connectorsId was null or undefined when calling getConnectorTypeHeadConnectorsSourceAssemblySubassembliesPartsGeometry.');
        }

        if (subassembliesId === null || subassembliesId === undefined) {
            throw new Error('Required parameter subassembliesId was null or undefined when calling getConnectorTypeHeadConnectorsSourceAssemblySubassembliesPartsGeometry.');
        }

        if (partsId === null || partsId === undefined) {
            throw new Error('Required parameter partsId was null or undefined when calling getConnectorTypeHeadConnectorsSourceAssemblySubassembliesPartsGeometry.');
        }


        let headers = this.defaultHeaders;
        if (eTag !== undefined && eTag !== null) {
            headers = headers.set('ETag', String(eTag));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedPartGeometry>('get',`${this.basePath}/connectortypes/${encodeURIComponent(String(connectortypeId))}/connectors/${encodeURIComponent(String(connectorsId))}/source/assembly/subassemblies/${encodeURIComponent(String(subassembliesId))}/parts/${encodeURIComponent(String(partsId))}/geometry`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param connectortypeId ID of ConnectorType to return
     * @param connectorsId 
     * @param subassembliesId 
     * @param partsId 
     * @param from First version to consider in log
     * @param to Last version to consider in log
     * @param limit Maximum number of versions in log
     * @param tag Tag of version to find
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConnectorTypeHeadConnectorsSourceAssemblySubassembliesPartsGeometryLog(connectortypeId: string, connectorsId: string, subassembliesId: string, partsId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionLog>;
    public getConnectorTypeHeadConnectorsSourceAssemblySubassembliesPartsGeometryLog(connectortypeId: string, connectorsId: string, subassembliesId: string, partsId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLog>>;
    public getConnectorTypeHeadConnectorsSourceAssemblySubassembliesPartsGeometryLog(connectortypeId: string, connectorsId: string, subassembliesId: string, partsId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLog>>;
    public getConnectorTypeHeadConnectorsSourceAssemblySubassembliesPartsGeometryLog(connectortypeId: string, connectorsId: string, subassembliesId: string, partsId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (connectortypeId === null || connectortypeId === undefined) {
            throw new Error('Required parameter connectortypeId was null or undefined when calling getConnectorTypeHeadConnectorsSourceAssemblySubassembliesPartsGeometryLog.');
        }

        if (connectorsId === null || connectorsId === undefined) {
            throw new Error('Required parameter connectorsId was null or undefined when calling getConnectorTypeHeadConnectorsSourceAssemblySubassembliesPartsGeometryLog.');
        }

        if (subassembliesId === null || subassembliesId === undefined) {
            throw new Error('Required parameter subassembliesId was null or undefined when calling getConnectorTypeHeadConnectorsSourceAssemblySubassembliesPartsGeometryLog.');
        }

        if (partsId === null || partsId === undefined) {
            throw new Error('Required parameter partsId was null or undefined when calling getConnectorTypeHeadConnectorsSourceAssemblySubassembliesPartsGeometryLog.');
        }







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from);
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('to', <any>to);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (tag !== undefined && tag !== null) {
            queryParameters = queryParameters.set('tag', <any>tag);
        }
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLog>('get',`${this.basePath}/connectortypes/${encodeURIComponent(String(connectortypeId))}/connectors/${encodeURIComponent(String(connectorsId))}/source/assembly/subassemblies/${encodeURIComponent(String(subassembliesId))}/parts/${encodeURIComponent(String(partsId))}/geometry/log`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param connectortypeId ID of ConnectorType
     * @param connectorsId 
     * @param eTag Version of Assembly considered to be HEAD, if no newer Assembly is found then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConnectorTypeHeadConnectorsSourceFileAssembly(connectortypeId: string, connectorsId: string, eTag?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedAssembly>;
    public getConnectorTypeHeadConnectorsSourceFileAssembly(connectortypeId: string, connectorsId: string, eTag?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedAssembly>>;
    public getConnectorTypeHeadConnectorsSourceFileAssembly(connectortypeId: string, connectorsId: string, eTag?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedAssembly>>;
    public getConnectorTypeHeadConnectorsSourceFileAssembly(connectortypeId: string, connectorsId: string, eTag?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (connectortypeId === null || connectortypeId === undefined) {
            throw new Error('Required parameter connectortypeId was null or undefined when calling getConnectorTypeHeadConnectorsSourceFileAssembly.');
        }

        if (connectorsId === null || connectorsId === undefined) {
            throw new Error('Required parameter connectorsId was null or undefined when calling getConnectorTypeHeadConnectorsSourceFileAssembly.');
        }


        let headers = this.defaultHeaders;
        if (eTag !== undefined && eTag !== null) {
            headers = headers.set('ETag', String(eTag));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedAssembly>('get',`${this.basePath}/connectortypes/${encodeURIComponent(String(connectortypeId))}/connectors/${encodeURIComponent(String(connectorsId))}/source/file/assembly`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param connectortypeId ID of ConnectorType to return
     * @param connectorsId 
     * @param from First version to consider in log
     * @param to Last version to consider in log
     * @param limit Maximum number of versions in log
     * @param tag Tag of version to find
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConnectorTypeHeadConnectorsSourceFileAssemblyLog(connectortypeId: string, connectorsId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionLog>;
    public getConnectorTypeHeadConnectorsSourceFileAssemblyLog(connectortypeId: string, connectorsId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLog>>;
    public getConnectorTypeHeadConnectorsSourceFileAssemblyLog(connectortypeId: string, connectorsId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLog>>;
    public getConnectorTypeHeadConnectorsSourceFileAssemblyLog(connectortypeId: string, connectorsId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (connectortypeId === null || connectortypeId === undefined) {
            throw new Error('Required parameter connectortypeId was null or undefined when calling getConnectorTypeHeadConnectorsSourceFileAssemblyLog.');
        }

        if (connectorsId === null || connectorsId === undefined) {
            throw new Error('Required parameter connectorsId was null or undefined when calling getConnectorTypeHeadConnectorsSourceFileAssemblyLog.');
        }







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from);
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('to', <any>to);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (tag !== undefined && tag !== null) {
            queryParameters = queryParameters.set('tag', <any>tag);
        }
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLog>('get',`${this.basePath}/connectortypes/${encodeURIComponent(String(connectortypeId))}/connectors/${encodeURIComponent(String(connectorsId))}/source/file/assembly/log`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param connectortypeId ID of ConnectorType
     * @param connectorsId 
     * @param partsId 
     * @param eTag Version of PartGeometry considered to be HEAD, if no newer PartGeometry is found then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConnectorTypeHeadConnectorsSourceFileAssemblyPartsGeometry(connectortypeId: string, connectorsId: string, partsId: string, eTag?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedPartGeometry>;
    public getConnectorTypeHeadConnectorsSourceFileAssemblyPartsGeometry(connectortypeId: string, connectorsId: string, partsId: string, eTag?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedPartGeometry>>;
    public getConnectorTypeHeadConnectorsSourceFileAssemblyPartsGeometry(connectortypeId: string, connectorsId: string, partsId: string, eTag?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedPartGeometry>>;
    public getConnectorTypeHeadConnectorsSourceFileAssemblyPartsGeometry(connectortypeId: string, connectorsId: string, partsId: string, eTag?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (connectortypeId === null || connectortypeId === undefined) {
            throw new Error('Required parameter connectortypeId was null or undefined when calling getConnectorTypeHeadConnectorsSourceFileAssemblyPartsGeometry.');
        }

        if (connectorsId === null || connectorsId === undefined) {
            throw new Error('Required parameter connectorsId was null or undefined when calling getConnectorTypeHeadConnectorsSourceFileAssemblyPartsGeometry.');
        }

        if (partsId === null || partsId === undefined) {
            throw new Error('Required parameter partsId was null or undefined when calling getConnectorTypeHeadConnectorsSourceFileAssemblyPartsGeometry.');
        }


        let headers = this.defaultHeaders;
        if (eTag !== undefined && eTag !== null) {
            headers = headers.set('ETag', String(eTag));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedPartGeometry>('get',`${this.basePath}/connectortypes/${encodeURIComponent(String(connectortypeId))}/connectors/${encodeURIComponent(String(connectorsId))}/source/file/assembly/parts/${encodeURIComponent(String(partsId))}/geometry`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param connectortypeId ID of ConnectorType to return
     * @param connectorsId 
     * @param partsId 
     * @param from First version to consider in log
     * @param to Last version to consider in log
     * @param limit Maximum number of versions in log
     * @param tag Tag of version to find
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConnectorTypeHeadConnectorsSourceFileAssemblyPartsGeometryLog(connectortypeId: string, connectorsId: string, partsId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionLog>;
    public getConnectorTypeHeadConnectorsSourceFileAssemblyPartsGeometryLog(connectortypeId: string, connectorsId: string, partsId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLog>>;
    public getConnectorTypeHeadConnectorsSourceFileAssemblyPartsGeometryLog(connectortypeId: string, connectorsId: string, partsId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLog>>;
    public getConnectorTypeHeadConnectorsSourceFileAssemblyPartsGeometryLog(connectortypeId: string, connectorsId: string, partsId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (connectortypeId === null || connectortypeId === undefined) {
            throw new Error('Required parameter connectortypeId was null or undefined when calling getConnectorTypeHeadConnectorsSourceFileAssemblyPartsGeometryLog.');
        }

        if (connectorsId === null || connectorsId === undefined) {
            throw new Error('Required parameter connectorsId was null or undefined when calling getConnectorTypeHeadConnectorsSourceFileAssemblyPartsGeometryLog.');
        }

        if (partsId === null || partsId === undefined) {
            throw new Error('Required parameter partsId was null or undefined when calling getConnectorTypeHeadConnectorsSourceFileAssemblyPartsGeometryLog.');
        }







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from);
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('to', <any>to);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (tag !== undefined && tag !== null) {
            queryParameters = queryParameters.set('tag', <any>tag);
        }
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLog>('get',`${this.basePath}/connectortypes/${encodeURIComponent(String(connectortypeId))}/connectors/${encodeURIComponent(String(connectorsId))}/source/file/assembly/parts/${encodeURIComponent(String(partsId))}/geometry/log`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param connectortypeId ID of ConnectorType
     * @param connectorsId 
     * @param subassembliesId 
     * @param partsId 
     * @param eTag Version of PartGeometry considered to be HEAD, if no newer PartGeometry is found then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConnectorTypeHeadConnectorsSourceFileAssemblySubassembliesPartsGeometry(connectortypeId: string, connectorsId: string, subassembliesId: string, partsId: string, eTag?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedPartGeometry>;
    public getConnectorTypeHeadConnectorsSourceFileAssemblySubassembliesPartsGeometry(connectortypeId: string, connectorsId: string, subassembliesId: string, partsId: string, eTag?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedPartGeometry>>;
    public getConnectorTypeHeadConnectorsSourceFileAssemblySubassembliesPartsGeometry(connectortypeId: string, connectorsId: string, subassembliesId: string, partsId: string, eTag?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedPartGeometry>>;
    public getConnectorTypeHeadConnectorsSourceFileAssemblySubassembliesPartsGeometry(connectortypeId: string, connectorsId: string, subassembliesId: string, partsId: string, eTag?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (connectortypeId === null || connectortypeId === undefined) {
            throw new Error('Required parameter connectortypeId was null or undefined when calling getConnectorTypeHeadConnectorsSourceFileAssemblySubassembliesPartsGeometry.');
        }

        if (connectorsId === null || connectorsId === undefined) {
            throw new Error('Required parameter connectorsId was null or undefined when calling getConnectorTypeHeadConnectorsSourceFileAssemblySubassembliesPartsGeometry.');
        }

        if (subassembliesId === null || subassembliesId === undefined) {
            throw new Error('Required parameter subassembliesId was null or undefined when calling getConnectorTypeHeadConnectorsSourceFileAssemblySubassembliesPartsGeometry.');
        }

        if (partsId === null || partsId === undefined) {
            throw new Error('Required parameter partsId was null or undefined when calling getConnectorTypeHeadConnectorsSourceFileAssemblySubassembliesPartsGeometry.');
        }


        let headers = this.defaultHeaders;
        if (eTag !== undefined && eTag !== null) {
            headers = headers.set('ETag', String(eTag));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedPartGeometry>('get',`${this.basePath}/connectortypes/${encodeURIComponent(String(connectortypeId))}/connectors/${encodeURIComponent(String(connectorsId))}/source/file/assembly/subassemblies/${encodeURIComponent(String(subassembliesId))}/parts/${encodeURIComponent(String(partsId))}/geometry`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param connectortypeId ID of ConnectorType to return
     * @param connectorsId 
     * @param subassembliesId 
     * @param partsId 
     * @param from First version to consider in log
     * @param to Last version to consider in log
     * @param limit Maximum number of versions in log
     * @param tag Tag of version to find
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConnectorTypeHeadConnectorsSourceFileAssemblySubassembliesPartsGeometryLog(connectortypeId: string, connectorsId: string, subassembliesId: string, partsId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionLog>;
    public getConnectorTypeHeadConnectorsSourceFileAssemblySubassembliesPartsGeometryLog(connectortypeId: string, connectorsId: string, subassembliesId: string, partsId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLog>>;
    public getConnectorTypeHeadConnectorsSourceFileAssemblySubassembliesPartsGeometryLog(connectortypeId: string, connectorsId: string, subassembliesId: string, partsId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLog>>;
    public getConnectorTypeHeadConnectorsSourceFileAssemblySubassembliesPartsGeometryLog(connectortypeId: string, connectorsId: string, subassembliesId: string, partsId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (connectortypeId === null || connectortypeId === undefined) {
            throw new Error('Required parameter connectortypeId was null or undefined when calling getConnectorTypeHeadConnectorsSourceFileAssemblySubassembliesPartsGeometryLog.');
        }

        if (connectorsId === null || connectorsId === undefined) {
            throw new Error('Required parameter connectorsId was null or undefined when calling getConnectorTypeHeadConnectorsSourceFileAssemblySubassembliesPartsGeometryLog.');
        }

        if (subassembliesId === null || subassembliesId === undefined) {
            throw new Error('Required parameter subassembliesId was null or undefined when calling getConnectorTypeHeadConnectorsSourceFileAssemblySubassembliesPartsGeometryLog.');
        }

        if (partsId === null || partsId === undefined) {
            throw new Error('Required parameter partsId was null or undefined when calling getConnectorTypeHeadConnectorsSourceFileAssemblySubassembliesPartsGeometryLog.');
        }







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from);
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('to', <any>to);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (tag !== undefined && tag !== null) {
            queryParameters = queryParameters.set('tag', <any>tag);
        }
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLog>('get',`${this.basePath}/connectortypes/${encodeURIComponent(String(connectortypeId))}/connectors/${encodeURIComponent(String(connectorsId))}/source/file/assembly/subassemblies/${encodeURIComponent(String(subassembliesId))}/parts/${encodeURIComponent(String(partsId))}/geometry/log`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param connectortypeId ID of ConnectorType
     * @param connectorsId 
     * @param eTag Version of Artefact considered to be HEAD, if no newer Artefact is found then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConnectorTypeHeadConnectorsSourceFileFile(connectortypeId: string, connectorsId: string, eTag?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedArtefact>;
    public getConnectorTypeHeadConnectorsSourceFileFile(connectortypeId: string, connectorsId: string, eTag?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedArtefact>>;
    public getConnectorTypeHeadConnectorsSourceFileFile(connectortypeId: string, connectorsId: string, eTag?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedArtefact>>;
    public getConnectorTypeHeadConnectorsSourceFileFile(connectortypeId: string, connectorsId: string, eTag?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (connectortypeId === null || connectortypeId === undefined) {
            throw new Error('Required parameter connectortypeId was null or undefined when calling getConnectorTypeHeadConnectorsSourceFileFile.');
        }

        if (connectorsId === null || connectorsId === undefined) {
            throw new Error('Required parameter connectorsId was null or undefined when calling getConnectorTypeHeadConnectorsSourceFileFile.');
        }


        let headers = this.defaultHeaders;
        if (eTag !== undefined && eTag !== null) {
            headers = headers.set('ETag', String(eTag));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedArtefact>('get',`${this.basePath}/connectortypes/${encodeURIComponent(String(connectortypeId))}/connectors/${encodeURIComponent(String(connectorsId))}/source/file/file`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param connectortypeId ID of ConnectorType to return
     * @param connectorsId 
     * @param extension File extension requested
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConnectorTypeHeadConnectorsSourceFileFileContent(connectortypeId: string, connectorsId: string, extension: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public getConnectorTypeHeadConnectorsSourceFileFileContent(connectortypeId: string, connectorsId: string, extension: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public getConnectorTypeHeadConnectorsSourceFileFileContent(connectortypeId: string, connectorsId: string, extension: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public getConnectorTypeHeadConnectorsSourceFileFileContent(connectortypeId: string, connectorsId: string, extension: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (connectortypeId === null || connectortypeId === undefined) {
            throw new Error('Required parameter connectortypeId was null or undefined when calling getConnectorTypeHeadConnectorsSourceFileFileContent.');
        }

        if (connectorsId === null || connectorsId === undefined) {
            throw new Error('Required parameter connectorsId was null or undefined when calling getConnectorTypeHeadConnectorsSourceFileFileContent.');
        }

        if (extension === null || extension === undefined) {
            throw new Error('Required parameter extension was null or undefined when calling getConnectorTypeHeadConnectorsSourceFileFileContent.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'binary/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<string>('get',`${this.basePath}/connectortypes/${encodeURIComponent(String(connectortypeId))}/connectors/${encodeURIComponent(String(connectorsId))}/source/file/file.${encodeURIComponent(String(extension))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param connectortypeId ID of ConnectorType to return
     * @param connectorsId 
     * @param from First version to consider in log
     * @param to Last version to consider in log
     * @param limit Maximum number of versions in log
     * @param tag Tag of version to find
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConnectorTypeHeadConnectorsSourceFileFileLog(connectortypeId: string, connectorsId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionLog>;
    public getConnectorTypeHeadConnectorsSourceFileFileLog(connectortypeId: string, connectorsId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLog>>;
    public getConnectorTypeHeadConnectorsSourceFileFileLog(connectortypeId: string, connectorsId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLog>>;
    public getConnectorTypeHeadConnectorsSourceFileFileLog(connectortypeId: string, connectorsId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (connectortypeId === null || connectortypeId === undefined) {
            throw new Error('Required parameter connectortypeId was null or undefined when calling getConnectorTypeHeadConnectorsSourceFileFileLog.');
        }

        if (connectorsId === null || connectorsId === undefined) {
            throw new Error('Required parameter connectorsId was null or undefined when calling getConnectorTypeHeadConnectorsSourceFileFileLog.');
        }







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from);
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('to', <any>to);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (tag !== undefined && tag !== null) {
            queryParameters = queryParameters.set('tag', <any>tag);
        }
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLog>('get',`${this.basePath}/connectortypes/${encodeURIComponent(String(connectortypeId))}/connectors/${encodeURIComponent(String(connectorsId))}/source/file/file/log`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param connectortypeId ID of ConnectorType
     * @param connectorsId 
     * @param eTag Version of Artefact considered to be HEAD, if no newer Artefact is found then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConnectorTypeHeadConnectorsSourceImage(connectortypeId: string, connectorsId: string, eTag?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedArtefact>;
    public getConnectorTypeHeadConnectorsSourceImage(connectortypeId: string, connectorsId: string, eTag?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedArtefact>>;
    public getConnectorTypeHeadConnectorsSourceImage(connectortypeId: string, connectorsId: string, eTag?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedArtefact>>;
    public getConnectorTypeHeadConnectorsSourceImage(connectortypeId: string, connectorsId: string, eTag?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (connectortypeId === null || connectortypeId === undefined) {
            throw new Error('Required parameter connectortypeId was null or undefined when calling getConnectorTypeHeadConnectorsSourceImage.');
        }

        if (connectorsId === null || connectorsId === undefined) {
            throw new Error('Required parameter connectorsId was null or undefined when calling getConnectorTypeHeadConnectorsSourceImage.');
        }


        let headers = this.defaultHeaders;
        if (eTag !== undefined && eTag !== null) {
            headers = headers.set('ETag', String(eTag));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedArtefact>('get',`${this.basePath}/connectortypes/${encodeURIComponent(String(connectortypeId))}/connectors/${encodeURIComponent(String(connectorsId))}/source/image`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param connectortypeId ID of ConnectorType to return
     * @param connectorsId 
     * @param extension File extension requested
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConnectorTypeHeadConnectorsSourceImageContent(connectortypeId: string, connectorsId: string, extension: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public getConnectorTypeHeadConnectorsSourceImageContent(connectortypeId: string, connectorsId: string, extension: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public getConnectorTypeHeadConnectorsSourceImageContent(connectortypeId: string, connectorsId: string, extension: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public getConnectorTypeHeadConnectorsSourceImageContent(connectortypeId: string, connectorsId: string, extension: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (connectortypeId === null || connectortypeId === undefined) {
            throw new Error('Required parameter connectortypeId was null or undefined when calling getConnectorTypeHeadConnectorsSourceImageContent.');
        }

        if (connectorsId === null || connectorsId === undefined) {
            throw new Error('Required parameter connectorsId was null or undefined when calling getConnectorTypeHeadConnectorsSourceImageContent.');
        }

        if (extension === null || extension === undefined) {
            throw new Error('Required parameter extension was null or undefined when calling getConnectorTypeHeadConnectorsSourceImageContent.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'binary/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<string>('get',`${this.basePath}/connectortypes/${encodeURIComponent(String(connectortypeId))}/connectors/${encodeURIComponent(String(connectorsId))}/source/image.${encodeURIComponent(String(extension))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param connectortypeId ID of ConnectorType to return
     * @param connectorsId 
     * @param from First version to consider in log
     * @param to Last version to consider in log
     * @param limit Maximum number of versions in log
     * @param tag Tag of version to find
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConnectorTypeHeadConnectorsSourceImageLog(connectortypeId: string, connectorsId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionLog>;
    public getConnectorTypeHeadConnectorsSourceImageLog(connectortypeId: string, connectorsId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLog>>;
    public getConnectorTypeHeadConnectorsSourceImageLog(connectortypeId: string, connectorsId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLog>>;
    public getConnectorTypeHeadConnectorsSourceImageLog(connectortypeId: string, connectorsId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (connectortypeId === null || connectortypeId === undefined) {
            throw new Error('Required parameter connectortypeId was null or undefined when calling getConnectorTypeHeadConnectorsSourceImageLog.');
        }

        if (connectorsId === null || connectorsId === undefined) {
            throw new Error('Required parameter connectorsId was null or undefined when calling getConnectorTypeHeadConnectorsSourceImageLog.');
        }







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from);
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('to', <any>to);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (tag !== undefined && tag !== null) {
            queryParameters = queryParameters.set('tag', <any>tag);
        }
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLog>('get',`${this.basePath}/connectortypes/${encodeURIComponent(String(connectortypeId))}/connectors/${encodeURIComponent(String(connectorsId))}/source/image/log`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param connectortypeId ID of ConnectorType
     * @param connectorsId 
     * @param eTag Version of MaterialStyling considered to be HEAD, if no newer MaterialStyling is found then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConnectorTypeHeadConnectorsSourceMaterial(connectortypeId: string, connectorsId: string, eTag?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedMaterialStyling>;
    public getConnectorTypeHeadConnectorsSourceMaterial(connectortypeId: string, connectorsId: string, eTag?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedMaterialStyling>>;
    public getConnectorTypeHeadConnectorsSourceMaterial(connectortypeId: string, connectorsId: string, eTag?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedMaterialStyling>>;
    public getConnectorTypeHeadConnectorsSourceMaterial(connectortypeId: string, connectorsId: string, eTag?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (connectortypeId === null || connectortypeId === undefined) {
            throw new Error('Required parameter connectortypeId was null or undefined when calling getConnectorTypeHeadConnectorsSourceMaterial.');
        }

        if (connectorsId === null || connectorsId === undefined) {
            throw new Error('Required parameter connectorsId was null or undefined when calling getConnectorTypeHeadConnectorsSourceMaterial.');
        }


        let headers = this.defaultHeaders;
        if (eTag !== undefined && eTag !== null) {
            headers = headers.set('ETag', String(eTag));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedMaterialStyling>('get',`${this.basePath}/connectortypes/${encodeURIComponent(String(connectortypeId))}/connectors/${encodeURIComponent(String(connectorsId))}/source/material`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param connectortypeId ID of ConnectorType to return
     * @param connectorsId 
     * @param from First version to consider in log
     * @param to Last version to consider in log
     * @param limit Maximum number of versions in log
     * @param tag Tag of version to find
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConnectorTypeHeadConnectorsSourceMaterialLog(connectortypeId: string, connectorsId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionLog>;
    public getConnectorTypeHeadConnectorsSourceMaterialLog(connectortypeId: string, connectorsId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLog>>;
    public getConnectorTypeHeadConnectorsSourceMaterialLog(connectortypeId: string, connectorsId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLog>>;
    public getConnectorTypeHeadConnectorsSourceMaterialLog(connectortypeId: string, connectorsId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (connectortypeId === null || connectortypeId === undefined) {
            throw new Error('Required parameter connectortypeId was null or undefined when calling getConnectorTypeHeadConnectorsSourceMaterialLog.');
        }

        if (connectorsId === null || connectorsId === undefined) {
            throw new Error('Required parameter connectorsId was null or undefined when calling getConnectorTypeHeadConnectorsSourceMaterialLog.');
        }







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from);
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('to', <any>to);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (tag !== undefined && tag !== null) {
            queryParameters = queryParameters.set('tag', <any>tag);
        }
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLog>('get',`${this.basePath}/connectortypes/${encodeURIComponent(String(connectortypeId))}/connectors/${encodeURIComponent(String(connectorsId))}/source/material/log`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param connectortypeId ID of ConnectorType
     * @param connectorsId 
     * @param eTag Version of Artefact considered to be HEAD, if no newer Artefact is found then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConnectorTypeHeadConnectorsSourceRender(connectortypeId: string, connectorsId: string, eTag?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedArtefact>;
    public getConnectorTypeHeadConnectorsSourceRender(connectortypeId: string, connectorsId: string, eTag?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedArtefact>>;
    public getConnectorTypeHeadConnectorsSourceRender(connectortypeId: string, connectorsId: string, eTag?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedArtefact>>;
    public getConnectorTypeHeadConnectorsSourceRender(connectortypeId: string, connectorsId: string, eTag?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (connectortypeId === null || connectortypeId === undefined) {
            throw new Error('Required parameter connectortypeId was null or undefined when calling getConnectorTypeHeadConnectorsSourceRender.');
        }

        if (connectorsId === null || connectorsId === undefined) {
            throw new Error('Required parameter connectorsId was null or undefined when calling getConnectorTypeHeadConnectorsSourceRender.');
        }


        let headers = this.defaultHeaders;
        if (eTag !== undefined && eTag !== null) {
            headers = headers.set('ETag', String(eTag));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedArtefact>('get',`${this.basePath}/connectortypes/${encodeURIComponent(String(connectortypeId))}/connectors/${encodeURIComponent(String(connectorsId))}/source/render`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param connectortypeId ID of ConnectorType to return
     * @param connectorsId 
     * @param extension File extension requested
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConnectorTypeHeadConnectorsSourceRenderContent(connectortypeId: string, connectorsId: string, extension: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public getConnectorTypeHeadConnectorsSourceRenderContent(connectortypeId: string, connectorsId: string, extension: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public getConnectorTypeHeadConnectorsSourceRenderContent(connectortypeId: string, connectorsId: string, extension: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public getConnectorTypeHeadConnectorsSourceRenderContent(connectortypeId: string, connectorsId: string, extension: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (connectortypeId === null || connectortypeId === undefined) {
            throw new Error('Required parameter connectortypeId was null or undefined when calling getConnectorTypeHeadConnectorsSourceRenderContent.');
        }

        if (connectorsId === null || connectorsId === undefined) {
            throw new Error('Required parameter connectorsId was null or undefined when calling getConnectorTypeHeadConnectorsSourceRenderContent.');
        }

        if (extension === null || extension === undefined) {
            throw new Error('Required parameter extension was null or undefined when calling getConnectorTypeHeadConnectorsSourceRenderContent.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'binary/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<string>('get',`${this.basePath}/connectortypes/${encodeURIComponent(String(connectortypeId))}/connectors/${encodeURIComponent(String(connectorsId))}/source/render.${encodeURIComponent(String(extension))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param connectortypeId ID of ConnectorType to return
     * @param connectorsId 
     * @param from First version to consider in log
     * @param to Last version to consider in log
     * @param limit Maximum number of versions in log
     * @param tag Tag of version to find
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConnectorTypeHeadConnectorsSourceRenderLog(connectortypeId: string, connectorsId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionLog>;
    public getConnectorTypeHeadConnectorsSourceRenderLog(connectortypeId: string, connectorsId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLog>>;
    public getConnectorTypeHeadConnectorsSourceRenderLog(connectortypeId: string, connectorsId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLog>>;
    public getConnectorTypeHeadConnectorsSourceRenderLog(connectortypeId: string, connectorsId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (connectortypeId === null || connectortypeId === undefined) {
            throw new Error('Required parameter connectortypeId was null or undefined when calling getConnectorTypeHeadConnectorsSourceRenderLog.');
        }

        if (connectorsId === null || connectorsId === undefined) {
            throw new Error('Required parameter connectorsId was null or undefined when calling getConnectorTypeHeadConnectorsSourceRenderLog.');
        }







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from);
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('to', <any>to);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (tag !== undefined && tag !== null) {
            queryParameters = queryParameters.set('tag', <any>tag);
        }
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLog>('get',`${this.basePath}/connectortypes/${encodeURIComponent(String(connectortypeId))}/connectors/${encodeURIComponent(String(connectorsId))}/source/render/log`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get history of specified ConnectorType
     * @param connectortypeId ID of ConnectorType to return
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConnectorTypeHistory(connectortypeId: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionGraph>;
    public getConnectorTypeHistory(connectortypeId: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionGraph>>;
    public getConnectorTypeHistory(connectortypeId: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionGraph>>;
    public getConnectorTypeHistory(connectortypeId: string, multiWorkspace?: boolean, complete?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (connectortypeId === null || connectortypeId === undefined) {
            throw new Error('Required parameter connectortypeId was null or undefined when calling getConnectorTypeHistory.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionGraph>('get',`${this.basePath}/connectortypes/${encodeURIComponent(String(connectortypeId))}/history`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get log of specified ConnectorType
     * @param connectortypeId ID of ConnectorType to return
     * @param from First version to consider in log
     * @param to Last version to consider in log
     * @param limit Maximum number of versions in log
     * @param tag Tag of version to find
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConnectorTypeLog(connectortypeId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionLog>;
    public getConnectorTypeLog(connectortypeId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLog>>;
    public getConnectorTypeLog(connectortypeId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLog>>;
    public getConnectorTypeLog(connectortypeId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (connectortypeId === null || connectortypeId === undefined) {
            throw new Error('Required parameter connectortypeId was null or undefined when calling getConnectorTypeLog.');
        }







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from);
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('to', <any>to);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (tag !== undefined && tag !== null) {
            queryParameters = queryParameters.set('tag', <any>tag);
        }
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLog>('get',`${this.basePath}/connectortypes/${encodeURIComponent(String(connectortypeId))}/log`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get ConnectorType permissions
     * @param connectortypeId ID of ConnectorType to check
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConnectorTypePermissions(connectortypeId: string, observe?: 'body', reportProgress?: boolean): Observable<UserPermissions>;
    public getConnectorTypePermissions(connectortypeId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserPermissions>>;
    public getConnectorTypePermissions(connectortypeId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserPermissions>>;
    public getConnectorTypePermissions(connectortypeId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (connectortypeId === null || connectortypeId === undefined) {
            throw new Error('Required parameter connectortypeId was null or undefined when calling getConnectorTypePermissions.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<UserPermissions>('get',`${this.basePath}/connectortypes/${encodeURIComponent(String(connectortypeId))}/permissions`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get sharing details of specified ConnectorType
     * @param connectortypeId ID of ConnectorType to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConnectorTypeSharing(connectortypeId: string, observe?: 'body', reportProgress?: boolean): Observable<Sharing>;
    public getConnectorTypeSharing(connectortypeId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Sharing>>;
    public getConnectorTypeSharing(connectortypeId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Sharing>>;
    public getConnectorTypeSharing(connectortypeId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (connectortypeId === null || connectortypeId === undefined) {
            throw new Error('Required parameter connectortypeId was null or undefined when calling getConnectorTypeSharing.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Sharing>('get',`${this.basePath}/connectortypes/${encodeURIComponent(String(connectortypeId))}/sharing`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get short code to share the specified ConnectorType
     * @param connectortypeId ID of ConnectorType to share
     * @param mode Mode: copy or view or edit
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConnectorTypeShortCode(connectortypeId: string, mode: string, observe?: 'body', reportProgress?: boolean): Observable<ShortCode>;
    public getConnectorTypeShortCode(connectortypeId: string, mode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ShortCode>>;
    public getConnectorTypeShortCode(connectortypeId: string, mode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ShortCode>>;
    public getConnectorTypeShortCode(connectortypeId: string, mode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (connectortypeId === null || connectortypeId === undefined) {
            throw new Error('Required parameter connectortypeId was null or undefined when calling getConnectorTypeShortCode.');
        }

        if (mode === null || mode === undefined) {
            throw new Error('Required parameter mode was null or undefined when calling getConnectorTypeShortCode.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ShortCode>('get',`${this.basePath}/s/connectortypes/${encodeURIComponent(String(connectortypeId))}/${encodeURIComponent(String(mode))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Find usages of specified ConnectorType
     * @param connectortypeId ID of ConnectorType to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConnectorTypeUsages(connectortypeId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Reference>>;
    public getConnectorTypeUsages(connectortypeId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Reference>>>;
    public getConnectorTypeUsages(connectortypeId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Reference>>>;
    public getConnectorTypeUsages(connectortypeId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (connectortypeId === null || connectortypeId === undefined) {
            throw new Error('Required parameter connectortypeId was null or undefined when calling getConnectorTypeUsages.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Reference>>('get',`${this.basePath}/connectortypes/${encodeURIComponent(String(connectortypeId))}/usages`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get version summary of specified ConnectorType
     * @param connectortypeId ID of ConnectorType to return
     * @param versionId Version to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConnectorTypeVersionSummary(connectortypeId: string, versionId: string, observe?: 'body', reportProgress?: boolean): Observable<VersionLogVersion>;
    public getConnectorTypeVersionSummary(connectortypeId: string, versionId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLogVersion>>;
    public getConnectorTypeVersionSummary(connectortypeId: string, versionId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLogVersion>>;
    public getConnectorTypeVersionSummary(connectortypeId: string, versionId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (connectortypeId === null || connectortypeId === undefined) {
            throw new Error('Required parameter connectortypeId was null or undefined when calling getConnectorTypeVersionSummary.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getConnectorTypeVersionSummary.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLogVersion>('get',`${this.basePath}/connectortypes/${encodeURIComponent(String(connectortypeId))}/log/${encodeURIComponent(String(versionId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Find usages of specified ConnectorType version
     * @param connectortypeId ID of ConnectorType to return
     * @param versionId Version of ConnectorType to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConnectorTypeVersionUsages(connectortypeId: string, versionId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Reference>>;
    public getConnectorTypeVersionUsages(connectortypeId: string, versionId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Reference>>>;
    public getConnectorTypeVersionUsages(connectortypeId: string, versionId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Reference>>>;
    public getConnectorTypeVersionUsages(connectortypeId: string, versionId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (connectortypeId === null || connectortypeId === undefined) {
            throw new Error('Required parameter connectortypeId was null or undefined when calling getConnectorTypeVersionUsages.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getConnectorTypeVersionUsages.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Reference>>('get',`${this.basePath}/connectortypes/${encodeURIComponent(String(connectortypeId))}/v/${encodeURIComponent(String(versionId))}/usages`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get details of current user
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMe(observe?: 'body', reportProgress?: boolean): Observable<User>;
    public getMe(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<User>>;
    public getMe(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<User>>;
    public getMe(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<User>('get',`${this.basePath}/users/me`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get specified version of specified Module
     * @param moduleId ID of Module to return
     * @param versionId Version of Module to return
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param ifNoneMatch Version of Module already received, if no change to Module since version then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getModule(moduleId: string, versionId: string, multiWorkspace?: boolean, complete?: boolean, ifNoneMatch?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedModule>;
    public getModule(moduleId: string, versionId: string, multiWorkspace?: boolean, complete?: boolean, ifNoneMatch?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedModule>>;
    public getModule(moduleId: string, versionId: string, multiWorkspace?: boolean, complete?: boolean, ifNoneMatch?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedModule>>;
    public getModule(moduleId: string, versionId: string, multiWorkspace?: boolean, complete?: boolean, ifNoneMatch?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (moduleId === null || moduleId === undefined) {
            throw new Error('Required parameter moduleId was null or undefined when calling getModule.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getModule.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;
        if (ifNoneMatch !== undefined && ifNoneMatch !== null) {
            headers = headers.set('If-None-Match', String(ifNoneMatch));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedModule>('get',`${this.basePath}/modules/${encodeURIComponent(String(moduleId))}/v/${encodeURIComponent(String(versionId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get diff between versions of Modules
     * @param moduleId1 ID of first Module to compare
     * @param versionId1 Version of first Module to compare
     * @param moduleId2 ID of second Module to compare
     * @param versionId2 Version of second Module to compare
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getModuleDiff(moduleId1: string, versionId1: string, moduleId2: string, versionId2: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedModuleDiff>;
    public getModuleDiff(moduleId1: string, versionId1: string, moduleId2: string, versionId2: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedModuleDiff>>;
    public getModuleDiff(moduleId1: string, versionId1: string, moduleId2: string, versionId2: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedModuleDiff>>;
    public getModuleDiff(moduleId1: string, versionId1: string, moduleId2: string, versionId2: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (moduleId1 === null || moduleId1 === undefined) {
            throw new Error('Required parameter moduleId1 was null or undefined when calling getModuleDiff.');
        }

        if (versionId1 === null || versionId1 === undefined) {
            throw new Error('Required parameter versionId1 was null or undefined when calling getModuleDiff.');
        }

        if (moduleId2 === null || moduleId2 === undefined) {
            throw new Error('Required parameter moduleId2 was null or undefined when calling getModuleDiff.');
        }

        if (versionId2 === null || versionId2 === undefined) {
            throw new Error('Required parameter versionId2 was null or undefined when calling getModuleDiff.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedModuleDiff>('get',`${this.basePath}/modules/${encodeURIComponent(String(moduleId1))}/v/${encodeURIComponent(String(versionId1))}/diff/${encodeURIComponent(String(moduleId2))}/v/${encodeURIComponent(String(versionId2))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get latest version of specified Module
     * @param moduleId ID of Module to return
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param eTag Version of Module considered to be HEAD, if no newer Module is found then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getModuleHead(moduleId: string, multiWorkspace?: boolean, complete?: boolean, eTag?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedModule>;
    public getModuleHead(moduleId: string, multiWorkspace?: boolean, complete?: boolean, eTag?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedModule>>;
    public getModuleHead(moduleId: string, multiWorkspace?: boolean, complete?: boolean, eTag?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedModule>>;
    public getModuleHead(moduleId: string, multiWorkspace?: boolean, complete?: boolean, eTag?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (moduleId === null || moduleId === undefined) {
            throw new Error('Required parameter moduleId was null or undefined when calling getModuleHead.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;
        if (eTag !== undefined && eTag !== null) {
            headers = headers.set('ETag', String(eTag));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedModule>('get',`${this.basePath}/modules/${encodeURIComponent(String(moduleId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param moduleId ID of Module
     * @param eTag Version of ProductionParts considered to be HEAD, if no newer ProductionParts is found then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getModuleHeadProductionParts(moduleId: string, eTag?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedProductionParts>;
    public getModuleHeadProductionParts(moduleId: string, eTag?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedProductionParts>>;
    public getModuleHeadProductionParts(moduleId: string, eTag?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedProductionParts>>;
    public getModuleHeadProductionParts(moduleId: string, eTag?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (moduleId === null || moduleId === undefined) {
            throw new Error('Required parameter moduleId was null or undefined when calling getModuleHeadProductionParts.');
        }


        let headers = this.defaultHeaders;
        if (eTag !== undefined && eTag !== null) {
            headers = headers.set('ETag', String(eTag));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedProductionParts>('get',`${this.basePath}/modules/${encodeURIComponent(String(moduleId))}/productionParts`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param moduleId ID of Module to return
     * @param from First version to consider in log
     * @param to Last version to consider in log
     * @param limit Maximum number of versions in log
     * @param tag Tag of version to find
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getModuleHeadProductionPartsLog(moduleId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionLog>;
    public getModuleHeadProductionPartsLog(moduleId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLog>>;
    public getModuleHeadProductionPartsLog(moduleId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLog>>;
    public getModuleHeadProductionPartsLog(moduleId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (moduleId === null || moduleId === undefined) {
            throw new Error('Required parameter moduleId was null or undefined when calling getModuleHeadProductionPartsLog.');
        }







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from);
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('to', <any>to);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (tag !== undefined && tag !== null) {
            queryParameters = queryParameters.set('tag', <any>tag);
        }
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLog>('get',`${this.basePath}/modules/${encodeURIComponent(String(moduleId))}/productionParts/log`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param moduleId ID of Module
     * @param eTag Version of Assembly considered to be HEAD, if no newer Assembly is found then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getModuleHeadSourceAssembly(moduleId: string, eTag?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedAssembly>;
    public getModuleHeadSourceAssembly(moduleId: string, eTag?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedAssembly>>;
    public getModuleHeadSourceAssembly(moduleId: string, eTag?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedAssembly>>;
    public getModuleHeadSourceAssembly(moduleId: string, eTag?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (moduleId === null || moduleId === undefined) {
            throw new Error('Required parameter moduleId was null or undefined when calling getModuleHeadSourceAssembly.');
        }


        let headers = this.defaultHeaders;
        if (eTag !== undefined && eTag !== null) {
            headers = headers.set('ETag', String(eTag));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedAssembly>('get',`${this.basePath}/modules/${encodeURIComponent(String(moduleId))}/source/assembly`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param moduleId ID of Module to return
     * @param from First version to consider in log
     * @param to Last version to consider in log
     * @param limit Maximum number of versions in log
     * @param tag Tag of version to find
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getModuleHeadSourceAssemblyLog(moduleId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionLog>;
    public getModuleHeadSourceAssemblyLog(moduleId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLog>>;
    public getModuleHeadSourceAssemblyLog(moduleId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLog>>;
    public getModuleHeadSourceAssemblyLog(moduleId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (moduleId === null || moduleId === undefined) {
            throw new Error('Required parameter moduleId was null or undefined when calling getModuleHeadSourceAssemblyLog.');
        }







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from);
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('to', <any>to);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (tag !== undefined && tag !== null) {
            queryParameters = queryParameters.set('tag', <any>tag);
        }
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLog>('get',`${this.basePath}/modules/${encodeURIComponent(String(moduleId))}/source/assembly/log`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param moduleId ID of Module
     * @param partsId 
     * @param eTag Version of PartGeometry considered to be HEAD, if no newer PartGeometry is found then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getModuleHeadSourceAssemblyPartsGeometry(moduleId: string, partsId: string, eTag?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedPartGeometry>;
    public getModuleHeadSourceAssemblyPartsGeometry(moduleId: string, partsId: string, eTag?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedPartGeometry>>;
    public getModuleHeadSourceAssemblyPartsGeometry(moduleId: string, partsId: string, eTag?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedPartGeometry>>;
    public getModuleHeadSourceAssemblyPartsGeometry(moduleId: string, partsId: string, eTag?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (moduleId === null || moduleId === undefined) {
            throw new Error('Required parameter moduleId was null or undefined when calling getModuleHeadSourceAssemblyPartsGeometry.');
        }

        if (partsId === null || partsId === undefined) {
            throw new Error('Required parameter partsId was null or undefined when calling getModuleHeadSourceAssemblyPartsGeometry.');
        }


        let headers = this.defaultHeaders;
        if (eTag !== undefined && eTag !== null) {
            headers = headers.set('ETag', String(eTag));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedPartGeometry>('get',`${this.basePath}/modules/${encodeURIComponent(String(moduleId))}/source/assembly/parts/${encodeURIComponent(String(partsId))}/geometry`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param moduleId ID of Module to return
     * @param partsId 
     * @param from First version to consider in log
     * @param to Last version to consider in log
     * @param limit Maximum number of versions in log
     * @param tag Tag of version to find
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getModuleHeadSourceAssemblyPartsGeometryLog(moduleId: string, partsId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionLog>;
    public getModuleHeadSourceAssemblyPartsGeometryLog(moduleId: string, partsId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLog>>;
    public getModuleHeadSourceAssemblyPartsGeometryLog(moduleId: string, partsId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLog>>;
    public getModuleHeadSourceAssemblyPartsGeometryLog(moduleId: string, partsId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (moduleId === null || moduleId === undefined) {
            throw new Error('Required parameter moduleId was null or undefined when calling getModuleHeadSourceAssemblyPartsGeometryLog.');
        }

        if (partsId === null || partsId === undefined) {
            throw new Error('Required parameter partsId was null or undefined when calling getModuleHeadSourceAssemblyPartsGeometryLog.');
        }







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from);
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('to', <any>to);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (tag !== undefined && tag !== null) {
            queryParameters = queryParameters.set('tag', <any>tag);
        }
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLog>('get',`${this.basePath}/modules/${encodeURIComponent(String(moduleId))}/source/assembly/parts/${encodeURIComponent(String(partsId))}/geometry/log`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param moduleId ID of Module
     * @param subassembliesId 
     * @param partsId 
     * @param eTag Version of PartGeometry considered to be HEAD, if no newer PartGeometry is found then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getModuleHeadSourceAssemblySubassembliesPartsGeometry(moduleId: string, subassembliesId: string, partsId: string, eTag?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedPartGeometry>;
    public getModuleHeadSourceAssemblySubassembliesPartsGeometry(moduleId: string, subassembliesId: string, partsId: string, eTag?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedPartGeometry>>;
    public getModuleHeadSourceAssemblySubassembliesPartsGeometry(moduleId: string, subassembliesId: string, partsId: string, eTag?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedPartGeometry>>;
    public getModuleHeadSourceAssemblySubassembliesPartsGeometry(moduleId: string, subassembliesId: string, partsId: string, eTag?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (moduleId === null || moduleId === undefined) {
            throw new Error('Required parameter moduleId was null or undefined when calling getModuleHeadSourceAssemblySubassembliesPartsGeometry.');
        }

        if (subassembliesId === null || subassembliesId === undefined) {
            throw new Error('Required parameter subassembliesId was null or undefined when calling getModuleHeadSourceAssemblySubassembliesPartsGeometry.');
        }

        if (partsId === null || partsId === undefined) {
            throw new Error('Required parameter partsId was null or undefined when calling getModuleHeadSourceAssemblySubassembliesPartsGeometry.');
        }


        let headers = this.defaultHeaders;
        if (eTag !== undefined && eTag !== null) {
            headers = headers.set('ETag', String(eTag));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedPartGeometry>('get',`${this.basePath}/modules/${encodeURIComponent(String(moduleId))}/source/assembly/subassemblies/${encodeURIComponent(String(subassembliesId))}/parts/${encodeURIComponent(String(partsId))}/geometry`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param moduleId ID of Module to return
     * @param subassembliesId 
     * @param partsId 
     * @param from First version to consider in log
     * @param to Last version to consider in log
     * @param limit Maximum number of versions in log
     * @param tag Tag of version to find
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getModuleHeadSourceAssemblySubassembliesPartsGeometryLog(moduleId: string, subassembliesId: string, partsId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionLog>;
    public getModuleHeadSourceAssemblySubassembliesPartsGeometryLog(moduleId: string, subassembliesId: string, partsId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLog>>;
    public getModuleHeadSourceAssemblySubassembliesPartsGeometryLog(moduleId: string, subassembliesId: string, partsId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLog>>;
    public getModuleHeadSourceAssemblySubassembliesPartsGeometryLog(moduleId: string, subassembliesId: string, partsId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (moduleId === null || moduleId === undefined) {
            throw new Error('Required parameter moduleId was null or undefined when calling getModuleHeadSourceAssemblySubassembliesPartsGeometryLog.');
        }

        if (subassembliesId === null || subassembliesId === undefined) {
            throw new Error('Required parameter subassembliesId was null or undefined when calling getModuleHeadSourceAssemblySubassembliesPartsGeometryLog.');
        }

        if (partsId === null || partsId === undefined) {
            throw new Error('Required parameter partsId was null or undefined when calling getModuleHeadSourceAssemblySubassembliesPartsGeometryLog.');
        }







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from);
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('to', <any>to);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (tag !== undefined && tag !== null) {
            queryParameters = queryParameters.set('tag', <any>tag);
        }
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLog>('get',`${this.basePath}/modules/${encodeURIComponent(String(moduleId))}/source/assembly/subassemblies/${encodeURIComponent(String(subassembliesId))}/parts/${encodeURIComponent(String(partsId))}/geometry/log`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param moduleId ID of Module
     * @param eTag Version of Assembly considered to be HEAD, if no newer Assembly is found then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getModuleHeadSourceFileAssembly(moduleId: string, eTag?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedAssembly>;
    public getModuleHeadSourceFileAssembly(moduleId: string, eTag?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedAssembly>>;
    public getModuleHeadSourceFileAssembly(moduleId: string, eTag?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedAssembly>>;
    public getModuleHeadSourceFileAssembly(moduleId: string, eTag?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (moduleId === null || moduleId === undefined) {
            throw new Error('Required parameter moduleId was null or undefined when calling getModuleHeadSourceFileAssembly.');
        }


        let headers = this.defaultHeaders;
        if (eTag !== undefined && eTag !== null) {
            headers = headers.set('ETag', String(eTag));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedAssembly>('get',`${this.basePath}/modules/${encodeURIComponent(String(moduleId))}/source/file/assembly`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param moduleId ID of Module to return
     * @param from First version to consider in log
     * @param to Last version to consider in log
     * @param limit Maximum number of versions in log
     * @param tag Tag of version to find
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getModuleHeadSourceFileAssemblyLog(moduleId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionLog>;
    public getModuleHeadSourceFileAssemblyLog(moduleId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLog>>;
    public getModuleHeadSourceFileAssemblyLog(moduleId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLog>>;
    public getModuleHeadSourceFileAssemblyLog(moduleId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (moduleId === null || moduleId === undefined) {
            throw new Error('Required parameter moduleId was null or undefined when calling getModuleHeadSourceFileAssemblyLog.');
        }







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from);
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('to', <any>to);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (tag !== undefined && tag !== null) {
            queryParameters = queryParameters.set('tag', <any>tag);
        }
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLog>('get',`${this.basePath}/modules/${encodeURIComponent(String(moduleId))}/source/file/assembly/log`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param moduleId ID of Module
     * @param partsId 
     * @param eTag Version of PartGeometry considered to be HEAD, if no newer PartGeometry is found then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getModuleHeadSourceFileAssemblyPartsGeometry(moduleId: string, partsId: string, eTag?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedPartGeometry>;
    public getModuleHeadSourceFileAssemblyPartsGeometry(moduleId: string, partsId: string, eTag?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedPartGeometry>>;
    public getModuleHeadSourceFileAssemblyPartsGeometry(moduleId: string, partsId: string, eTag?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedPartGeometry>>;
    public getModuleHeadSourceFileAssemblyPartsGeometry(moduleId: string, partsId: string, eTag?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (moduleId === null || moduleId === undefined) {
            throw new Error('Required parameter moduleId was null or undefined when calling getModuleHeadSourceFileAssemblyPartsGeometry.');
        }

        if (partsId === null || partsId === undefined) {
            throw new Error('Required parameter partsId was null or undefined when calling getModuleHeadSourceFileAssemblyPartsGeometry.');
        }


        let headers = this.defaultHeaders;
        if (eTag !== undefined && eTag !== null) {
            headers = headers.set('ETag', String(eTag));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedPartGeometry>('get',`${this.basePath}/modules/${encodeURIComponent(String(moduleId))}/source/file/assembly/parts/${encodeURIComponent(String(partsId))}/geometry`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param moduleId ID of Module to return
     * @param partsId 
     * @param from First version to consider in log
     * @param to Last version to consider in log
     * @param limit Maximum number of versions in log
     * @param tag Tag of version to find
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getModuleHeadSourceFileAssemblyPartsGeometryLog(moduleId: string, partsId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionLog>;
    public getModuleHeadSourceFileAssemblyPartsGeometryLog(moduleId: string, partsId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLog>>;
    public getModuleHeadSourceFileAssemblyPartsGeometryLog(moduleId: string, partsId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLog>>;
    public getModuleHeadSourceFileAssemblyPartsGeometryLog(moduleId: string, partsId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (moduleId === null || moduleId === undefined) {
            throw new Error('Required parameter moduleId was null or undefined when calling getModuleHeadSourceFileAssemblyPartsGeometryLog.');
        }

        if (partsId === null || partsId === undefined) {
            throw new Error('Required parameter partsId was null or undefined when calling getModuleHeadSourceFileAssemblyPartsGeometryLog.');
        }







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from);
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('to', <any>to);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (tag !== undefined && tag !== null) {
            queryParameters = queryParameters.set('tag', <any>tag);
        }
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLog>('get',`${this.basePath}/modules/${encodeURIComponent(String(moduleId))}/source/file/assembly/parts/${encodeURIComponent(String(partsId))}/geometry/log`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param moduleId ID of Module
     * @param subassembliesId 
     * @param partsId 
     * @param eTag Version of PartGeometry considered to be HEAD, if no newer PartGeometry is found then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getModuleHeadSourceFileAssemblySubassembliesPartsGeometry(moduleId: string, subassembliesId: string, partsId: string, eTag?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedPartGeometry>;
    public getModuleHeadSourceFileAssemblySubassembliesPartsGeometry(moduleId: string, subassembliesId: string, partsId: string, eTag?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedPartGeometry>>;
    public getModuleHeadSourceFileAssemblySubassembliesPartsGeometry(moduleId: string, subassembliesId: string, partsId: string, eTag?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedPartGeometry>>;
    public getModuleHeadSourceFileAssemblySubassembliesPartsGeometry(moduleId: string, subassembliesId: string, partsId: string, eTag?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (moduleId === null || moduleId === undefined) {
            throw new Error('Required parameter moduleId was null or undefined when calling getModuleHeadSourceFileAssemblySubassembliesPartsGeometry.');
        }

        if (subassembliesId === null || subassembliesId === undefined) {
            throw new Error('Required parameter subassembliesId was null or undefined when calling getModuleHeadSourceFileAssemblySubassembliesPartsGeometry.');
        }

        if (partsId === null || partsId === undefined) {
            throw new Error('Required parameter partsId was null or undefined when calling getModuleHeadSourceFileAssemblySubassembliesPartsGeometry.');
        }


        let headers = this.defaultHeaders;
        if (eTag !== undefined && eTag !== null) {
            headers = headers.set('ETag', String(eTag));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedPartGeometry>('get',`${this.basePath}/modules/${encodeURIComponent(String(moduleId))}/source/file/assembly/subassemblies/${encodeURIComponent(String(subassembliesId))}/parts/${encodeURIComponent(String(partsId))}/geometry`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param moduleId ID of Module to return
     * @param subassembliesId 
     * @param partsId 
     * @param from First version to consider in log
     * @param to Last version to consider in log
     * @param limit Maximum number of versions in log
     * @param tag Tag of version to find
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getModuleHeadSourceFileAssemblySubassembliesPartsGeometryLog(moduleId: string, subassembliesId: string, partsId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionLog>;
    public getModuleHeadSourceFileAssemblySubassembliesPartsGeometryLog(moduleId: string, subassembliesId: string, partsId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLog>>;
    public getModuleHeadSourceFileAssemblySubassembliesPartsGeometryLog(moduleId: string, subassembliesId: string, partsId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLog>>;
    public getModuleHeadSourceFileAssemblySubassembliesPartsGeometryLog(moduleId: string, subassembliesId: string, partsId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (moduleId === null || moduleId === undefined) {
            throw new Error('Required parameter moduleId was null or undefined when calling getModuleHeadSourceFileAssemblySubassembliesPartsGeometryLog.');
        }

        if (subassembliesId === null || subassembliesId === undefined) {
            throw new Error('Required parameter subassembliesId was null or undefined when calling getModuleHeadSourceFileAssemblySubassembliesPartsGeometryLog.');
        }

        if (partsId === null || partsId === undefined) {
            throw new Error('Required parameter partsId was null or undefined when calling getModuleHeadSourceFileAssemblySubassembliesPartsGeometryLog.');
        }







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from);
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('to', <any>to);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (tag !== undefined && tag !== null) {
            queryParameters = queryParameters.set('tag', <any>tag);
        }
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLog>('get',`${this.basePath}/modules/${encodeURIComponent(String(moduleId))}/source/file/assembly/subassemblies/${encodeURIComponent(String(subassembliesId))}/parts/${encodeURIComponent(String(partsId))}/geometry/log`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param moduleId ID of Module
     * @param eTag Version of Artefact considered to be HEAD, if no newer Artefact is found then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getModuleHeadSourceFileFile(moduleId: string, eTag?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedArtefact>;
    public getModuleHeadSourceFileFile(moduleId: string, eTag?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedArtefact>>;
    public getModuleHeadSourceFileFile(moduleId: string, eTag?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedArtefact>>;
    public getModuleHeadSourceFileFile(moduleId: string, eTag?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (moduleId === null || moduleId === undefined) {
            throw new Error('Required parameter moduleId was null or undefined when calling getModuleHeadSourceFileFile.');
        }


        let headers = this.defaultHeaders;
        if (eTag !== undefined && eTag !== null) {
            headers = headers.set('ETag', String(eTag));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedArtefact>('get',`${this.basePath}/modules/${encodeURIComponent(String(moduleId))}/source/file/file`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param moduleId ID of Module to return
     * @param extension File extension requested
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getModuleHeadSourceFileFileContent(moduleId: string, extension: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public getModuleHeadSourceFileFileContent(moduleId: string, extension: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public getModuleHeadSourceFileFileContent(moduleId: string, extension: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public getModuleHeadSourceFileFileContent(moduleId: string, extension: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (moduleId === null || moduleId === undefined) {
            throw new Error('Required parameter moduleId was null or undefined when calling getModuleHeadSourceFileFileContent.');
        }

        if (extension === null || extension === undefined) {
            throw new Error('Required parameter extension was null or undefined when calling getModuleHeadSourceFileFileContent.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'binary/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<string>('get',`${this.basePath}/modules/${encodeURIComponent(String(moduleId))}/source/file/file.${encodeURIComponent(String(extension))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param moduleId ID of Module to return
     * @param from First version to consider in log
     * @param to Last version to consider in log
     * @param limit Maximum number of versions in log
     * @param tag Tag of version to find
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getModuleHeadSourceFileFileLog(moduleId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionLog>;
    public getModuleHeadSourceFileFileLog(moduleId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLog>>;
    public getModuleHeadSourceFileFileLog(moduleId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLog>>;
    public getModuleHeadSourceFileFileLog(moduleId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (moduleId === null || moduleId === undefined) {
            throw new Error('Required parameter moduleId was null or undefined when calling getModuleHeadSourceFileFileLog.');
        }







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from);
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('to', <any>to);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (tag !== undefined && tag !== null) {
            queryParameters = queryParameters.set('tag', <any>tag);
        }
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLog>('get',`${this.basePath}/modules/${encodeURIComponent(String(moduleId))}/source/file/file/log`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param moduleId ID of Module
     * @param eTag Version of Artefact considered to be HEAD, if no newer Artefact is found then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getModuleHeadSourceImage(moduleId: string, eTag?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedArtefact>;
    public getModuleHeadSourceImage(moduleId: string, eTag?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedArtefact>>;
    public getModuleHeadSourceImage(moduleId: string, eTag?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedArtefact>>;
    public getModuleHeadSourceImage(moduleId: string, eTag?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (moduleId === null || moduleId === undefined) {
            throw new Error('Required parameter moduleId was null or undefined when calling getModuleHeadSourceImage.');
        }


        let headers = this.defaultHeaders;
        if (eTag !== undefined && eTag !== null) {
            headers = headers.set('ETag', String(eTag));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedArtefact>('get',`${this.basePath}/modules/${encodeURIComponent(String(moduleId))}/source/image`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param moduleId ID of Module to return
     * @param extension File extension requested
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getModuleHeadSourceImageContent(moduleId: string, extension: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public getModuleHeadSourceImageContent(moduleId: string, extension: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public getModuleHeadSourceImageContent(moduleId: string, extension: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public getModuleHeadSourceImageContent(moduleId: string, extension: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (moduleId === null || moduleId === undefined) {
            throw new Error('Required parameter moduleId was null or undefined when calling getModuleHeadSourceImageContent.');
        }

        if (extension === null || extension === undefined) {
            throw new Error('Required parameter extension was null or undefined when calling getModuleHeadSourceImageContent.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'binary/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<string>('get',`${this.basePath}/modules/${encodeURIComponent(String(moduleId))}/source/image.${encodeURIComponent(String(extension))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param moduleId ID of Module to return
     * @param from First version to consider in log
     * @param to Last version to consider in log
     * @param limit Maximum number of versions in log
     * @param tag Tag of version to find
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getModuleHeadSourceImageLog(moduleId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionLog>;
    public getModuleHeadSourceImageLog(moduleId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLog>>;
    public getModuleHeadSourceImageLog(moduleId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLog>>;
    public getModuleHeadSourceImageLog(moduleId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (moduleId === null || moduleId === undefined) {
            throw new Error('Required parameter moduleId was null or undefined when calling getModuleHeadSourceImageLog.');
        }







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from);
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('to', <any>to);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (tag !== undefined && tag !== null) {
            queryParameters = queryParameters.set('tag', <any>tag);
        }
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLog>('get',`${this.basePath}/modules/${encodeURIComponent(String(moduleId))}/source/image/log`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param moduleId ID of Module
     * @param eTag Version of MaterialStyling considered to be HEAD, if no newer MaterialStyling is found then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getModuleHeadSourceMaterial(moduleId: string, eTag?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedMaterialStyling>;
    public getModuleHeadSourceMaterial(moduleId: string, eTag?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedMaterialStyling>>;
    public getModuleHeadSourceMaterial(moduleId: string, eTag?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedMaterialStyling>>;
    public getModuleHeadSourceMaterial(moduleId: string, eTag?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (moduleId === null || moduleId === undefined) {
            throw new Error('Required parameter moduleId was null or undefined when calling getModuleHeadSourceMaterial.');
        }


        let headers = this.defaultHeaders;
        if (eTag !== undefined && eTag !== null) {
            headers = headers.set('ETag', String(eTag));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedMaterialStyling>('get',`${this.basePath}/modules/${encodeURIComponent(String(moduleId))}/source/material`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param moduleId ID of Module to return
     * @param from First version to consider in log
     * @param to Last version to consider in log
     * @param limit Maximum number of versions in log
     * @param tag Tag of version to find
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getModuleHeadSourceMaterialLog(moduleId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionLog>;
    public getModuleHeadSourceMaterialLog(moduleId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLog>>;
    public getModuleHeadSourceMaterialLog(moduleId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLog>>;
    public getModuleHeadSourceMaterialLog(moduleId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (moduleId === null || moduleId === undefined) {
            throw new Error('Required parameter moduleId was null or undefined when calling getModuleHeadSourceMaterialLog.');
        }







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from);
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('to', <any>to);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (tag !== undefined && tag !== null) {
            queryParameters = queryParameters.set('tag', <any>tag);
        }
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLog>('get',`${this.basePath}/modules/${encodeURIComponent(String(moduleId))}/source/material/log`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param moduleId ID of Module
     * @param eTag Version of Artefact considered to be HEAD, if no newer Artefact is found then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getModuleHeadSourceRender(moduleId: string, eTag?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedArtefact>;
    public getModuleHeadSourceRender(moduleId: string, eTag?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedArtefact>>;
    public getModuleHeadSourceRender(moduleId: string, eTag?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedArtefact>>;
    public getModuleHeadSourceRender(moduleId: string, eTag?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (moduleId === null || moduleId === undefined) {
            throw new Error('Required parameter moduleId was null or undefined when calling getModuleHeadSourceRender.');
        }


        let headers = this.defaultHeaders;
        if (eTag !== undefined && eTag !== null) {
            headers = headers.set('ETag', String(eTag));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedArtefact>('get',`${this.basePath}/modules/${encodeURIComponent(String(moduleId))}/source/render`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param moduleId ID of Module to return
     * @param extension File extension requested
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getModuleHeadSourceRenderContent(moduleId: string, extension: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public getModuleHeadSourceRenderContent(moduleId: string, extension: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public getModuleHeadSourceRenderContent(moduleId: string, extension: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public getModuleHeadSourceRenderContent(moduleId: string, extension: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (moduleId === null || moduleId === undefined) {
            throw new Error('Required parameter moduleId was null or undefined when calling getModuleHeadSourceRenderContent.');
        }

        if (extension === null || extension === undefined) {
            throw new Error('Required parameter extension was null or undefined when calling getModuleHeadSourceRenderContent.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'binary/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<string>('get',`${this.basePath}/modules/${encodeURIComponent(String(moduleId))}/source/render.${encodeURIComponent(String(extension))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param moduleId ID of Module to return
     * @param from First version to consider in log
     * @param to Last version to consider in log
     * @param limit Maximum number of versions in log
     * @param tag Tag of version to find
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getModuleHeadSourceRenderLog(moduleId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionLog>;
    public getModuleHeadSourceRenderLog(moduleId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLog>>;
    public getModuleHeadSourceRenderLog(moduleId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLog>>;
    public getModuleHeadSourceRenderLog(moduleId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (moduleId === null || moduleId === undefined) {
            throw new Error('Required parameter moduleId was null or undefined when calling getModuleHeadSourceRenderLog.');
        }







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from);
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('to', <any>to);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (tag !== undefined && tag !== null) {
            queryParameters = queryParameters.set('tag', <any>tag);
        }
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLog>('get',`${this.basePath}/modules/${encodeURIComponent(String(moduleId))}/source/render/log`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get history of specified Module
     * @param moduleId ID of Module to return
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getModuleHistory(moduleId: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionGraph>;
    public getModuleHistory(moduleId: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionGraph>>;
    public getModuleHistory(moduleId: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionGraph>>;
    public getModuleHistory(moduleId: string, multiWorkspace?: boolean, complete?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (moduleId === null || moduleId === undefined) {
            throw new Error('Required parameter moduleId was null or undefined when calling getModuleHistory.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionGraph>('get',`${this.basePath}/modules/${encodeURIComponent(String(moduleId))}/history`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get log of specified Module
     * @param moduleId ID of Module to return
     * @param from First version to consider in log
     * @param to Last version to consider in log
     * @param limit Maximum number of versions in log
     * @param tag Tag of version to find
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getModuleLog(moduleId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionLog>;
    public getModuleLog(moduleId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLog>>;
    public getModuleLog(moduleId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLog>>;
    public getModuleLog(moduleId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (moduleId === null || moduleId === undefined) {
            throw new Error('Required parameter moduleId was null or undefined when calling getModuleLog.');
        }







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from);
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('to', <any>to);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (tag !== undefined && tag !== null) {
            queryParameters = queryParameters.set('tag', <any>tag);
        }
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLog>('get',`${this.basePath}/modules/${encodeURIComponent(String(moduleId))}/log`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get Module permissions
     * @param moduleId ID of Module to check
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getModulePermissions(moduleId: string, observe?: 'body', reportProgress?: boolean): Observable<UserPermissions>;
    public getModulePermissions(moduleId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserPermissions>>;
    public getModulePermissions(moduleId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserPermissions>>;
    public getModulePermissions(moduleId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (moduleId === null || moduleId === undefined) {
            throw new Error('Required parameter moduleId was null or undefined when calling getModulePermissions.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<UserPermissions>('get',`${this.basePath}/modules/${encodeURIComponent(String(moduleId))}/permissions`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param moduleId ID of Module
     * @param versionId Version of Module
     * @param ifNoneMatch Version of ProductionParts already received, if no change to ProductionParts since version then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getModuleProductionParts(moduleId: string, versionId: string, ifNoneMatch?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedProductionParts>;
    public getModuleProductionParts(moduleId: string, versionId: string, ifNoneMatch?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedProductionParts>>;
    public getModuleProductionParts(moduleId: string, versionId: string, ifNoneMatch?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedProductionParts>>;
    public getModuleProductionParts(moduleId: string, versionId: string, ifNoneMatch?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (moduleId === null || moduleId === undefined) {
            throw new Error('Required parameter moduleId was null or undefined when calling getModuleProductionParts.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getModuleProductionParts.');
        }


        let headers = this.defaultHeaders;
        if (ifNoneMatch !== undefined && ifNoneMatch !== null) {
            headers = headers.set('If-None-Match', String(ifNoneMatch));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedProductionParts>('get',`${this.basePath}/modules/${encodeURIComponent(String(moduleId))}/v/${encodeURIComponent(String(versionId))}/productionParts`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get sharing details of specified Module
     * @param moduleId ID of Module to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getModuleSharing(moduleId: string, observe?: 'body', reportProgress?: boolean): Observable<Sharing>;
    public getModuleSharing(moduleId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Sharing>>;
    public getModuleSharing(moduleId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Sharing>>;
    public getModuleSharing(moduleId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (moduleId === null || moduleId === undefined) {
            throw new Error('Required parameter moduleId was null or undefined when calling getModuleSharing.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Sharing>('get',`${this.basePath}/modules/${encodeURIComponent(String(moduleId))}/sharing`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get short code to share the specified Module
     * @param moduleId ID of Module to share
     * @param mode Mode: copy or view or edit
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getModuleShortCode(moduleId: string, mode: string, observe?: 'body', reportProgress?: boolean): Observable<ShortCode>;
    public getModuleShortCode(moduleId: string, mode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ShortCode>>;
    public getModuleShortCode(moduleId: string, mode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ShortCode>>;
    public getModuleShortCode(moduleId: string, mode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (moduleId === null || moduleId === undefined) {
            throw new Error('Required parameter moduleId was null or undefined when calling getModuleShortCode.');
        }

        if (mode === null || mode === undefined) {
            throw new Error('Required parameter mode was null or undefined when calling getModuleShortCode.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ShortCode>('get',`${this.basePath}/s/modules/${encodeURIComponent(String(moduleId))}/${encodeURIComponent(String(mode))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param moduleId ID of Module
     * @param versionId Version of Module
     * @param ifNoneMatch Version of Assembly already received, if no change to Assembly since version then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getModuleSourceAssembly(moduleId: string, versionId: string, ifNoneMatch?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedAssembly>;
    public getModuleSourceAssembly(moduleId: string, versionId: string, ifNoneMatch?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedAssembly>>;
    public getModuleSourceAssembly(moduleId: string, versionId: string, ifNoneMatch?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedAssembly>>;
    public getModuleSourceAssembly(moduleId: string, versionId: string, ifNoneMatch?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (moduleId === null || moduleId === undefined) {
            throw new Error('Required parameter moduleId was null or undefined when calling getModuleSourceAssembly.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getModuleSourceAssembly.');
        }


        let headers = this.defaultHeaders;
        if (ifNoneMatch !== undefined && ifNoneMatch !== null) {
            headers = headers.set('If-None-Match', String(ifNoneMatch));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedAssembly>('get',`${this.basePath}/modules/${encodeURIComponent(String(moduleId))}/v/${encodeURIComponent(String(versionId))}/source/assembly`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param moduleId ID of Module
     * @param versionId Version of Module
     * @param partsId 
     * @param ifNoneMatch Version of PartGeometry already received, if no change to PartGeometry since version then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getModuleSourceAssemblyPartsGeometry(moduleId: string, versionId: string, partsId: string, ifNoneMatch?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedPartGeometry>;
    public getModuleSourceAssemblyPartsGeometry(moduleId: string, versionId: string, partsId: string, ifNoneMatch?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedPartGeometry>>;
    public getModuleSourceAssemblyPartsGeometry(moduleId: string, versionId: string, partsId: string, ifNoneMatch?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedPartGeometry>>;
    public getModuleSourceAssemblyPartsGeometry(moduleId: string, versionId: string, partsId: string, ifNoneMatch?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (moduleId === null || moduleId === undefined) {
            throw new Error('Required parameter moduleId was null or undefined when calling getModuleSourceAssemblyPartsGeometry.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getModuleSourceAssemblyPartsGeometry.');
        }

        if (partsId === null || partsId === undefined) {
            throw new Error('Required parameter partsId was null or undefined when calling getModuleSourceAssemblyPartsGeometry.');
        }


        let headers = this.defaultHeaders;
        if (ifNoneMatch !== undefined && ifNoneMatch !== null) {
            headers = headers.set('If-None-Match', String(ifNoneMatch));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedPartGeometry>('get',`${this.basePath}/modules/${encodeURIComponent(String(moduleId))}/v/${encodeURIComponent(String(versionId))}/source/assembly/parts/${encodeURIComponent(String(partsId))}/geometry`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param moduleId ID of Module
     * @param versionId Version of Module
     * @param subassembliesId 
     * @param partsId 
     * @param ifNoneMatch Version of PartGeometry already received, if no change to PartGeometry since version then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getModuleSourceAssemblySubassembliesPartsGeometry(moduleId: string, versionId: string, subassembliesId: string, partsId: string, ifNoneMatch?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedPartGeometry>;
    public getModuleSourceAssemblySubassembliesPartsGeometry(moduleId: string, versionId: string, subassembliesId: string, partsId: string, ifNoneMatch?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedPartGeometry>>;
    public getModuleSourceAssemblySubassembliesPartsGeometry(moduleId: string, versionId: string, subassembliesId: string, partsId: string, ifNoneMatch?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedPartGeometry>>;
    public getModuleSourceAssemblySubassembliesPartsGeometry(moduleId: string, versionId: string, subassembliesId: string, partsId: string, ifNoneMatch?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (moduleId === null || moduleId === undefined) {
            throw new Error('Required parameter moduleId was null or undefined when calling getModuleSourceAssemblySubassembliesPartsGeometry.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getModuleSourceAssemblySubassembliesPartsGeometry.');
        }

        if (subassembliesId === null || subassembliesId === undefined) {
            throw new Error('Required parameter subassembliesId was null or undefined when calling getModuleSourceAssemblySubassembliesPartsGeometry.');
        }

        if (partsId === null || partsId === undefined) {
            throw new Error('Required parameter partsId was null or undefined when calling getModuleSourceAssemblySubassembliesPartsGeometry.');
        }


        let headers = this.defaultHeaders;
        if (ifNoneMatch !== undefined && ifNoneMatch !== null) {
            headers = headers.set('If-None-Match', String(ifNoneMatch));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedPartGeometry>('get',`${this.basePath}/modules/${encodeURIComponent(String(moduleId))}/v/${encodeURIComponent(String(versionId))}/source/assembly/subassemblies/${encodeURIComponent(String(subassembliesId))}/parts/${encodeURIComponent(String(partsId))}/geometry`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param moduleId ID of Module
     * @param versionId Version of Module
     * @param ifNoneMatch Version of Assembly already received, if no change to Assembly since version then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getModuleSourceFileAssembly(moduleId: string, versionId: string, ifNoneMatch?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedAssembly>;
    public getModuleSourceFileAssembly(moduleId: string, versionId: string, ifNoneMatch?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedAssembly>>;
    public getModuleSourceFileAssembly(moduleId: string, versionId: string, ifNoneMatch?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedAssembly>>;
    public getModuleSourceFileAssembly(moduleId: string, versionId: string, ifNoneMatch?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (moduleId === null || moduleId === undefined) {
            throw new Error('Required parameter moduleId was null or undefined when calling getModuleSourceFileAssembly.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getModuleSourceFileAssembly.');
        }


        let headers = this.defaultHeaders;
        if (ifNoneMatch !== undefined && ifNoneMatch !== null) {
            headers = headers.set('If-None-Match', String(ifNoneMatch));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedAssembly>('get',`${this.basePath}/modules/${encodeURIComponent(String(moduleId))}/v/${encodeURIComponent(String(versionId))}/source/file/assembly`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param moduleId ID of Module
     * @param versionId Version of Module
     * @param partsId 
     * @param ifNoneMatch Version of PartGeometry already received, if no change to PartGeometry since version then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getModuleSourceFileAssemblyPartsGeometry(moduleId: string, versionId: string, partsId: string, ifNoneMatch?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedPartGeometry>;
    public getModuleSourceFileAssemblyPartsGeometry(moduleId: string, versionId: string, partsId: string, ifNoneMatch?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedPartGeometry>>;
    public getModuleSourceFileAssemblyPartsGeometry(moduleId: string, versionId: string, partsId: string, ifNoneMatch?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedPartGeometry>>;
    public getModuleSourceFileAssemblyPartsGeometry(moduleId: string, versionId: string, partsId: string, ifNoneMatch?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (moduleId === null || moduleId === undefined) {
            throw new Error('Required parameter moduleId was null or undefined when calling getModuleSourceFileAssemblyPartsGeometry.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getModuleSourceFileAssemblyPartsGeometry.');
        }

        if (partsId === null || partsId === undefined) {
            throw new Error('Required parameter partsId was null or undefined when calling getModuleSourceFileAssemblyPartsGeometry.');
        }


        let headers = this.defaultHeaders;
        if (ifNoneMatch !== undefined && ifNoneMatch !== null) {
            headers = headers.set('If-None-Match', String(ifNoneMatch));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedPartGeometry>('get',`${this.basePath}/modules/${encodeURIComponent(String(moduleId))}/v/${encodeURIComponent(String(versionId))}/source/file/assembly/parts/${encodeURIComponent(String(partsId))}/geometry`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param moduleId ID of Module
     * @param versionId Version of Module
     * @param subassembliesId 
     * @param partsId 
     * @param ifNoneMatch Version of PartGeometry already received, if no change to PartGeometry since version then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getModuleSourceFileAssemblySubassembliesPartsGeometry(moduleId: string, versionId: string, subassembliesId: string, partsId: string, ifNoneMatch?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedPartGeometry>;
    public getModuleSourceFileAssemblySubassembliesPartsGeometry(moduleId: string, versionId: string, subassembliesId: string, partsId: string, ifNoneMatch?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedPartGeometry>>;
    public getModuleSourceFileAssemblySubassembliesPartsGeometry(moduleId: string, versionId: string, subassembliesId: string, partsId: string, ifNoneMatch?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedPartGeometry>>;
    public getModuleSourceFileAssemblySubassembliesPartsGeometry(moduleId: string, versionId: string, subassembliesId: string, partsId: string, ifNoneMatch?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (moduleId === null || moduleId === undefined) {
            throw new Error('Required parameter moduleId was null or undefined when calling getModuleSourceFileAssemblySubassembliesPartsGeometry.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getModuleSourceFileAssemblySubassembliesPartsGeometry.');
        }

        if (subassembliesId === null || subassembliesId === undefined) {
            throw new Error('Required parameter subassembliesId was null or undefined when calling getModuleSourceFileAssemblySubassembliesPartsGeometry.');
        }

        if (partsId === null || partsId === undefined) {
            throw new Error('Required parameter partsId was null or undefined when calling getModuleSourceFileAssemblySubassembliesPartsGeometry.');
        }


        let headers = this.defaultHeaders;
        if (ifNoneMatch !== undefined && ifNoneMatch !== null) {
            headers = headers.set('If-None-Match', String(ifNoneMatch));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedPartGeometry>('get',`${this.basePath}/modules/${encodeURIComponent(String(moduleId))}/v/${encodeURIComponent(String(versionId))}/source/file/assembly/subassemblies/${encodeURIComponent(String(subassembliesId))}/parts/${encodeURIComponent(String(partsId))}/geometry`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param moduleId ID of Module
     * @param versionId Version of Module
     * @param ifNoneMatch Version of Artefact already received, if no change to Artefact since version then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getModuleSourceFileFile(moduleId: string, versionId: string, ifNoneMatch?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedArtefact>;
    public getModuleSourceFileFile(moduleId: string, versionId: string, ifNoneMatch?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedArtefact>>;
    public getModuleSourceFileFile(moduleId: string, versionId: string, ifNoneMatch?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedArtefact>>;
    public getModuleSourceFileFile(moduleId: string, versionId: string, ifNoneMatch?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (moduleId === null || moduleId === undefined) {
            throw new Error('Required parameter moduleId was null or undefined when calling getModuleSourceFileFile.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getModuleSourceFileFile.');
        }


        let headers = this.defaultHeaders;
        if (ifNoneMatch !== undefined && ifNoneMatch !== null) {
            headers = headers.set('If-None-Match', String(ifNoneMatch));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedArtefact>('get',`${this.basePath}/modules/${encodeURIComponent(String(moduleId))}/v/${encodeURIComponent(String(versionId))}/source/file/file`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param moduleId ID of Module to return
     * @param versionId Version of Module
     * @param extension File extension requested
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getModuleSourceFileFileContent(moduleId: string, versionId: string, extension: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public getModuleSourceFileFileContent(moduleId: string, versionId: string, extension: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public getModuleSourceFileFileContent(moduleId: string, versionId: string, extension: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public getModuleSourceFileFileContent(moduleId: string, versionId: string, extension: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (moduleId === null || moduleId === undefined) {
            throw new Error('Required parameter moduleId was null or undefined when calling getModuleSourceFileFileContent.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getModuleSourceFileFileContent.');
        }

        if (extension === null || extension === undefined) {
            throw new Error('Required parameter extension was null or undefined when calling getModuleSourceFileFileContent.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'binary/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<string>('get',`${this.basePath}/modules/${encodeURIComponent(String(moduleId))}/v/${encodeURIComponent(String(versionId))}/source/file/file.${encodeURIComponent(String(extension))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param moduleId ID of Module
     * @param versionId Version of Module
     * @param ifNoneMatch Version of Artefact already received, if no change to Artefact since version then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getModuleSourceImage(moduleId: string, versionId: string, ifNoneMatch?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedArtefact>;
    public getModuleSourceImage(moduleId: string, versionId: string, ifNoneMatch?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedArtefact>>;
    public getModuleSourceImage(moduleId: string, versionId: string, ifNoneMatch?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedArtefact>>;
    public getModuleSourceImage(moduleId: string, versionId: string, ifNoneMatch?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (moduleId === null || moduleId === undefined) {
            throw new Error('Required parameter moduleId was null or undefined when calling getModuleSourceImage.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getModuleSourceImage.');
        }


        let headers = this.defaultHeaders;
        if (ifNoneMatch !== undefined && ifNoneMatch !== null) {
            headers = headers.set('If-None-Match', String(ifNoneMatch));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedArtefact>('get',`${this.basePath}/modules/${encodeURIComponent(String(moduleId))}/v/${encodeURIComponent(String(versionId))}/source/image`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param moduleId ID of Module to return
     * @param versionId Version of Module
     * @param extension File extension requested
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getModuleSourceImageContent(moduleId: string, versionId: string, extension: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public getModuleSourceImageContent(moduleId: string, versionId: string, extension: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public getModuleSourceImageContent(moduleId: string, versionId: string, extension: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public getModuleSourceImageContent(moduleId: string, versionId: string, extension: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (moduleId === null || moduleId === undefined) {
            throw new Error('Required parameter moduleId was null or undefined when calling getModuleSourceImageContent.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getModuleSourceImageContent.');
        }

        if (extension === null || extension === undefined) {
            throw new Error('Required parameter extension was null or undefined when calling getModuleSourceImageContent.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'binary/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<string>('get',`${this.basePath}/modules/${encodeURIComponent(String(moduleId))}/v/${encodeURIComponent(String(versionId))}/source/image.${encodeURIComponent(String(extension))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param moduleId ID of Module
     * @param versionId Version of Module
     * @param ifNoneMatch Version of MaterialStyling already received, if no change to MaterialStyling since version then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getModuleSourceMaterial(moduleId: string, versionId: string, ifNoneMatch?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedMaterialStyling>;
    public getModuleSourceMaterial(moduleId: string, versionId: string, ifNoneMatch?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedMaterialStyling>>;
    public getModuleSourceMaterial(moduleId: string, versionId: string, ifNoneMatch?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedMaterialStyling>>;
    public getModuleSourceMaterial(moduleId: string, versionId: string, ifNoneMatch?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (moduleId === null || moduleId === undefined) {
            throw new Error('Required parameter moduleId was null or undefined when calling getModuleSourceMaterial.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getModuleSourceMaterial.');
        }


        let headers = this.defaultHeaders;
        if (ifNoneMatch !== undefined && ifNoneMatch !== null) {
            headers = headers.set('If-None-Match', String(ifNoneMatch));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedMaterialStyling>('get',`${this.basePath}/modules/${encodeURIComponent(String(moduleId))}/v/${encodeURIComponent(String(versionId))}/source/material`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param moduleId ID of Module
     * @param versionId Version of Module
     * @param ifNoneMatch Version of Artefact already received, if no change to Artefact since version then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getModuleSourceRender(moduleId: string, versionId: string, ifNoneMatch?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedArtefact>;
    public getModuleSourceRender(moduleId: string, versionId: string, ifNoneMatch?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedArtefact>>;
    public getModuleSourceRender(moduleId: string, versionId: string, ifNoneMatch?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedArtefact>>;
    public getModuleSourceRender(moduleId: string, versionId: string, ifNoneMatch?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (moduleId === null || moduleId === undefined) {
            throw new Error('Required parameter moduleId was null or undefined when calling getModuleSourceRender.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getModuleSourceRender.');
        }


        let headers = this.defaultHeaders;
        if (ifNoneMatch !== undefined && ifNoneMatch !== null) {
            headers = headers.set('If-None-Match', String(ifNoneMatch));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedArtefact>('get',`${this.basePath}/modules/${encodeURIComponent(String(moduleId))}/v/${encodeURIComponent(String(versionId))}/source/render`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param moduleId ID of Module to return
     * @param versionId Version of Module
     * @param extension File extension requested
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getModuleSourceRenderContent(moduleId: string, versionId: string, extension: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public getModuleSourceRenderContent(moduleId: string, versionId: string, extension: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public getModuleSourceRenderContent(moduleId: string, versionId: string, extension: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public getModuleSourceRenderContent(moduleId: string, versionId: string, extension: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (moduleId === null || moduleId === undefined) {
            throw new Error('Required parameter moduleId was null or undefined when calling getModuleSourceRenderContent.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getModuleSourceRenderContent.');
        }

        if (extension === null || extension === undefined) {
            throw new Error('Required parameter extension was null or undefined when calling getModuleSourceRenderContent.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'binary/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<string>('get',`${this.basePath}/modules/${encodeURIComponent(String(moduleId))}/v/${encodeURIComponent(String(versionId))}/source/render.${encodeURIComponent(String(extension))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Find usages of specified Module
     * @param moduleId ID of Module to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getModuleUsages(moduleId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Reference>>;
    public getModuleUsages(moduleId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Reference>>>;
    public getModuleUsages(moduleId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Reference>>>;
    public getModuleUsages(moduleId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (moduleId === null || moduleId === undefined) {
            throw new Error('Required parameter moduleId was null or undefined when calling getModuleUsages.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Reference>>('get',`${this.basePath}/modules/${encodeURIComponent(String(moduleId))}/usages`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get version summary of specified Module
     * @param moduleId ID of Module to return
     * @param versionId Version to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getModuleVersionSummary(moduleId: string, versionId: string, observe?: 'body', reportProgress?: boolean): Observable<VersionLogVersion>;
    public getModuleVersionSummary(moduleId: string, versionId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLogVersion>>;
    public getModuleVersionSummary(moduleId: string, versionId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLogVersion>>;
    public getModuleVersionSummary(moduleId: string, versionId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (moduleId === null || moduleId === undefined) {
            throw new Error('Required parameter moduleId was null or undefined when calling getModuleVersionSummary.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getModuleVersionSummary.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLogVersion>('get',`${this.basePath}/modules/${encodeURIComponent(String(moduleId))}/log/${encodeURIComponent(String(versionId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Find usages of specified Module version
     * @param moduleId ID of Module to return
     * @param versionId Version of Module to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getModuleVersionUsages(moduleId: string, versionId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Reference>>;
    public getModuleVersionUsages(moduleId: string, versionId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Reference>>>;
    public getModuleVersionUsages(moduleId: string, versionId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Reference>>>;
    public getModuleVersionUsages(moduleId: string, versionId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (moduleId === null || moduleId === undefined) {
            throw new Error('Required parameter moduleId was null or undefined when calling getModuleVersionUsages.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getModuleVersionUsages.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Reference>>('get',`${this.basePath}/modules/${encodeURIComponent(String(moduleId))}/v/${encodeURIComponent(String(versionId))}/usages`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get activity of current user
     * @param from From date
     * @param to To date
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMyActivity(from?: string, to?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Activity>>;
    public getMyActivity(from?: string, to?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Activity>>>;
    public getMyActivity(from?: string, to?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Activity>>>;
    public getMyActivity(from?: string, to?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from);
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('to', <any>to);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Activity>>('get',`${this.basePath}/users/me/activity`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get specified version of specified OfflineAction
     * @param offlineactionId ID of OfflineAction to return
     * @param versionId Version of OfflineAction to return
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param ifNoneMatch Version of OfflineAction already received, if no change to OfflineAction since version then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOfflineAction(offlineactionId: string, versionId: string, multiWorkspace?: boolean, complete?: boolean, ifNoneMatch?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedOfflineAction>;
    public getOfflineAction(offlineactionId: string, versionId: string, multiWorkspace?: boolean, complete?: boolean, ifNoneMatch?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedOfflineAction>>;
    public getOfflineAction(offlineactionId: string, versionId: string, multiWorkspace?: boolean, complete?: boolean, ifNoneMatch?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedOfflineAction>>;
    public getOfflineAction(offlineactionId: string, versionId: string, multiWorkspace?: boolean, complete?: boolean, ifNoneMatch?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (offlineactionId === null || offlineactionId === undefined) {
            throw new Error('Required parameter offlineactionId was null or undefined when calling getOfflineAction.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getOfflineAction.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;
        if (ifNoneMatch !== undefined && ifNoneMatch !== null) {
            headers = headers.set('If-None-Match', String(ifNoneMatch));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedOfflineAction>('get',`${this.basePath}/offlineactions/${encodeURIComponent(String(offlineactionId))}/v/${encodeURIComponent(String(versionId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get diff between versions of OfflineActions
     * @param offlineactionId1 ID of first OfflineAction to compare
     * @param versionId1 Version of first OfflineAction to compare
     * @param offlineactionId2 ID of second OfflineAction to compare
     * @param versionId2 Version of second OfflineAction to compare
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOfflineActionDiff(offlineactionId1: string, versionId1: string, offlineactionId2: string, versionId2: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedOfflineActionDiff>;
    public getOfflineActionDiff(offlineactionId1: string, versionId1: string, offlineactionId2: string, versionId2: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedOfflineActionDiff>>;
    public getOfflineActionDiff(offlineactionId1: string, versionId1: string, offlineactionId2: string, versionId2: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedOfflineActionDiff>>;
    public getOfflineActionDiff(offlineactionId1: string, versionId1: string, offlineactionId2: string, versionId2: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (offlineactionId1 === null || offlineactionId1 === undefined) {
            throw new Error('Required parameter offlineactionId1 was null or undefined when calling getOfflineActionDiff.');
        }

        if (versionId1 === null || versionId1 === undefined) {
            throw new Error('Required parameter versionId1 was null or undefined when calling getOfflineActionDiff.');
        }

        if (offlineactionId2 === null || offlineactionId2 === undefined) {
            throw new Error('Required parameter offlineactionId2 was null or undefined when calling getOfflineActionDiff.');
        }

        if (versionId2 === null || versionId2 === undefined) {
            throw new Error('Required parameter versionId2 was null or undefined when calling getOfflineActionDiff.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedOfflineActionDiff>('get',`${this.basePath}/offlineactions/${encodeURIComponent(String(offlineactionId1))}/v/${encodeURIComponent(String(versionId1))}/diff/${encodeURIComponent(String(offlineactionId2))}/v/${encodeURIComponent(String(versionId2))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get latest version of specified OfflineAction
     * @param offlineactionId ID of OfflineAction to return
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param eTag Version of OfflineAction considered to be HEAD, if no newer OfflineAction is found then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOfflineActionHead(offlineactionId: string, multiWorkspace?: boolean, complete?: boolean, eTag?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedOfflineAction>;
    public getOfflineActionHead(offlineactionId: string, multiWorkspace?: boolean, complete?: boolean, eTag?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedOfflineAction>>;
    public getOfflineActionHead(offlineactionId: string, multiWorkspace?: boolean, complete?: boolean, eTag?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedOfflineAction>>;
    public getOfflineActionHead(offlineactionId: string, multiWorkspace?: boolean, complete?: boolean, eTag?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (offlineactionId === null || offlineactionId === undefined) {
            throw new Error('Required parameter offlineactionId was null or undefined when calling getOfflineActionHead.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;
        if (eTag !== undefined && eTag !== null) {
            headers = headers.set('ETag', String(eTag));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedOfflineAction>('get',`${this.basePath}/offlineactions/${encodeURIComponent(String(offlineactionId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param offlineactionId ID of OfflineAction
     * @param eTag Version of Artefact considered to be HEAD, if no newer Artefact is found then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOfflineActionHeadResult(offlineactionId: string, eTag?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedArtefact>;
    public getOfflineActionHeadResult(offlineactionId: string, eTag?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedArtefact>>;
    public getOfflineActionHeadResult(offlineactionId: string, eTag?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedArtefact>>;
    public getOfflineActionHeadResult(offlineactionId: string, eTag?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (offlineactionId === null || offlineactionId === undefined) {
            throw new Error('Required parameter offlineactionId was null or undefined when calling getOfflineActionHeadResult.');
        }


        let headers = this.defaultHeaders;
        if (eTag !== undefined && eTag !== null) {
            headers = headers.set('ETag', String(eTag));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedArtefact>('get',`${this.basePath}/offlineactions/${encodeURIComponent(String(offlineactionId))}/result`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param offlineactionId ID of OfflineAction to return
     * @param extension File extension requested
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOfflineActionHeadResultContent(offlineactionId: string, extension: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public getOfflineActionHeadResultContent(offlineactionId: string, extension: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public getOfflineActionHeadResultContent(offlineactionId: string, extension: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public getOfflineActionHeadResultContent(offlineactionId: string, extension: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (offlineactionId === null || offlineactionId === undefined) {
            throw new Error('Required parameter offlineactionId was null or undefined when calling getOfflineActionHeadResultContent.');
        }

        if (extension === null || extension === undefined) {
            throw new Error('Required parameter extension was null or undefined when calling getOfflineActionHeadResultContent.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'binary/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<string>('get',`${this.basePath}/offlineactions/${encodeURIComponent(String(offlineactionId))}/result.${encodeURIComponent(String(extension))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param offlineactionId ID of OfflineAction to return
     * @param from First version to consider in log
     * @param to Last version to consider in log
     * @param limit Maximum number of versions in log
     * @param tag Tag of version to find
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOfflineActionHeadResultLog(offlineactionId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionLog>;
    public getOfflineActionHeadResultLog(offlineactionId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLog>>;
    public getOfflineActionHeadResultLog(offlineactionId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLog>>;
    public getOfflineActionHeadResultLog(offlineactionId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (offlineactionId === null || offlineactionId === undefined) {
            throw new Error('Required parameter offlineactionId was null or undefined when calling getOfflineActionHeadResultLog.');
        }







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from);
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('to', <any>to);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (tag !== undefined && tag !== null) {
            queryParameters = queryParameters.set('tag', <any>tag);
        }
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLog>('get',`${this.basePath}/offlineactions/${encodeURIComponent(String(offlineactionId))}/result/log`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get history of specified OfflineAction
     * @param offlineactionId ID of OfflineAction to return
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOfflineActionHistory(offlineactionId: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionGraph>;
    public getOfflineActionHistory(offlineactionId: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionGraph>>;
    public getOfflineActionHistory(offlineactionId: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionGraph>>;
    public getOfflineActionHistory(offlineactionId: string, multiWorkspace?: boolean, complete?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (offlineactionId === null || offlineactionId === undefined) {
            throw new Error('Required parameter offlineactionId was null or undefined when calling getOfflineActionHistory.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionGraph>('get',`${this.basePath}/offlineactions/${encodeURIComponent(String(offlineactionId))}/history`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get log of specified OfflineAction
     * @param offlineactionId ID of OfflineAction to return
     * @param from First version to consider in log
     * @param to Last version to consider in log
     * @param limit Maximum number of versions in log
     * @param tag Tag of version to find
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOfflineActionLog(offlineactionId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionLog>;
    public getOfflineActionLog(offlineactionId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLog>>;
    public getOfflineActionLog(offlineactionId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLog>>;
    public getOfflineActionLog(offlineactionId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (offlineactionId === null || offlineactionId === undefined) {
            throw new Error('Required parameter offlineactionId was null or undefined when calling getOfflineActionLog.');
        }







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from);
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('to', <any>to);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (tag !== undefined && tag !== null) {
            queryParameters = queryParameters.set('tag', <any>tag);
        }
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLog>('get',`${this.basePath}/offlineactions/${encodeURIComponent(String(offlineactionId))}/log`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get OfflineAction permissions
     * @param offlineactionId ID of OfflineAction to check
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOfflineActionPermissions(offlineactionId: string, observe?: 'body', reportProgress?: boolean): Observable<UserPermissions>;
    public getOfflineActionPermissions(offlineactionId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserPermissions>>;
    public getOfflineActionPermissions(offlineactionId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserPermissions>>;
    public getOfflineActionPermissions(offlineactionId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (offlineactionId === null || offlineactionId === undefined) {
            throw new Error('Required parameter offlineactionId was null or undefined when calling getOfflineActionPermissions.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<UserPermissions>('get',`${this.basePath}/offlineactions/${encodeURIComponent(String(offlineactionId))}/permissions`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param offlineactionId ID of OfflineAction
     * @param versionId Version of OfflineAction
     * @param ifNoneMatch Version of Artefact already received, if no change to Artefact since version then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOfflineActionResult(offlineactionId: string, versionId: string, ifNoneMatch?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedArtefact>;
    public getOfflineActionResult(offlineactionId: string, versionId: string, ifNoneMatch?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedArtefact>>;
    public getOfflineActionResult(offlineactionId: string, versionId: string, ifNoneMatch?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedArtefact>>;
    public getOfflineActionResult(offlineactionId: string, versionId: string, ifNoneMatch?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (offlineactionId === null || offlineactionId === undefined) {
            throw new Error('Required parameter offlineactionId was null or undefined when calling getOfflineActionResult.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getOfflineActionResult.');
        }


        let headers = this.defaultHeaders;
        if (ifNoneMatch !== undefined && ifNoneMatch !== null) {
            headers = headers.set('If-None-Match', String(ifNoneMatch));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedArtefact>('get',`${this.basePath}/offlineactions/${encodeURIComponent(String(offlineactionId))}/v/${encodeURIComponent(String(versionId))}/result`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param offlineactionId ID of OfflineAction to return
     * @param versionId Version of OfflineAction
     * @param extension File extension requested
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOfflineActionResultContent(offlineactionId: string, versionId: string, extension: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public getOfflineActionResultContent(offlineactionId: string, versionId: string, extension: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public getOfflineActionResultContent(offlineactionId: string, versionId: string, extension: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public getOfflineActionResultContent(offlineactionId: string, versionId: string, extension: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (offlineactionId === null || offlineactionId === undefined) {
            throw new Error('Required parameter offlineactionId was null or undefined when calling getOfflineActionResultContent.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getOfflineActionResultContent.');
        }

        if (extension === null || extension === undefined) {
            throw new Error('Required parameter extension was null or undefined when calling getOfflineActionResultContent.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'binary/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<string>('get',`${this.basePath}/offlineactions/${encodeURIComponent(String(offlineactionId))}/v/${encodeURIComponent(String(versionId))}/result.${encodeURIComponent(String(extension))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get sharing details of specified OfflineAction
     * @param offlineactionId ID of OfflineAction to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOfflineActionSharing(offlineactionId: string, observe?: 'body', reportProgress?: boolean): Observable<Sharing>;
    public getOfflineActionSharing(offlineactionId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Sharing>>;
    public getOfflineActionSharing(offlineactionId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Sharing>>;
    public getOfflineActionSharing(offlineactionId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (offlineactionId === null || offlineactionId === undefined) {
            throw new Error('Required parameter offlineactionId was null or undefined when calling getOfflineActionSharing.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Sharing>('get',`${this.basePath}/offlineactions/${encodeURIComponent(String(offlineactionId))}/sharing`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get short code to share the specified OfflineAction
     * @param offlineactionId ID of OfflineAction to share
     * @param mode Mode: copy or view or edit
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOfflineActionShortCode(offlineactionId: string, mode: string, observe?: 'body', reportProgress?: boolean): Observable<ShortCode>;
    public getOfflineActionShortCode(offlineactionId: string, mode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ShortCode>>;
    public getOfflineActionShortCode(offlineactionId: string, mode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ShortCode>>;
    public getOfflineActionShortCode(offlineactionId: string, mode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (offlineactionId === null || offlineactionId === undefined) {
            throw new Error('Required parameter offlineactionId was null or undefined when calling getOfflineActionShortCode.');
        }

        if (mode === null || mode === undefined) {
            throw new Error('Required parameter mode was null or undefined when calling getOfflineActionShortCode.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ShortCode>('get',`${this.basePath}/s/offlineactions/${encodeURIComponent(String(offlineactionId))}/${encodeURIComponent(String(mode))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Find usages of specified OfflineAction
     * @param offlineactionId ID of OfflineAction to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOfflineActionUsages(offlineactionId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Reference>>;
    public getOfflineActionUsages(offlineactionId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Reference>>>;
    public getOfflineActionUsages(offlineactionId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Reference>>>;
    public getOfflineActionUsages(offlineactionId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (offlineactionId === null || offlineactionId === undefined) {
            throw new Error('Required parameter offlineactionId was null or undefined when calling getOfflineActionUsages.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Reference>>('get',`${this.basePath}/offlineactions/${encodeURIComponent(String(offlineactionId))}/usages`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get version summary of specified OfflineAction
     * @param offlineactionId ID of OfflineAction to return
     * @param versionId Version to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOfflineActionVersionSummary(offlineactionId: string, versionId: string, observe?: 'body', reportProgress?: boolean): Observable<VersionLogVersion>;
    public getOfflineActionVersionSummary(offlineactionId: string, versionId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLogVersion>>;
    public getOfflineActionVersionSummary(offlineactionId: string, versionId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLogVersion>>;
    public getOfflineActionVersionSummary(offlineactionId: string, versionId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (offlineactionId === null || offlineactionId === undefined) {
            throw new Error('Required parameter offlineactionId was null or undefined when calling getOfflineActionVersionSummary.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getOfflineActionVersionSummary.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLogVersion>('get',`${this.basePath}/offlineactions/${encodeURIComponent(String(offlineactionId))}/log/${encodeURIComponent(String(versionId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Find usages of specified OfflineAction version
     * @param offlineactionId ID of OfflineAction to return
     * @param versionId Version of OfflineAction to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOfflineActionVersionUsages(offlineactionId: string, versionId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Reference>>;
    public getOfflineActionVersionUsages(offlineactionId: string, versionId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Reference>>>;
    public getOfflineActionVersionUsages(offlineactionId: string, versionId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Reference>>>;
    public getOfflineActionVersionUsages(offlineactionId: string, versionId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (offlineactionId === null || offlineactionId === undefined) {
            throw new Error('Required parameter offlineactionId was null or undefined when calling getOfflineActionVersionUsages.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getOfflineActionVersionUsages.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Reference>>('get',`${this.basePath}/offlineactions/${encodeURIComponent(String(offlineactionId))}/v/${encodeURIComponent(String(versionId))}/usages`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get specified version of specified Order
     * @param orderId ID of Order to return
     * @param versionId Version of Order to return
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param ifNoneMatch Version of Order already received, if no change to Order since version then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOrder(orderId: string, versionId: string, multiWorkspace?: boolean, complete?: boolean, ifNoneMatch?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedOrder>;
    public getOrder(orderId: string, versionId: string, multiWorkspace?: boolean, complete?: boolean, ifNoneMatch?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedOrder>>;
    public getOrder(orderId: string, versionId: string, multiWorkspace?: boolean, complete?: boolean, ifNoneMatch?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedOrder>>;
    public getOrder(orderId: string, versionId: string, multiWorkspace?: boolean, complete?: boolean, ifNoneMatch?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (orderId === null || orderId === undefined) {
            throw new Error('Required parameter orderId was null or undefined when calling getOrder.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getOrder.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;
        if (ifNoneMatch !== undefined && ifNoneMatch !== null) {
            headers = headers.set('If-None-Match', String(ifNoneMatch));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedOrder>('get',`${this.basePath}/orders/${encodeURIComponent(String(orderId))}/v/${encodeURIComponent(String(versionId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get diff between versions of Orders
     * @param orderId1 ID of first Order to compare
     * @param versionId1 Version of first Order to compare
     * @param orderId2 ID of second Order to compare
     * @param versionId2 Version of second Order to compare
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOrderDiff(orderId1: string, versionId1: string, orderId2: string, versionId2: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedOrderDiff>;
    public getOrderDiff(orderId1: string, versionId1: string, orderId2: string, versionId2: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedOrderDiff>>;
    public getOrderDiff(orderId1: string, versionId1: string, orderId2: string, versionId2: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedOrderDiff>>;
    public getOrderDiff(orderId1: string, versionId1: string, orderId2: string, versionId2: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (orderId1 === null || orderId1 === undefined) {
            throw new Error('Required parameter orderId1 was null or undefined when calling getOrderDiff.');
        }

        if (versionId1 === null || versionId1 === undefined) {
            throw new Error('Required parameter versionId1 was null or undefined when calling getOrderDiff.');
        }

        if (orderId2 === null || orderId2 === undefined) {
            throw new Error('Required parameter orderId2 was null or undefined when calling getOrderDiff.');
        }

        if (versionId2 === null || versionId2 === undefined) {
            throw new Error('Required parameter versionId2 was null or undefined when calling getOrderDiff.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedOrderDiff>('get',`${this.basePath}/orders/${encodeURIComponent(String(orderId1))}/v/${encodeURIComponent(String(versionId1))}/diff/${encodeURIComponent(String(orderId2))}/v/${encodeURIComponent(String(versionId2))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get latest version of specified Order
     * @param orderId ID of Order to return
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param eTag Version of Order considered to be HEAD, if no newer Order is found then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOrderHead(orderId: string, multiWorkspace?: boolean, complete?: boolean, eTag?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedOrder>;
    public getOrderHead(orderId: string, multiWorkspace?: boolean, complete?: boolean, eTag?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedOrder>>;
    public getOrderHead(orderId: string, multiWorkspace?: boolean, complete?: boolean, eTag?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedOrder>>;
    public getOrderHead(orderId: string, multiWorkspace?: boolean, complete?: boolean, eTag?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (orderId === null || orderId === undefined) {
            throw new Error('Required parameter orderId was null or undefined when calling getOrderHead.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;
        if (eTag !== undefined && eTag !== null) {
            headers = headers.set('ETag', String(eTag));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedOrder>('get',`${this.basePath}/orders/${encodeURIComponent(String(orderId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param orderId ID of Order
     * @param eTag Version of TransitionHistory considered to be HEAD, if no newer TransitionHistory is found then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOrderHeadTransitions(orderId: string, eTag?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedTransitionHistory>;
    public getOrderHeadTransitions(orderId: string, eTag?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedTransitionHistory>>;
    public getOrderHeadTransitions(orderId: string, eTag?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedTransitionHistory>>;
    public getOrderHeadTransitions(orderId: string, eTag?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (orderId === null || orderId === undefined) {
            throw new Error('Required parameter orderId was null or undefined when calling getOrderHeadTransitions.');
        }


        let headers = this.defaultHeaders;
        if (eTag !== undefined && eTag !== null) {
            headers = headers.set('ETag', String(eTag));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedTransitionHistory>('get',`${this.basePath}/orders/${encodeURIComponent(String(orderId))}/transitions`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param orderId ID of Order to return
     * @param from First version to consider in log
     * @param to Last version to consider in log
     * @param limit Maximum number of versions in log
     * @param tag Tag of version to find
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOrderHeadTransitionsLog(orderId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionLog>;
    public getOrderHeadTransitionsLog(orderId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLog>>;
    public getOrderHeadTransitionsLog(orderId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLog>>;
    public getOrderHeadTransitionsLog(orderId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (orderId === null || orderId === undefined) {
            throw new Error('Required parameter orderId was null or undefined when calling getOrderHeadTransitionsLog.');
        }







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from);
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('to', <any>to);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (tag !== undefined && tag !== null) {
            queryParameters = queryParameters.set('tag', <any>tag);
        }
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLog>('get',`${this.basePath}/orders/${encodeURIComponent(String(orderId))}/transitions/log`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get history of specified Order
     * @param orderId ID of Order to return
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOrderHistory(orderId: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionGraph>;
    public getOrderHistory(orderId: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionGraph>>;
    public getOrderHistory(orderId: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionGraph>>;
    public getOrderHistory(orderId: string, multiWorkspace?: boolean, complete?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (orderId === null || orderId === undefined) {
            throw new Error('Required parameter orderId was null or undefined when calling getOrderHistory.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionGraph>('get',`${this.basePath}/orders/${encodeURIComponent(String(orderId))}/history`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get log of specified Order
     * @param orderId ID of Order to return
     * @param from First version to consider in log
     * @param to Last version to consider in log
     * @param limit Maximum number of versions in log
     * @param tag Tag of version to find
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOrderLog(orderId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionLog>;
    public getOrderLog(orderId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLog>>;
    public getOrderLog(orderId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLog>>;
    public getOrderLog(orderId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (orderId === null || orderId === undefined) {
            throw new Error('Required parameter orderId was null or undefined when calling getOrderLog.');
        }







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from);
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('to', <any>to);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (tag !== undefined && tag !== null) {
            queryParameters = queryParameters.set('tag', <any>tag);
        }
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLog>('get',`${this.basePath}/orders/${encodeURIComponent(String(orderId))}/log`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get Order permissions
     * @param orderId ID of Order to check
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOrderPermissions(orderId: string, observe?: 'body', reportProgress?: boolean): Observable<UserPermissions>;
    public getOrderPermissions(orderId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserPermissions>>;
    public getOrderPermissions(orderId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserPermissions>>;
    public getOrderPermissions(orderId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (orderId === null || orderId === undefined) {
            throw new Error('Required parameter orderId was null or undefined when calling getOrderPermissions.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<UserPermissions>('get',`${this.basePath}/orders/${encodeURIComponent(String(orderId))}/permissions`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get sharing details of specified Order
     * @param orderId ID of Order to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOrderSharing(orderId: string, observe?: 'body', reportProgress?: boolean): Observable<Sharing>;
    public getOrderSharing(orderId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Sharing>>;
    public getOrderSharing(orderId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Sharing>>;
    public getOrderSharing(orderId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (orderId === null || orderId === undefined) {
            throw new Error('Required parameter orderId was null or undefined when calling getOrderSharing.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Sharing>('get',`${this.basePath}/orders/${encodeURIComponent(String(orderId))}/sharing`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get short code to share the specified Order
     * @param orderId ID of Order to share
     * @param mode Mode: copy or view or edit
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOrderShortCode(orderId: string, mode: string, observe?: 'body', reportProgress?: boolean): Observable<ShortCode>;
    public getOrderShortCode(orderId: string, mode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ShortCode>>;
    public getOrderShortCode(orderId: string, mode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ShortCode>>;
    public getOrderShortCode(orderId: string, mode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (orderId === null || orderId === undefined) {
            throw new Error('Required parameter orderId was null or undefined when calling getOrderShortCode.');
        }

        if (mode === null || mode === undefined) {
            throw new Error('Required parameter mode was null or undefined when calling getOrderShortCode.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ShortCode>('get',`${this.basePath}/s/orders/${encodeURIComponent(String(orderId))}/${encodeURIComponent(String(mode))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param orderId ID of Order
     * @param versionId Version of Order
     * @param ifNoneMatch Version of TransitionHistory already received, if no change to TransitionHistory since version then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOrderTransitions(orderId: string, versionId: string, ifNoneMatch?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedTransitionHistory>;
    public getOrderTransitions(orderId: string, versionId: string, ifNoneMatch?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedTransitionHistory>>;
    public getOrderTransitions(orderId: string, versionId: string, ifNoneMatch?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedTransitionHistory>>;
    public getOrderTransitions(orderId: string, versionId: string, ifNoneMatch?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (orderId === null || orderId === undefined) {
            throw new Error('Required parameter orderId was null or undefined when calling getOrderTransitions.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getOrderTransitions.');
        }


        let headers = this.defaultHeaders;
        if (ifNoneMatch !== undefined && ifNoneMatch !== null) {
            headers = headers.set('If-None-Match', String(ifNoneMatch));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedTransitionHistory>('get',`${this.basePath}/orders/${encodeURIComponent(String(orderId))}/v/${encodeURIComponent(String(versionId))}/transitions`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Find usages of specified Order
     * @param orderId ID of Order to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOrderUsages(orderId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Reference>>;
    public getOrderUsages(orderId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Reference>>>;
    public getOrderUsages(orderId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Reference>>>;
    public getOrderUsages(orderId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (orderId === null || orderId === undefined) {
            throw new Error('Required parameter orderId was null or undefined when calling getOrderUsages.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Reference>>('get',`${this.basePath}/orders/${encodeURIComponent(String(orderId))}/usages`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get version summary of specified Order
     * @param orderId ID of Order to return
     * @param versionId Version to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOrderVersionSummary(orderId: string, versionId: string, observe?: 'body', reportProgress?: boolean): Observable<VersionLogVersion>;
    public getOrderVersionSummary(orderId: string, versionId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLogVersion>>;
    public getOrderVersionSummary(orderId: string, versionId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLogVersion>>;
    public getOrderVersionSummary(orderId: string, versionId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (orderId === null || orderId === undefined) {
            throw new Error('Required parameter orderId was null or undefined when calling getOrderVersionSummary.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getOrderVersionSummary.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLogVersion>('get',`${this.basePath}/orders/${encodeURIComponent(String(orderId))}/log/${encodeURIComponent(String(versionId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Find usages of specified Order version
     * @param orderId ID of Order to return
     * @param versionId Version of Order to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOrderVersionUsages(orderId: string, versionId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Reference>>;
    public getOrderVersionUsages(orderId: string, versionId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Reference>>>;
    public getOrderVersionUsages(orderId: string, versionId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Reference>>>;
    public getOrderVersionUsages(orderId: string, versionId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (orderId === null || orderId === undefined) {
            throw new Error('Required parameter orderId was null or undefined when calling getOrderVersionUsages.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getOrderVersionUsages.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Reference>>('get',`${this.basePath}/orders/${encodeURIComponent(String(orderId))}/v/${encodeURIComponent(String(versionId))}/usages`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get specified version of specified Population
     * @param populationId ID of Population to return
     * @param versionId Version of Population to return
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param ifNoneMatch Version of Population already received, if no change to Population since version then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPopulation(populationId: string, versionId: string, multiWorkspace?: boolean, complete?: boolean, ifNoneMatch?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedPopulation>;
    public getPopulation(populationId: string, versionId: string, multiWorkspace?: boolean, complete?: boolean, ifNoneMatch?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedPopulation>>;
    public getPopulation(populationId: string, versionId: string, multiWorkspace?: boolean, complete?: boolean, ifNoneMatch?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedPopulation>>;
    public getPopulation(populationId: string, versionId: string, multiWorkspace?: boolean, complete?: boolean, ifNoneMatch?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (populationId === null || populationId === undefined) {
            throw new Error('Required parameter populationId was null or undefined when calling getPopulation.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getPopulation.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;
        if (ifNoneMatch !== undefined && ifNoneMatch !== null) {
            headers = headers.set('If-None-Match', String(ifNoneMatch));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedPopulation>('get',`${this.basePath}/populations/${encodeURIComponent(String(populationId))}/v/${encodeURIComponent(String(versionId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get diff between versions of Populations
     * @param populationId1 ID of first Population to compare
     * @param versionId1 Version of first Population to compare
     * @param populationId2 ID of second Population to compare
     * @param versionId2 Version of second Population to compare
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPopulationDiff(populationId1: string, versionId1: string, populationId2: string, versionId2: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedPopulationDiff>;
    public getPopulationDiff(populationId1: string, versionId1: string, populationId2: string, versionId2: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedPopulationDiff>>;
    public getPopulationDiff(populationId1: string, versionId1: string, populationId2: string, versionId2: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedPopulationDiff>>;
    public getPopulationDiff(populationId1: string, versionId1: string, populationId2: string, versionId2: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (populationId1 === null || populationId1 === undefined) {
            throw new Error('Required parameter populationId1 was null or undefined when calling getPopulationDiff.');
        }

        if (versionId1 === null || versionId1 === undefined) {
            throw new Error('Required parameter versionId1 was null or undefined when calling getPopulationDiff.');
        }

        if (populationId2 === null || populationId2 === undefined) {
            throw new Error('Required parameter populationId2 was null or undefined when calling getPopulationDiff.');
        }

        if (versionId2 === null || versionId2 === undefined) {
            throw new Error('Required parameter versionId2 was null or undefined when calling getPopulationDiff.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedPopulationDiff>('get',`${this.basePath}/populations/${encodeURIComponent(String(populationId1))}/v/${encodeURIComponent(String(versionId1))}/diff/${encodeURIComponent(String(populationId2))}/v/${encodeURIComponent(String(versionId2))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get latest version of specified Population
     * @param populationId ID of Population to return
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param eTag Version of Population considered to be HEAD, if no newer Population is found then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPopulationHead(populationId: string, multiWorkspace?: boolean, complete?: boolean, eTag?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedPopulation>;
    public getPopulationHead(populationId: string, multiWorkspace?: boolean, complete?: boolean, eTag?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedPopulation>>;
    public getPopulationHead(populationId: string, multiWorkspace?: boolean, complete?: boolean, eTag?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedPopulation>>;
    public getPopulationHead(populationId: string, multiWorkspace?: boolean, complete?: boolean, eTag?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (populationId === null || populationId === undefined) {
            throw new Error('Required parameter populationId was null or undefined when calling getPopulationHead.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;
        if (eTag !== undefined && eTag !== null) {
            headers = headers.set('ETag', String(eTag));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedPopulation>('get',`${this.basePath}/populations/${encodeURIComponent(String(populationId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param populationId ID of Population
     * @param eTag Version of PopulationState considered to be HEAD, if no newer PopulationState is found then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPopulationHeadState(populationId: string, eTag?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedPopulationState>;
    public getPopulationHeadState(populationId: string, eTag?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedPopulationState>>;
    public getPopulationHeadState(populationId: string, eTag?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedPopulationState>>;
    public getPopulationHeadState(populationId: string, eTag?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (populationId === null || populationId === undefined) {
            throw new Error('Required parameter populationId was null or undefined when calling getPopulationHeadState.');
        }


        let headers = this.defaultHeaders;
        if (eTag !== undefined && eTag !== null) {
            headers = headers.set('ETag', String(eTag));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedPopulationState>('get',`${this.basePath}/populations/${encodeURIComponent(String(populationId))}/state`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param populationId ID of Population to return
     * @param from First version to consider in log
     * @param to Last version to consider in log
     * @param limit Maximum number of versions in log
     * @param tag Tag of version to find
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPopulationHeadStateLog(populationId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionLog>;
    public getPopulationHeadStateLog(populationId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLog>>;
    public getPopulationHeadStateLog(populationId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLog>>;
    public getPopulationHeadStateLog(populationId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (populationId === null || populationId === undefined) {
            throw new Error('Required parameter populationId was null or undefined when calling getPopulationHeadStateLog.');
        }







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from);
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('to', <any>to);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (tag !== undefined && tag !== null) {
            queryParameters = queryParameters.set('tag', <any>tag);
        }
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLog>('get',`${this.basePath}/populations/${encodeURIComponent(String(populationId))}/state/log`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get history of specified Population
     * @param populationId ID of Population to return
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPopulationHistory(populationId: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionGraph>;
    public getPopulationHistory(populationId: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionGraph>>;
    public getPopulationHistory(populationId: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionGraph>>;
    public getPopulationHistory(populationId: string, multiWorkspace?: boolean, complete?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (populationId === null || populationId === undefined) {
            throw new Error('Required parameter populationId was null or undefined when calling getPopulationHistory.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionGraph>('get',`${this.basePath}/populations/${encodeURIComponent(String(populationId))}/history`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get log of specified Population
     * @param populationId ID of Population to return
     * @param from First version to consider in log
     * @param to Last version to consider in log
     * @param limit Maximum number of versions in log
     * @param tag Tag of version to find
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPopulationLog(populationId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionLog>;
    public getPopulationLog(populationId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLog>>;
    public getPopulationLog(populationId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLog>>;
    public getPopulationLog(populationId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (populationId === null || populationId === undefined) {
            throw new Error('Required parameter populationId was null or undefined when calling getPopulationLog.');
        }







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from);
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('to', <any>to);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (tag !== undefined && tag !== null) {
            queryParameters = queryParameters.set('tag', <any>tag);
        }
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLog>('get',`${this.basePath}/populations/${encodeURIComponent(String(populationId))}/log`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get Population permissions
     * @param populationId ID of Population to check
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPopulationPermissions(populationId: string, observe?: 'body', reportProgress?: boolean): Observable<UserPermissions>;
    public getPopulationPermissions(populationId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserPermissions>>;
    public getPopulationPermissions(populationId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserPermissions>>;
    public getPopulationPermissions(populationId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (populationId === null || populationId === undefined) {
            throw new Error('Required parameter populationId was null or undefined when calling getPopulationPermissions.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<UserPermissions>('get',`${this.basePath}/populations/${encodeURIComponent(String(populationId))}/permissions`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get sharing details of specified Population
     * @param populationId ID of Population to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPopulationSharing(populationId: string, observe?: 'body', reportProgress?: boolean): Observable<Sharing>;
    public getPopulationSharing(populationId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Sharing>>;
    public getPopulationSharing(populationId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Sharing>>;
    public getPopulationSharing(populationId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (populationId === null || populationId === undefined) {
            throw new Error('Required parameter populationId was null or undefined when calling getPopulationSharing.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Sharing>('get',`${this.basePath}/populations/${encodeURIComponent(String(populationId))}/sharing`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get short code to share the specified Population
     * @param populationId ID of Population to share
     * @param mode Mode: copy or view or edit
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPopulationShortCode(populationId: string, mode: string, observe?: 'body', reportProgress?: boolean): Observable<ShortCode>;
    public getPopulationShortCode(populationId: string, mode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ShortCode>>;
    public getPopulationShortCode(populationId: string, mode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ShortCode>>;
    public getPopulationShortCode(populationId: string, mode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (populationId === null || populationId === undefined) {
            throw new Error('Required parameter populationId was null or undefined when calling getPopulationShortCode.');
        }

        if (mode === null || mode === undefined) {
            throw new Error('Required parameter mode was null or undefined when calling getPopulationShortCode.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ShortCode>('get',`${this.basePath}/s/populations/${encodeURIComponent(String(populationId))}/${encodeURIComponent(String(mode))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param populationId ID of Population
     * @param versionId Version of Population
     * @param ifNoneMatch Version of PopulationState already received, if no change to PopulationState since version then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPopulationState(populationId: string, versionId: string, ifNoneMatch?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedPopulationState>;
    public getPopulationState(populationId: string, versionId: string, ifNoneMatch?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedPopulationState>>;
    public getPopulationState(populationId: string, versionId: string, ifNoneMatch?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedPopulationState>>;
    public getPopulationState(populationId: string, versionId: string, ifNoneMatch?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (populationId === null || populationId === undefined) {
            throw new Error('Required parameter populationId was null or undefined when calling getPopulationState.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getPopulationState.');
        }


        let headers = this.defaultHeaders;
        if (ifNoneMatch !== undefined && ifNoneMatch !== null) {
            headers = headers.set('If-None-Match', String(ifNoneMatch));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedPopulationState>('get',`${this.basePath}/populations/${encodeURIComponent(String(populationId))}/v/${encodeURIComponent(String(versionId))}/state`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Find usages of specified Population
     * @param populationId ID of Population to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPopulationUsages(populationId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Reference>>;
    public getPopulationUsages(populationId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Reference>>>;
    public getPopulationUsages(populationId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Reference>>>;
    public getPopulationUsages(populationId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (populationId === null || populationId === undefined) {
            throw new Error('Required parameter populationId was null or undefined when calling getPopulationUsages.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Reference>>('get',`${this.basePath}/populations/${encodeURIComponent(String(populationId))}/usages`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get version summary of specified Population
     * @param populationId ID of Population to return
     * @param versionId Version to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPopulationVersionSummary(populationId: string, versionId: string, observe?: 'body', reportProgress?: boolean): Observable<VersionLogVersion>;
    public getPopulationVersionSummary(populationId: string, versionId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLogVersion>>;
    public getPopulationVersionSummary(populationId: string, versionId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLogVersion>>;
    public getPopulationVersionSummary(populationId: string, versionId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (populationId === null || populationId === undefined) {
            throw new Error('Required parameter populationId was null or undefined when calling getPopulationVersionSummary.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getPopulationVersionSummary.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLogVersion>('get',`${this.basePath}/populations/${encodeURIComponent(String(populationId))}/log/${encodeURIComponent(String(versionId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Find usages of specified Population version
     * @param populationId ID of Population to return
     * @param versionId Version of Population to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPopulationVersionUsages(populationId: string, versionId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Reference>>;
    public getPopulationVersionUsages(populationId: string, versionId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Reference>>>;
    public getPopulationVersionUsages(populationId: string, versionId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Reference>>>;
    public getPopulationVersionUsages(populationId: string, versionId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (populationId === null || populationId === undefined) {
            throw new Error('Required parameter populationId was null or undefined when calling getPopulationVersionUsages.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getPopulationVersionUsages.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Reference>>('get',`${this.basePath}/populations/${encodeURIComponent(String(populationId))}/v/${encodeURIComponent(String(versionId))}/usages`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get specified version of specified Product
     * @param productId ID of Product to return
     * @param versionId Version of Product to return
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param ifNoneMatch Version of Product already received, if no change to Product since version then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProduct(productId: string, versionId: string, multiWorkspace?: boolean, complete?: boolean, ifNoneMatch?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedProduct>;
    public getProduct(productId: string, versionId: string, multiWorkspace?: boolean, complete?: boolean, ifNoneMatch?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedProduct>>;
    public getProduct(productId: string, versionId: string, multiWorkspace?: boolean, complete?: boolean, ifNoneMatch?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedProduct>>;
    public getProduct(productId: string, versionId: string, multiWorkspace?: boolean, complete?: boolean, ifNoneMatch?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productId === null || productId === undefined) {
            throw new Error('Required parameter productId was null or undefined when calling getProduct.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getProduct.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;
        if (ifNoneMatch !== undefined && ifNoneMatch !== null) {
            headers = headers.set('If-None-Match', String(ifNoneMatch));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedProduct>('get',`${this.basePath}/products/${encodeURIComponent(String(productId))}/v/${encodeURIComponent(String(versionId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param productId ID of Product
     * @param versionId Version of Product
     * @param ifNoneMatch Version of CategoryModulesArray already received, if no change to CategoryModulesArray since version then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProductCategoryModules(productId: string, versionId: string, ifNoneMatch?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedCategoryModulesArray>;
    public getProductCategoryModules(productId: string, versionId: string, ifNoneMatch?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedCategoryModulesArray>>;
    public getProductCategoryModules(productId: string, versionId: string, ifNoneMatch?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedCategoryModulesArray>>;
    public getProductCategoryModules(productId: string, versionId: string, ifNoneMatch?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productId === null || productId === undefined) {
            throw new Error('Required parameter productId was null or undefined when calling getProductCategoryModules.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getProductCategoryModules.');
        }


        let headers = this.defaultHeaders;
        if (ifNoneMatch !== undefined && ifNoneMatch !== null) {
            headers = headers.set('If-None-Match', String(ifNoneMatch));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedCategoryModulesArray>('get',`${this.basePath}/products/${encodeURIComponent(String(productId))}/v/${encodeURIComponent(String(versionId))}/categoryModules`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param productId ID of Product
     * @param versionId Version of Product
     * @param categoryModulesId 
     * @param ifNoneMatch Version of CategoryModules already received, if no change to CategoryModules since version then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProductCategoryModulesCategoryModules(productId: string, versionId: string, categoryModulesId: string, ifNoneMatch?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedCategoryModules>;
    public getProductCategoryModulesCategoryModules(productId: string, versionId: string, categoryModulesId: string, ifNoneMatch?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedCategoryModules>>;
    public getProductCategoryModulesCategoryModules(productId: string, versionId: string, categoryModulesId: string, ifNoneMatch?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedCategoryModules>>;
    public getProductCategoryModulesCategoryModules(productId: string, versionId: string, categoryModulesId: string, ifNoneMatch?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productId === null || productId === undefined) {
            throw new Error('Required parameter productId was null or undefined when calling getProductCategoryModulesCategoryModules.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getProductCategoryModulesCategoryModules.');
        }

        if (categoryModulesId === null || categoryModulesId === undefined) {
            throw new Error('Required parameter categoryModulesId was null or undefined when calling getProductCategoryModulesCategoryModules.');
        }


        let headers = this.defaultHeaders;
        if (ifNoneMatch !== undefined && ifNoneMatch !== null) {
            headers = headers.set('If-None-Match', String(ifNoneMatch));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedCategoryModules>('get',`${this.basePath}/products/${encodeURIComponent(String(productId))}/v/${encodeURIComponent(String(versionId))}/categoryModules/${encodeURIComponent(String(categoryModulesId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get specified version of specified ProductConfiguration
     * @param productconfigurationId ID of ProductConfiguration to return
     * @param versionId Version of ProductConfiguration to return
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param ifNoneMatch Version of ProductConfiguration already received, if no change to ProductConfiguration since version then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProductConfiguration(productconfigurationId: string, versionId: string, multiWorkspace?: boolean, complete?: boolean, ifNoneMatch?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedProductConfiguration>;
    public getProductConfiguration(productconfigurationId: string, versionId: string, multiWorkspace?: boolean, complete?: boolean, ifNoneMatch?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedProductConfiguration>>;
    public getProductConfiguration(productconfigurationId: string, versionId: string, multiWorkspace?: boolean, complete?: boolean, ifNoneMatch?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedProductConfiguration>>;
    public getProductConfiguration(productconfigurationId: string, versionId: string, multiWorkspace?: boolean, complete?: boolean, ifNoneMatch?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productconfigurationId === null || productconfigurationId === undefined) {
            throw new Error('Required parameter productconfigurationId was null or undefined when calling getProductConfiguration.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getProductConfiguration.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;
        if (ifNoneMatch !== undefined && ifNoneMatch !== null) {
            headers = headers.set('If-None-Match', String(ifNoneMatch));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedProductConfiguration>('get',`${this.basePath}/productconfigurations/${encodeURIComponent(String(productconfigurationId))}/v/${encodeURIComponent(String(versionId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get diff between versions of ProductConfigurations
     * @param productconfigurationId1 ID of first ProductConfiguration to compare
     * @param versionId1 Version of first ProductConfiguration to compare
     * @param productconfigurationId2 ID of second ProductConfiguration to compare
     * @param versionId2 Version of second ProductConfiguration to compare
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProductConfigurationDiff(productconfigurationId1: string, versionId1: string, productconfigurationId2: string, versionId2: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedProductConfigurationDiff>;
    public getProductConfigurationDiff(productconfigurationId1: string, versionId1: string, productconfigurationId2: string, versionId2: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedProductConfigurationDiff>>;
    public getProductConfigurationDiff(productconfigurationId1: string, versionId1: string, productconfigurationId2: string, versionId2: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedProductConfigurationDiff>>;
    public getProductConfigurationDiff(productconfigurationId1: string, versionId1: string, productconfigurationId2: string, versionId2: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productconfigurationId1 === null || productconfigurationId1 === undefined) {
            throw new Error('Required parameter productconfigurationId1 was null or undefined when calling getProductConfigurationDiff.');
        }

        if (versionId1 === null || versionId1 === undefined) {
            throw new Error('Required parameter versionId1 was null or undefined when calling getProductConfigurationDiff.');
        }

        if (productconfigurationId2 === null || productconfigurationId2 === undefined) {
            throw new Error('Required parameter productconfigurationId2 was null or undefined when calling getProductConfigurationDiff.');
        }

        if (versionId2 === null || versionId2 === undefined) {
            throw new Error('Required parameter versionId2 was null or undefined when calling getProductConfigurationDiff.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedProductConfigurationDiff>('get',`${this.basePath}/productconfigurations/${encodeURIComponent(String(productconfigurationId1))}/v/${encodeURIComponent(String(versionId1))}/diff/${encodeURIComponent(String(productconfigurationId2))}/v/${encodeURIComponent(String(versionId2))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get latest version of specified ProductConfiguration
     * @param productconfigurationId ID of ProductConfiguration to return
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param eTag Version of ProductConfiguration considered to be HEAD, if no newer ProductConfiguration is found then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProductConfigurationHead(productconfigurationId: string, multiWorkspace?: boolean, complete?: boolean, eTag?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedProductConfiguration>;
    public getProductConfigurationHead(productconfigurationId: string, multiWorkspace?: boolean, complete?: boolean, eTag?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedProductConfiguration>>;
    public getProductConfigurationHead(productconfigurationId: string, multiWorkspace?: boolean, complete?: boolean, eTag?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedProductConfiguration>>;
    public getProductConfigurationHead(productconfigurationId: string, multiWorkspace?: boolean, complete?: boolean, eTag?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productconfigurationId === null || productconfigurationId === undefined) {
            throw new Error('Required parameter productconfigurationId was null or undefined when calling getProductConfigurationHead.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;
        if (eTag !== undefined && eTag !== null) {
            headers = headers.set('ETag', String(eTag));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedProductConfiguration>('get',`${this.basePath}/productconfigurations/${encodeURIComponent(String(productconfigurationId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param productconfigurationId ID of ProductConfiguration
     * @param eTag Version of Artefact considered to be HEAD, if no newer Artefact is found then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProductConfigurationHeadRender(productconfigurationId: string, eTag?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedArtefact>;
    public getProductConfigurationHeadRender(productconfigurationId: string, eTag?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedArtefact>>;
    public getProductConfigurationHeadRender(productconfigurationId: string, eTag?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedArtefact>>;
    public getProductConfigurationHeadRender(productconfigurationId: string, eTag?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productconfigurationId === null || productconfigurationId === undefined) {
            throw new Error('Required parameter productconfigurationId was null or undefined when calling getProductConfigurationHeadRender.');
        }


        let headers = this.defaultHeaders;
        if (eTag !== undefined && eTag !== null) {
            headers = headers.set('ETag', String(eTag));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedArtefact>('get',`${this.basePath}/productconfigurations/${encodeURIComponent(String(productconfigurationId))}/render`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param productconfigurationId ID of ProductConfiguration to return
     * @param extension File extension requested
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProductConfigurationHeadRenderContent(productconfigurationId: string, extension: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public getProductConfigurationHeadRenderContent(productconfigurationId: string, extension: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public getProductConfigurationHeadRenderContent(productconfigurationId: string, extension: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public getProductConfigurationHeadRenderContent(productconfigurationId: string, extension: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productconfigurationId === null || productconfigurationId === undefined) {
            throw new Error('Required parameter productconfigurationId was null or undefined when calling getProductConfigurationHeadRenderContent.');
        }

        if (extension === null || extension === undefined) {
            throw new Error('Required parameter extension was null or undefined when calling getProductConfigurationHeadRenderContent.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'binary/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<string>('get',`${this.basePath}/productconfigurations/${encodeURIComponent(String(productconfigurationId))}/render.${encodeURIComponent(String(extension))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param productconfigurationId ID of ProductConfiguration to return
     * @param from First version to consider in log
     * @param to Last version to consider in log
     * @param limit Maximum number of versions in log
     * @param tag Tag of version to find
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProductConfigurationHeadRenderLog(productconfigurationId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionLog>;
    public getProductConfigurationHeadRenderLog(productconfigurationId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLog>>;
    public getProductConfigurationHeadRenderLog(productconfigurationId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLog>>;
    public getProductConfigurationHeadRenderLog(productconfigurationId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productconfigurationId === null || productconfigurationId === undefined) {
            throw new Error('Required parameter productconfigurationId was null or undefined when calling getProductConfigurationHeadRenderLog.');
        }







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from);
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('to', <any>to);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (tag !== undefined && tag !== null) {
            queryParameters = queryParameters.set('tag', <any>tag);
        }
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLog>('get',`${this.basePath}/productconfigurations/${encodeURIComponent(String(productconfigurationId))}/render/log`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param productconfigurationId ID of ProductConfiguration
     * @param eTag Version of ConfigurationScore considered to be HEAD, if no newer ConfigurationScore is found then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProductConfigurationHeadScore(productconfigurationId: string, eTag?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedConfigurationScore>;
    public getProductConfigurationHeadScore(productconfigurationId: string, eTag?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedConfigurationScore>>;
    public getProductConfigurationHeadScore(productconfigurationId: string, eTag?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedConfigurationScore>>;
    public getProductConfigurationHeadScore(productconfigurationId: string, eTag?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productconfigurationId === null || productconfigurationId === undefined) {
            throw new Error('Required parameter productconfigurationId was null or undefined when calling getProductConfigurationHeadScore.');
        }


        let headers = this.defaultHeaders;
        if (eTag !== undefined && eTag !== null) {
            headers = headers.set('ETag', String(eTag));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedConfigurationScore>('get',`${this.basePath}/productconfigurations/${encodeURIComponent(String(productconfigurationId))}/score`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param productconfigurationId ID of ProductConfiguration to return
     * @param from First version to consider in log
     * @param to Last version to consider in log
     * @param limit Maximum number of versions in log
     * @param tag Tag of version to find
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProductConfigurationHeadScoreLog(productconfigurationId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionLog>;
    public getProductConfigurationHeadScoreLog(productconfigurationId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLog>>;
    public getProductConfigurationHeadScoreLog(productconfigurationId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLog>>;
    public getProductConfigurationHeadScoreLog(productconfigurationId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productconfigurationId === null || productconfigurationId === undefined) {
            throw new Error('Required parameter productconfigurationId was null or undefined when calling getProductConfigurationHeadScoreLog.');
        }







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from);
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('to', <any>to);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (tag !== undefined && tag !== null) {
            queryParameters = queryParameters.set('tag', <any>tag);
        }
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLog>('get',`${this.basePath}/productconfigurations/${encodeURIComponent(String(productconfigurationId))}/score/log`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param productconfigurationId ID of ProductConfiguration
     * @param eTag Version of ConfigurationState considered to be HEAD, if no newer ConfigurationState is found then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProductConfigurationHeadState(productconfigurationId: string, eTag?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedConfigurationState>;
    public getProductConfigurationHeadState(productconfigurationId: string, eTag?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedConfigurationState>>;
    public getProductConfigurationHeadState(productconfigurationId: string, eTag?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedConfigurationState>>;
    public getProductConfigurationHeadState(productconfigurationId: string, eTag?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productconfigurationId === null || productconfigurationId === undefined) {
            throw new Error('Required parameter productconfigurationId was null or undefined when calling getProductConfigurationHeadState.');
        }


        let headers = this.defaultHeaders;
        if (eTag !== undefined && eTag !== null) {
            headers = headers.set('ETag', String(eTag));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedConfigurationState>('get',`${this.basePath}/productconfigurations/${encodeURIComponent(String(productconfigurationId))}/state`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param productconfigurationId ID of ProductConfiguration to return
     * @param from First version to consider in log
     * @param to Last version to consider in log
     * @param limit Maximum number of versions in log
     * @param tag Tag of version to find
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProductConfigurationHeadStateLog(productconfigurationId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionLog>;
    public getProductConfigurationHeadStateLog(productconfigurationId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLog>>;
    public getProductConfigurationHeadStateLog(productconfigurationId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLog>>;
    public getProductConfigurationHeadStateLog(productconfigurationId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productconfigurationId === null || productconfigurationId === undefined) {
            throw new Error('Required parameter productconfigurationId was null or undefined when calling getProductConfigurationHeadStateLog.');
        }







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from);
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('to', <any>to);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (tag !== undefined && tag !== null) {
            queryParameters = queryParameters.set('tag', <any>tag);
        }
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLog>('get',`${this.basePath}/productconfigurations/${encodeURIComponent(String(productconfigurationId))}/state/log`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get history of specified ProductConfiguration
     * @param productconfigurationId ID of ProductConfiguration to return
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProductConfigurationHistory(productconfigurationId: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionGraph>;
    public getProductConfigurationHistory(productconfigurationId: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionGraph>>;
    public getProductConfigurationHistory(productconfigurationId: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionGraph>>;
    public getProductConfigurationHistory(productconfigurationId: string, multiWorkspace?: boolean, complete?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productconfigurationId === null || productconfigurationId === undefined) {
            throw new Error('Required parameter productconfigurationId was null or undefined when calling getProductConfigurationHistory.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionGraph>('get',`${this.basePath}/productconfigurations/${encodeURIComponent(String(productconfigurationId))}/history`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get log of specified ProductConfiguration
     * @param productconfigurationId ID of ProductConfiguration to return
     * @param from First version to consider in log
     * @param to Last version to consider in log
     * @param limit Maximum number of versions in log
     * @param tag Tag of version to find
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProductConfigurationLog(productconfigurationId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionLog>;
    public getProductConfigurationLog(productconfigurationId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLog>>;
    public getProductConfigurationLog(productconfigurationId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLog>>;
    public getProductConfigurationLog(productconfigurationId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productconfigurationId === null || productconfigurationId === undefined) {
            throw new Error('Required parameter productconfigurationId was null or undefined when calling getProductConfigurationLog.');
        }







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from);
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('to', <any>to);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (tag !== undefined && tag !== null) {
            queryParameters = queryParameters.set('tag', <any>tag);
        }
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLog>('get',`${this.basePath}/productconfigurations/${encodeURIComponent(String(productconfigurationId))}/log`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get ProductConfiguration permissions
     * @param productconfigurationId ID of ProductConfiguration to check
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProductConfigurationPermissions(productconfigurationId: string, observe?: 'body', reportProgress?: boolean): Observable<UserPermissions>;
    public getProductConfigurationPermissions(productconfigurationId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserPermissions>>;
    public getProductConfigurationPermissions(productconfigurationId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserPermissions>>;
    public getProductConfigurationPermissions(productconfigurationId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productconfigurationId === null || productconfigurationId === undefined) {
            throw new Error('Required parameter productconfigurationId was null or undefined when calling getProductConfigurationPermissions.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<UserPermissions>('get',`${this.basePath}/productconfigurations/${encodeURIComponent(String(productconfigurationId))}/permissions`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param productconfigurationId ID of ProductConfiguration
     * @param versionId Version of ProductConfiguration
     * @param ifNoneMatch Version of Artefact already received, if no change to Artefact since version then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProductConfigurationRender(productconfigurationId: string, versionId: string, ifNoneMatch?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedArtefact>;
    public getProductConfigurationRender(productconfigurationId: string, versionId: string, ifNoneMatch?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedArtefact>>;
    public getProductConfigurationRender(productconfigurationId: string, versionId: string, ifNoneMatch?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedArtefact>>;
    public getProductConfigurationRender(productconfigurationId: string, versionId: string, ifNoneMatch?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productconfigurationId === null || productconfigurationId === undefined) {
            throw new Error('Required parameter productconfigurationId was null or undefined when calling getProductConfigurationRender.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getProductConfigurationRender.');
        }


        let headers = this.defaultHeaders;
        if (ifNoneMatch !== undefined && ifNoneMatch !== null) {
            headers = headers.set('If-None-Match', String(ifNoneMatch));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedArtefact>('get',`${this.basePath}/productconfigurations/${encodeURIComponent(String(productconfigurationId))}/v/${encodeURIComponent(String(versionId))}/render`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param productconfigurationId ID of ProductConfiguration to return
     * @param versionId Version of ProductConfiguration
     * @param extension File extension requested
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProductConfigurationRenderContent(productconfigurationId: string, versionId: string, extension: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public getProductConfigurationRenderContent(productconfigurationId: string, versionId: string, extension: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public getProductConfigurationRenderContent(productconfigurationId: string, versionId: string, extension: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public getProductConfigurationRenderContent(productconfigurationId: string, versionId: string, extension: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productconfigurationId === null || productconfigurationId === undefined) {
            throw new Error('Required parameter productconfigurationId was null or undefined when calling getProductConfigurationRenderContent.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getProductConfigurationRenderContent.');
        }

        if (extension === null || extension === undefined) {
            throw new Error('Required parameter extension was null or undefined when calling getProductConfigurationRenderContent.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'binary/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<string>('get',`${this.basePath}/productconfigurations/${encodeURIComponent(String(productconfigurationId))}/v/${encodeURIComponent(String(versionId))}/render.${encodeURIComponent(String(extension))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param productconfigurationId ID of ProductConfiguration
     * @param versionId Version of ProductConfiguration
     * @param ifNoneMatch Version of ConfigurationScore already received, if no change to ConfigurationScore since version then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProductConfigurationScore(productconfigurationId: string, versionId: string, ifNoneMatch?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedConfigurationScore>;
    public getProductConfigurationScore(productconfigurationId: string, versionId: string, ifNoneMatch?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedConfigurationScore>>;
    public getProductConfigurationScore(productconfigurationId: string, versionId: string, ifNoneMatch?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedConfigurationScore>>;
    public getProductConfigurationScore(productconfigurationId: string, versionId: string, ifNoneMatch?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productconfigurationId === null || productconfigurationId === undefined) {
            throw new Error('Required parameter productconfigurationId was null or undefined when calling getProductConfigurationScore.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getProductConfigurationScore.');
        }


        let headers = this.defaultHeaders;
        if (ifNoneMatch !== undefined && ifNoneMatch !== null) {
            headers = headers.set('If-None-Match', String(ifNoneMatch));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedConfigurationScore>('get',`${this.basePath}/productconfigurations/${encodeURIComponent(String(productconfigurationId))}/v/${encodeURIComponent(String(versionId))}/score`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get sharing details of specified ProductConfiguration
     * @param productconfigurationId ID of ProductConfiguration to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProductConfigurationSharing(productconfigurationId: string, observe?: 'body', reportProgress?: boolean): Observable<Sharing>;
    public getProductConfigurationSharing(productconfigurationId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Sharing>>;
    public getProductConfigurationSharing(productconfigurationId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Sharing>>;
    public getProductConfigurationSharing(productconfigurationId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productconfigurationId === null || productconfigurationId === undefined) {
            throw new Error('Required parameter productconfigurationId was null or undefined when calling getProductConfigurationSharing.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Sharing>('get',`${this.basePath}/productconfigurations/${encodeURIComponent(String(productconfigurationId))}/sharing`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get short code to share the specified ProductConfiguration
     * @param productconfigurationId ID of ProductConfiguration to share
     * @param mode Mode: copy or view or edit
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProductConfigurationShortCode(productconfigurationId: string, mode: string, observe?: 'body', reportProgress?: boolean): Observable<ShortCode>;
    public getProductConfigurationShortCode(productconfigurationId: string, mode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ShortCode>>;
    public getProductConfigurationShortCode(productconfigurationId: string, mode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ShortCode>>;
    public getProductConfigurationShortCode(productconfigurationId: string, mode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productconfigurationId === null || productconfigurationId === undefined) {
            throw new Error('Required parameter productconfigurationId was null or undefined when calling getProductConfigurationShortCode.');
        }

        if (mode === null || mode === undefined) {
            throw new Error('Required parameter mode was null or undefined when calling getProductConfigurationShortCode.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ShortCode>('get',`${this.basePath}/s/productconfigurations/${encodeURIComponent(String(productconfigurationId))}/${encodeURIComponent(String(mode))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param productconfigurationId ID of ProductConfiguration
     * @param versionId Version of ProductConfiguration
     * @param ifNoneMatch Version of ConfigurationState already received, if no change to ConfigurationState since version then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProductConfigurationState(productconfigurationId: string, versionId: string, ifNoneMatch?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedConfigurationState>;
    public getProductConfigurationState(productconfigurationId: string, versionId: string, ifNoneMatch?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedConfigurationState>>;
    public getProductConfigurationState(productconfigurationId: string, versionId: string, ifNoneMatch?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedConfigurationState>>;
    public getProductConfigurationState(productconfigurationId: string, versionId: string, ifNoneMatch?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productconfigurationId === null || productconfigurationId === undefined) {
            throw new Error('Required parameter productconfigurationId was null or undefined when calling getProductConfigurationState.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getProductConfigurationState.');
        }


        let headers = this.defaultHeaders;
        if (ifNoneMatch !== undefined && ifNoneMatch !== null) {
            headers = headers.set('If-None-Match', String(ifNoneMatch));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedConfigurationState>('get',`${this.basePath}/productconfigurations/${encodeURIComponent(String(productconfigurationId))}/v/${encodeURIComponent(String(versionId))}/state`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Find usages of specified ProductConfiguration
     * @param productconfigurationId ID of ProductConfiguration to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProductConfigurationUsages(productconfigurationId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Reference>>;
    public getProductConfigurationUsages(productconfigurationId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Reference>>>;
    public getProductConfigurationUsages(productconfigurationId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Reference>>>;
    public getProductConfigurationUsages(productconfigurationId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productconfigurationId === null || productconfigurationId === undefined) {
            throw new Error('Required parameter productconfigurationId was null or undefined when calling getProductConfigurationUsages.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Reference>>('get',`${this.basePath}/productconfigurations/${encodeURIComponent(String(productconfigurationId))}/usages`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get version summary of specified ProductConfiguration
     * @param productconfigurationId ID of ProductConfiguration to return
     * @param versionId Version to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProductConfigurationVersionSummary(productconfigurationId: string, versionId: string, observe?: 'body', reportProgress?: boolean): Observable<VersionLogVersion>;
    public getProductConfigurationVersionSummary(productconfigurationId: string, versionId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLogVersion>>;
    public getProductConfigurationVersionSummary(productconfigurationId: string, versionId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLogVersion>>;
    public getProductConfigurationVersionSummary(productconfigurationId: string, versionId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productconfigurationId === null || productconfigurationId === undefined) {
            throw new Error('Required parameter productconfigurationId was null or undefined when calling getProductConfigurationVersionSummary.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getProductConfigurationVersionSummary.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLogVersion>('get',`${this.basePath}/productconfigurations/${encodeURIComponent(String(productconfigurationId))}/log/${encodeURIComponent(String(versionId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Find usages of specified ProductConfiguration version
     * @param productconfigurationId ID of ProductConfiguration to return
     * @param versionId Version of ProductConfiguration to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProductConfigurationVersionUsages(productconfigurationId: string, versionId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Reference>>;
    public getProductConfigurationVersionUsages(productconfigurationId: string, versionId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Reference>>>;
    public getProductConfigurationVersionUsages(productconfigurationId: string, versionId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Reference>>>;
    public getProductConfigurationVersionUsages(productconfigurationId: string, versionId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productconfigurationId === null || productconfigurationId === undefined) {
            throw new Error('Required parameter productconfigurationId was null or undefined when calling getProductConfigurationVersionUsages.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getProductConfigurationVersionUsages.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Reference>>('get',`${this.basePath}/productconfigurations/${encodeURIComponent(String(productconfigurationId))}/v/${encodeURIComponent(String(versionId))}/usages`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get diff between versions of Products
     * @param productId1 ID of first Product to compare
     * @param versionId1 Version of first Product to compare
     * @param productId2 ID of second Product to compare
     * @param versionId2 Version of second Product to compare
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProductDiff(productId1: string, versionId1: string, productId2: string, versionId2: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedProductDiff>;
    public getProductDiff(productId1: string, versionId1: string, productId2: string, versionId2: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedProductDiff>>;
    public getProductDiff(productId1: string, versionId1: string, productId2: string, versionId2: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedProductDiff>>;
    public getProductDiff(productId1: string, versionId1: string, productId2: string, versionId2: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productId1 === null || productId1 === undefined) {
            throw new Error('Required parameter productId1 was null or undefined when calling getProductDiff.');
        }

        if (versionId1 === null || versionId1 === undefined) {
            throw new Error('Required parameter versionId1 was null or undefined when calling getProductDiff.');
        }

        if (productId2 === null || productId2 === undefined) {
            throw new Error('Required parameter productId2 was null or undefined when calling getProductDiff.');
        }

        if (versionId2 === null || versionId2 === undefined) {
            throw new Error('Required parameter versionId2 was null or undefined when calling getProductDiff.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedProductDiff>('get',`${this.basePath}/products/${encodeURIComponent(String(productId1))}/v/${encodeURIComponent(String(versionId1))}/diff/${encodeURIComponent(String(productId2))}/v/${encodeURIComponent(String(versionId2))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get latest version of specified Product
     * @param productId ID of Product to return
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param eTag Version of Product considered to be HEAD, if no newer Product is found then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProductHead(productId: string, multiWorkspace?: boolean, complete?: boolean, eTag?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedProduct>;
    public getProductHead(productId: string, multiWorkspace?: boolean, complete?: boolean, eTag?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedProduct>>;
    public getProductHead(productId: string, multiWorkspace?: boolean, complete?: boolean, eTag?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedProduct>>;
    public getProductHead(productId: string, multiWorkspace?: boolean, complete?: boolean, eTag?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productId === null || productId === undefined) {
            throw new Error('Required parameter productId was null or undefined when calling getProductHead.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;
        if (eTag !== undefined && eTag !== null) {
            headers = headers.set('ETag', String(eTag));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedProduct>('get',`${this.basePath}/products/${encodeURIComponent(String(productId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param productId ID of Product
     * @param eTag Version of CategoryModulesArray considered to be HEAD, if no newer CategoryModulesArray is found then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProductHeadCategoryModules(productId: string, eTag?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedCategoryModulesArray>;
    public getProductHeadCategoryModules(productId: string, eTag?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedCategoryModulesArray>>;
    public getProductHeadCategoryModules(productId: string, eTag?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedCategoryModulesArray>>;
    public getProductHeadCategoryModules(productId: string, eTag?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productId === null || productId === undefined) {
            throw new Error('Required parameter productId was null or undefined when calling getProductHeadCategoryModules.');
        }


        let headers = this.defaultHeaders;
        if (eTag !== undefined && eTag !== null) {
            headers = headers.set('ETag', String(eTag));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedCategoryModulesArray>('get',`${this.basePath}/products/${encodeURIComponent(String(productId))}/categoryModules`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param productId ID of Product
     * @param categoryModulesId 
     * @param eTag Version of CategoryModules considered to be HEAD, if no newer CategoryModules is found then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProductHeadCategoryModulesCategoryModules(productId: string, categoryModulesId: string, eTag?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedCategoryModules>;
    public getProductHeadCategoryModulesCategoryModules(productId: string, categoryModulesId: string, eTag?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedCategoryModules>>;
    public getProductHeadCategoryModulesCategoryModules(productId: string, categoryModulesId: string, eTag?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedCategoryModules>>;
    public getProductHeadCategoryModulesCategoryModules(productId: string, categoryModulesId: string, eTag?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productId === null || productId === undefined) {
            throw new Error('Required parameter productId was null or undefined when calling getProductHeadCategoryModulesCategoryModules.');
        }

        if (categoryModulesId === null || categoryModulesId === undefined) {
            throw new Error('Required parameter categoryModulesId was null or undefined when calling getProductHeadCategoryModulesCategoryModules.');
        }


        let headers = this.defaultHeaders;
        if (eTag !== undefined && eTag !== null) {
            headers = headers.set('ETag', String(eTag));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedCategoryModules>('get',`${this.basePath}/products/${encodeURIComponent(String(productId))}/categoryModules/${encodeURIComponent(String(categoryModulesId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param productId ID of Product to return
     * @param categoryModulesId 
     * @param from First version to consider in log
     * @param to Last version to consider in log
     * @param limit Maximum number of versions in log
     * @param tag Tag of version to find
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProductHeadCategoryModulesCategoryModulesLog(productId: string, categoryModulesId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionLog>;
    public getProductHeadCategoryModulesCategoryModulesLog(productId: string, categoryModulesId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLog>>;
    public getProductHeadCategoryModulesCategoryModulesLog(productId: string, categoryModulesId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLog>>;
    public getProductHeadCategoryModulesCategoryModulesLog(productId: string, categoryModulesId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productId === null || productId === undefined) {
            throw new Error('Required parameter productId was null or undefined when calling getProductHeadCategoryModulesCategoryModulesLog.');
        }

        if (categoryModulesId === null || categoryModulesId === undefined) {
            throw new Error('Required parameter categoryModulesId was null or undefined when calling getProductHeadCategoryModulesCategoryModulesLog.');
        }







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from);
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('to', <any>to);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (tag !== undefined && tag !== null) {
            queryParameters = queryParameters.set('tag', <any>tag);
        }
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLog>('get',`${this.basePath}/products/${encodeURIComponent(String(productId))}/categoryModules/${encodeURIComponent(String(categoryModulesId))}/log`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param productId ID of Product to return
     * @param from First version to consider in log
     * @param to Last version to consider in log
     * @param limit Maximum number of versions in log
     * @param tag Tag of version to find
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProductHeadCategoryModulesLog(productId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionLog>;
    public getProductHeadCategoryModulesLog(productId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLog>>;
    public getProductHeadCategoryModulesLog(productId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLog>>;
    public getProductHeadCategoryModulesLog(productId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productId === null || productId === undefined) {
            throw new Error('Required parameter productId was null or undefined when calling getProductHeadCategoryModulesLog.');
        }







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from);
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('to', <any>to);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (tag !== undefined && tag !== null) {
            queryParameters = queryParameters.set('tag', <any>tag);
        }
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLog>('get',`${this.basePath}/products/${encodeURIComponent(String(productId))}/categoryModules/log`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param productId ID of Product
     * @param eTag Version of StoredModuleGraph considered to be HEAD, if no newer StoredModuleGraph is found then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProductHeadModuleGraph(productId: string, eTag?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedStoredModuleGraph>;
    public getProductHeadModuleGraph(productId: string, eTag?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedStoredModuleGraph>>;
    public getProductHeadModuleGraph(productId: string, eTag?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedStoredModuleGraph>>;
    public getProductHeadModuleGraph(productId: string, eTag?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productId === null || productId === undefined) {
            throw new Error('Required parameter productId was null or undefined when calling getProductHeadModuleGraph.');
        }


        let headers = this.defaultHeaders;
        if (eTag !== undefined && eTag !== null) {
            headers = headers.set('ETag', String(eTag));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedStoredModuleGraph>('get',`${this.basePath}/products/${encodeURIComponent(String(productId))}/moduleGraph`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param productId ID of Product to return
     * @param from First version to consider in log
     * @param to Last version to consider in log
     * @param limit Maximum number of versions in log
     * @param tag Tag of version to find
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProductHeadModuleGraphLog(productId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionLog>;
    public getProductHeadModuleGraphLog(productId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLog>>;
    public getProductHeadModuleGraphLog(productId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLog>>;
    public getProductHeadModuleGraphLog(productId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productId === null || productId === undefined) {
            throw new Error('Required parameter productId was null or undefined when calling getProductHeadModuleGraphLog.');
        }







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from);
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('to', <any>to);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (tag !== undefined && tag !== null) {
            queryParameters = queryParameters.set('tag', <any>tag);
        }
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLog>('get',`${this.basePath}/products/${encodeURIComponent(String(productId))}/moduleGraph/log`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param productId ID of Product
     * @param eTag Version of ConfiguratorProductionData considered to be HEAD, if no newer ConfiguratorProductionData is found then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProductHeadProductionData(productId: string, eTag?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedConfiguratorProductionData>;
    public getProductHeadProductionData(productId: string, eTag?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedConfiguratorProductionData>>;
    public getProductHeadProductionData(productId: string, eTag?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedConfiguratorProductionData>>;
    public getProductHeadProductionData(productId: string, eTag?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productId === null || productId === undefined) {
            throw new Error('Required parameter productId was null or undefined when calling getProductHeadProductionData.');
        }


        let headers = this.defaultHeaders;
        if (eTag !== undefined && eTag !== null) {
            headers = headers.set('ETag', String(eTag));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedConfiguratorProductionData>('get',`${this.basePath}/products/${encodeURIComponent(String(productId))}/productionData`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param productId ID of Product
     * @param groupsId 
     * @param eTag Version of LargeObject considered to be HEAD, if no newer LargeObject is found then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProductHeadProductionDataGroupsSheetResultPlacementCandidates(productId: string, groupsId: string, eTag?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedLargeObject>;
    public getProductHeadProductionDataGroupsSheetResultPlacementCandidates(productId: string, groupsId: string, eTag?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedLargeObject>>;
    public getProductHeadProductionDataGroupsSheetResultPlacementCandidates(productId: string, groupsId: string, eTag?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedLargeObject>>;
    public getProductHeadProductionDataGroupsSheetResultPlacementCandidates(productId: string, groupsId: string, eTag?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productId === null || productId === undefined) {
            throw new Error('Required parameter productId was null or undefined when calling getProductHeadProductionDataGroupsSheetResultPlacementCandidates.');
        }

        if (groupsId === null || groupsId === undefined) {
            throw new Error('Required parameter groupsId was null or undefined when calling getProductHeadProductionDataGroupsSheetResultPlacementCandidates.');
        }


        let headers = this.defaultHeaders;
        if (eTag !== undefined && eTag !== null) {
            headers = headers.set('ETag', String(eTag));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedLargeObject>('get',`${this.basePath}/products/${encodeURIComponent(String(productId))}/productionData/groups/${encodeURIComponent(String(groupsId))}/sheetResult/placementCandidates`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param productId ID of Product to return
     * @param groupsId 
     * @param from First version to consider in log
     * @param to Last version to consider in log
     * @param limit Maximum number of versions in log
     * @param tag Tag of version to find
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProductHeadProductionDataGroupsSheetResultPlacementCandidatesLog(productId: string, groupsId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionLog>;
    public getProductHeadProductionDataGroupsSheetResultPlacementCandidatesLog(productId: string, groupsId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLog>>;
    public getProductHeadProductionDataGroupsSheetResultPlacementCandidatesLog(productId: string, groupsId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLog>>;
    public getProductHeadProductionDataGroupsSheetResultPlacementCandidatesLog(productId: string, groupsId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productId === null || productId === undefined) {
            throw new Error('Required parameter productId was null or undefined when calling getProductHeadProductionDataGroupsSheetResultPlacementCandidatesLog.');
        }

        if (groupsId === null || groupsId === undefined) {
            throw new Error('Required parameter groupsId was null or undefined when calling getProductHeadProductionDataGroupsSheetResultPlacementCandidatesLog.');
        }







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from);
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('to', <any>to);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (tag !== undefined && tag !== null) {
            queryParameters = queryParameters.set('tag', <any>tag);
        }
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLog>('get',`${this.basePath}/products/${encodeURIComponent(String(productId))}/productionData/groups/${encodeURIComponent(String(groupsId))}/sheetResult/placementCandidates/log`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param productId ID of Product to return
     * @param from First version to consider in log
     * @param to Last version to consider in log
     * @param limit Maximum number of versions in log
     * @param tag Tag of version to find
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProductHeadProductionDataLog(productId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionLog>;
    public getProductHeadProductionDataLog(productId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLog>>;
    public getProductHeadProductionDataLog(productId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLog>>;
    public getProductHeadProductionDataLog(productId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productId === null || productId === undefined) {
            throw new Error('Required parameter productId was null or undefined when calling getProductHeadProductionDataLog.');
        }







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from);
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('to', <any>to);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (tag !== undefined && tag !== null) {
            queryParameters = queryParameters.set('tag', <any>tag);
        }
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLog>('get',`${this.basePath}/products/${encodeURIComponent(String(productId))}/productionData/log`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get history of specified Product
     * @param productId ID of Product to return
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProductHistory(productId: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionGraph>;
    public getProductHistory(productId: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionGraph>>;
    public getProductHistory(productId: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionGraph>>;
    public getProductHistory(productId: string, multiWorkspace?: boolean, complete?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productId === null || productId === undefined) {
            throw new Error('Required parameter productId was null or undefined when calling getProductHistory.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionGraph>('get',`${this.basePath}/products/${encodeURIComponent(String(productId))}/history`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get log of specified Product
     * @param productId ID of Product to return
     * @param from First version to consider in log
     * @param to Last version to consider in log
     * @param limit Maximum number of versions in log
     * @param tag Tag of version to find
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProductLog(productId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionLog>;
    public getProductLog(productId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLog>>;
    public getProductLog(productId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLog>>;
    public getProductLog(productId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productId === null || productId === undefined) {
            throw new Error('Required parameter productId was null or undefined when calling getProductLog.');
        }







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from);
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('to', <any>to);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (tag !== undefined && tag !== null) {
            queryParameters = queryParameters.set('tag', <any>tag);
        }
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLog>('get',`${this.basePath}/products/${encodeURIComponent(String(productId))}/log`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param productId ID of Product
     * @param versionId Version of Product
     * @param ifNoneMatch Version of StoredModuleGraph already received, if no change to StoredModuleGraph since version then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProductModuleGraph(productId: string, versionId: string, ifNoneMatch?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedStoredModuleGraph>;
    public getProductModuleGraph(productId: string, versionId: string, ifNoneMatch?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedStoredModuleGraph>>;
    public getProductModuleGraph(productId: string, versionId: string, ifNoneMatch?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedStoredModuleGraph>>;
    public getProductModuleGraph(productId: string, versionId: string, ifNoneMatch?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productId === null || productId === undefined) {
            throw new Error('Required parameter productId was null or undefined when calling getProductModuleGraph.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getProductModuleGraph.');
        }


        let headers = this.defaultHeaders;
        if (ifNoneMatch !== undefined && ifNoneMatch !== null) {
            headers = headers.set('If-None-Match', String(ifNoneMatch));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedStoredModuleGraph>('get',`${this.basePath}/products/${encodeURIComponent(String(productId))}/v/${encodeURIComponent(String(versionId))}/moduleGraph`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get Product permissions
     * @param productId ID of Product to check
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProductPermissions(productId: string, observe?: 'body', reportProgress?: boolean): Observable<UserPermissions>;
    public getProductPermissions(productId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserPermissions>>;
    public getProductPermissions(productId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserPermissions>>;
    public getProductPermissions(productId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productId === null || productId === undefined) {
            throw new Error('Required parameter productId was null or undefined when calling getProductPermissions.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<UserPermissions>('get',`${this.basePath}/products/${encodeURIComponent(String(productId))}/permissions`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param productId ID of Product
     * @param versionId Version of Product
     * @param ifNoneMatch Version of ConfiguratorProductionData already received, if no change to ConfiguratorProductionData since version then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProductProductionData(productId: string, versionId: string, ifNoneMatch?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedConfiguratorProductionData>;
    public getProductProductionData(productId: string, versionId: string, ifNoneMatch?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedConfiguratorProductionData>>;
    public getProductProductionData(productId: string, versionId: string, ifNoneMatch?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedConfiguratorProductionData>>;
    public getProductProductionData(productId: string, versionId: string, ifNoneMatch?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productId === null || productId === undefined) {
            throw new Error('Required parameter productId was null or undefined when calling getProductProductionData.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getProductProductionData.');
        }


        let headers = this.defaultHeaders;
        if (ifNoneMatch !== undefined && ifNoneMatch !== null) {
            headers = headers.set('If-None-Match', String(ifNoneMatch));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedConfiguratorProductionData>('get',`${this.basePath}/products/${encodeURIComponent(String(productId))}/v/${encodeURIComponent(String(versionId))}/productionData`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param productId ID of Product
     * @param versionId Version of Product
     * @param groupsId 
     * @param ifNoneMatch Version of LargeObject already received, if no change to LargeObject since version then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProductProductionDataGroupsSheetResultPlacementCandidates(productId: string, versionId: string, groupsId: string, ifNoneMatch?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedLargeObject>;
    public getProductProductionDataGroupsSheetResultPlacementCandidates(productId: string, versionId: string, groupsId: string, ifNoneMatch?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedLargeObject>>;
    public getProductProductionDataGroupsSheetResultPlacementCandidates(productId: string, versionId: string, groupsId: string, ifNoneMatch?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedLargeObject>>;
    public getProductProductionDataGroupsSheetResultPlacementCandidates(productId: string, versionId: string, groupsId: string, ifNoneMatch?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productId === null || productId === undefined) {
            throw new Error('Required parameter productId was null or undefined when calling getProductProductionDataGroupsSheetResultPlacementCandidates.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getProductProductionDataGroupsSheetResultPlacementCandidates.');
        }

        if (groupsId === null || groupsId === undefined) {
            throw new Error('Required parameter groupsId was null or undefined when calling getProductProductionDataGroupsSheetResultPlacementCandidates.');
        }


        let headers = this.defaultHeaders;
        if (ifNoneMatch !== undefined && ifNoneMatch !== null) {
            headers = headers.set('If-None-Match', String(ifNoneMatch));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedLargeObject>('get',`${this.basePath}/products/${encodeURIComponent(String(productId))}/v/${encodeURIComponent(String(versionId))}/productionData/groups/${encodeURIComponent(String(groupsId))}/sheetResult/placementCandidates`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get sharing details of specified Product
     * @param productId ID of Product to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProductSharing(productId: string, observe?: 'body', reportProgress?: boolean): Observable<Sharing>;
    public getProductSharing(productId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Sharing>>;
    public getProductSharing(productId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Sharing>>;
    public getProductSharing(productId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productId === null || productId === undefined) {
            throw new Error('Required parameter productId was null or undefined when calling getProductSharing.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Sharing>('get',`${this.basePath}/products/${encodeURIComponent(String(productId))}/sharing`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get short code to share the specified Product
     * @param productId ID of Product to share
     * @param mode Mode: copy or view or edit
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProductShortCode(productId: string, mode: string, observe?: 'body', reportProgress?: boolean): Observable<ShortCode>;
    public getProductShortCode(productId: string, mode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ShortCode>>;
    public getProductShortCode(productId: string, mode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ShortCode>>;
    public getProductShortCode(productId: string, mode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productId === null || productId === undefined) {
            throw new Error('Required parameter productId was null or undefined when calling getProductShortCode.');
        }

        if (mode === null || mode === undefined) {
            throw new Error('Required parameter mode was null or undefined when calling getProductShortCode.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ShortCode>('get',`${this.basePath}/s/products/${encodeURIComponent(String(productId))}/${encodeURIComponent(String(mode))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Find usages of specified Product
     * @param productId ID of Product to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProductUsages(productId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Reference>>;
    public getProductUsages(productId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Reference>>>;
    public getProductUsages(productId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Reference>>>;
    public getProductUsages(productId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productId === null || productId === undefined) {
            throw new Error('Required parameter productId was null or undefined when calling getProductUsages.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Reference>>('get',`${this.basePath}/products/${encodeURIComponent(String(productId))}/usages`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get version summary of specified Product
     * @param productId ID of Product to return
     * @param versionId Version to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProductVersionSummary(productId: string, versionId: string, observe?: 'body', reportProgress?: boolean): Observable<VersionLogVersion>;
    public getProductVersionSummary(productId: string, versionId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLogVersion>>;
    public getProductVersionSummary(productId: string, versionId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLogVersion>>;
    public getProductVersionSummary(productId: string, versionId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productId === null || productId === undefined) {
            throw new Error('Required parameter productId was null or undefined when calling getProductVersionSummary.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getProductVersionSummary.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLogVersion>('get',`${this.basePath}/products/${encodeURIComponent(String(productId))}/log/${encodeURIComponent(String(versionId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Find usages of specified Product version
     * @param productId ID of Product to return
     * @param versionId Version of Product to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProductVersionUsages(productId: string, versionId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Reference>>;
    public getProductVersionUsages(productId: string, versionId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Reference>>>;
    public getProductVersionUsages(productId: string, versionId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Reference>>>;
    public getProductVersionUsages(productId: string, versionId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productId === null || productId === undefined) {
            throw new Error('Required parameter productId was null or undefined when calling getProductVersionUsages.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getProductVersionUsages.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Reference>>('get',`${this.basePath}/products/${encodeURIComponent(String(productId))}/v/${encodeURIComponent(String(versionId))}/usages`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get specified version of specified ProductionMaterial
     * @param productionmaterialId ID of ProductionMaterial to return
     * @param versionId Version of ProductionMaterial to return
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param ifNoneMatch Version of ProductionMaterial already received, if no change to ProductionMaterial since version then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProductionMaterial(productionmaterialId: string, versionId: string, multiWorkspace?: boolean, complete?: boolean, ifNoneMatch?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedProductionMaterial>;
    public getProductionMaterial(productionmaterialId: string, versionId: string, multiWorkspace?: boolean, complete?: boolean, ifNoneMatch?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedProductionMaterial>>;
    public getProductionMaterial(productionmaterialId: string, versionId: string, multiWorkspace?: boolean, complete?: boolean, ifNoneMatch?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedProductionMaterial>>;
    public getProductionMaterial(productionmaterialId: string, versionId: string, multiWorkspace?: boolean, complete?: boolean, ifNoneMatch?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productionmaterialId === null || productionmaterialId === undefined) {
            throw new Error('Required parameter productionmaterialId was null or undefined when calling getProductionMaterial.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getProductionMaterial.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;
        if (ifNoneMatch !== undefined && ifNoneMatch !== null) {
            headers = headers.set('If-None-Match', String(ifNoneMatch));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedProductionMaterial>('get',`${this.basePath}/productionmaterials/${encodeURIComponent(String(productionmaterialId))}/v/${encodeURIComponent(String(versionId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get diff between versions of ProductionMaterials
     * @param productionmaterialId1 ID of first ProductionMaterial to compare
     * @param versionId1 Version of first ProductionMaterial to compare
     * @param productionmaterialId2 ID of second ProductionMaterial to compare
     * @param versionId2 Version of second ProductionMaterial to compare
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProductionMaterialDiff(productionmaterialId1: string, versionId1: string, productionmaterialId2: string, versionId2: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedProductionMaterialDiff>;
    public getProductionMaterialDiff(productionmaterialId1: string, versionId1: string, productionmaterialId2: string, versionId2: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedProductionMaterialDiff>>;
    public getProductionMaterialDiff(productionmaterialId1: string, versionId1: string, productionmaterialId2: string, versionId2: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedProductionMaterialDiff>>;
    public getProductionMaterialDiff(productionmaterialId1: string, versionId1: string, productionmaterialId2: string, versionId2: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productionmaterialId1 === null || productionmaterialId1 === undefined) {
            throw new Error('Required parameter productionmaterialId1 was null or undefined when calling getProductionMaterialDiff.');
        }

        if (versionId1 === null || versionId1 === undefined) {
            throw new Error('Required parameter versionId1 was null or undefined when calling getProductionMaterialDiff.');
        }

        if (productionmaterialId2 === null || productionmaterialId2 === undefined) {
            throw new Error('Required parameter productionmaterialId2 was null or undefined when calling getProductionMaterialDiff.');
        }

        if (versionId2 === null || versionId2 === undefined) {
            throw new Error('Required parameter versionId2 was null or undefined when calling getProductionMaterialDiff.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedProductionMaterialDiff>('get',`${this.basePath}/productionmaterials/${encodeURIComponent(String(productionmaterialId1))}/v/${encodeURIComponent(String(versionId1))}/diff/${encodeURIComponent(String(productionmaterialId2))}/v/${encodeURIComponent(String(versionId2))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get latest version of specified ProductionMaterial
     * @param productionmaterialId ID of ProductionMaterial to return
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param eTag Version of ProductionMaterial considered to be HEAD, if no newer ProductionMaterial is found then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProductionMaterialHead(productionmaterialId: string, multiWorkspace?: boolean, complete?: boolean, eTag?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedProductionMaterial>;
    public getProductionMaterialHead(productionmaterialId: string, multiWorkspace?: boolean, complete?: boolean, eTag?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedProductionMaterial>>;
    public getProductionMaterialHead(productionmaterialId: string, multiWorkspace?: boolean, complete?: boolean, eTag?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedProductionMaterial>>;
    public getProductionMaterialHead(productionmaterialId: string, multiWorkspace?: boolean, complete?: boolean, eTag?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productionmaterialId === null || productionmaterialId === undefined) {
            throw new Error('Required parameter productionmaterialId was null or undefined when calling getProductionMaterialHead.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;
        if (eTag !== undefined && eTag !== null) {
            headers = headers.set('ETag', String(eTag));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedProductionMaterial>('get',`${this.basePath}/productionmaterials/${encodeURIComponent(String(productionmaterialId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get history of specified ProductionMaterial
     * @param productionmaterialId ID of ProductionMaterial to return
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProductionMaterialHistory(productionmaterialId: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionGraph>;
    public getProductionMaterialHistory(productionmaterialId: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionGraph>>;
    public getProductionMaterialHistory(productionmaterialId: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionGraph>>;
    public getProductionMaterialHistory(productionmaterialId: string, multiWorkspace?: boolean, complete?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productionmaterialId === null || productionmaterialId === undefined) {
            throw new Error('Required parameter productionmaterialId was null or undefined when calling getProductionMaterialHistory.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionGraph>('get',`${this.basePath}/productionmaterials/${encodeURIComponent(String(productionmaterialId))}/history`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get log of specified ProductionMaterial
     * @param productionmaterialId ID of ProductionMaterial to return
     * @param from First version to consider in log
     * @param to Last version to consider in log
     * @param limit Maximum number of versions in log
     * @param tag Tag of version to find
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProductionMaterialLog(productionmaterialId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionLog>;
    public getProductionMaterialLog(productionmaterialId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLog>>;
    public getProductionMaterialLog(productionmaterialId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLog>>;
    public getProductionMaterialLog(productionmaterialId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productionmaterialId === null || productionmaterialId === undefined) {
            throw new Error('Required parameter productionmaterialId was null or undefined when calling getProductionMaterialLog.');
        }







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from);
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('to', <any>to);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (tag !== undefined && tag !== null) {
            queryParameters = queryParameters.set('tag', <any>tag);
        }
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLog>('get',`${this.basePath}/productionmaterials/${encodeURIComponent(String(productionmaterialId))}/log`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get ProductionMaterial permissions
     * @param productionmaterialId ID of ProductionMaterial to check
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProductionMaterialPermissions(productionmaterialId: string, observe?: 'body', reportProgress?: boolean): Observable<UserPermissions>;
    public getProductionMaterialPermissions(productionmaterialId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserPermissions>>;
    public getProductionMaterialPermissions(productionmaterialId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserPermissions>>;
    public getProductionMaterialPermissions(productionmaterialId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productionmaterialId === null || productionmaterialId === undefined) {
            throw new Error('Required parameter productionmaterialId was null or undefined when calling getProductionMaterialPermissions.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<UserPermissions>('get',`${this.basePath}/productionmaterials/${encodeURIComponent(String(productionmaterialId))}/permissions`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get sharing details of specified ProductionMaterial
     * @param productionmaterialId ID of ProductionMaterial to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProductionMaterialSharing(productionmaterialId: string, observe?: 'body', reportProgress?: boolean): Observable<Sharing>;
    public getProductionMaterialSharing(productionmaterialId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Sharing>>;
    public getProductionMaterialSharing(productionmaterialId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Sharing>>;
    public getProductionMaterialSharing(productionmaterialId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productionmaterialId === null || productionmaterialId === undefined) {
            throw new Error('Required parameter productionmaterialId was null or undefined when calling getProductionMaterialSharing.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Sharing>('get',`${this.basePath}/productionmaterials/${encodeURIComponent(String(productionmaterialId))}/sharing`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get short code to share the specified ProductionMaterial
     * @param productionmaterialId ID of ProductionMaterial to share
     * @param mode Mode: copy or view or edit
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProductionMaterialShortCode(productionmaterialId: string, mode: string, observe?: 'body', reportProgress?: boolean): Observable<ShortCode>;
    public getProductionMaterialShortCode(productionmaterialId: string, mode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ShortCode>>;
    public getProductionMaterialShortCode(productionmaterialId: string, mode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ShortCode>>;
    public getProductionMaterialShortCode(productionmaterialId: string, mode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productionmaterialId === null || productionmaterialId === undefined) {
            throw new Error('Required parameter productionmaterialId was null or undefined when calling getProductionMaterialShortCode.');
        }

        if (mode === null || mode === undefined) {
            throw new Error('Required parameter mode was null or undefined when calling getProductionMaterialShortCode.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ShortCode>('get',`${this.basePath}/s/productionmaterials/${encodeURIComponent(String(productionmaterialId))}/${encodeURIComponent(String(mode))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Find usages of specified ProductionMaterial
     * @param productionmaterialId ID of ProductionMaterial to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProductionMaterialUsages(productionmaterialId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Reference>>;
    public getProductionMaterialUsages(productionmaterialId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Reference>>>;
    public getProductionMaterialUsages(productionmaterialId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Reference>>>;
    public getProductionMaterialUsages(productionmaterialId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productionmaterialId === null || productionmaterialId === undefined) {
            throw new Error('Required parameter productionmaterialId was null or undefined when calling getProductionMaterialUsages.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Reference>>('get',`${this.basePath}/productionmaterials/${encodeURIComponent(String(productionmaterialId))}/usages`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get version summary of specified ProductionMaterial
     * @param productionmaterialId ID of ProductionMaterial to return
     * @param versionId Version to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProductionMaterialVersionSummary(productionmaterialId: string, versionId: string, observe?: 'body', reportProgress?: boolean): Observable<VersionLogVersion>;
    public getProductionMaterialVersionSummary(productionmaterialId: string, versionId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLogVersion>>;
    public getProductionMaterialVersionSummary(productionmaterialId: string, versionId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLogVersion>>;
    public getProductionMaterialVersionSummary(productionmaterialId: string, versionId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productionmaterialId === null || productionmaterialId === undefined) {
            throw new Error('Required parameter productionmaterialId was null or undefined when calling getProductionMaterialVersionSummary.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getProductionMaterialVersionSummary.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLogVersion>('get',`${this.basePath}/productionmaterials/${encodeURIComponent(String(productionmaterialId))}/log/${encodeURIComponent(String(versionId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Find usages of specified ProductionMaterial version
     * @param productionmaterialId ID of ProductionMaterial to return
     * @param versionId Version of ProductionMaterial to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProductionMaterialVersionUsages(productionmaterialId: string, versionId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Reference>>;
    public getProductionMaterialVersionUsages(productionmaterialId: string, versionId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Reference>>>;
    public getProductionMaterialVersionUsages(productionmaterialId: string, versionId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Reference>>>;
    public getProductionMaterialVersionUsages(productionmaterialId: string, versionId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productionmaterialId === null || productionmaterialId === undefined) {
            throw new Error('Required parameter productionmaterialId was null or undefined when calling getProductionMaterialVersionUsages.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getProductionMaterialVersionUsages.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Reference>>('get',`${this.basePath}/productionmaterials/${encodeURIComponent(String(productionmaterialId))}/v/${encodeURIComponent(String(versionId))}/usages`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get specified version of specified PublishedConfigurator
     * @param publishedconfiguratorId ID of PublishedConfigurator to return
     * @param versionId Version of PublishedConfigurator to return
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param ifNoneMatch Version of PublishedConfigurator already received, if no change to PublishedConfigurator since version then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPublishedConfigurator(publishedconfiguratorId: string, versionId: string, multiWorkspace?: boolean, complete?: boolean, ifNoneMatch?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedPublishedConfigurator>;
    public getPublishedConfigurator(publishedconfiguratorId: string, versionId: string, multiWorkspace?: boolean, complete?: boolean, ifNoneMatch?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedPublishedConfigurator>>;
    public getPublishedConfigurator(publishedconfiguratorId: string, versionId: string, multiWorkspace?: boolean, complete?: boolean, ifNoneMatch?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedPublishedConfigurator>>;
    public getPublishedConfigurator(publishedconfiguratorId: string, versionId: string, multiWorkspace?: boolean, complete?: boolean, ifNoneMatch?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (publishedconfiguratorId === null || publishedconfiguratorId === undefined) {
            throw new Error('Required parameter publishedconfiguratorId was null or undefined when calling getPublishedConfigurator.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getPublishedConfigurator.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;
        if (ifNoneMatch !== undefined && ifNoneMatch !== null) {
            headers = headers.set('If-None-Match', String(ifNoneMatch));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedPublishedConfigurator>('get',`${this.basePath}/publishedconfigurators/${encodeURIComponent(String(publishedconfiguratorId))}/v/${encodeURIComponent(String(versionId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get diff between versions of PublishedConfigurators
     * @param publishedconfiguratorId1 ID of first PublishedConfigurator to compare
     * @param versionId1 Version of first PublishedConfigurator to compare
     * @param publishedconfiguratorId2 ID of second PublishedConfigurator to compare
     * @param versionId2 Version of second PublishedConfigurator to compare
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPublishedConfiguratorDiff(publishedconfiguratorId1: string, versionId1: string, publishedconfiguratorId2: string, versionId2: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedPublishedConfiguratorDiff>;
    public getPublishedConfiguratorDiff(publishedconfiguratorId1: string, versionId1: string, publishedconfiguratorId2: string, versionId2: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedPublishedConfiguratorDiff>>;
    public getPublishedConfiguratorDiff(publishedconfiguratorId1: string, versionId1: string, publishedconfiguratorId2: string, versionId2: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedPublishedConfiguratorDiff>>;
    public getPublishedConfiguratorDiff(publishedconfiguratorId1: string, versionId1: string, publishedconfiguratorId2: string, versionId2: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (publishedconfiguratorId1 === null || publishedconfiguratorId1 === undefined) {
            throw new Error('Required parameter publishedconfiguratorId1 was null or undefined when calling getPublishedConfiguratorDiff.');
        }

        if (versionId1 === null || versionId1 === undefined) {
            throw new Error('Required parameter versionId1 was null or undefined when calling getPublishedConfiguratorDiff.');
        }

        if (publishedconfiguratorId2 === null || publishedconfiguratorId2 === undefined) {
            throw new Error('Required parameter publishedconfiguratorId2 was null or undefined when calling getPublishedConfiguratorDiff.');
        }

        if (versionId2 === null || versionId2 === undefined) {
            throw new Error('Required parameter versionId2 was null or undefined when calling getPublishedConfiguratorDiff.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedPublishedConfiguratorDiff>('get',`${this.basePath}/publishedconfigurators/${encodeURIComponent(String(publishedconfiguratorId1))}/v/${encodeURIComponent(String(versionId1))}/diff/${encodeURIComponent(String(publishedconfiguratorId2))}/v/${encodeURIComponent(String(versionId2))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get latest version of specified PublishedConfigurator
     * @param publishedconfiguratorId ID of PublishedConfigurator to return
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param eTag Version of PublishedConfigurator considered to be HEAD, if no newer PublishedConfigurator is found then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPublishedConfiguratorHead(publishedconfiguratorId: string, multiWorkspace?: boolean, complete?: boolean, eTag?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedPublishedConfigurator>;
    public getPublishedConfiguratorHead(publishedconfiguratorId: string, multiWorkspace?: boolean, complete?: boolean, eTag?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedPublishedConfigurator>>;
    public getPublishedConfiguratorHead(publishedconfiguratorId: string, multiWorkspace?: boolean, complete?: boolean, eTag?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedPublishedConfigurator>>;
    public getPublishedConfiguratorHead(publishedconfiguratorId: string, multiWorkspace?: boolean, complete?: boolean, eTag?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (publishedconfiguratorId === null || publishedconfiguratorId === undefined) {
            throw new Error('Required parameter publishedconfiguratorId was null or undefined when calling getPublishedConfiguratorHead.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;
        if (eTag !== undefined && eTag !== null) {
            headers = headers.set('ETag', String(eTag));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedPublishedConfigurator>('get',`${this.basePath}/publishedconfigurators/${encodeURIComponent(String(publishedconfiguratorId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get history of specified PublishedConfigurator
     * @param publishedconfiguratorId ID of PublishedConfigurator to return
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPublishedConfiguratorHistory(publishedconfiguratorId: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionGraph>;
    public getPublishedConfiguratorHistory(publishedconfiguratorId: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionGraph>>;
    public getPublishedConfiguratorHistory(publishedconfiguratorId: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionGraph>>;
    public getPublishedConfiguratorHistory(publishedconfiguratorId: string, multiWorkspace?: boolean, complete?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (publishedconfiguratorId === null || publishedconfiguratorId === undefined) {
            throw new Error('Required parameter publishedconfiguratorId was null or undefined when calling getPublishedConfiguratorHistory.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionGraph>('get',`${this.basePath}/publishedconfigurators/${encodeURIComponent(String(publishedconfiguratorId))}/history`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get log of specified PublishedConfigurator
     * @param publishedconfiguratorId ID of PublishedConfigurator to return
     * @param from First version to consider in log
     * @param to Last version to consider in log
     * @param limit Maximum number of versions in log
     * @param tag Tag of version to find
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPublishedConfiguratorLog(publishedconfiguratorId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionLog>;
    public getPublishedConfiguratorLog(publishedconfiguratorId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLog>>;
    public getPublishedConfiguratorLog(publishedconfiguratorId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLog>>;
    public getPublishedConfiguratorLog(publishedconfiguratorId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (publishedconfiguratorId === null || publishedconfiguratorId === undefined) {
            throw new Error('Required parameter publishedconfiguratorId was null or undefined when calling getPublishedConfiguratorLog.');
        }







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from);
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('to', <any>to);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (tag !== undefined && tag !== null) {
            queryParameters = queryParameters.set('tag', <any>tag);
        }
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLog>('get',`${this.basePath}/publishedconfigurators/${encodeURIComponent(String(publishedconfiguratorId))}/log`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get PublishedConfigurator permissions
     * @param publishedconfiguratorId ID of PublishedConfigurator to check
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPublishedConfiguratorPermissions(publishedconfiguratorId: string, observe?: 'body', reportProgress?: boolean): Observable<UserPermissions>;
    public getPublishedConfiguratorPermissions(publishedconfiguratorId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserPermissions>>;
    public getPublishedConfiguratorPermissions(publishedconfiguratorId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserPermissions>>;
    public getPublishedConfiguratorPermissions(publishedconfiguratorId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (publishedconfiguratorId === null || publishedconfiguratorId === undefined) {
            throw new Error('Required parameter publishedconfiguratorId was null or undefined when calling getPublishedConfiguratorPermissions.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<UserPermissions>('get',`${this.basePath}/publishedconfigurators/${encodeURIComponent(String(publishedconfiguratorId))}/permissions`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get sharing details of specified PublishedConfigurator
     * @param publishedconfiguratorId ID of PublishedConfigurator to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPublishedConfiguratorSharing(publishedconfiguratorId: string, observe?: 'body', reportProgress?: boolean): Observable<Sharing>;
    public getPublishedConfiguratorSharing(publishedconfiguratorId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Sharing>>;
    public getPublishedConfiguratorSharing(publishedconfiguratorId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Sharing>>;
    public getPublishedConfiguratorSharing(publishedconfiguratorId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (publishedconfiguratorId === null || publishedconfiguratorId === undefined) {
            throw new Error('Required parameter publishedconfiguratorId was null or undefined when calling getPublishedConfiguratorSharing.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Sharing>('get',`${this.basePath}/publishedconfigurators/${encodeURIComponent(String(publishedconfiguratorId))}/sharing`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get short code to share the specified PublishedConfigurator
     * @param publishedconfiguratorId ID of PublishedConfigurator to share
     * @param mode Mode: copy or view or edit
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPublishedConfiguratorShortCode(publishedconfiguratorId: string, mode: string, observe?: 'body', reportProgress?: boolean): Observable<ShortCode>;
    public getPublishedConfiguratorShortCode(publishedconfiguratorId: string, mode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ShortCode>>;
    public getPublishedConfiguratorShortCode(publishedconfiguratorId: string, mode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ShortCode>>;
    public getPublishedConfiguratorShortCode(publishedconfiguratorId: string, mode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (publishedconfiguratorId === null || publishedconfiguratorId === undefined) {
            throw new Error('Required parameter publishedconfiguratorId was null or undefined when calling getPublishedConfiguratorShortCode.');
        }

        if (mode === null || mode === undefined) {
            throw new Error('Required parameter mode was null or undefined when calling getPublishedConfiguratorShortCode.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ShortCode>('get',`${this.basePath}/s/publishedconfigurators/${encodeURIComponent(String(publishedconfiguratorId))}/${encodeURIComponent(String(mode))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Find usages of specified PublishedConfigurator
     * @param publishedconfiguratorId ID of PublishedConfigurator to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPublishedConfiguratorUsages(publishedconfiguratorId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Reference>>;
    public getPublishedConfiguratorUsages(publishedconfiguratorId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Reference>>>;
    public getPublishedConfiguratorUsages(publishedconfiguratorId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Reference>>>;
    public getPublishedConfiguratorUsages(publishedconfiguratorId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (publishedconfiguratorId === null || publishedconfiguratorId === undefined) {
            throw new Error('Required parameter publishedconfiguratorId was null or undefined when calling getPublishedConfiguratorUsages.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Reference>>('get',`${this.basePath}/publishedconfigurators/${encodeURIComponent(String(publishedconfiguratorId))}/usages`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get version summary of specified PublishedConfigurator
     * @param publishedconfiguratorId ID of PublishedConfigurator to return
     * @param versionId Version to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPublishedConfiguratorVersionSummary(publishedconfiguratorId: string, versionId: string, observe?: 'body', reportProgress?: boolean): Observable<VersionLogVersion>;
    public getPublishedConfiguratorVersionSummary(publishedconfiguratorId: string, versionId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLogVersion>>;
    public getPublishedConfiguratorVersionSummary(publishedconfiguratorId: string, versionId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLogVersion>>;
    public getPublishedConfiguratorVersionSummary(publishedconfiguratorId: string, versionId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (publishedconfiguratorId === null || publishedconfiguratorId === undefined) {
            throw new Error('Required parameter publishedconfiguratorId was null or undefined when calling getPublishedConfiguratorVersionSummary.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getPublishedConfiguratorVersionSummary.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLogVersion>('get',`${this.basePath}/publishedconfigurators/${encodeURIComponent(String(publishedconfiguratorId))}/log/${encodeURIComponent(String(versionId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Find usages of specified PublishedConfigurator version
     * @param publishedconfiguratorId ID of PublishedConfigurator to return
     * @param versionId Version of PublishedConfigurator to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPublishedConfiguratorVersionUsages(publishedconfiguratorId: string, versionId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Reference>>;
    public getPublishedConfiguratorVersionUsages(publishedconfiguratorId: string, versionId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Reference>>>;
    public getPublishedConfiguratorVersionUsages(publishedconfiguratorId: string, versionId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Reference>>>;
    public getPublishedConfiguratorVersionUsages(publishedconfiguratorId: string, versionId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (publishedconfiguratorId === null || publishedconfiguratorId === undefined) {
            throw new Error('Required parameter publishedconfiguratorId was null or undefined when calling getPublishedConfiguratorVersionUsages.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getPublishedConfiguratorVersionUsages.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Reference>>('get',`${this.basePath}/publishedconfigurators/${encodeURIComponent(String(publishedconfiguratorId))}/v/${encodeURIComponent(String(versionId))}/usages`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get specified version of specified Theme
     * @param themeId ID of Theme to return
     * @param versionId Version of Theme to return
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param ifNoneMatch Version of Theme already received, if no change to Theme since version then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTheme(themeId: string, versionId: string, multiWorkspace?: boolean, complete?: boolean, ifNoneMatch?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedTheme>;
    public getTheme(themeId: string, versionId: string, multiWorkspace?: boolean, complete?: boolean, ifNoneMatch?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedTheme>>;
    public getTheme(themeId: string, versionId: string, multiWorkspace?: boolean, complete?: boolean, ifNoneMatch?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedTheme>>;
    public getTheme(themeId: string, versionId: string, multiWorkspace?: boolean, complete?: boolean, ifNoneMatch?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (themeId === null || themeId === undefined) {
            throw new Error('Required parameter themeId was null or undefined when calling getTheme.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getTheme.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;
        if (ifNoneMatch !== undefined && ifNoneMatch !== null) {
            headers = headers.set('If-None-Match', String(ifNoneMatch));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedTheme>('get',`${this.basePath}/themes/${encodeURIComponent(String(themeId))}/v/${encodeURIComponent(String(versionId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get diff between versions of Themes
     * @param themeId1 ID of first Theme to compare
     * @param versionId1 Version of first Theme to compare
     * @param themeId2 ID of second Theme to compare
     * @param versionId2 Version of second Theme to compare
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getThemeDiff(themeId1: string, versionId1: string, themeId2: string, versionId2: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedThemeDiff>;
    public getThemeDiff(themeId1: string, versionId1: string, themeId2: string, versionId2: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedThemeDiff>>;
    public getThemeDiff(themeId1: string, versionId1: string, themeId2: string, versionId2: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedThemeDiff>>;
    public getThemeDiff(themeId1: string, versionId1: string, themeId2: string, versionId2: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (themeId1 === null || themeId1 === undefined) {
            throw new Error('Required parameter themeId1 was null or undefined when calling getThemeDiff.');
        }

        if (versionId1 === null || versionId1 === undefined) {
            throw new Error('Required parameter versionId1 was null or undefined when calling getThemeDiff.');
        }

        if (themeId2 === null || themeId2 === undefined) {
            throw new Error('Required parameter themeId2 was null or undefined when calling getThemeDiff.');
        }

        if (versionId2 === null || versionId2 === undefined) {
            throw new Error('Required parameter versionId2 was null or undefined when calling getThemeDiff.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedThemeDiff>('get',`${this.basePath}/themes/${encodeURIComponent(String(themeId1))}/v/${encodeURIComponent(String(versionId1))}/diff/${encodeURIComponent(String(themeId2))}/v/${encodeURIComponent(String(versionId2))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get latest version of specified Theme
     * @param themeId ID of Theme to return
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param eTag Version of Theme considered to be HEAD, if no newer Theme is found then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getThemeHead(themeId: string, multiWorkspace?: boolean, complete?: boolean, eTag?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedTheme>;
    public getThemeHead(themeId: string, multiWorkspace?: boolean, complete?: boolean, eTag?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedTheme>>;
    public getThemeHead(themeId: string, multiWorkspace?: boolean, complete?: boolean, eTag?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedTheme>>;
    public getThemeHead(themeId: string, multiWorkspace?: boolean, complete?: boolean, eTag?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (themeId === null || themeId === undefined) {
            throw new Error('Required parameter themeId was null or undefined when calling getThemeHead.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;
        if (eTag !== undefined && eTag !== null) {
            headers = headers.set('ETag', String(eTag));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedTheme>('get',`${this.basePath}/themes/${encodeURIComponent(String(themeId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get history of specified Theme
     * @param themeId ID of Theme to return
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getThemeHistory(themeId: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionGraph>;
    public getThemeHistory(themeId: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionGraph>>;
    public getThemeHistory(themeId: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionGraph>>;
    public getThemeHistory(themeId: string, multiWorkspace?: boolean, complete?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (themeId === null || themeId === undefined) {
            throw new Error('Required parameter themeId was null or undefined when calling getThemeHistory.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionGraph>('get',`${this.basePath}/themes/${encodeURIComponent(String(themeId))}/history`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get log of specified Theme
     * @param themeId ID of Theme to return
     * @param from First version to consider in log
     * @param to Last version to consider in log
     * @param limit Maximum number of versions in log
     * @param tag Tag of version to find
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getThemeLog(themeId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionLog>;
    public getThemeLog(themeId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLog>>;
    public getThemeLog(themeId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLog>>;
    public getThemeLog(themeId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (themeId === null || themeId === undefined) {
            throw new Error('Required parameter themeId was null or undefined when calling getThemeLog.');
        }







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from);
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('to', <any>to);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (tag !== undefined && tag !== null) {
            queryParameters = queryParameters.set('tag', <any>tag);
        }
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLog>('get',`${this.basePath}/themes/${encodeURIComponent(String(themeId))}/log`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get Theme permissions
     * @param themeId ID of Theme to check
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getThemePermissions(themeId: string, observe?: 'body', reportProgress?: boolean): Observable<UserPermissions>;
    public getThemePermissions(themeId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserPermissions>>;
    public getThemePermissions(themeId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserPermissions>>;
    public getThemePermissions(themeId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (themeId === null || themeId === undefined) {
            throw new Error('Required parameter themeId was null or undefined when calling getThemePermissions.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<UserPermissions>('get',`${this.basePath}/themes/${encodeURIComponent(String(themeId))}/permissions`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get sharing details of specified Theme
     * @param themeId ID of Theme to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getThemeSharing(themeId: string, observe?: 'body', reportProgress?: boolean): Observable<Sharing>;
    public getThemeSharing(themeId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Sharing>>;
    public getThemeSharing(themeId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Sharing>>;
    public getThemeSharing(themeId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (themeId === null || themeId === undefined) {
            throw new Error('Required parameter themeId was null or undefined when calling getThemeSharing.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Sharing>('get',`${this.basePath}/themes/${encodeURIComponent(String(themeId))}/sharing`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get short code to share the specified Theme
     * @param themeId ID of Theme to share
     * @param mode Mode: copy or view or edit
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getThemeShortCode(themeId: string, mode: string, observe?: 'body', reportProgress?: boolean): Observable<ShortCode>;
    public getThemeShortCode(themeId: string, mode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ShortCode>>;
    public getThemeShortCode(themeId: string, mode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ShortCode>>;
    public getThemeShortCode(themeId: string, mode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (themeId === null || themeId === undefined) {
            throw new Error('Required parameter themeId was null or undefined when calling getThemeShortCode.');
        }

        if (mode === null || mode === undefined) {
            throw new Error('Required parameter mode was null or undefined when calling getThemeShortCode.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ShortCode>('get',`${this.basePath}/s/themes/${encodeURIComponent(String(themeId))}/${encodeURIComponent(String(mode))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Find usages of specified Theme
     * @param themeId ID of Theme to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getThemeUsages(themeId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Reference>>;
    public getThemeUsages(themeId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Reference>>>;
    public getThemeUsages(themeId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Reference>>>;
    public getThemeUsages(themeId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (themeId === null || themeId === undefined) {
            throw new Error('Required parameter themeId was null or undefined when calling getThemeUsages.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Reference>>('get',`${this.basePath}/themes/${encodeURIComponent(String(themeId))}/usages`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get version summary of specified Theme
     * @param themeId ID of Theme to return
     * @param versionId Version to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getThemeVersionSummary(themeId: string, versionId: string, observe?: 'body', reportProgress?: boolean): Observable<VersionLogVersion>;
    public getThemeVersionSummary(themeId: string, versionId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLogVersion>>;
    public getThemeVersionSummary(themeId: string, versionId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLogVersion>>;
    public getThemeVersionSummary(themeId: string, versionId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (themeId === null || themeId === undefined) {
            throw new Error('Required parameter themeId was null or undefined when calling getThemeVersionSummary.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getThemeVersionSummary.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLogVersion>('get',`${this.basePath}/themes/${encodeURIComponent(String(themeId))}/log/${encodeURIComponent(String(versionId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Find usages of specified Theme version
     * @param themeId ID of Theme to return
     * @param versionId Version of Theme to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getThemeVersionUsages(themeId: string, versionId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Reference>>;
    public getThemeVersionUsages(themeId: string, versionId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Reference>>>;
    public getThemeVersionUsages(themeId: string, versionId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Reference>>>;
    public getThemeVersionUsages(themeId: string, versionId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (themeId === null || themeId === undefined) {
            throw new Error('Required parameter themeId was null or undefined when calling getThemeVersionUsages.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getThemeVersionUsages.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Reference>>('get',`${this.basePath}/themes/${encodeURIComponent(String(themeId))}/v/${encodeURIComponent(String(versionId))}/usages`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get details of specific user
     * @param email Email of user
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUser(email: string, observe?: 'body', reportProgress?: boolean): Observable<User>;
    public getUser(email: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<User>>;
    public getUser(email: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<User>>;
    public getUser(email: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (email === null || email === undefined) {
            throw new Error('Required parameter email was null or undefined when calling getUser.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<User>('get',`${this.basePath}/users/${encodeURIComponent(String(email))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get details of visible users
     * @param guests Whether to include guest users
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUsers(guests?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Array<User>>;
    public getUsers(guests?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<User>>>;
    public getUsers(guests?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<User>>>;
    public getUsers(guests?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (guests !== undefined && guests !== null) {
            queryParameters = queryParameters.set('guests', <any>guests);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<User>>('get',`${this.basePath}/users`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get specified version of specified WorkBatch
     * @param workbatchId ID of WorkBatch to return
     * @param versionId Version of WorkBatch to return
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param ifNoneMatch Version of WorkBatch already received, if no change to WorkBatch since version then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWorkBatch(workbatchId: string, versionId: string, multiWorkspace?: boolean, complete?: boolean, ifNoneMatch?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedWorkBatch>;
    public getWorkBatch(workbatchId: string, versionId: string, multiWorkspace?: boolean, complete?: boolean, ifNoneMatch?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedWorkBatch>>;
    public getWorkBatch(workbatchId: string, versionId: string, multiWorkspace?: boolean, complete?: boolean, ifNoneMatch?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedWorkBatch>>;
    public getWorkBatch(workbatchId: string, versionId: string, multiWorkspace?: boolean, complete?: boolean, ifNoneMatch?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workbatchId === null || workbatchId === undefined) {
            throw new Error('Required parameter workbatchId was null or undefined when calling getWorkBatch.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getWorkBatch.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;
        if (ifNoneMatch !== undefined && ifNoneMatch !== null) {
            headers = headers.set('If-None-Match', String(ifNoneMatch));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedWorkBatch>('get',`${this.basePath}/workbatches/${encodeURIComponent(String(workbatchId))}/v/${encodeURIComponent(String(versionId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get diff between versions of WorkBatches
     * @param workbatchId1 ID of first WorkBatch to compare
     * @param versionId1 Version of first WorkBatch to compare
     * @param workbatchId2 ID of second WorkBatch to compare
     * @param versionId2 Version of second WorkBatch to compare
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWorkBatchDiff(workbatchId1: string, versionId1: string, workbatchId2: string, versionId2: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedWorkBatchDiff>;
    public getWorkBatchDiff(workbatchId1: string, versionId1: string, workbatchId2: string, versionId2: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedWorkBatchDiff>>;
    public getWorkBatchDiff(workbatchId1: string, versionId1: string, workbatchId2: string, versionId2: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedWorkBatchDiff>>;
    public getWorkBatchDiff(workbatchId1: string, versionId1: string, workbatchId2: string, versionId2: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workbatchId1 === null || workbatchId1 === undefined) {
            throw new Error('Required parameter workbatchId1 was null or undefined when calling getWorkBatchDiff.');
        }

        if (versionId1 === null || versionId1 === undefined) {
            throw new Error('Required parameter versionId1 was null or undefined when calling getWorkBatchDiff.');
        }

        if (workbatchId2 === null || workbatchId2 === undefined) {
            throw new Error('Required parameter workbatchId2 was null or undefined when calling getWorkBatchDiff.');
        }

        if (versionId2 === null || versionId2 === undefined) {
            throw new Error('Required parameter versionId2 was null or undefined when calling getWorkBatchDiff.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedWorkBatchDiff>('get',`${this.basePath}/workbatches/${encodeURIComponent(String(workbatchId1))}/v/${encodeURIComponent(String(versionId1))}/diff/${encodeURIComponent(String(workbatchId2))}/v/${encodeURIComponent(String(versionId2))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workbatchId ID of WorkBatch
     * @param versionId Version of WorkBatch
     * @param ifNoneMatch Version of WorkBatchGroupArray already received, if no change to WorkBatchGroupArray since version then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWorkBatchGroups(workbatchId: string, versionId: string, ifNoneMatch?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedWorkBatchGroupArray>;
    public getWorkBatchGroups(workbatchId: string, versionId: string, ifNoneMatch?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedWorkBatchGroupArray>>;
    public getWorkBatchGroups(workbatchId: string, versionId: string, ifNoneMatch?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedWorkBatchGroupArray>>;
    public getWorkBatchGroups(workbatchId: string, versionId: string, ifNoneMatch?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workbatchId === null || workbatchId === undefined) {
            throw new Error('Required parameter workbatchId was null or undefined when calling getWorkBatchGroups.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getWorkBatchGroups.');
        }


        let headers = this.defaultHeaders;
        if (ifNoneMatch !== undefined && ifNoneMatch !== null) {
            headers = headers.set('If-None-Match', String(ifNoneMatch));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedWorkBatchGroupArray>('get',`${this.basePath}/workbatches/${encodeURIComponent(String(workbatchId))}/v/${encodeURIComponent(String(versionId))}/groups`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workbatchId ID of WorkBatch
     * @param versionId Version of WorkBatch
     * @param groupsId 
     * @param ifNoneMatch Version of WorkBatchGroupData already received, if no change to WorkBatchGroupData since version then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWorkBatchGroupsData(workbatchId: string, versionId: string, groupsId: string, ifNoneMatch?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedWorkBatchGroupData>;
    public getWorkBatchGroupsData(workbatchId: string, versionId: string, groupsId: string, ifNoneMatch?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedWorkBatchGroupData>>;
    public getWorkBatchGroupsData(workbatchId: string, versionId: string, groupsId: string, ifNoneMatch?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedWorkBatchGroupData>>;
    public getWorkBatchGroupsData(workbatchId: string, versionId: string, groupsId: string, ifNoneMatch?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workbatchId === null || workbatchId === undefined) {
            throw new Error('Required parameter workbatchId was null or undefined when calling getWorkBatchGroupsData.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getWorkBatchGroupsData.');
        }

        if (groupsId === null || groupsId === undefined) {
            throw new Error('Required parameter groupsId was null or undefined when calling getWorkBatchGroupsData.');
        }


        let headers = this.defaultHeaders;
        if (ifNoneMatch !== undefined && ifNoneMatch !== null) {
            headers = headers.set('If-None-Match', String(ifNoneMatch));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedWorkBatchGroupData>('get',`${this.basePath}/workbatches/${encodeURIComponent(String(workbatchId))}/v/${encodeURIComponent(String(versionId))}/groups/${encodeURIComponent(String(groupsId))}/data`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workbatchId ID of WorkBatch
     * @param versionId Version of WorkBatch
     * @param groupsId 
     * @param ifNoneMatch Version of WorkBatchGroup already received, if no change to WorkBatchGroup since version then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWorkBatchGroupsWorkBatchGroup(workbatchId: string, versionId: string, groupsId: string, ifNoneMatch?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedWorkBatchGroup>;
    public getWorkBatchGroupsWorkBatchGroup(workbatchId: string, versionId: string, groupsId: string, ifNoneMatch?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedWorkBatchGroup>>;
    public getWorkBatchGroupsWorkBatchGroup(workbatchId: string, versionId: string, groupsId: string, ifNoneMatch?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedWorkBatchGroup>>;
    public getWorkBatchGroupsWorkBatchGroup(workbatchId: string, versionId: string, groupsId: string, ifNoneMatch?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workbatchId === null || workbatchId === undefined) {
            throw new Error('Required parameter workbatchId was null or undefined when calling getWorkBatchGroupsWorkBatchGroup.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getWorkBatchGroupsWorkBatchGroup.');
        }

        if (groupsId === null || groupsId === undefined) {
            throw new Error('Required parameter groupsId was null or undefined when calling getWorkBatchGroupsWorkBatchGroup.');
        }


        let headers = this.defaultHeaders;
        if (ifNoneMatch !== undefined && ifNoneMatch !== null) {
            headers = headers.set('If-None-Match', String(ifNoneMatch));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedWorkBatchGroup>('get',`${this.basePath}/workbatches/${encodeURIComponent(String(workbatchId))}/v/${encodeURIComponent(String(versionId))}/groups/${encodeURIComponent(String(groupsId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get latest version of specified WorkBatch
     * @param workbatchId ID of WorkBatch to return
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param eTag Version of WorkBatch considered to be HEAD, if no newer WorkBatch is found then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWorkBatchHead(workbatchId: string, multiWorkspace?: boolean, complete?: boolean, eTag?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedWorkBatch>;
    public getWorkBatchHead(workbatchId: string, multiWorkspace?: boolean, complete?: boolean, eTag?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedWorkBatch>>;
    public getWorkBatchHead(workbatchId: string, multiWorkspace?: boolean, complete?: boolean, eTag?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedWorkBatch>>;
    public getWorkBatchHead(workbatchId: string, multiWorkspace?: boolean, complete?: boolean, eTag?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workbatchId === null || workbatchId === undefined) {
            throw new Error('Required parameter workbatchId was null or undefined when calling getWorkBatchHead.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;
        if (eTag !== undefined && eTag !== null) {
            headers = headers.set('ETag', String(eTag));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedWorkBatch>('get',`${this.basePath}/workbatches/${encodeURIComponent(String(workbatchId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workbatchId ID of WorkBatch
     * @param eTag Version of WorkBatchGroupArray considered to be HEAD, if no newer WorkBatchGroupArray is found then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWorkBatchHeadGroups(workbatchId: string, eTag?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedWorkBatchGroupArray>;
    public getWorkBatchHeadGroups(workbatchId: string, eTag?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedWorkBatchGroupArray>>;
    public getWorkBatchHeadGroups(workbatchId: string, eTag?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedWorkBatchGroupArray>>;
    public getWorkBatchHeadGroups(workbatchId: string, eTag?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workbatchId === null || workbatchId === undefined) {
            throw new Error('Required parameter workbatchId was null or undefined when calling getWorkBatchHeadGroups.');
        }


        let headers = this.defaultHeaders;
        if (eTag !== undefined && eTag !== null) {
            headers = headers.set('ETag', String(eTag));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedWorkBatchGroupArray>('get',`${this.basePath}/workbatches/${encodeURIComponent(String(workbatchId))}/groups`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workbatchId ID of WorkBatch
     * @param groupsId 
     * @param eTag Version of WorkBatchGroupData considered to be HEAD, if no newer WorkBatchGroupData is found then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWorkBatchHeadGroupsData(workbatchId: string, groupsId: string, eTag?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedWorkBatchGroupData>;
    public getWorkBatchHeadGroupsData(workbatchId: string, groupsId: string, eTag?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedWorkBatchGroupData>>;
    public getWorkBatchHeadGroupsData(workbatchId: string, groupsId: string, eTag?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedWorkBatchGroupData>>;
    public getWorkBatchHeadGroupsData(workbatchId: string, groupsId: string, eTag?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workbatchId === null || workbatchId === undefined) {
            throw new Error('Required parameter workbatchId was null or undefined when calling getWorkBatchHeadGroupsData.');
        }

        if (groupsId === null || groupsId === undefined) {
            throw new Error('Required parameter groupsId was null or undefined when calling getWorkBatchHeadGroupsData.');
        }


        let headers = this.defaultHeaders;
        if (eTag !== undefined && eTag !== null) {
            headers = headers.set('ETag', String(eTag));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedWorkBatchGroupData>('get',`${this.basePath}/workbatches/${encodeURIComponent(String(workbatchId))}/groups/${encodeURIComponent(String(groupsId))}/data`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workbatchId ID of WorkBatch to return
     * @param groupsId 
     * @param from First version to consider in log
     * @param to Last version to consider in log
     * @param limit Maximum number of versions in log
     * @param tag Tag of version to find
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWorkBatchHeadGroupsDataLog(workbatchId: string, groupsId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionLog>;
    public getWorkBatchHeadGroupsDataLog(workbatchId: string, groupsId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLog>>;
    public getWorkBatchHeadGroupsDataLog(workbatchId: string, groupsId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLog>>;
    public getWorkBatchHeadGroupsDataLog(workbatchId: string, groupsId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workbatchId === null || workbatchId === undefined) {
            throw new Error('Required parameter workbatchId was null or undefined when calling getWorkBatchHeadGroupsDataLog.');
        }

        if (groupsId === null || groupsId === undefined) {
            throw new Error('Required parameter groupsId was null or undefined when calling getWorkBatchHeadGroupsDataLog.');
        }







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from);
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('to', <any>to);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (tag !== undefined && tag !== null) {
            queryParameters = queryParameters.set('tag', <any>tag);
        }
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLog>('get',`${this.basePath}/workbatches/${encodeURIComponent(String(workbatchId))}/groups/${encodeURIComponent(String(groupsId))}/data/log`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workbatchId ID of WorkBatch to return
     * @param from First version to consider in log
     * @param to Last version to consider in log
     * @param limit Maximum number of versions in log
     * @param tag Tag of version to find
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWorkBatchHeadGroupsLog(workbatchId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionLog>;
    public getWorkBatchHeadGroupsLog(workbatchId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLog>>;
    public getWorkBatchHeadGroupsLog(workbatchId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLog>>;
    public getWorkBatchHeadGroupsLog(workbatchId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workbatchId === null || workbatchId === undefined) {
            throw new Error('Required parameter workbatchId was null or undefined when calling getWorkBatchHeadGroupsLog.');
        }







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from);
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('to', <any>to);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (tag !== undefined && tag !== null) {
            queryParameters = queryParameters.set('tag', <any>tag);
        }
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLog>('get',`${this.basePath}/workbatches/${encodeURIComponent(String(workbatchId))}/groups/log`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workbatchId ID of WorkBatch
     * @param groupsId 
     * @param eTag Version of WorkBatchGroup considered to be HEAD, if no newer WorkBatchGroup is found then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWorkBatchHeadGroupsWorkBatchGroup(workbatchId: string, groupsId: string, eTag?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedWorkBatchGroup>;
    public getWorkBatchHeadGroupsWorkBatchGroup(workbatchId: string, groupsId: string, eTag?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedWorkBatchGroup>>;
    public getWorkBatchHeadGroupsWorkBatchGroup(workbatchId: string, groupsId: string, eTag?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedWorkBatchGroup>>;
    public getWorkBatchHeadGroupsWorkBatchGroup(workbatchId: string, groupsId: string, eTag?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workbatchId === null || workbatchId === undefined) {
            throw new Error('Required parameter workbatchId was null or undefined when calling getWorkBatchHeadGroupsWorkBatchGroup.');
        }

        if (groupsId === null || groupsId === undefined) {
            throw new Error('Required parameter groupsId was null or undefined when calling getWorkBatchHeadGroupsWorkBatchGroup.');
        }


        let headers = this.defaultHeaders;
        if (eTag !== undefined && eTag !== null) {
            headers = headers.set('ETag', String(eTag));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedWorkBatchGroup>('get',`${this.basePath}/workbatches/${encodeURIComponent(String(workbatchId))}/groups/${encodeURIComponent(String(groupsId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workbatchId ID of WorkBatch to return
     * @param groupsId 
     * @param from First version to consider in log
     * @param to Last version to consider in log
     * @param limit Maximum number of versions in log
     * @param tag Tag of version to find
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWorkBatchHeadGroupsWorkBatchGroupLog(workbatchId: string, groupsId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionLog>;
    public getWorkBatchHeadGroupsWorkBatchGroupLog(workbatchId: string, groupsId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLog>>;
    public getWorkBatchHeadGroupsWorkBatchGroupLog(workbatchId: string, groupsId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLog>>;
    public getWorkBatchHeadGroupsWorkBatchGroupLog(workbatchId: string, groupsId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workbatchId === null || workbatchId === undefined) {
            throw new Error('Required parameter workbatchId was null or undefined when calling getWorkBatchHeadGroupsWorkBatchGroupLog.');
        }

        if (groupsId === null || groupsId === undefined) {
            throw new Error('Required parameter groupsId was null or undefined when calling getWorkBatchHeadGroupsWorkBatchGroupLog.');
        }







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from);
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('to', <any>to);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (tag !== undefined && tag !== null) {
            queryParameters = queryParameters.set('tag', <any>tag);
        }
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLog>('get',`${this.basePath}/workbatches/${encodeURIComponent(String(workbatchId))}/groups/${encodeURIComponent(String(groupsId))}/log`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workbatchId ID of WorkBatch
     * @param eTag Version of WorkBatchProviderMap considered to be HEAD, if no newer WorkBatchProviderMap is found then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWorkBatchHeadProviders(workbatchId: string, eTag?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedWorkBatchProviderMap>;
    public getWorkBatchHeadProviders(workbatchId: string, eTag?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedWorkBatchProviderMap>>;
    public getWorkBatchHeadProviders(workbatchId: string, eTag?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedWorkBatchProviderMap>>;
    public getWorkBatchHeadProviders(workbatchId: string, eTag?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workbatchId === null || workbatchId === undefined) {
            throw new Error('Required parameter workbatchId was null or undefined when calling getWorkBatchHeadProviders.');
        }


        let headers = this.defaultHeaders;
        if (eTag !== undefined && eTag !== null) {
            headers = headers.set('ETag', String(eTag));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedWorkBatchProviderMap>('get',`${this.basePath}/workbatches/${encodeURIComponent(String(workbatchId))}/providers`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workbatchId ID of WorkBatch to return
     * @param from First version to consider in log
     * @param to Last version to consider in log
     * @param limit Maximum number of versions in log
     * @param tag Tag of version to find
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWorkBatchHeadProvidersLog(workbatchId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionLog>;
    public getWorkBatchHeadProvidersLog(workbatchId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLog>>;
    public getWorkBatchHeadProvidersLog(workbatchId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLog>>;
    public getWorkBatchHeadProvidersLog(workbatchId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workbatchId === null || workbatchId === undefined) {
            throw new Error('Required parameter workbatchId was null or undefined when calling getWorkBatchHeadProvidersLog.');
        }







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from);
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('to', <any>to);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (tag !== undefined && tag !== null) {
            queryParameters = queryParameters.set('tag', <any>tag);
        }
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLog>('get',`${this.basePath}/workbatches/${encodeURIComponent(String(workbatchId))}/providers/log`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workbatchId ID of WorkBatch
     * @param providersId 
     * @param eTag Version of WorkBatchProvider considered to be HEAD, if no newer WorkBatchProvider is found then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWorkBatchHeadProvidersWorkBatchProvider(workbatchId: string, providersId: string, eTag?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedWorkBatchProvider>;
    public getWorkBatchHeadProvidersWorkBatchProvider(workbatchId: string, providersId: string, eTag?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedWorkBatchProvider>>;
    public getWorkBatchHeadProvidersWorkBatchProvider(workbatchId: string, providersId: string, eTag?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedWorkBatchProvider>>;
    public getWorkBatchHeadProvidersWorkBatchProvider(workbatchId: string, providersId: string, eTag?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workbatchId === null || workbatchId === undefined) {
            throw new Error('Required parameter workbatchId was null or undefined when calling getWorkBatchHeadProvidersWorkBatchProvider.');
        }

        if (providersId === null || providersId === undefined) {
            throw new Error('Required parameter providersId was null or undefined when calling getWorkBatchHeadProvidersWorkBatchProvider.');
        }


        let headers = this.defaultHeaders;
        if (eTag !== undefined && eTag !== null) {
            headers = headers.set('ETag', String(eTag));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedWorkBatchProvider>('get',`${this.basePath}/workbatches/${encodeURIComponent(String(workbatchId))}/providers/${encodeURIComponent(String(providersId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workbatchId ID of WorkBatch to return
     * @param providersId 
     * @param from First version to consider in log
     * @param to Last version to consider in log
     * @param limit Maximum number of versions in log
     * @param tag Tag of version to find
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWorkBatchHeadProvidersWorkBatchProviderLog(workbatchId: string, providersId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionLog>;
    public getWorkBatchHeadProvidersWorkBatchProviderLog(workbatchId: string, providersId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLog>>;
    public getWorkBatchHeadProvidersWorkBatchProviderLog(workbatchId: string, providersId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLog>>;
    public getWorkBatchHeadProvidersWorkBatchProviderLog(workbatchId: string, providersId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workbatchId === null || workbatchId === undefined) {
            throw new Error('Required parameter workbatchId was null or undefined when calling getWorkBatchHeadProvidersWorkBatchProviderLog.');
        }

        if (providersId === null || providersId === undefined) {
            throw new Error('Required parameter providersId was null or undefined when calling getWorkBatchHeadProvidersWorkBatchProviderLog.');
        }







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from);
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('to', <any>to);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (tag !== undefined && tag !== null) {
            queryParameters = queryParameters.set('tag', <any>tag);
        }
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLog>('get',`${this.basePath}/workbatches/${encodeURIComponent(String(workbatchId))}/providers/${encodeURIComponent(String(providersId))}/log`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get history of specified WorkBatch
     * @param workbatchId ID of WorkBatch to return
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWorkBatchHistory(workbatchId: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionGraph>;
    public getWorkBatchHistory(workbatchId: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionGraph>>;
    public getWorkBatchHistory(workbatchId: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionGraph>>;
    public getWorkBatchHistory(workbatchId: string, multiWorkspace?: boolean, complete?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workbatchId === null || workbatchId === undefined) {
            throw new Error('Required parameter workbatchId was null or undefined when calling getWorkBatchHistory.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionGraph>('get',`${this.basePath}/workbatches/${encodeURIComponent(String(workbatchId))}/history`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get log of specified WorkBatch
     * @param workbatchId ID of WorkBatch to return
     * @param from First version to consider in log
     * @param to Last version to consider in log
     * @param limit Maximum number of versions in log
     * @param tag Tag of version to find
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWorkBatchLog(workbatchId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionLog>;
    public getWorkBatchLog(workbatchId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLog>>;
    public getWorkBatchLog(workbatchId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLog>>;
    public getWorkBatchLog(workbatchId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workbatchId === null || workbatchId === undefined) {
            throw new Error('Required parameter workbatchId was null or undefined when calling getWorkBatchLog.');
        }







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from);
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('to', <any>to);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (tag !== undefined && tag !== null) {
            queryParameters = queryParameters.set('tag', <any>tag);
        }
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLog>('get',`${this.basePath}/workbatches/${encodeURIComponent(String(workbatchId))}/log`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get WorkBatch permissions
     * @param workbatchId ID of WorkBatch to check
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWorkBatchPermissions(workbatchId: string, observe?: 'body', reportProgress?: boolean): Observable<UserPermissions>;
    public getWorkBatchPermissions(workbatchId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserPermissions>>;
    public getWorkBatchPermissions(workbatchId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserPermissions>>;
    public getWorkBatchPermissions(workbatchId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workbatchId === null || workbatchId === undefined) {
            throw new Error('Required parameter workbatchId was null or undefined when calling getWorkBatchPermissions.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<UserPermissions>('get',`${this.basePath}/workbatches/${encodeURIComponent(String(workbatchId))}/permissions`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workbatchId ID of WorkBatch
     * @param versionId Version of WorkBatch
     * @param ifNoneMatch Version of WorkBatchProviderMap already received, if no change to WorkBatchProviderMap since version then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWorkBatchProviders(workbatchId: string, versionId: string, ifNoneMatch?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedWorkBatchProviderMap>;
    public getWorkBatchProviders(workbatchId: string, versionId: string, ifNoneMatch?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedWorkBatchProviderMap>>;
    public getWorkBatchProviders(workbatchId: string, versionId: string, ifNoneMatch?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedWorkBatchProviderMap>>;
    public getWorkBatchProviders(workbatchId: string, versionId: string, ifNoneMatch?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workbatchId === null || workbatchId === undefined) {
            throw new Error('Required parameter workbatchId was null or undefined when calling getWorkBatchProviders.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getWorkBatchProviders.');
        }


        let headers = this.defaultHeaders;
        if (ifNoneMatch !== undefined && ifNoneMatch !== null) {
            headers = headers.set('If-None-Match', String(ifNoneMatch));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedWorkBatchProviderMap>('get',`${this.basePath}/workbatches/${encodeURIComponent(String(workbatchId))}/v/${encodeURIComponent(String(versionId))}/providers`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workbatchId ID of WorkBatch
     * @param versionId Version of WorkBatch
     * @param providersId 
     * @param ifNoneMatch Version of WorkBatchProvider already received, if no change to WorkBatchProvider since version then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWorkBatchProvidersWorkBatchProvider(workbatchId: string, versionId: string, providersId: string, ifNoneMatch?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedWorkBatchProvider>;
    public getWorkBatchProvidersWorkBatchProvider(workbatchId: string, versionId: string, providersId: string, ifNoneMatch?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedWorkBatchProvider>>;
    public getWorkBatchProvidersWorkBatchProvider(workbatchId: string, versionId: string, providersId: string, ifNoneMatch?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedWorkBatchProvider>>;
    public getWorkBatchProvidersWorkBatchProvider(workbatchId: string, versionId: string, providersId: string, ifNoneMatch?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workbatchId === null || workbatchId === undefined) {
            throw new Error('Required parameter workbatchId was null or undefined when calling getWorkBatchProvidersWorkBatchProvider.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getWorkBatchProvidersWorkBatchProvider.');
        }

        if (providersId === null || providersId === undefined) {
            throw new Error('Required parameter providersId was null or undefined when calling getWorkBatchProvidersWorkBatchProvider.');
        }


        let headers = this.defaultHeaders;
        if (ifNoneMatch !== undefined && ifNoneMatch !== null) {
            headers = headers.set('If-None-Match', String(ifNoneMatch));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedWorkBatchProvider>('get',`${this.basePath}/workbatches/${encodeURIComponent(String(workbatchId))}/v/${encodeURIComponent(String(versionId))}/providers/${encodeURIComponent(String(providersId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get sharing details of specified WorkBatch
     * @param workbatchId ID of WorkBatch to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWorkBatchSharing(workbatchId: string, observe?: 'body', reportProgress?: boolean): Observable<Sharing>;
    public getWorkBatchSharing(workbatchId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Sharing>>;
    public getWorkBatchSharing(workbatchId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Sharing>>;
    public getWorkBatchSharing(workbatchId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workbatchId === null || workbatchId === undefined) {
            throw new Error('Required parameter workbatchId was null or undefined when calling getWorkBatchSharing.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Sharing>('get',`${this.basePath}/workbatches/${encodeURIComponent(String(workbatchId))}/sharing`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get short code to share the specified WorkBatch
     * @param workbatchId ID of WorkBatch to share
     * @param mode Mode: copy or view or edit
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWorkBatchShortCode(workbatchId: string, mode: string, observe?: 'body', reportProgress?: boolean): Observable<ShortCode>;
    public getWorkBatchShortCode(workbatchId: string, mode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ShortCode>>;
    public getWorkBatchShortCode(workbatchId: string, mode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ShortCode>>;
    public getWorkBatchShortCode(workbatchId: string, mode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workbatchId === null || workbatchId === undefined) {
            throw new Error('Required parameter workbatchId was null or undefined when calling getWorkBatchShortCode.');
        }

        if (mode === null || mode === undefined) {
            throw new Error('Required parameter mode was null or undefined when calling getWorkBatchShortCode.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ShortCode>('get',`${this.basePath}/s/workbatches/${encodeURIComponent(String(workbatchId))}/${encodeURIComponent(String(mode))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Find usages of specified WorkBatch
     * @param workbatchId ID of WorkBatch to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWorkBatchUsages(workbatchId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Reference>>;
    public getWorkBatchUsages(workbatchId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Reference>>>;
    public getWorkBatchUsages(workbatchId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Reference>>>;
    public getWorkBatchUsages(workbatchId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workbatchId === null || workbatchId === undefined) {
            throw new Error('Required parameter workbatchId was null or undefined when calling getWorkBatchUsages.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Reference>>('get',`${this.basePath}/workbatches/${encodeURIComponent(String(workbatchId))}/usages`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get version summary of specified WorkBatch
     * @param workbatchId ID of WorkBatch to return
     * @param versionId Version to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWorkBatchVersionSummary(workbatchId: string, versionId: string, observe?: 'body', reportProgress?: boolean): Observable<VersionLogVersion>;
    public getWorkBatchVersionSummary(workbatchId: string, versionId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLogVersion>>;
    public getWorkBatchVersionSummary(workbatchId: string, versionId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLogVersion>>;
    public getWorkBatchVersionSummary(workbatchId: string, versionId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workbatchId === null || workbatchId === undefined) {
            throw new Error('Required parameter workbatchId was null or undefined when calling getWorkBatchVersionSummary.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getWorkBatchVersionSummary.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLogVersion>('get',`${this.basePath}/workbatches/${encodeURIComponent(String(workbatchId))}/log/${encodeURIComponent(String(versionId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Find usages of specified WorkBatch version
     * @param workbatchId ID of WorkBatch to return
     * @param versionId Version of WorkBatch to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWorkBatchVersionUsages(workbatchId: string, versionId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Reference>>;
    public getWorkBatchVersionUsages(workbatchId: string, versionId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Reference>>>;
    public getWorkBatchVersionUsages(workbatchId: string, versionId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Reference>>>;
    public getWorkBatchVersionUsages(workbatchId: string, versionId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workbatchId === null || workbatchId === undefined) {
            throw new Error('Required parameter workbatchId was null or undefined when calling getWorkBatchVersionUsages.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getWorkBatchVersionUsages.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Reference>>('get',`${this.basePath}/workbatches/${encodeURIComponent(String(workbatchId))}/v/${encodeURIComponent(String(versionId))}/usages`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get specified version of specified Workflow
     * @param workflowId ID of Workflow to return
     * @param versionId Version of Workflow to return
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param ifNoneMatch Version of Workflow already received, if no change to Workflow since version then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWorkflow(workflowId: string, versionId: string, multiWorkspace?: boolean, complete?: boolean, ifNoneMatch?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedWorkflow>;
    public getWorkflow(workflowId: string, versionId: string, multiWorkspace?: boolean, complete?: boolean, ifNoneMatch?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedWorkflow>>;
    public getWorkflow(workflowId: string, versionId: string, multiWorkspace?: boolean, complete?: boolean, ifNoneMatch?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedWorkflow>>;
    public getWorkflow(workflowId: string, versionId: string, multiWorkspace?: boolean, complete?: boolean, ifNoneMatch?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workflowId === null || workflowId === undefined) {
            throw new Error('Required parameter workflowId was null or undefined when calling getWorkflow.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getWorkflow.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;
        if (ifNoneMatch !== undefined && ifNoneMatch !== null) {
            headers = headers.set('If-None-Match', String(ifNoneMatch));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedWorkflow>('get',`${this.basePath}/workflows/${encodeURIComponent(String(workflowId))}/v/${encodeURIComponent(String(versionId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get diff between versions of Workflows
     * @param workflowId1 ID of first Workflow to compare
     * @param versionId1 Version of first Workflow to compare
     * @param workflowId2 ID of second Workflow to compare
     * @param versionId2 Version of second Workflow to compare
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWorkflowDiff(workflowId1: string, versionId1: string, workflowId2: string, versionId2: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedWorkflowDiff>;
    public getWorkflowDiff(workflowId1: string, versionId1: string, workflowId2: string, versionId2: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedWorkflowDiff>>;
    public getWorkflowDiff(workflowId1: string, versionId1: string, workflowId2: string, versionId2: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedWorkflowDiff>>;
    public getWorkflowDiff(workflowId1: string, versionId1: string, workflowId2: string, versionId2: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workflowId1 === null || workflowId1 === undefined) {
            throw new Error('Required parameter workflowId1 was null or undefined when calling getWorkflowDiff.');
        }

        if (versionId1 === null || versionId1 === undefined) {
            throw new Error('Required parameter versionId1 was null or undefined when calling getWorkflowDiff.');
        }

        if (workflowId2 === null || workflowId2 === undefined) {
            throw new Error('Required parameter workflowId2 was null or undefined when calling getWorkflowDiff.');
        }

        if (versionId2 === null || versionId2 === undefined) {
            throw new Error('Required parameter versionId2 was null or undefined when calling getWorkflowDiff.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedWorkflowDiff>('get',`${this.basePath}/workflows/${encodeURIComponent(String(workflowId1))}/v/${encodeURIComponent(String(versionId1))}/diff/${encodeURIComponent(String(workflowId2))}/v/${encodeURIComponent(String(versionId2))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get latest version of specified Workflow
     * @param workflowId ID of Workflow to return
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param eTag Version of Workflow considered to be HEAD, if no newer Workflow is found then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWorkflowHead(workflowId: string, multiWorkspace?: boolean, complete?: boolean, eTag?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedWorkflow>;
    public getWorkflowHead(workflowId: string, multiWorkspace?: boolean, complete?: boolean, eTag?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedWorkflow>>;
    public getWorkflowHead(workflowId: string, multiWorkspace?: boolean, complete?: boolean, eTag?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedWorkflow>>;
    public getWorkflowHead(workflowId: string, multiWorkspace?: boolean, complete?: boolean, eTag?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workflowId === null || workflowId === undefined) {
            throw new Error('Required parameter workflowId was null or undefined when calling getWorkflowHead.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;
        if (eTag !== undefined && eTag !== null) {
            headers = headers.set('ETag', String(eTag));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedWorkflow>('get',`${this.basePath}/workflows/${encodeURIComponent(String(workflowId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get history of specified Workflow
     * @param workflowId ID of Workflow to return
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWorkflowHistory(workflowId: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionGraph>;
    public getWorkflowHistory(workflowId: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionGraph>>;
    public getWorkflowHistory(workflowId: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionGraph>>;
    public getWorkflowHistory(workflowId: string, multiWorkspace?: boolean, complete?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workflowId === null || workflowId === undefined) {
            throw new Error('Required parameter workflowId was null or undefined when calling getWorkflowHistory.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionGraph>('get',`${this.basePath}/workflows/${encodeURIComponent(String(workflowId))}/history`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get log of specified Workflow
     * @param workflowId ID of Workflow to return
     * @param from First version to consider in log
     * @param to Last version to consider in log
     * @param limit Maximum number of versions in log
     * @param tag Tag of version to find
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWorkflowLog(workflowId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionLog>;
    public getWorkflowLog(workflowId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLog>>;
    public getWorkflowLog(workflowId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLog>>;
    public getWorkflowLog(workflowId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workflowId === null || workflowId === undefined) {
            throw new Error('Required parameter workflowId was null or undefined when calling getWorkflowLog.');
        }







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from);
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('to', <any>to);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (tag !== undefined && tag !== null) {
            queryParameters = queryParameters.set('tag', <any>tag);
        }
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLog>('get',`${this.basePath}/workflows/${encodeURIComponent(String(workflowId))}/log`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get Workflow permissions
     * @param workflowId ID of Workflow to check
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWorkflowPermissions(workflowId: string, observe?: 'body', reportProgress?: boolean): Observable<UserPermissions>;
    public getWorkflowPermissions(workflowId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserPermissions>>;
    public getWorkflowPermissions(workflowId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserPermissions>>;
    public getWorkflowPermissions(workflowId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workflowId === null || workflowId === undefined) {
            throw new Error('Required parameter workflowId was null or undefined when calling getWorkflowPermissions.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<UserPermissions>('get',`${this.basePath}/workflows/${encodeURIComponent(String(workflowId))}/permissions`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get sharing details of specified Workflow
     * @param workflowId ID of Workflow to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWorkflowSharing(workflowId: string, observe?: 'body', reportProgress?: boolean): Observable<Sharing>;
    public getWorkflowSharing(workflowId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Sharing>>;
    public getWorkflowSharing(workflowId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Sharing>>;
    public getWorkflowSharing(workflowId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workflowId === null || workflowId === undefined) {
            throw new Error('Required parameter workflowId was null or undefined when calling getWorkflowSharing.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Sharing>('get',`${this.basePath}/workflows/${encodeURIComponent(String(workflowId))}/sharing`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get short code to share the specified Workflow
     * @param workflowId ID of Workflow to share
     * @param mode Mode: copy or view or edit
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWorkflowShortCode(workflowId: string, mode: string, observe?: 'body', reportProgress?: boolean): Observable<ShortCode>;
    public getWorkflowShortCode(workflowId: string, mode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ShortCode>>;
    public getWorkflowShortCode(workflowId: string, mode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ShortCode>>;
    public getWorkflowShortCode(workflowId: string, mode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workflowId === null || workflowId === undefined) {
            throw new Error('Required parameter workflowId was null or undefined when calling getWorkflowShortCode.');
        }

        if (mode === null || mode === undefined) {
            throw new Error('Required parameter mode was null or undefined when calling getWorkflowShortCode.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ShortCode>('get',`${this.basePath}/s/workflows/${encodeURIComponent(String(workflowId))}/${encodeURIComponent(String(mode))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Find usages of specified Workflow
     * @param workflowId ID of Workflow to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWorkflowUsages(workflowId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Reference>>;
    public getWorkflowUsages(workflowId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Reference>>>;
    public getWorkflowUsages(workflowId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Reference>>>;
    public getWorkflowUsages(workflowId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workflowId === null || workflowId === undefined) {
            throw new Error('Required parameter workflowId was null or undefined when calling getWorkflowUsages.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Reference>>('get',`${this.basePath}/workflows/${encodeURIComponent(String(workflowId))}/usages`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get version summary of specified Workflow
     * @param workflowId ID of Workflow to return
     * @param versionId Version to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWorkflowVersionSummary(workflowId: string, versionId: string, observe?: 'body', reportProgress?: boolean): Observable<VersionLogVersion>;
    public getWorkflowVersionSummary(workflowId: string, versionId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLogVersion>>;
    public getWorkflowVersionSummary(workflowId: string, versionId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLogVersion>>;
    public getWorkflowVersionSummary(workflowId: string, versionId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workflowId === null || workflowId === undefined) {
            throw new Error('Required parameter workflowId was null or undefined when calling getWorkflowVersionSummary.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getWorkflowVersionSummary.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLogVersion>('get',`${this.basePath}/workflows/${encodeURIComponent(String(workflowId))}/log/${encodeURIComponent(String(versionId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Find usages of specified Workflow version
     * @param workflowId ID of Workflow to return
     * @param versionId Version of Workflow to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWorkflowVersionUsages(workflowId: string, versionId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Reference>>;
    public getWorkflowVersionUsages(workflowId: string, versionId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Reference>>>;
    public getWorkflowVersionUsages(workflowId: string, versionId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Reference>>>;
    public getWorkflowVersionUsages(workflowId: string, versionId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workflowId === null || workflowId === undefined) {
            throw new Error('Required parameter workflowId was null or undefined when calling getWorkflowVersionUsages.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getWorkflowVersionUsages.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Reference>>('get',`${this.basePath}/workflows/${encodeURIComponent(String(workflowId))}/v/${encodeURIComponent(String(versionId))}/usages`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Invite new user(s) using email address(es)
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public inviteUsers(body?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<User>>;
    public inviteUsers(body?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<User>>>;
    public inviteUsers(body?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<User>>>;
    public inviteUsers(body?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'text/plain'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<User>>('post',`${this.basePath}/users/invitations`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Patch specific version of a Appearance
     * @param appearanceId ID of Appearance to update
     * @param versionId Version of Appearance to update
     * @param body 
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param updateAllReferences Update affected documents owned by current user
     * @param updateMyReferences Update affected documents owned by any user
     * @param notHead Do not set this version as the head version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public patchAppearance(appearanceId: string, versionId: string, body?: Patch, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedAppearancePatch>;
    public patchAppearance(appearanceId: string, versionId: string, body?: Patch, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedAppearancePatch>>;
    public patchAppearance(appearanceId: string, versionId: string, body?: Patch, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedAppearancePatch>>;
    public patchAppearance(appearanceId: string, versionId: string, body?: Patch, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (appearanceId === null || appearanceId === undefined) {
            throw new Error('Required parameter appearanceId was null or undefined when calling patchAppearance.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling patchAppearance.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }
        if (notHead !== undefined && notHead !== null) {
            queryParameters = queryParameters.set('notHead', <any>notHead);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedAppearancePatch>('patch',`${this.basePath}/appearances/${encodeURIComponent(String(appearanceId))}/v/${encodeURIComponent(String(versionId))}`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Patch specific version of a Company
     * @param companyId ID of Company to update
     * @param versionId Version of Company to update
     * @param body 
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param updateAllReferences Update affected documents owned by current user
     * @param updateMyReferences Update affected documents owned by any user
     * @param notHead Do not set this version as the head version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public patchCompany(companyId: string, versionId: string, body?: Patch, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedCompanyPatch>;
    public patchCompany(companyId: string, versionId: string, body?: Patch, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedCompanyPatch>>;
    public patchCompany(companyId: string, versionId: string, body?: Patch, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedCompanyPatch>>;
    public patchCompany(companyId: string, versionId: string, body?: Patch, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling patchCompany.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling patchCompany.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }
        if (notHead !== undefined && notHead !== null) {
            queryParameters = queryParameters.set('notHead', <any>notHead);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedCompanyPatch>('patch',`${this.basePath}/companies/${encodeURIComponent(String(companyId))}/v/${encodeURIComponent(String(versionId))}`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Patch specific version of a ConnectorType
     * @param connectortypeId ID of ConnectorType to update
     * @param versionId Version of ConnectorType to update
     * @param body 
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param updateAllReferences Update affected documents owned by current user
     * @param updateMyReferences Update affected documents owned by any user
     * @param notHead Do not set this version as the head version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public patchConnectorType(connectortypeId: string, versionId: string, body?: Patch, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedConnectorTypePatch>;
    public patchConnectorType(connectortypeId: string, versionId: string, body?: Patch, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedConnectorTypePatch>>;
    public patchConnectorType(connectortypeId: string, versionId: string, body?: Patch, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedConnectorTypePatch>>;
    public patchConnectorType(connectortypeId: string, versionId: string, body?: Patch, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (connectortypeId === null || connectortypeId === undefined) {
            throw new Error('Required parameter connectortypeId was null or undefined when calling patchConnectorType.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling patchConnectorType.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }
        if (notHead !== undefined && notHead !== null) {
            queryParameters = queryParameters.set('notHead', <any>notHead);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedConnectorTypePatch>('patch',`${this.basePath}/connectortypes/${encodeURIComponent(String(connectortypeId))}/v/${encodeURIComponent(String(versionId))}`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Patch specific version of a Module
     * @param moduleId ID of Module to update
     * @param versionId Version of Module to update
     * @param body 
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param updateAllReferences Update affected documents owned by current user
     * @param updateMyReferences Update affected documents owned by any user
     * @param notHead Do not set this version as the head version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public patchModule(moduleId: string, versionId: string, body?: Patch, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedModulePatch>;
    public patchModule(moduleId: string, versionId: string, body?: Patch, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedModulePatch>>;
    public patchModule(moduleId: string, versionId: string, body?: Patch, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedModulePatch>>;
    public patchModule(moduleId: string, versionId: string, body?: Patch, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (moduleId === null || moduleId === undefined) {
            throw new Error('Required parameter moduleId was null or undefined when calling patchModule.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling patchModule.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }
        if (notHead !== undefined && notHead !== null) {
            queryParameters = queryParameters.set('notHead', <any>notHead);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedModulePatch>('patch',`${this.basePath}/modules/${encodeURIComponent(String(moduleId))}/v/${encodeURIComponent(String(versionId))}`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Patch specific version of a OfflineAction
     * @param offlineactionId ID of OfflineAction to update
     * @param versionId Version of OfflineAction to update
     * @param body 
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param updateAllReferences Update affected documents owned by current user
     * @param updateMyReferences Update affected documents owned by any user
     * @param notHead Do not set this version as the head version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public patchOfflineAction(offlineactionId: string, versionId: string, body?: Patch, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedOfflineActionPatch>;
    public patchOfflineAction(offlineactionId: string, versionId: string, body?: Patch, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedOfflineActionPatch>>;
    public patchOfflineAction(offlineactionId: string, versionId: string, body?: Patch, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedOfflineActionPatch>>;
    public patchOfflineAction(offlineactionId: string, versionId: string, body?: Patch, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (offlineactionId === null || offlineactionId === undefined) {
            throw new Error('Required parameter offlineactionId was null or undefined when calling patchOfflineAction.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling patchOfflineAction.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }
        if (notHead !== undefined && notHead !== null) {
            queryParameters = queryParameters.set('notHead', <any>notHead);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedOfflineActionPatch>('patch',`${this.basePath}/offlineactions/${encodeURIComponent(String(offlineactionId))}/v/${encodeURIComponent(String(versionId))}`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Patch specific version of a Order
     * @param orderId ID of Order to update
     * @param versionId Version of Order to update
     * @param body 
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param updateAllReferences Update affected documents owned by current user
     * @param updateMyReferences Update affected documents owned by any user
     * @param notHead Do not set this version as the head version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public patchOrder(orderId: string, versionId: string, body?: Patch, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedOrderPatch>;
    public patchOrder(orderId: string, versionId: string, body?: Patch, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedOrderPatch>>;
    public patchOrder(orderId: string, versionId: string, body?: Patch, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedOrderPatch>>;
    public patchOrder(orderId: string, versionId: string, body?: Patch, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (orderId === null || orderId === undefined) {
            throw new Error('Required parameter orderId was null or undefined when calling patchOrder.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling patchOrder.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }
        if (notHead !== undefined && notHead !== null) {
            queryParameters = queryParameters.set('notHead', <any>notHead);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedOrderPatch>('patch',`${this.basePath}/orders/${encodeURIComponent(String(orderId))}/v/${encodeURIComponent(String(versionId))}`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Patch specific version of a Population
     * @param populationId ID of Population to update
     * @param versionId Version of Population to update
     * @param body 
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param updateAllReferences Update affected documents owned by current user
     * @param updateMyReferences Update affected documents owned by any user
     * @param notHead Do not set this version as the head version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public patchPopulation(populationId: string, versionId: string, body?: Patch, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedPopulationPatch>;
    public patchPopulation(populationId: string, versionId: string, body?: Patch, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedPopulationPatch>>;
    public patchPopulation(populationId: string, versionId: string, body?: Patch, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedPopulationPatch>>;
    public patchPopulation(populationId: string, versionId: string, body?: Patch, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (populationId === null || populationId === undefined) {
            throw new Error('Required parameter populationId was null or undefined when calling patchPopulation.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling patchPopulation.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }
        if (notHead !== undefined && notHead !== null) {
            queryParameters = queryParameters.set('notHead', <any>notHead);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedPopulationPatch>('patch',`${this.basePath}/populations/${encodeURIComponent(String(populationId))}/v/${encodeURIComponent(String(versionId))}`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Patch specific version of a Product
     * @param productId ID of Product to update
     * @param versionId Version of Product to update
     * @param body 
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param updateAllReferences Update affected documents owned by current user
     * @param updateMyReferences Update affected documents owned by any user
     * @param notHead Do not set this version as the head version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public patchProduct(productId: string, versionId: string, body?: Patch, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedProductPatch>;
    public patchProduct(productId: string, versionId: string, body?: Patch, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedProductPatch>>;
    public patchProduct(productId: string, versionId: string, body?: Patch, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedProductPatch>>;
    public patchProduct(productId: string, versionId: string, body?: Patch, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productId === null || productId === undefined) {
            throw new Error('Required parameter productId was null or undefined when calling patchProduct.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling patchProduct.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }
        if (notHead !== undefined && notHead !== null) {
            queryParameters = queryParameters.set('notHead', <any>notHead);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedProductPatch>('patch',`${this.basePath}/products/${encodeURIComponent(String(productId))}/v/${encodeURIComponent(String(versionId))}`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Patch specific version of a ProductConfiguration
     * @param productconfigurationId ID of ProductConfiguration to update
     * @param versionId Version of ProductConfiguration to update
     * @param body 
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param updateAllReferences Update affected documents owned by current user
     * @param updateMyReferences Update affected documents owned by any user
     * @param notHead Do not set this version as the head version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public patchProductConfiguration(productconfigurationId: string, versionId: string, body?: Patch, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedProductConfigurationPatch>;
    public patchProductConfiguration(productconfigurationId: string, versionId: string, body?: Patch, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedProductConfigurationPatch>>;
    public patchProductConfiguration(productconfigurationId: string, versionId: string, body?: Patch, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedProductConfigurationPatch>>;
    public patchProductConfiguration(productconfigurationId: string, versionId: string, body?: Patch, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productconfigurationId === null || productconfigurationId === undefined) {
            throw new Error('Required parameter productconfigurationId was null or undefined when calling patchProductConfiguration.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling patchProductConfiguration.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }
        if (notHead !== undefined && notHead !== null) {
            queryParameters = queryParameters.set('notHead', <any>notHead);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedProductConfigurationPatch>('patch',`${this.basePath}/productconfigurations/${encodeURIComponent(String(productconfigurationId))}/v/${encodeURIComponent(String(versionId))}`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Patch specific version of a ProductionMaterial
     * @param productionmaterialId ID of ProductionMaterial to update
     * @param versionId Version of ProductionMaterial to update
     * @param body 
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param updateAllReferences Update affected documents owned by current user
     * @param updateMyReferences Update affected documents owned by any user
     * @param notHead Do not set this version as the head version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public patchProductionMaterial(productionmaterialId: string, versionId: string, body?: Patch, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedProductionMaterialPatch>;
    public patchProductionMaterial(productionmaterialId: string, versionId: string, body?: Patch, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedProductionMaterialPatch>>;
    public patchProductionMaterial(productionmaterialId: string, versionId: string, body?: Patch, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedProductionMaterialPatch>>;
    public patchProductionMaterial(productionmaterialId: string, versionId: string, body?: Patch, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productionmaterialId === null || productionmaterialId === undefined) {
            throw new Error('Required parameter productionmaterialId was null or undefined when calling patchProductionMaterial.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling patchProductionMaterial.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }
        if (notHead !== undefined && notHead !== null) {
            queryParameters = queryParameters.set('notHead', <any>notHead);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedProductionMaterialPatch>('patch',`${this.basePath}/productionmaterials/${encodeURIComponent(String(productionmaterialId))}/v/${encodeURIComponent(String(versionId))}`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Patch specific version of a PublishedConfigurator
     * @param publishedconfiguratorId ID of PublishedConfigurator to update
     * @param versionId Version of PublishedConfigurator to update
     * @param body 
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param updateAllReferences Update affected documents owned by current user
     * @param updateMyReferences Update affected documents owned by any user
     * @param notHead Do not set this version as the head version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public patchPublishedConfigurator(publishedconfiguratorId: string, versionId: string, body?: Patch, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedPublishedConfiguratorPatch>;
    public patchPublishedConfigurator(publishedconfiguratorId: string, versionId: string, body?: Patch, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedPublishedConfiguratorPatch>>;
    public patchPublishedConfigurator(publishedconfiguratorId: string, versionId: string, body?: Patch, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedPublishedConfiguratorPatch>>;
    public patchPublishedConfigurator(publishedconfiguratorId: string, versionId: string, body?: Patch, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (publishedconfiguratorId === null || publishedconfiguratorId === undefined) {
            throw new Error('Required parameter publishedconfiguratorId was null or undefined when calling patchPublishedConfigurator.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling patchPublishedConfigurator.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }
        if (notHead !== undefined && notHead !== null) {
            queryParameters = queryParameters.set('notHead', <any>notHead);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedPublishedConfiguratorPatch>('patch',`${this.basePath}/publishedconfigurators/${encodeURIComponent(String(publishedconfiguratorId))}/v/${encodeURIComponent(String(versionId))}`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Patch specific version of a Theme
     * @param themeId ID of Theme to update
     * @param versionId Version of Theme to update
     * @param body 
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param updateAllReferences Update affected documents owned by current user
     * @param updateMyReferences Update affected documents owned by any user
     * @param notHead Do not set this version as the head version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public patchTheme(themeId: string, versionId: string, body?: Patch, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedThemePatch>;
    public patchTheme(themeId: string, versionId: string, body?: Patch, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedThemePatch>>;
    public patchTheme(themeId: string, versionId: string, body?: Patch, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedThemePatch>>;
    public patchTheme(themeId: string, versionId: string, body?: Patch, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (themeId === null || themeId === undefined) {
            throw new Error('Required parameter themeId was null or undefined when calling patchTheme.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling patchTheme.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }
        if (notHead !== undefined && notHead !== null) {
            queryParameters = queryParameters.set('notHead', <any>notHead);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedThemePatch>('patch',`${this.basePath}/themes/${encodeURIComponent(String(themeId))}/v/${encodeURIComponent(String(versionId))}`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Patch specific version of a WorkBatch
     * @param workbatchId ID of WorkBatch to update
     * @param versionId Version of WorkBatch to update
     * @param body 
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param updateAllReferences Update affected documents owned by current user
     * @param updateMyReferences Update affected documents owned by any user
     * @param notHead Do not set this version as the head version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public patchWorkBatch(workbatchId: string, versionId: string, body?: Patch, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedWorkBatchPatch>;
    public patchWorkBatch(workbatchId: string, versionId: string, body?: Patch, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedWorkBatchPatch>>;
    public patchWorkBatch(workbatchId: string, versionId: string, body?: Patch, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedWorkBatchPatch>>;
    public patchWorkBatch(workbatchId: string, versionId: string, body?: Patch, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workbatchId === null || workbatchId === undefined) {
            throw new Error('Required parameter workbatchId was null or undefined when calling patchWorkBatch.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling patchWorkBatch.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }
        if (notHead !== undefined && notHead !== null) {
            queryParameters = queryParameters.set('notHead', <any>notHead);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedWorkBatchPatch>('patch',`${this.basePath}/workbatches/${encodeURIComponent(String(workbatchId))}/v/${encodeURIComponent(String(versionId))}`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Patch specific version of a Workflow
     * @param workflowId ID of Workflow to update
     * @param versionId Version of Workflow to update
     * @param body 
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param updateAllReferences Update affected documents owned by current user
     * @param updateMyReferences Update affected documents owned by any user
     * @param notHead Do not set this version as the head version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public patchWorkflow(workflowId: string, versionId: string, body?: Patch, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedWorkflowPatch>;
    public patchWorkflow(workflowId: string, versionId: string, body?: Patch, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedWorkflowPatch>>;
    public patchWorkflow(workflowId: string, versionId: string, body?: Patch, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedWorkflowPatch>>;
    public patchWorkflow(workflowId: string, versionId: string, body?: Patch, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workflowId === null || workflowId === undefined) {
            throw new Error('Required parameter workflowId was null or undefined when calling patchWorkflow.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling patchWorkflow.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }
        if (notHead !== undefined && notHead !== null) {
            queryParameters = queryParameters.set('notHead', <any>notHead);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedWorkflowPatch>('patch',`${this.basePath}/workflows/${encodeURIComponent(String(workflowId))}/v/${encodeURIComponent(String(versionId))}`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Revoke an invitation
     * @param code Invitation code
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public revokeInvitation(code: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public revokeInvitation(code: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public revokeInvitation(code: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public revokeInvitation(code: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (code === null || code === undefined) {
            throw new Error('Required parameter code was null or undefined when calling revokeInvitation.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/users/invitations/${encodeURIComponent(String(code))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get Appearance summary
     * @param appearanceId ID of Appearance to check
     * @param includeAttributes DEPRECATED Include information such as number of versions
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public summariseAppearance(appearanceId: string, includeAttributes?: string, observe?: 'body', reportProgress?: boolean): Observable<DocumentSummary>;
    public summariseAppearance(appearanceId: string, includeAttributes?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DocumentSummary>>;
    public summariseAppearance(appearanceId: string, includeAttributes?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DocumentSummary>>;
    public summariseAppearance(appearanceId: string, includeAttributes?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (appearanceId === null || appearanceId === undefined) {
            throw new Error('Required parameter appearanceId was null or undefined when calling summariseAppearance.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (includeAttributes !== undefined && includeAttributes !== null) {
            queryParameters = queryParameters.set('include_attributes', <any>includeAttributes);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DocumentSummary>('get',`${this.basePath}/appearances/${encodeURIComponent(String(appearanceId))}/summary`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get Company summary
     * @param companyId ID of Company to check
     * @param includeAttributes DEPRECATED Include information such as number of versions
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public summariseCompany(companyId: string, includeAttributes?: string, observe?: 'body', reportProgress?: boolean): Observable<DocumentSummary>;
    public summariseCompany(companyId: string, includeAttributes?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DocumentSummary>>;
    public summariseCompany(companyId: string, includeAttributes?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DocumentSummary>>;
    public summariseCompany(companyId: string, includeAttributes?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling summariseCompany.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (includeAttributes !== undefined && includeAttributes !== null) {
            queryParameters = queryParameters.set('include_attributes', <any>includeAttributes);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DocumentSummary>('get',`${this.basePath}/companies/${encodeURIComponent(String(companyId))}/summary`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get ConnectorType summary
     * @param connectortypeId ID of ConnectorType to check
     * @param includeAttributes DEPRECATED Include information such as number of versions
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public summariseConnectorType(connectortypeId: string, includeAttributes?: string, observe?: 'body', reportProgress?: boolean): Observable<DocumentSummary>;
    public summariseConnectorType(connectortypeId: string, includeAttributes?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DocumentSummary>>;
    public summariseConnectorType(connectortypeId: string, includeAttributes?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DocumentSummary>>;
    public summariseConnectorType(connectortypeId: string, includeAttributes?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (connectortypeId === null || connectortypeId === undefined) {
            throw new Error('Required parameter connectortypeId was null or undefined when calling summariseConnectorType.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (includeAttributes !== undefined && includeAttributes !== null) {
            queryParameters = queryParameters.set('include_attributes', <any>includeAttributes);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DocumentSummary>('get',`${this.basePath}/connectortypes/${encodeURIComponent(String(connectortypeId))}/summary`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get Module summary
     * @param moduleId ID of Module to check
     * @param includeAttributes DEPRECATED Include information such as number of versions
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public summariseModule(moduleId: string, includeAttributes?: string, observe?: 'body', reportProgress?: boolean): Observable<DocumentSummary>;
    public summariseModule(moduleId: string, includeAttributes?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DocumentSummary>>;
    public summariseModule(moduleId: string, includeAttributes?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DocumentSummary>>;
    public summariseModule(moduleId: string, includeAttributes?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (moduleId === null || moduleId === undefined) {
            throw new Error('Required parameter moduleId was null or undefined when calling summariseModule.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (includeAttributes !== undefined && includeAttributes !== null) {
            queryParameters = queryParameters.set('include_attributes', <any>includeAttributes);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DocumentSummary>('get',`${this.basePath}/modules/${encodeURIComponent(String(moduleId))}/summary`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get OfflineAction summary
     * @param offlineactionId ID of OfflineAction to check
     * @param includeAttributes DEPRECATED Include information such as number of versions
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public summariseOfflineAction(offlineactionId: string, includeAttributes?: string, observe?: 'body', reportProgress?: boolean): Observable<DocumentSummary>;
    public summariseOfflineAction(offlineactionId: string, includeAttributes?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DocumentSummary>>;
    public summariseOfflineAction(offlineactionId: string, includeAttributes?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DocumentSummary>>;
    public summariseOfflineAction(offlineactionId: string, includeAttributes?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (offlineactionId === null || offlineactionId === undefined) {
            throw new Error('Required parameter offlineactionId was null or undefined when calling summariseOfflineAction.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (includeAttributes !== undefined && includeAttributes !== null) {
            queryParameters = queryParameters.set('include_attributes', <any>includeAttributes);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DocumentSummary>('get',`${this.basePath}/offlineactions/${encodeURIComponent(String(offlineactionId))}/summary`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get Order summary
     * @param orderId ID of Order to check
     * @param includeAttributes DEPRECATED Include information such as number of versions
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public summariseOrder(orderId: string, includeAttributes?: string, observe?: 'body', reportProgress?: boolean): Observable<DocumentSummary>;
    public summariseOrder(orderId: string, includeAttributes?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DocumentSummary>>;
    public summariseOrder(orderId: string, includeAttributes?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DocumentSummary>>;
    public summariseOrder(orderId: string, includeAttributes?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (orderId === null || orderId === undefined) {
            throw new Error('Required parameter orderId was null or undefined when calling summariseOrder.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (includeAttributes !== undefined && includeAttributes !== null) {
            queryParameters = queryParameters.set('include_attributes', <any>includeAttributes);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DocumentSummary>('get',`${this.basePath}/orders/${encodeURIComponent(String(orderId))}/summary`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get Population summary
     * @param populationId ID of Population to check
     * @param includeAttributes DEPRECATED Include information such as number of versions
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public summarisePopulation(populationId: string, includeAttributes?: string, observe?: 'body', reportProgress?: boolean): Observable<DocumentSummary>;
    public summarisePopulation(populationId: string, includeAttributes?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DocumentSummary>>;
    public summarisePopulation(populationId: string, includeAttributes?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DocumentSummary>>;
    public summarisePopulation(populationId: string, includeAttributes?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (populationId === null || populationId === undefined) {
            throw new Error('Required parameter populationId was null or undefined when calling summarisePopulation.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (includeAttributes !== undefined && includeAttributes !== null) {
            queryParameters = queryParameters.set('include_attributes', <any>includeAttributes);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DocumentSummary>('get',`${this.basePath}/populations/${encodeURIComponent(String(populationId))}/summary`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get Product summary
     * @param productId ID of Product to check
     * @param includeAttributes DEPRECATED Include information such as number of versions
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public summariseProduct(productId: string, includeAttributes?: string, observe?: 'body', reportProgress?: boolean): Observable<DocumentSummary>;
    public summariseProduct(productId: string, includeAttributes?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DocumentSummary>>;
    public summariseProduct(productId: string, includeAttributes?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DocumentSummary>>;
    public summariseProduct(productId: string, includeAttributes?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productId === null || productId === undefined) {
            throw new Error('Required parameter productId was null or undefined when calling summariseProduct.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (includeAttributes !== undefined && includeAttributes !== null) {
            queryParameters = queryParameters.set('include_attributes', <any>includeAttributes);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DocumentSummary>('get',`${this.basePath}/products/${encodeURIComponent(String(productId))}/summary`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get ProductConfiguration summary
     * @param productconfigurationId ID of ProductConfiguration to check
     * @param includeAttributes DEPRECATED Include information such as number of versions
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public summariseProductConfiguration(productconfigurationId: string, includeAttributes?: string, observe?: 'body', reportProgress?: boolean): Observable<DocumentSummary>;
    public summariseProductConfiguration(productconfigurationId: string, includeAttributes?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DocumentSummary>>;
    public summariseProductConfiguration(productconfigurationId: string, includeAttributes?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DocumentSummary>>;
    public summariseProductConfiguration(productconfigurationId: string, includeAttributes?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productconfigurationId === null || productconfigurationId === undefined) {
            throw new Error('Required parameter productconfigurationId was null or undefined when calling summariseProductConfiguration.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (includeAttributes !== undefined && includeAttributes !== null) {
            queryParameters = queryParameters.set('include_attributes', <any>includeAttributes);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DocumentSummary>('get',`${this.basePath}/productconfigurations/${encodeURIComponent(String(productconfigurationId))}/summary`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get ProductionMaterial summary
     * @param productionmaterialId ID of ProductionMaterial to check
     * @param includeAttributes DEPRECATED Include information such as number of versions
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public summariseProductionMaterial(productionmaterialId: string, includeAttributes?: string, observe?: 'body', reportProgress?: boolean): Observable<DocumentSummary>;
    public summariseProductionMaterial(productionmaterialId: string, includeAttributes?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DocumentSummary>>;
    public summariseProductionMaterial(productionmaterialId: string, includeAttributes?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DocumentSummary>>;
    public summariseProductionMaterial(productionmaterialId: string, includeAttributes?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productionmaterialId === null || productionmaterialId === undefined) {
            throw new Error('Required parameter productionmaterialId was null or undefined when calling summariseProductionMaterial.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (includeAttributes !== undefined && includeAttributes !== null) {
            queryParameters = queryParameters.set('include_attributes', <any>includeAttributes);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DocumentSummary>('get',`${this.basePath}/productionmaterials/${encodeURIComponent(String(productionmaterialId))}/summary`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get PublishedConfigurator summary
     * @param publishedconfiguratorId ID of PublishedConfigurator to check
     * @param includeAttributes DEPRECATED Include information such as number of versions
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public summarisePublishedConfigurator(publishedconfiguratorId: string, includeAttributes?: string, observe?: 'body', reportProgress?: boolean): Observable<DocumentSummary>;
    public summarisePublishedConfigurator(publishedconfiguratorId: string, includeAttributes?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DocumentSummary>>;
    public summarisePublishedConfigurator(publishedconfiguratorId: string, includeAttributes?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DocumentSummary>>;
    public summarisePublishedConfigurator(publishedconfiguratorId: string, includeAttributes?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (publishedconfiguratorId === null || publishedconfiguratorId === undefined) {
            throw new Error('Required parameter publishedconfiguratorId was null or undefined when calling summarisePublishedConfigurator.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (includeAttributes !== undefined && includeAttributes !== null) {
            queryParameters = queryParameters.set('include_attributes', <any>includeAttributes);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DocumentSummary>('get',`${this.basePath}/publishedconfigurators/${encodeURIComponent(String(publishedconfiguratorId))}/summary`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get Theme summary
     * @param themeId ID of Theme to check
     * @param includeAttributes DEPRECATED Include information such as number of versions
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public summariseTheme(themeId: string, includeAttributes?: string, observe?: 'body', reportProgress?: boolean): Observable<DocumentSummary>;
    public summariseTheme(themeId: string, includeAttributes?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DocumentSummary>>;
    public summariseTheme(themeId: string, includeAttributes?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DocumentSummary>>;
    public summariseTheme(themeId: string, includeAttributes?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (themeId === null || themeId === undefined) {
            throw new Error('Required parameter themeId was null or undefined when calling summariseTheme.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (includeAttributes !== undefined && includeAttributes !== null) {
            queryParameters = queryParameters.set('include_attributes', <any>includeAttributes);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DocumentSummary>('get',`${this.basePath}/themes/${encodeURIComponent(String(themeId))}/summary`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get WorkBatch summary
     * @param workbatchId ID of WorkBatch to check
     * @param includeAttributes DEPRECATED Include information such as number of versions
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public summariseWorkBatch(workbatchId: string, includeAttributes?: string, observe?: 'body', reportProgress?: boolean): Observable<DocumentSummary>;
    public summariseWorkBatch(workbatchId: string, includeAttributes?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DocumentSummary>>;
    public summariseWorkBatch(workbatchId: string, includeAttributes?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DocumentSummary>>;
    public summariseWorkBatch(workbatchId: string, includeAttributes?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workbatchId === null || workbatchId === undefined) {
            throw new Error('Required parameter workbatchId was null or undefined when calling summariseWorkBatch.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (includeAttributes !== undefined && includeAttributes !== null) {
            queryParameters = queryParameters.set('include_attributes', <any>includeAttributes);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DocumentSummary>('get',`${this.basePath}/workbatches/${encodeURIComponent(String(workbatchId))}/summary`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get Workflow summary
     * @param workflowId ID of Workflow to check
     * @param includeAttributes DEPRECATED Include information such as number of versions
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public summariseWorkflow(workflowId: string, includeAttributes?: string, observe?: 'body', reportProgress?: boolean): Observable<DocumentSummary>;
    public summariseWorkflow(workflowId: string, includeAttributes?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DocumentSummary>>;
    public summariseWorkflow(workflowId: string, includeAttributes?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DocumentSummary>>;
    public summariseWorkflow(workflowId: string, includeAttributes?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workflowId === null || workflowId === undefined) {
            throw new Error('Required parameter workflowId was null or undefined when calling summariseWorkflow.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (includeAttributes !== undefined && includeAttributes !== null) {
            queryParameters = queryParameters.set('include_attributes', <any>includeAttributes);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DocumentSummary>('get',`${this.basePath}/workflows/${encodeURIComponent(String(workflowId))}/summary`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update specific version of a Appearance
     * @param appearanceId ID of Appearance to update
     * @param versionId Version of Appearance to update
     * @param body 
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param updateAllReferences Update affected documents owned by current user
     * @param updateMyReferences Update affected documents owned by any user
     * @param notHead Do not set this version as the head version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateAppearance(appearanceId: string, versionId: string, body?: Appearance, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedAppearancePatch>;
    public updateAppearance(appearanceId: string, versionId: string, body?: Appearance, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedAppearancePatch>>;
    public updateAppearance(appearanceId: string, versionId: string, body?: Appearance, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedAppearancePatch>>;
    public updateAppearance(appearanceId: string, versionId: string, body?: Appearance, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (appearanceId === null || appearanceId === undefined) {
            throw new Error('Required parameter appearanceId was null or undefined when calling updateAppearance.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling updateAppearance.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }
        if (notHead !== undefined && notHead !== null) {
            queryParameters = queryParameters.set('notHead', <any>notHead);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedAppearancePatch>('put',`${this.basePath}/appearances/${encodeURIComponent(String(appearanceId))}/v/${encodeURIComponent(String(versionId))}`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param appearanceId ID of Appearance
     * @param versionId Version of Appearance
     * @param body 
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param updateAllReferences Update affected documents owned by current user
     * @param updateMyReferences Update affected documents owned by any user
     * @param notHead Do not set this version as the head version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateAppearanceDisplacementMapImage(appearanceId: string, versionId: string, body?: Artefact, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedArtefactPatch>;
    public updateAppearanceDisplacementMapImage(appearanceId: string, versionId: string, body?: Artefact, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedArtefactPatch>>;
    public updateAppearanceDisplacementMapImage(appearanceId: string, versionId: string, body?: Artefact, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedArtefactPatch>>;
    public updateAppearanceDisplacementMapImage(appearanceId: string, versionId: string, body?: Artefact, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (appearanceId === null || appearanceId === undefined) {
            throw new Error('Required parameter appearanceId was null or undefined when calling updateAppearanceDisplacementMapImage.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling updateAppearanceDisplacementMapImage.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }
        if (notHead !== undefined && notHead !== null) {
            queryParameters = queryParameters.set('notHead', <any>notHead);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedArtefactPatch>('put',`${this.basePath}/appearances/${encodeURIComponent(String(appearanceId))}/v/${encodeURIComponent(String(versionId))}/displacementMap/image`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update head version of Appearance
     * @param appearanceId ID of Appearance to update
     * @param body 
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param updateAllReferences Update affected documents owned by current user
     * @param updateMyReferences Update affected documents owned by any user
     * @param notHead Do not set this version as the head version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateAppearanceHead(appearanceId: string, body?: Appearance, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedAppearancePatch>;
    public updateAppearanceHead(appearanceId: string, body?: Appearance, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedAppearancePatch>>;
    public updateAppearanceHead(appearanceId: string, body?: Appearance, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedAppearancePatch>>;
    public updateAppearanceHead(appearanceId: string, body?: Appearance, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (appearanceId === null || appearanceId === undefined) {
            throw new Error('Required parameter appearanceId was null or undefined when calling updateAppearanceHead.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }
        if (notHead !== undefined && notHead !== null) {
            queryParameters = queryParameters.set('notHead', <any>notHead);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedAppearancePatch>('put',`${this.basePath}/appearances/${encodeURIComponent(String(appearanceId))}`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param appearanceId ID of Appearance
     * @param body 
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param updateAllReferences Update affected documents owned by current user
     * @param updateMyReferences Update affected documents owned by any user
     * @param notHead Do not set this version as the head version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateAppearanceHeadDisplacementMapImage(appearanceId: string, body?: Artefact, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedArtefactPatch>;
    public updateAppearanceHeadDisplacementMapImage(appearanceId: string, body?: Artefact, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedArtefactPatch>>;
    public updateAppearanceHeadDisplacementMapImage(appearanceId: string, body?: Artefact, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedArtefactPatch>>;
    public updateAppearanceHeadDisplacementMapImage(appearanceId: string, body?: Artefact, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (appearanceId === null || appearanceId === undefined) {
            throw new Error('Required parameter appearanceId was null or undefined when calling updateAppearanceHeadDisplacementMapImage.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }
        if (notHead !== undefined && notHead !== null) {
            queryParameters = queryParameters.set('notHead', <any>notHead);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedArtefactPatch>('put',`${this.basePath}/appearances/${encodeURIComponent(String(appearanceId))}/displacementMap/image`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param appearanceId ID of Appearance
     * @param body 
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param updateAllReferences Update affected documents owned by current user
     * @param updateMyReferences Update affected documents owned by any user
     * @param notHead Do not set this version as the head version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateAppearanceHeadMapImage(appearanceId: string, body?: Artefact, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedArtefactPatch>;
    public updateAppearanceHeadMapImage(appearanceId: string, body?: Artefact, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedArtefactPatch>>;
    public updateAppearanceHeadMapImage(appearanceId: string, body?: Artefact, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedArtefactPatch>>;
    public updateAppearanceHeadMapImage(appearanceId: string, body?: Artefact, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (appearanceId === null || appearanceId === undefined) {
            throw new Error('Required parameter appearanceId was null or undefined when calling updateAppearanceHeadMapImage.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }
        if (notHead !== undefined && notHead !== null) {
            queryParameters = queryParameters.set('notHead', <any>notHead);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedArtefactPatch>('put',`${this.basePath}/appearances/${encodeURIComponent(String(appearanceId))}/map/image`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param appearanceId ID of Appearance
     * @param versionId Version of Appearance
     * @param body 
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param updateAllReferences Update affected documents owned by current user
     * @param updateMyReferences Update affected documents owned by any user
     * @param notHead Do not set this version as the head version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateAppearanceMapImage(appearanceId: string, versionId: string, body?: Artefact, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedArtefactPatch>;
    public updateAppearanceMapImage(appearanceId: string, versionId: string, body?: Artefact, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedArtefactPatch>>;
    public updateAppearanceMapImage(appearanceId: string, versionId: string, body?: Artefact, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedArtefactPatch>>;
    public updateAppearanceMapImage(appearanceId: string, versionId: string, body?: Artefact, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (appearanceId === null || appearanceId === undefined) {
            throw new Error('Required parameter appearanceId was null or undefined when calling updateAppearanceMapImage.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling updateAppearanceMapImage.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }
        if (notHead !== undefined && notHead !== null) {
            queryParameters = queryParameters.set('notHead', <any>notHead);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedArtefactPatch>('put',`${this.basePath}/appearances/${encodeURIComponent(String(appearanceId))}/v/${encodeURIComponent(String(versionId))}/map/image`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update sharing details of specified Appearance
     * @param appearanceId ID of Appearance to update
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateAppearanceSharing(appearanceId: string, body?: Sharing, observe?: 'body', reportProgress?: boolean): Observable<Sharing>;
    public updateAppearanceSharing(appearanceId: string, body?: Sharing, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Sharing>>;
    public updateAppearanceSharing(appearanceId: string, body?: Sharing, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Sharing>>;
    public updateAppearanceSharing(appearanceId: string, body?: Sharing, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (appearanceId === null || appearanceId === undefined) {
            throw new Error('Required parameter appearanceId was null or undefined when calling updateAppearanceSharing.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Sharing>('put',`${this.basePath}/appearances/${encodeURIComponent(String(appearanceId))}/sharing`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update version summary of specified Appearance
     * @param appearanceId ID of Appearance to return
     * @param versionId Version to return
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateAppearanceVersionSummary(appearanceId: string, versionId: string, body?: VersionLogVersion, observe?: 'body', reportProgress?: boolean): Observable<VersionLogVersion>;
    public updateAppearanceVersionSummary(appearanceId: string, versionId: string, body?: VersionLogVersion, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLogVersion>>;
    public updateAppearanceVersionSummary(appearanceId: string, versionId: string, body?: VersionLogVersion, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLogVersion>>;
    public updateAppearanceVersionSummary(appearanceId: string, versionId: string, body?: VersionLogVersion, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (appearanceId === null || appearanceId === undefined) {
            throw new Error('Required parameter appearanceId was null or undefined when calling updateAppearanceVersionSummary.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling updateAppearanceVersionSummary.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionLogVersion>('put',`${this.basePath}/appearances/${encodeURIComponent(String(appearanceId))}/log/${encodeURIComponent(String(versionId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param companyId ID of Company to return
     * @param versionId Version of Company
     * @param extension File extension requested
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateAvatarContentMultipartForm(companyId: string, versionId: string, extension: string, file?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updateAvatarContentMultipartForm(companyId: string, versionId: string, extension: string, file?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updateAvatarContentMultipartForm(companyId: string, versionId: string, extension: string, file?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updateAvatarContentMultipartForm(companyId: string, versionId: string, extension: string, file?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling updateAvatarContentMultipart.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling updateAvatarContentMultipart.');
        }

        if (extension === null || extension === undefined) {
            throw new Error('Required parameter extension was null or undefined when calling updateAvatarContentMultipart.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }

        return this.httpClient.request<any>('put',`${this.basePath}/companies/${encodeURIComponent(String(companyId))}/v/${encodeURIComponent(String(versionId))}/avatar.${encodeURIComponent(String(extension))}`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update specific version of a Company
     * @param companyId ID of Company to update
     * @param versionId Version of Company to update
     * @param body 
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param updateAllReferences Update affected documents owned by current user
     * @param updateMyReferences Update affected documents owned by any user
     * @param notHead Do not set this version as the head version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateCompany(companyId: string, versionId: string, body?: Company, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedCompanyPatch>;
    public updateCompany(companyId: string, versionId: string, body?: Company, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedCompanyPatch>>;
    public updateCompany(companyId: string, versionId: string, body?: Company, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedCompanyPatch>>;
    public updateCompany(companyId: string, versionId: string, body?: Company, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling updateCompany.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling updateCompany.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }
        if (notHead !== undefined && notHead !== null) {
            queryParameters = queryParameters.set('notHead', <any>notHead);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedCompanyPatch>('put',`${this.basePath}/companies/${encodeURIComponent(String(companyId))}/v/${encodeURIComponent(String(versionId))}`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param companyId ID of Company
     * @param versionId Version of Company
     * @param body 
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param updateAllReferences Update affected documents owned by current user
     * @param updateMyReferences Update affected documents owned by any user
     * @param notHead Do not set this version as the head version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateCompanyAvatar(companyId: string, versionId: string, body?: Artefact, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedArtefactPatch>;
    public updateCompanyAvatar(companyId: string, versionId: string, body?: Artefact, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedArtefactPatch>>;
    public updateCompanyAvatar(companyId: string, versionId: string, body?: Artefact, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedArtefactPatch>>;
    public updateCompanyAvatar(companyId: string, versionId: string, body?: Artefact, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling updateCompanyAvatar.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling updateCompanyAvatar.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }
        if (notHead !== undefined && notHead !== null) {
            queryParameters = queryParameters.set('notHead', <any>notHead);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedArtefactPatch>('put',`${this.basePath}/companies/${encodeURIComponent(String(companyId))}/v/${encodeURIComponent(String(versionId))}/avatar`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update head version of Company
     * @param companyId ID of Company to update
     * @param body 
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param updateAllReferences Update affected documents owned by current user
     * @param updateMyReferences Update affected documents owned by any user
     * @param notHead Do not set this version as the head version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateCompanyHead(companyId: string, body?: Company, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedCompanyPatch>;
    public updateCompanyHead(companyId: string, body?: Company, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedCompanyPatch>>;
    public updateCompanyHead(companyId: string, body?: Company, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedCompanyPatch>>;
    public updateCompanyHead(companyId: string, body?: Company, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling updateCompanyHead.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }
        if (notHead !== undefined && notHead !== null) {
            queryParameters = queryParameters.set('notHead', <any>notHead);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedCompanyPatch>('put',`${this.basePath}/companies/${encodeURIComponent(String(companyId))}`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param companyId ID of Company
     * @param body 
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param updateAllReferences Update affected documents owned by current user
     * @param updateMyReferences Update affected documents owned by any user
     * @param notHead Do not set this version as the head version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateCompanyHeadAvatar(companyId: string, body?: Artefact, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedArtefactPatch>;
    public updateCompanyHeadAvatar(companyId: string, body?: Artefact, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedArtefactPatch>>;
    public updateCompanyHeadAvatar(companyId: string, body?: Artefact, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedArtefactPatch>>;
    public updateCompanyHeadAvatar(companyId: string, body?: Artefact, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling updateCompanyHeadAvatar.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }
        if (notHead !== undefined && notHead !== null) {
            queryParameters = queryParameters.set('notHead', <any>notHead);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedArtefactPatch>('put',`${this.basePath}/companies/${encodeURIComponent(String(companyId))}/avatar`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update sharing details of specified Company
     * @param companyId ID of Company to update
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateCompanySharing(companyId: string, body?: Sharing, observe?: 'body', reportProgress?: boolean): Observable<Sharing>;
    public updateCompanySharing(companyId: string, body?: Sharing, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Sharing>>;
    public updateCompanySharing(companyId: string, body?: Sharing, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Sharing>>;
    public updateCompanySharing(companyId: string, body?: Sharing, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling updateCompanySharing.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Sharing>('put',`${this.basePath}/companies/${encodeURIComponent(String(companyId))}/sharing`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update version summary of specified Company
     * @param companyId ID of Company to return
     * @param versionId Version to return
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateCompanyVersionSummary(companyId: string, versionId: string, body?: VersionLogVersion, observe?: 'body', reportProgress?: boolean): Observable<VersionLogVersion>;
    public updateCompanyVersionSummary(companyId: string, versionId: string, body?: VersionLogVersion, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLogVersion>>;
    public updateCompanyVersionSummary(companyId: string, versionId: string, body?: VersionLogVersion, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLogVersion>>;
    public updateCompanyVersionSummary(companyId: string, versionId: string, body?: VersionLogVersion, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling updateCompanyVersionSummary.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling updateCompanyVersionSummary.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionLogVersion>('put',`${this.basePath}/companies/${encodeURIComponent(String(companyId))}/log/${encodeURIComponent(String(versionId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update specific version of a ConnectorType
     * @param connectortypeId ID of ConnectorType to update
     * @param versionId Version of ConnectorType to update
     * @param body 
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param updateAllReferences Update affected documents owned by current user
     * @param updateMyReferences Update affected documents owned by any user
     * @param notHead Do not set this version as the head version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateConnectorType(connectortypeId: string, versionId: string, body?: ConnectorType, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedConnectorTypePatch>;
    public updateConnectorType(connectortypeId: string, versionId: string, body?: ConnectorType, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedConnectorTypePatch>>;
    public updateConnectorType(connectortypeId: string, versionId: string, body?: ConnectorType, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedConnectorTypePatch>>;
    public updateConnectorType(connectortypeId: string, versionId: string, body?: ConnectorType, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (connectortypeId === null || connectortypeId === undefined) {
            throw new Error('Required parameter connectortypeId was null or undefined when calling updateConnectorType.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling updateConnectorType.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }
        if (notHead !== undefined && notHead !== null) {
            queryParameters = queryParameters.set('notHead', <any>notHead);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedConnectorTypePatch>('put',`${this.basePath}/connectortypes/${encodeURIComponent(String(connectortypeId))}/v/${encodeURIComponent(String(versionId))}`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param connectortypeId ID of ConnectorType
     * @param versionId Version of ConnectorType
     * @param connectorsId 
     * @param partsId 
     * @param body 
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param updateAllReferences Update affected documents owned by current user
     * @param updateMyReferences Update affected documents owned by any user
     * @param notHead Do not set this version as the head version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateConnectorTypeConnectorsSourceAssemblyPartsGeometry(connectortypeId: string, versionId: string, connectorsId: string, partsId: string, body?: PartGeometry, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedPartGeometryPatch>;
    public updateConnectorTypeConnectorsSourceAssemblyPartsGeometry(connectortypeId: string, versionId: string, connectorsId: string, partsId: string, body?: PartGeometry, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedPartGeometryPatch>>;
    public updateConnectorTypeConnectorsSourceAssemblyPartsGeometry(connectortypeId: string, versionId: string, connectorsId: string, partsId: string, body?: PartGeometry, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedPartGeometryPatch>>;
    public updateConnectorTypeConnectorsSourceAssemblyPartsGeometry(connectortypeId: string, versionId: string, connectorsId: string, partsId: string, body?: PartGeometry, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (connectortypeId === null || connectortypeId === undefined) {
            throw new Error('Required parameter connectortypeId was null or undefined when calling updateConnectorTypeConnectorsSourceAssemblyPartsGeometry.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling updateConnectorTypeConnectorsSourceAssemblyPartsGeometry.');
        }

        if (connectorsId === null || connectorsId === undefined) {
            throw new Error('Required parameter connectorsId was null or undefined when calling updateConnectorTypeConnectorsSourceAssemblyPartsGeometry.');
        }

        if (partsId === null || partsId === undefined) {
            throw new Error('Required parameter partsId was null or undefined when calling updateConnectorTypeConnectorsSourceAssemblyPartsGeometry.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }
        if (notHead !== undefined && notHead !== null) {
            queryParameters = queryParameters.set('notHead', <any>notHead);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedPartGeometryPatch>('put',`${this.basePath}/connectortypes/${encodeURIComponent(String(connectortypeId))}/v/${encodeURIComponent(String(versionId))}/connectors/${encodeURIComponent(String(connectorsId))}/source/assembly/parts/${encodeURIComponent(String(partsId))}/geometry`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param connectortypeId ID of ConnectorType
     * @param versionId Version of ConnectorType
     * @param connectorsId 
     * @param subassembliesId 
     * @param partsId 
     * @param body 
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param updateAllReferences Update affected documents owned by current user
     * @param updateMyReferences Update affected documents owned by any user
     * @param notHead Do not set this version as the head version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateConnectorTypeConnectorsSourceAssemblySubassembliesPartsGeometry(connectortypeId: string, versionId: string, connectorsId: string, subassembliesId: string, partsId: string, body?: PartGeometry, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedPartGeometryPatch>;
    public updateConnectorTypeConnectorsSourceAssemblySubassembliesPartsGeometry(connectortypeId: string, versionId: string, connectorsId: string, subassembliesId: string, partsId: string, body?: PartGeometry, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedPartGeometryPatch>>;
    public updateConnectorTypeConnectorsSourceAssemblySubassembliesPartsGeometry(connectortypeId: string, versionId: string, connectorsId: string, subassembliesId: string, partsId: string, body?: PartGeometry, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedPartGeometryPatch>>;
    public updateConnectorTypeConnectorsSourceAssemblySubassembliesPartsGeometry(connectortypeId: string, versionId: string, connectorsId: string, subassembliesId: string, partsId: string, body?: PartGeometry, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (connectortypeId === null || connectortypeId === undefined) {
            throw new Error('Required parameter connectortypeId was null or undefined when calling updateConnectorTypeConnectorsSourceAssemblySubassembliesPartsGeometry.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling updateConnectorTypeConnectorsSourceAssemblySubassembliesPartsGeometry.');
        }

        if (connectorsId === null || connectorsId === undefined) {
            throw new Error('Required parameter connectorsId was null or undefined when calling updateConnectorTypeConnectorsSourceAssemblySubassembliesPartsGeometry.');
        }

        if (subassembliesId === null || subassembliesId === undefined) {
            throw new Error('Required parameter subassembliesId was null or undefined when calling updateConnectorTypeConnectorsSourceAssemblySubassembliesPartsGeometry.');
        }

        if (partsId === null || partsId === undefined) {
            throw new Error('Required parameter partsId was null or undefined when calling updateConnectorTypeConnectorsSourceAssemblySubassembliesPartsGeometry.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }
        if (notHead !== undefined && notHead !== null) {
            queryParameters = queryParameters.set('notHead', <any>notHead);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedPartGeometryPatch>('put',`${this.basePath}/connectortypes/${encodeURIComponent(String(connectortypeId))}/v/${encodeURIComponent(String(versionId))}/connectors/${encodeURIComponent(String(connectorsId))}/source/assembly/subassemblies/${encodeURIComponent(String(subassembliesId))}/parts/${encodeURIComponent(String(partsId))}/geometry`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param connectortypeId ID of ConnectorType
     * @param versionId Version of ConnectorType
     * @param connectorsId 
     * @param partsId 
     * @param body 
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param updateAllReferences Update affected documents owned by current user
     * @param updateMyReferences Update affected documents owned by any user
     * @param notHead Do not set this version as the head version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateConnectorTypeConnectorsSourceFileAssemblyPartsGeometry(connectortypeId: string, versionId: string, connectorsId: string, partsId: string, body?: PartGeometry, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedPartGeometryPatch>;
    public updateConnectorTypeConnectorsSourceFileAssemblyPartsGeometry(connectortypeId: string, versionId: string, connectorsId: string, partsId: string, body?: PartGeometry, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedPartGeometryPatch>>;
    public updateConnectorTypeConnectorsSourceFileAssemblyPartsGeometry(connectortypeId: string, versionId: string, connectorsId: string, partsId: string, body?: PartGeometry, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedPartGeometryPatch>>;
    public updateConnectorTypeConnectorsSourceFileAssemblyPartsGeometry(connectortypeId: string, versionId: string, connectorsId: string, partsId: string, body?: PartGeometry, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (connectortypeId === null || connectortypeId === undefined) {
            throw new Error('Required parameter connectortypeId was null or undefined when calling updateConnectorTypeConnectorsSourceFileAssemblyPartsGeometry.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling updateConnectorTypeConnectorsSourceFileAssemblyPartsGeometry.');
        }

        if (connectorsId === null || connectorsId === undefined) {
            throw new Error('Required parameter connectorsId was null or undefined when calling updateConnectorTypeConnectorsSourceFileAssemblyPartsGeometry.');
        }

        if (partsId === null || partsId === undefined) {
            throw new Error('Required parameter partsId was null or undefined when calling updateConnectorTypeConnectorsSourceFileAssemblyPartsGeometry.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }
        if (notHead !== undefined && notHead !== null) {
            queryParameters = queryParameters.set('notHead', <any>notHead);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedPartGeometryPatch>('put',`${this.basePath}/connectortypes/${encodeURIComponent(String(connectortypeId))}/v/${encodeURIComponent(String(versionId))}/connectors/${encodeURIComponent(String(connectorsId))}/source/file/assembly/parts/${encodeURIComponent(String(partsId))}/geometry`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param connectortypeId ID of ConnectorType
     * @param versionId Version of ConnectorType
     * @param connectorsId 
     * @param subassembliesId 
     * @param partsId 
     * @param body 
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param updateAllReferences Update affected documents owned by current user
     * @param updateMyReferences Update affected documents owned by any user
     * @param notHead Do not set this version as the head version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateConnectorTypeConnectorsSourceFileAssemblySubassembliesPartsGeometry(connectortypeId: string, versionId: string, connectorsId: string, subassembliesId: string, partsId: string, body?: PartGeometry, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedPartGeometryPatch>;
    public updateConnectorTypeConnectorsSourceFileAssemblySubassembliesPartsGeometry(connectortypeId: string, versionId: string, connectorsId: string, subassembliesId: string, partsId: string, body?: PartGeometry, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedPartGeometryPatch>>;
    public updateConnectorTypeConnectorsSourceFileAssemblySubassembliesPartsGeometry(connectortypeId: string, versionId: string, connectorsId: string, subassembliesId: string, partsId: string, body?: PartGeometry, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedPartGeometryPatch>>;
    public updateConnectorTypeConnectorsSourceFileAssemblySubassembliesPartsGeometry(connectortypeId: string, versionId: string, connectorsId: string, subassembliesId: string, partsId: string, body?: PartGeometry, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (connectortypeId === null || connectortypeId === undefined) {
            throw new Error('Required parameter connectortypeId was null or undefined when calling updateConnectorTypeConnectorsSourceFileAssemblySubassembliesPartsGeometry.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling updateConnectorTypeConnectorsSourceFileAssemblySubassembliesPartsGeometry.');
        }

        if (connectorsId === null || connectorsId === undefined) {
            throw new Error('Required parameter connectorsId was null or undefined when calling updateConnectorTypeConnectorsSourceFileAssemblySubassembliesPartsGeometry.');
        }

        if (subassembliesId === null || subassembliesId === undefined) {
            throw new Error('Required parameter subassembliesId was null or undefined when calling updateConnectorTypeConnectorsSourceFileAssemblySubassembliesPartsGeometry.');
        }

        if (partsId === null || partsId === undefined) {
            throw new Error('Required parameter partsId was null or undefined when calling updateConnectorTypeConnectorsSourceFileAssemblySubassembliesPartsGeometry.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }
        if (notHead !== undefined && notHead !== null) {
            queryParameters = queryParameters.set('notHead', <any>notHead);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedPartGeometryPatch>('put',`${this.basePath}/connectortypes/${encodeURIComponent(String(connectortypeId))}/v/${encodeURIComponent(String(versionId))}/connectors/${encodeURIComponent(String(connectorsId))}/source/file/assembly/subassemblies/${encodeURIComponent(String(subassembliesId))}/parts/${encodeURIComponent(String(partsId))}/geometry`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param connectortypeId ID of ConnectorType
     * @param versionId Version of ConnectorType
     * @param connectorsId 
     * @param body 
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param updateAllReferences Update affected documents owned by current user
     * @param updateMyReferences Update affected documents owned by any user
     * @param notHead Do not set this version as the head version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateConnectorTypeConnectorsSourceFileFile(connectortypeId: string, versionId: string, connectorsId: string, body?: Artefact, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedArtefactPatch>;
    public updateConnectorTypeConnectorsSourceFileFile(connectortypeId: string, versionId: string, connectorsId: string, body?: Artefact, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedArtefactPatch>>;
    public updateConnectorTypeConnectorsSourceFileFile(connectortypeId: string, versionId: string, connectorsId: string, body?: Artefact, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedArtefactPatch>>;
    public updateConnectorTypeConnectorsSourceFileFile(connectortypeId: string, versionId: string, connectorsId: string, body?: Artefact, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (connectortypeId === null || connectortypeId === undefined) {
            throw new Error('Required parameter connectortypeId was null or undefined when calling updateConnectorTypeConnectorsSourceFileFile.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling updateConnectorTypeConnectorsSourceFileFile.');
        }

        if (connectorsId === null || connectorsId === undefined) {
            throw new Error('Required parameter connectorsId was null or undefined when calling updateConnectorTypeConnectorsSourceFileFile.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }
        if (notHead !== undefined && notHead !== null) {
            queryParameters = queryParameters.set('notHead', <any>notHead);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedArtefactPatch>('put',`${this.basePath}/connectortypes/${encodeURIComponent(String(connectortypeId))}/v/${encodeURIComponent(String(versionId))}/connectors/${encodeURIComponent(String(connectorsId))}/source/file/file`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param connectortypeId ID of ConnectorType
     * @param versionId Version of ConnectorType
     * @param connectorsId 
     * @param body 
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param updateAllReferences Update affected documents owned by current user
     * @param updateMyReferences Update affected documents owned by any user
     * @param notHead Do not set this version as the head version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateConnectorTypeConnectorsSourceImage(connectortypeId: string, versionId: string, connectorsId: string, body?: Artefact, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedArtefactPatch>;
    public updateConnectorTypeConnectorsSourceImage(connectortypeId: string, versionId: string, connectorsId: string, body?: Artefact, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedArtefactPatch>>;
    public updateConnectorTypeConnectorsSourceImage(connectortypeId: string, versionId: string, connectorsId: string, body?: Artefact, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedArtefactPatch>>;
    public updateConnectorTypeConnectorsSourceImage(connectortypeId: string, versionId: string, connectorsId: string, body?: Artefact, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (connectortypeId === null || connectortypeId === undefined) {
            throw new Error('Required parameter connectortypeId was null or undefined when calling updateConnectorTypeConnectorsSourceImage.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling updateConnectorTypeConnectorsSourceImage.');
        }

        if (connectorsId === null || connectorsId === undefined) {
            throw new Error('Required parameter connectorsId was null or undefined when calling updateConnectorTypeConnectorsSourceImage.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }
        if (notHead !== undefined && notHead !== null) {
            queryParameters = queryParameters.set('notHead', <any>notHead);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedArtefactPatch>('put',`${this.basePath}/connectortypes/${encodeURIComponent(String(connectortypeId))}/v/${encodeURIComponent(String(versionId))}/connectors/${encodeURIComponent(String(connectorsId))}/source/image`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param connectortypeId ID of ConnectorType
     * @param versionId Version of ConnectorType
     * @param connectorsId 
     * @param body 
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param updateAllReferences Update affected documents owned by current user
     * @param updateMyReferences Update affected documents owned by any user
     * @param notHead Do not set this version as the head version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateConnectorTypeConnectorsSourceMaterial(connectortypeId: string, versionId: string, connectorsId: string, body?: MaterialStyling, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedMaterialStylingPatch>;
    public updateConnectorTypeConnectorsSourceMaterial(connectortypeId: string, versionId: string, connectorsId: string, body?: MaterialStyling, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedMaterialStylingPatch>>;
    public updateConnectorTypeConnectorsSourceMaterial(connectortypeId: string, versionId: string, connectorsId: string, body?: MaterialStyling, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedMaterialStylingPatch>>;
    public updateConnectorTypeConnectorsSourceMaterial(connectortypeId: string, versionId: string, connectorsId: string, body?: MaterialStyling, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (connectortypeId === null || connectortypeId === undefined) {
            throw new Error('Required parameter connectortypeId was null or undefined when calling updateConnectorTypeConnectorsSourceMaterial.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling updateConnectorTypeConnectorsSourceMaterial.');
        }

        if (connectorsId === null || connectorsId === undefined) {
            throw new Error('Required parameter connectorsId was null or undefined when calling updateConnectorTypeConnectorsSourceMaterial.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }
        if (notHead !== undefined && notHead !== null) {
            queryParameters = queryParameters.set('notHead', <any>notHead);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedMaterialStylingPatch>('put',`${this.basePath}/connectortypes/${encodeURIComponent(String(connectortypeId))}/v/${encodeURIComponent(String(versionId))}/connectors/${encodeURIComponent(String(connectorsId))}/source/material`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update head version of ConnectorType
     * @param connectortypeId ID of ConnectorType to update
     * @param body 
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param updateAllReferences Update affected documents owned by current user
     * @param updateMyReferences Update affected documents owned by any user
     * @param notHead Do not set this version as the head version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateConnectorTypeHead(connectortypeId: string, body?: ConnectorType, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedConnectorTypePatch>;
    public updateConnectorTypeHead(connectortypeId: string, body?: ConnectorType, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedConnectorTypePatch>>;
    public updateConnectorTypeHead(connectortypeId: string, body?: ConnectorType, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedConnectorTypePatch>>;
    public updateConnectorTypeHead(connectortypeId: string, body?: ConnectorType, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (connectortypeId === null || connectortypeId === undefined) {
            throw new Error('Required parameter connectortypeId was null or undefined when calling updateConnectorTypeHead.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }
        if (notHead !== undefined && notHead !== null) {
            queryParameters = queryParameters.set('notHead', <any>notHead);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedConnectorTypePatch>('put',`${this.basePath}/connectortypes/${encodeURIComponent(String(connectortypeId))}`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param connectortypeId ID of ConnectorType
     * @param connectorsId 
     * @param partsId 
     * @param body 
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param updateAllReferences Update affected documents owned by current user
     * @param updateMyReferences Update affected documents owned by any user
     * @param notHead Do not set this version as the head version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateConnectorTypeHeadConnectorsSourceAssemblyPartsGeometry(connectortypeId: string, connectorsId: string, partsId: string, body?: PartGeometry, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedPartGeometryPatch>;
    public updateConnectorTypeHeadConnectorsSourceAssemblyPartsGeometry(connectortypeId: string, connectorsId: string, partsId: string, body?: PartGeometry, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedPartGeometryPatch>>;
    public updateConnectorTypeHeadConnectorsSourceAssemblyPartsGeometry(connectortypeId: string, connectorsId: string, partsId: string, body?: PartGeometry, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedPartGeometryPatch>>;
    public updateConnectorTypeHeadConnectorsSourceAssemblyPartsGeometry(connectortypeId: string, connectorsId: string, partsId: string, body?: PartGeometry, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (connectortypeId === null || connectortypeId === undefined) {
            throw new Error('Required parameter connectortypeId was null or undefined when calling updateConnectorTypeHeadConnectorsSourceAssemblyPartsGeometry.');
        }

        if (connectorsId === null || connectorsId === undefined) {
            throw new Error('Required parameter connectorsId was null or undefined when calling updateConnectorTypeHeadConnectorsSourceAssemblyPartsGeometry.');
        }

        if (partsId === null || partsId === undefined) {
            throw new Error('Required parameter partsId was null or undefined when calling updateConnectorTypeHeadConnectorsSourceAssemblyPartsGeometry.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }
        if (notHead !== undefined && notHead !== null) {
            queryParameters = queryParameters.set('notHead', <any>notHead);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedPartGeometryPatch>('put',`${this.basePath}/connectortypes/${encodeURIComponent(String(connectortypeId))}/connectors/${encodeURIComponent(String(connectorsId))}/source/assembly/parts/${encodeURIComponent(String(partsId))}/geometry`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param connectortypeId ID of ConnectorType
     * @param connectorsId 
     * @param subassembliesId 
     * @param partsId 
     * @param body 
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param updateAllReferences Update affected documents owned by current user
     * @param updateMyReferences Update affected documents owned by any user
     * @param notHead Do not set this version as the head version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateConnectorTypeHeadConnectorsSourceAssemblySubassembliesPartsGeometry(connectortypeId: string, connectorsId: string, subassembliesId: string, partsId: string, body?: PartGeometry, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedPartGeometryPatch>;
    public updateConnectorTypeHeadConnectorsSourceAssemblySubassembliesPartsGeometry(connectortypeId: string, connectorsId: string, subassembliesId: string, partsId: string, body?: PartGeometry, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedPartGeometryPatch>>;
    public updateConnectorTypeHeadConnectorsSourceAssemblySubassembliesPartsGeometry(connectortypeId: string, connectorsId: string, subassembliesId: string, partsId: string, body?: PartGeometry, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedPartGeometryPatch>>;
    public updateConnectorTypeHeadConnectorsSourceAssemblySubassembliesPartsGeometry(connectortypeId: string, connectorsId: string, subassembliesId: string, partsId: string, body?: PartGeometry, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (connectortypeId === null || connectortypeId === undefined) {
            throw new Error('Required parameter connectortypeId was null or undefined when calling updateConnectorTypeHeadConnectorsSourceAssemblySubassembliesPartsGeometry.');
        }

        if (connectorsId === null || connectorsId === undefined) {
            throw new Error('Required parameter connectorsId was null or undefined when calling updateConnectorTypeHeadConnectorsSourceAssemblySubassembliesPartsGeometry.');
        }

        if (subassembliesId === null || subassembliesId === undefined) {
            throw new Error('Required parameter subassembliesId was null or undefined when calling updateConnectorTypeHeadConnectorsSourceAssemblySubassembliesPartsGeometry.');
        }

        if (partsId === null || partsId === undefined) {
            throw new Error('Required parameter partsId was null or undefined when calling updateConnectorTypeHeadConnectorsSourceAssemblySubassembliesPartsGeometry.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }
        if (notHead !== undefined && notHead !== null) {
            queryParameters = queryParameters.set('notHead', <any>notHead);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedPartGeometryPatch>('put',`${this.basePath}/connectortypes/${encodeURIComponent(String(connectortypeId))}/connectors/${encodeURIComponent(String(connectorsId))}/source/assembly/subassemblies/${encodeURIComponent(String(subassembliesId))}/parts/${encodeURIComponent(String(partsId))}/geometry`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param connectortypeId ID of ConnectorType
     * @param connectorsId 
     * @param partsId 
     * @param body 
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param updateAllReferences Update affected documents owned by current user
     * @param updateMyReferences Update affected documents owned by any user
     * @param notHead Do not set this version as the head version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateConnectorTypeHeadConnectorsSourceFileAssemblyPartsGeometry(connectortypeId: string, connectorsId: string, partsId: string, body?: PartGeometry, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedPartGeometryPatch>;
    public updateConnectorTypeHeadConnectorsSourceFileAssemblyPartsGeometry(connectortypeId: string, connectorsId: string, partsId: string, body?: PartGeometry, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedPartGeometryPatch>>;
    public updateConnectorTypeHeadConnectorsSourceFileAssemblyPartsGeometry(connectortypeId: string, connectorsId: string, partsId: string, body?: PartGeometry, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedPartGeometryPatch>>;
    public updateConnectorTypeHeadConnectorsSourceFileAssemblyPartsGeometry(connectortypeId: string, connectorsId: string, partsId: string, body?: PartGeometry, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (connectortypeId === null || connectortypeId === undefined) {
            throw new Error('Required parameter connectortypeId was null or undefined when calling updateConnectorTypeHeadConnectorsSourceFileAssemblyPartsGeometry.');
        }

        if (connectorsId === null || connectorsId === undefined) {
            throw new Error('Required parameter connectorsId was null or undefined when calling updateConnectorTypeHeadConnectorsSourceFileAssemblyPartsGeometry.');
        }

        if (partsId === null || partsId === undefined) {
            throw new Error('Required parameter partsId was null or undefined when calling updateConnectorTypeHeadConnectorsSourceFileAssemblyPartsGeometry.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }
        if (notHead !== undefined && notHead !== null) {
            queryParameters = queryParameters.set('notHead', <any>notHead);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedPartGeometryPatch>('put',`${this.basePath}/connectortypes/${encodeURIComponent(String(connectortypeId))}/connectors/${encodeURIComponent(String(connectorsId))}/source/file/assembly/parts/${encodeURIComponent(String(partsId))}/geometry`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param connectortypeId ID of ConnectorType
     * @param connectorsId 
     * @param subassembliesId 
     * @param partsId 
     * @param body 
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param updateAllReferences Update affected documents owned by current user
     * @param updateMyReferences Update affected documents owned by any user
     * @param notHead Do not set this version as the head version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateConnectorTypeHeadConnectorsSourceFileAssemblySubassembliesPartsGeometry(connectortypeId: string, connectorsId: string, subassembliesId: string, partsId: string, body?: PartGeometry, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedPartGeometryPatch>;
    public updateConnectorTypeHeadConnectorsSourceFileAssemblySubassembliesPartsGeometry(connectortypeId: string, connectorsId: string, subassembliesId: string, partsId: string, body?: PartGeometry, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedPartGeometryPatch>>;
    public updateConnectorTypeHeadConnectorsSourceFileAssemblySubassembliesPartsGeometry(connectortypeId: string, connectorsId: string, subassembliesId: string, partsId: string, body?: PartGeometry, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedPartGeometryPatch>>;
    public updateConnectorTypeHeadConnectorsSourceFileAssemblySubassembliesPartsGeometry(connectortypeId: string, connectorsId: string, subassembliesId: string, partsId: string, body?: PartGeometry, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (connectortypeId === null || connectortypeId === undefined) {
            throw new Error('Required parameter connectortypeId was null or undefined when calling updateConnectorTypeHeadConnectorsSourceFileAssemblySubassembliesPartsGeometry.');
        }

        if (connectorsId === null || connectorsId === undefined) {
            throw new Error('Required parameter connectorsId was null or undefined when calling updateConnectorTypeHeadConnectorsSourceFileAssemblySubassembliesPartsGeometry.');
        }

        if (subassembliesId === null || subassembliesId === undefined) {
            throw new Error('Required parameter subassembliesId was null or undefined when calling updateConnectorTypeHeadConnectorsSourceFileAssemblySubassembliesPartsGeometry.');
        }

        if (partsId === null || partsId === undefined) {
            throw new Error('Required parameter partsId was null or undefined when calling updateConnectorTypeHeadConnectorsSourceFileAssemblySubassembliesPartsGeometry.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }
        if (notHead !== undefined && notHead !== null) {
            queryParameters = queryParameters.set('notHead', <any>notHead);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedPartGeometryPatch>('put',`${this.basePath}/connectortypes/${encodeURIComponent(String(connectortypeId))}/connectors/${encodeURIComponent(String(connectorsId))}/source/file/assembly/subassemblies/${encodeURIComponent(String(subassembliesId))}/parts/${encodeURIComponent(String(partsId))}/geometry`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param connectortypeId ID of ConnectorType
     * @param connectorsId 
     * @param body 
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param updateAllReferences Update affected documents owned by current user
     * @param updateMyReferences Update affected documents owned by any user
     * @param notHead Do not set this version as the head version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateConnectorTypeHeadConnectorsSourceFileFile(connectortypeId: string, connectorsId: string, body?: Artefact, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedArtefactPatch>;
    public updateConnectorTypeHeadConnectorsSourceFileFile(connectortypeId: string, connectorsId: string, body?: Artefact, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedArtefactPatch>>;
    public updateConnectorTypeHeadConnectorsSourceFileFile(connectortypeId: string, connectorsId: string, body?: Artefact, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedArtefactPatch>>;
    public updateConnectorTypeHeadConnectorsSourceFileFile(connectortypeId: string, connectorsId: string, body?: Artefact, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (connectortypeId === null || connectortypeId === undefined) {
            throw new Error('Required parameter connectortypeId was null or undefined when calling updateConnectorTypeHeadConnectorsSourceFileFile.');
        }

        if (connectorsId === null || connectorsId === undefined) {
            throw new Error('Required parameter connectorsId was null or undefined when calling updateConnectorTypeHeadConnectorsSourceFileFile.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }
        if (notHead !== undefined && notHead !== null) {
            queryParameters = queryParameters.set('notHead', <any>notHead);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedArtefactPatch>('put',`${this.basePath}/connectortypes/${encodeURIComponent(String(connectortypeId))}/connectors/${encodeURIComponent(String(connectorsId))}/source/file/file`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param connectortypeId ID of ConnectorType
     * @param connectorsId 
     * @param body 
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param updateAllReferences Update affected documents owned by current user
     * @param updateMyReferences Update affected documents owned by any user
     * @param notHead Do not set this version as the head version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateConnectorTypeHeadConnectorsSourceImage(connectortypeId: string, connectorsId: string, body?: Artefact, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedArtefactPatch>;
    public updateConnectorTypeHeadConnectorsSourceImage(connectortypeId: string, connectorsId: string, body?: Artefact, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedArtefactPatch>>;
    public updateConnectorTypeHeadConnectorsSourceImage(connectortypeId: string, connectorsId: string, body?: Artefact, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedArtefactPatch>>;
    public updateConnectorTypeHeadConnectorsSourceImage(connectortypeId: string, connectorsId: string, body?: Artefact, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (connectortypeId === null || connectortypeId === undefined) {
            throw new Error('Required parameter connectortypeId was null or undefined when calling updateConnectorTypeHeadConnectorsSourceImage.');
        }

        if (connectorsId === null || connectorsId === undefined) {
            throw new Error('Required parameter connectorsId was null or undefined when calling updateConnectorTypeHeadConnectorsSourceImage.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }
        if (notHead !== undefined && notHead !== null) {
            queryParameters = queryParameters.set('notHead', <any>notHead);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedArtefactPatch>('put',`${this.basePath}/connectortypes/${encodeURIComponent(String(connectortypeId))}/connectors/${encodeURIComponent(String(connectorsId))}/source/image`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param connectortypeId ID of ConnectorType
     * @param connectorsId 
     * @param body 
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param updateAllReferences Update affected documents owned by current user
     * @param updateMyReferences Update affected documents owned by any user
     * @param notHead Do not set this version as the head version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateConnectorTypeHeadConnectorsSourceMaterial(connectortypeId: string, connectorsId: string, body?: MaterialStyling, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedMaterialStylingPatch>;
    public updateConnectorTypeHeadConnectorsSourceMaterial(connectortypeId: string, connectorsId: string, body?: MaterialStyling, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedMaterialStylingPatch>>;
    public updateConnectorTypeHeadConnectorsSourceMaterial(connectortypeId: string, connectorsId: string, body?: MaterialStyling, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedMaterialStylingPatch>>;
    public updateConnectorTypeHeadConnectorsSourceMaterial(connectortypeId: string, connectorsId: string, body?: MaterialStyling, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (connectortypeId === null || connectortypeId === undefined) {
            throw new Error('Required parameter connectortypeId was null or undefined when calling updateConnectorTypeHeadConnectorsSourceMaterial.');
        }

        if (connectorsId === null || connectorsId === undefined) {
            throw new Error('Required parameter connectorsId was null or undefined when calling updateConnectorTypeHeadConnectorsSourceMaterial.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }
        if (notHead !== undefined && notHead !== null) {
            queryParameters = queryParameters.set('notHead', <any>notHead);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedMaterialStylingPatch>('put',`${this.basePath}/connectortypes/${encodeURIComponent(String(connectortypeId))}/connectors/${encodeURIComponent(String(connectorsId))}/source/material`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update sharing details of specified ConnectorType
     * @param connectortypeId ID of ConnectorType to update
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateConnectorTypeSharing(connectortypeId: string, body?: Sharing, observe?: 'body', reportProgress?: boolean): Observable<Sharing>;
    public updateConnectorTypeSharing(connectortypeId: string, body?: Sharing, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Sharing>>;
    public updateConnectorTypeSharing(connectortypeId: string, body?: Sharing, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Sharing>>;
    public updateConnectorTypeSharing(connectortypeId: string, body?: Sharing, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (connectortypeId === null || connectortypeId === undefined) {
            throw new Error('Required parameter connectortypeId was null or undefined when calling updateConnectorTypeSharing.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Sharing>('put',`${this.basePath}/connectortypes/${encodeURIComponent(String(connectortypeId))}/sharing`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update version summary of specified ConnectorType
     * @param connectortypeId ID of ConnectorType to return
     * @param versionId Version to return
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateConnectorTypeVersionSummary(connectortypeId: string, versionId: string, body?: VersionLogVersion, observe?: 'body', reportProgress?: boolean): Observable<VersionLogVersion>;
    public updateConnectorTypeVersionSummary(connectortypeId: string, versionId: string, body?: VersionLogVersion, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLogVersion>>;
    public updateConnectorTypeVersionSummary(connectortypeId: string, versionId: string, body?: VersionLogVersion, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLogVersion>>;
    public updateConnectorTypeVersionSummary(connectortypeId: string, versionId: string, body?: VersionLogVersion, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (connectortypeId === null || connectortypeId === undefined) {
            throw new Error('Required parameter connectortypeId was null or undefined when calling updateConnectorTypeVersionSummary.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling updateConnectorTypeVersionSummary.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionLogVersion>('put',`${this.basePath}/connectortypes/${encodeURIComponent(String(connectortypeId))}/log/${encodeURIComponent(String(versionId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param connectortypeId ID of ConnectorType to return
     * @param versionId Version of ConnectorType
     * @param connectorsId 
     * @param extension File extension requested
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateConnectorsSourceFileFileContentMultipartForm(connectortypeId: string, versionId: string, connectorsId: string, extension: string, file?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updateConnectorsSourceFileFileContentMultipartForm(connectortypeId: string, versionId: string, connectorsId: string, extension: string, file?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updateConnectorsSourceFileFileContentMultipartForm(connectortypeId: string, versionId: string, connectorsId: string, extension: string, file?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updateConnectorsSourceFileFileContentMultipartForm(connectortypeId: string, versionId: string, connectorsId: string, extension: string, file?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (connectortypeId === null || connectortypeId === undefined) {
            throw new Error('Required parameter connectortypeId was null or undefined when calling updateConnectorsSourceFileFileContentMultipart.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling updateConnectorsSourceFileFileContentMultipart.');
        }

        if (connectorsId === null || connectorsId === undefined) {
            throw new Error('Required parameter connectorsId was null or undefined when calling updateConnectorsSourceFileFileContentMultipart.');
        }

        if (extension === null || extension === undefined) {
            throw new Error('Required parameter extension was null or undefined when calling updateConnectorsSourceFileFileContentMultipart.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }

        return this.httpClient.request<any>('put',`${this.basePath}/connectortypes/${encodeURIComponent(String(connectortypeId))}/v/${encodeURIComponent(String(versionId))}/connectors/${encodeURIComponent(String(connectorsId))}/source/file/file.${encodeURIComponent(String(extension))}`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param connectortypeId ID of ConnectorType to return
     * @param versionId Version of ConnectorType
     * @param connectorsId 
     * @param extension File extension requested
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateConnectorsSourceImageContentMultipartForm(connectortypeId: string, versionId: string, connectorsId: string, extension: string, file?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updateConnectorsSourceImageContentMultipartForm(connectortypeId: string, versionId: string, connectorsId: string, extension: string, file?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updateConnectorsSourceImageContentMultipartForm(connectortypeId: string, versionId: string, connectorsId: string, extension: string, file?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updateConnectorsSourceImageContentMultipartForm(connectortypeId: string, versionId: string, connectorsId: string, extension: string, file?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (connectortypeId === null || connectortypeId === undefined) {
            throw new Error('Required parameter connectortypeId was null or undefined when calling updateConnectorsSourceImageContentMultipart.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling updateConnectorsSourceImageContentMultipart.');
        }

        if (connectorsId === null || connectorsId === undefined) {
            throw new Error('Required parameter connectorsId was null or undefined when calling updateConnectorsSourceImageContentMultipart.');
        }

        if (extension === null || extension === undefined) {
            throw new Error('Required parameter extension was null or undefined when calling updateConnectorsSourceImageContentMultipart.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }

        return this.httpClient.request<any>('put',`${this.basePath}/connectortypes/${encodeURIComponent(String(connectortypeId))}/v/${encodeURIComponent(String(versionId))}/connectors/${encodeURIComponent(String(connectorsId))}/source/image.${encodeURIComponent(String(extension))}`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param connectortypeId ID of ConnectorType to return
     * @param versionId Version of ConnectorType
     * @param connectorsId 
     * @param extension File extension requested
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateConnectorsSourceRenderContentMultipartForm(connectortypeId: string, versionId: string, connectorsId: string, extension: string, file?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updateConnectorsSourceRenderContentMultipartForm(connectortypeId: string, versionId: string, connectorsId: string, extension: string, file?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updateConnectorsSourceRenderContentMultipartForm(connectortypeId: string, versionId: string, connectorsId: string, extension: string, file?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updateConnectorsSourceRenderContentMultipartForm(connectortypeId: string, versionId: string, connectorsId: string, extension: string, file?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (connectortypeId === null || connectortypeId === undefined) {
            throw new Error('Required parameter connectortypeId was null or undefined when calling updateConnectorsSourceRenderContentMultipart.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling updateConnectorsSourceRenderContentMultipart.');
        }

        if (connectorsId === null || connectorsId === undefined) {
            throw new Error('Required parameter connectorsId was null or undefined when calling updateConnectorsSourceRenderContentMultipart.');
        }

        if (extension === null || extension === undefined) {
            throw new Error('Required parameter extension was null or undefined when calling updateConnectorsSourceRenderContentMultipart.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }

        return this.httpClient.request<any>('put',`${this.basePath}/connectortypes/${encodeURIComponent(String(connectortypeId))}/v/${encodeURIComponent(String(versionId))}/connectors/${encodeURIComponent(String(connectorsId))}/source/render.${encodeURIComponent(String(extension))}`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param appearanceId ID of Appearance to return
     * @param versionId Version of Appearance
     * @param extension File extension requested
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateDisplacementMapImageContentMultipartForm(appearanceId: string, versionId: string, extension: string, file?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updateDisplacementMapImageContentMultipartForm(appearanceId: string, versionId: string, extension: string, file?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updateDisplacementMapImageContentMultipartForm(appearanceId: string, versionId: string, extension: string, file?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updateDisplacementMapImageContentMultipartForm(appearanceId: string, versionId: string, extension: string, file?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (appearanceId === null || appearanceId === undefined) {
            throw new Error('Required parameter appearanceId was null or undefined when calling updateDisplacementMapImageContentMultipart.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling updateDisplacementMapImageContentMultipart.');
        }

        if (extension === null || extension === undefined) {
            throw new Error('Required parameter extension was null or undefined when calling updateDisplacementMapImageContentMultipart.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }

        return this.httpClient.request<any>('put',`${this.basePath}/appearances/${encodeURIComponent(String(appearanceId))}/v/${encodeURIComponent(String(versionId))}/displacementMap/image.${encodeURIComponent(String(extension))}`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param appearanceId ID of Appearance to return
     * @param versionId Version of Appearance
     * @param extension File extension requested
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateMapImageContentMultipartForm(appearanceId: string, versionId: string, extension: string, file?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updateMapImageContentMultipartForm(appearanceId: string, versionId: string, extension: string, file?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updateMapImageContentMultipartForm(appearanceId: string, versionId: string, extension: string, file?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updateMapImageContentMultipartForm(appearanceId: string, versionId: string, extension: string, file?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (appearanceId === null || appearanceId === undefined) {
            throw new Error('Required parameter appearanceId was null or undefined when calling updateMapImageContentMultipart.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling updateMapImageContentMultipart.');
        }

        if (extension === null || extension === undefined) {
            throw new Error('Required parameter extension was null or undefined when calling updateMapImageContentMultipart.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }

        return this.httpClient.request<any>('put',`${this.basePath}/appearances/${encodeURIComponent(String(appearanceId))}/v/${encodeURIComponent(String(versionId))}/map/image.${encodeURIComponent(String(extension))}`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update details of current user
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateMe(body?: User, observe?: 'body', reportProgress?: boolean): Observable<User>;
    public updateMe(body?: User, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<User>>;
    public updateMe(body?: User, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<User>>;
    public updateMe(body?: User, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<User>('put',`${this.basePath}/users/me`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update specific version of a Module
     * @param moduleId ID of Module to update
     * @param versionId Version of Module to update
     * @param body 
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param updateAllReferences Update affected documents owned by current user
     * @param updateMyReferences Update affected documents owned by any user
     * @param notHead Do not set this version as the head version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateModule(moduleId: string, versionId: string, body?: Module, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedModulePatch>;
    public updateModule(moduleId: string, versionId: string, body?: Module, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedModulePatch>>;
    public updateModule(moduleId: string, versionId: string, body?: Module, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedModulePatch>>;
    public updateModule(moduleId: string, versionId: string, body?: Module, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (moduleId === null || moduleId === undefined) {
            throw new Error('Required parameter moduleId was null or undefined when calling updateModule.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling updateModule.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }
        if (notHead !== undefined && notHead !== null) {
            queryParameters = queryParameters.set('notHead', <any>notHead);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedModulePatch>('put',`${this.basePath}/modules/${encodeURIComponent(String(moduleId))}/v/${encodeURIComponent(String(versionId))}`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update head version of Module
     * @param moduleId ID of Module to update
     * @param body 
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param updateAllReferences Update affected documents owned by current user
     * @param updateMyReferences Update affected documents owned by any user
     * @param notHead Do not set this version as the head version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateModuleHead(moduleId: string, body?: Module, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedModulePatch>;
    public updateModuleHead(moduleId: string, body?: Module, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedModulePatch>>;
    public updateModuleHead(moduleId: string, body?: Module, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedModulePatch>>;
    public updateModuleHead(moduleId: string, body?: Module, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (moduleId === null || moduleId === undefined) {
            throw new Error('Required parameter moduleId was null or undefined when calling updateModuleHead.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }
        if (notHead !== undefined && notHead !== null) {
            queryParameters = queryParameters.set('notHead', <any>notHead);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedModulePatch>('put',`${this.basePath}/modules/${encodeURIComponent(String(moduleId))}`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param moduleId ID of Module
     * @param partsId 
     * @param body 
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param updateAllReferences Update affected documents owned by current user
     * @param updateMyReferences Update affected documents owned by any user
     * @param notHead Do not set this version as the head version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateModuleHeadSourceAssemblyPartsGeometry(moduleId: string, partsId: string, body?: PartGeometry, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedPartGeometryPatch>;
    public updateModuleHeadSourceAssemblyPartsGeometry(moduleId: string, partsId: string, body?: PartGeometry, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedPartGeometryPatch>>;
    public updateModuleHeadSourceAssemblyPartsGeometry(moduleId: string, partsId: string, body?: PartGeometry, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedPartGeometryPatch>>;
    public updateModuleHeadSourceAssemblyPartsGeometry(moduleId: string, partsId: string, body?: PartGeometry, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (moduleId === null || moduleId === undefined) {
            throw new Error('Required parameter moduleId was null or undefined when calling updateModuleHeadSourceAssemblyPartsGeometry.');
        }

        if (partsId === null || partsId === undefined) {
            throw new Error('Required parameter partsId was null or undefined when calling updateModuleHeadSourceAssemblyPartsGeometry.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }
        if (notHead !== undefined && notHead !== null) {
            queryParameters = queryParameters.set('notHead', <any>notHead);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedPartGeometryPatch>('put',`${this.basePath}/modules/${encodeURIComponent(String(moduleId))}/source/assembly/parts/${encodeURIComponent(String(partsId))}/geometry`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param moduleId ID of Module
     * @param subassembliesId 
     * @param partsId 
     * @param body 
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param updateAllReferences Update affected documents owned by current user
     * @param updateMyReferences Update affected documents owned by any user
     * @param notHead Do not set this version as the head version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateModuleHeadSourceAssemblySubassembliesPartsGeometry(moduleId: string, subassembliesId: string, partsId: string, body?: PartGeometry, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedPartGeometryPatch>;
    public updateModuleHeadSourceAssemblySubassembliesPartsGeometry(moduleId: string, subassembliesId: string, partsId: string, body?: PartGeometry, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedPartGeometryPatch>>;
    public updateModuleHeadSourceAssemblySubassembliesPartsGeometry(moduleId: string, subassembliesId: string, partsId: string, body?: PartGeometry, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedPartGeometryPatch>>;
    public updateModuleHeadSourceAssemblySubassembliesPartsGeometry(moduleId: string, subassembliesId: string, partsId: string, body?: PartGeometry, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (moduleId === null || moduleId === undefined) {
            throw new Error('Required parameter moduleId was null or undefined when calling updateModuleHeadSourceAssemblySubassembliesPartsGeometry.');
        }

        if (subassembliesId === null || subassembliesId === undefined) {
            throw new Error('Required parameter subassembliesId was null or undefined when calling updateModuleHeadSourceAssemblySubassembliesPartsGeometry.');
        }

        if (partsId === null || partsId === undefined) {
            throw new Error('Required parameter partsId was null or undefined when calling updateModuleHeadSourceAssemblySubassembliesPartsGeometry.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }
        if (notHead !== undefined && notHead !== null) {
            queryParameters = queryParameters.set('notHead', <any>notHead);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedPartGeometryPatch>('put',`${this.basePath}/modules/${encodeURIComponent(String(moduleId))}/source/assembly/subassemblies/${encodeURIComponent(String(subassembliesId))}/parts/${encodeURIComponent(String(partsId))}/geometry`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param moduleId ID of Module
     * @param partsId 
     * @param body 
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param updateAllReferences Update affected documents owned by current user
     * @param updateMyReferences Update affected documents owned by any user
     * @param notHead Do not set this version as the head version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateModuleHeadSourceFileAssemblyPartsGeometry(moduleId: string, partsId: string, body?: PartGeometry, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedPartGeometryPatch>;
    public updateModuleHeadSourceFileAssemblyPartsGeometry(moduleId: string, partsId: string, body?: PartGeometry, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedPartGeometryPatch>>;
    public updateModuleHeadSourceFileAssemblyPartsGeometry(moduleId: string, partsId: string, body?: PartGeometry, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedPartGeometryPatch>>;
    public updateModuleHeadSourceFileAssemblyPartsGeometry(moduleId: string, partsId: string, body?: PartGeometry, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (moduleId === null || moduleId === undefined) {
            throw new Error('Required parameter moduleId was null or undefined when calling updateModuleHeadSourceFileAssemblyPartsGeometry.');
        }

        if (partsId === null || partsId === undefined) {
            throw new Error('Required parameter partsId was null or undefined when calling updateModuleHeadSourceFileAssemblyPartsGeometry.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }
        if (notHead !== undefined && notHead !== null) {
            queryParameters = queryParameters.set('notHead', <any>notHead);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedPartGeometryPatch>('put',`${this.basePath}/modules/${encodeURIComponent(String(moduleId))}/source/file/assembly/parts/${encodeURIComponent(String(partsId))}/geometry`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param moduleId ID of Module
     * @param subassembliesId 
     * @param partsId 
     * @param body 
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param updateAllReferences Update affected documents owned by current user
     * @param updateMyReferences Update affected documents owned by any user
     * @param notHead Do not set this version as the head version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateModuleHeadSourceFileAssemblySubassembliesPartsGeometry(moduleId: string, subassembliesId: string, partsId: string, body?: PartGeometry, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedPartGeometryPatch>;
    public updateModuleHeadSourceFileAssemblySubassembliesPartsGeometry(moduleId: string, subassembliesId: string, partsId: string, body?: PartGeometry, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedPartGeometryPatch>>;
    public updateModuleHeadSourceFileAssemblySubassembliesPartsGeometry(moduleId: string, subassembliesId: string, partsId: string, body?: PartGeometry, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedPartGeometryPatch>>;
    public updateModuleHeadSourceFileAssemblySubassembliesPartsGeometry(moduleId: string, subassembliesId: string, partsId: string, body?: PartGeometry, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (moduleId === null || moduleId === undefined) {
            throw new Error('Required parameter moduleId was null or undefined when calling updateModuleHeadSourceFileAssemblySubassembliesPartsGeometry.');
        }

        if (subassembliesId === null || subassembliesId === undefined) {
            throw new Error('Required parameter subassembliesId was null or undefined when calling updateModuleHeadSourceFileAssemblySubassembliesPartsGeometry.');
        }

        if (partsId === null || partsId === undefined) {
            throw new Error('Required parameter partsId was null or undefined when calling updateModuleHeadSourceFileAssemblySubassembliesPartsGeometry.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }
        if (notHead !== undefined && notHead !== null) {
            queryParameters = queryParameters.set('notHead', <any>notHead);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedPartGeometryPatch>('put',`${this.basePath}/modules/${encodeURIComponent(String(moduleId))}/source/file/assembly/subassemblies/${encodeURIComponent(String(subassembliesId))}/parts/${encodeURIComponent(String(partsId))}/geometry`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param moduleId ID of Module
     * @param body 
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param updateAllReferences Update affected documents owned by current user
     * @param updateMyReferences Update affected documents owned by any user
     * @param notHead Do not set this version as the head version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateModuleHeadSourceFileFile(moduleId: string, body?: Artefact, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedArtefactPatch>;
    public updateModuleHeadSourceFileFile(moduleId: string, body?: Artefact, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedArtefactPatch>>;
    public updateModuleHeadSourceFileFile(moduleId: string, body?: Artefact, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedArtefactPatch>>;
    public updateModuleHeadSourceFileFile(moduleId: string, body?: Artefact, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (moduleId === null || moduleId === undefined) {
            throw new Error('Required parameter moduleId was null or undefined when calling updateModuleHeadSourceFileFile.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }
        if (notHead !== undefined && notHead !== null) {
            queryParameters = queryParameters.set('notHead', <any>notHead);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedArtefactPatch>('put',`${this.basePath}/modules/${encodeURIComponent(String(moduleId))}/source/file/file`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param moduleId ID of Module
     * @param body 
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param updateAllReferences Update affected documents owned by current user
     * @param updateMyReferences Update affected documents owned by any user
     * @param notHead Do not set this version as the head version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateModuleHeadSourceImage(moduleId: string, body?: Artefact, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedArtefactPatch>;
    public updateModuleHeadSourceImage(moduleId: string, body?: Artefact, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedArtefactPatch>>;
    public updateModuleHeadSourceImage(moduleId: string, body?: Artefact, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedArtefactPatch>>;
    public updateModuleHeadSourceImage(moduleId: string, body?: Artefact, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (moduleId === null || moduleId === undefined) {
            throw new Error('Required parameter moduleId was null or undefined when calling updateModuleHeadSourceImage.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }
        if (notHead !== undefined && notHead !== null) {
            queryParameters = queryParameters.set('notHead', <any>notHead);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedArtefactPatch>('put',`${this.basePath}/modules/${encodeURIComponent(String(moduleId))}/source/image`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param moduleId ID of Module
     * @param body 
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param updateAllReferences Update affected documents owned by current user
     * @param updateMyReferences Update affected documents owned by any user
     * @param notHead Do not set this version as the head version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateModuleHeadSourceMaterial(moduleId: string, body?: MaterialStyling, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedMaterialStylingPatch>;
    public updateModuleHeadSourceMaterial(moduleId: string, body?: MaterialStyling, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedMaterialStylingPatch>>;
    public updateModuleHeadSourceMaterial(moduleId: string, body?: MaterialStyling, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedMaterialStylingPatch>>;
    public updateModuleHeadSourceMaterial(moduleId: string, body?: MaterialStyling, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (moduleId === null || moduleId === undefined) {
            throw new Error('Required parameter moduleId was null or undefined when calling updateModuleHeadSourceMaterial.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }
        if (notHead !== undefined && notHead !== null) {
            queryParameters = queryParameters.set('notHead', <any>notHead);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedMaterialStylingPatch>('put',`${this.basePath}/modules/${encodeURIComponent(String(moduleId))}/source/material`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update sharing details of specified Module
     * @param moduleId ID of Module to update
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateModuleSharing(moduleId: string, body?: Sharing, observe?: 'body', reportProgress?: boolean): Observable<Sharing>;
    public updateModuleSharing(moduleId: string, body?: Sharing, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Sharing>>;
    public updateModuleSharing(moduleId: string, body?: Sharing, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Sharing>>;
    public updateModuleSharing(moduleId: string, body?: Sharing, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (moduleId === null || moduleId === undefined) {
            throw new Error('Required parameter moduleId was null or undefined when calling updateModuleSharing.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Sharing>('put',`${this.basePath}/modules/${encodeURIComponent(String(moduleId))}/sharing`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param moduleId ID of Module
     * @param versionId Version of Module
     * @param partsId 
     * @param body 
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param updateAllReferences Update affected documents owned by current user
     * @param updateMyReferences Update affected documents owned by any user
     * @param notHead Do not set this version as the head version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateModuleSourceAssemblyPartsGeometry(moduleId: string, versionId: string, partsId: string, body?: PartGeometry, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedPartGeometryPatch>;
    public updateModuleSourceAssemblyPartsGeometry(moduleId: string, versionId: string, partsId: string, body?: PartGeometry, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedPartGeometryPatch>>;
    public updateModuleSourceAssemblyPartsGeometry(moduleId: string, versionId: string, partsId: string, body?: PartGeometry, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedPartGeometryPatch>>;
    public updateModuleSourceAssemblyPartsGeometry(moduleId: string, versionId: string, partsId: string, body?: PartGeometry, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (moduleId === null || moduleId === undefined) {
            throw new Error('Required parameter moduleId was null or undefined when calling updateModuleSourceAssemblyPartsGeometry.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling updateModuleSourceAssemblyPartsGeometry.');
        }

        if (partsId === null || partsId === undefined) {
            throw new Error('Required parameter partsId was null or undefined when calling updateModuleSourceAssemblyPartsGeometry.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }
        if (notHead !== undefined && notHead !== null) {
            queryParameters = queryParameters.set('notHead', <any>notHead);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedPartGeometryPatch>('put',`${this.basePath}/modules/${encodeURIComponent(String(moduleId))}/v/${encodeURIComponent(String(versionId))}/source/assembly/parts/${encodeURIComponent(String(partsId))}/geometry`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param moduleId ID of Module
     * @param versionId Version of Module
     * @param subassembliesId 
     * @param partsId 
     * @param body 
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param updateAllReferences Update affected documents owned by current user
     * @param updateMyReferences Update affected documents owned by any user
     * @param notHead Do not set this version as the head version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateModuleSourceAssemblySubassembliesPartsGeometry(moduleId: string, versionId: string, subassembliesId: string, partsId: string, body?: PartGeometry, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedPartGeometryPatch>;
    public updateModuleSourceAssemblySubassembliesPartsGeometry(moduleId: string, versionId: string, subassembliesId: string, partsId: string, body?: PartGeometry, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedPartGeometryPatch>>;
    public updateModuleSourceAssemblySubassembliesPartsGeometry(moduleId: string, versionId: string, subassembliesId: string, partsId: string, body?: PartGeometry, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedPartGeometryPatch>>;
    public updateModuleSourceAssemblySubassembliesPartsGeometry(moduleId: string, versionId: string, subassembliesId: string, partsId: string, body?: PartGeometry, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (moduleId === null || moduleId === undefined) {
            throw new Error('Required parameter moduleId was null or undefined when calling updateModuleSourceAssemblySubassembliesPartsGeometry.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling updateModuleSourceAssemblySubassembliesPartsGeometry.');
        }

        if (subassembliesId === null || subassembliesId === undefined) {
            throw new Error('Required parameter subassembliesId was null or undefined when calling updateModuleSourceAssemblySubassembliesPartsGeometry.');
        }

        if (partsId === null || partsId === undefined) {
            throw new Error('Required parameter partsId was null or undefined when calling updateModuleSourceAssemblySubassembliesPartsGeometry.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }
        if (notHead !== undefined && notHead !== null) {
            queryParameters = queryParameters.set('notHead', <any>notHead);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedPartGeometryPatch>('put',`${this.basePath}/modules/${encodeURIComponent(String(moduleId))}/v/${encodeURIComponent(String(versionId))}/source/assembly/subassemblies/${encodeURIComponent(String(subassembliesId))}/parts/${encodeURIComponent(String(partsId))}/geometry`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param moduleId ID of Module
     * @param versionId Version of Module
     * @param partsId 
     * @param body 
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param updateAllReferences Update affected documents owned by current user
     * @param updateMyReferences Update affected documents owned by any user
     * @param notHead Do not set this version as the head version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateModuleSourceFileAssemblyPartsGeometry(moduleId: string, versionId: string, partsId: string, body?: PartGeometry, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedPartGeometryPatch>;
    public updateModuleSourceFileAssemblyPartsGeometry(moduleId: string, versionId: string, partsId: string, body?: PartGeometry, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedPartGeometryPatch>>;
    public updateModuleSourceFileAssemblyPartsGeometry(moduleId: string, versionId: string, partsId: string, body?: PartGeometry, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedPartGeometryPatch>>;
    public updateModuleSourceFileAssemblyPartsGeometry(moduleId: string, versionId: string, partsId: string, body?: PartGeometry, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (moduleId === null || moduleId === undefined) {
            throw new Error('Required parameter moduleId was null or undefined when calling updateModuleSourceFileAssemblyPartsGeometry.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling updateModuleSourceFileAssemblyPartsGeometry.');
        }

        if (partsId === null || partsId === undefined) {
            throw new Error('Required parameter partsId was null or undefined when calling updateModuleSourceFileAssemblyPartsGeometry.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }
        if (notHead !== undefined && notHead !== null) {
            queryParameters = queryParameters.set('notHead', <any>notHead);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedPartGeometryPatch>('put',`${this.basePath}/modules/${encodeURIComponent(String(moduleId))}/v/${encodeURIComponent(String(versionId))}/source/file/assembly/parts/${encodeURIComponent(String(partsId))}/geometry`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param moduleId ID of Module
     * @param versionId Version of Module
     * @param subassembliesId 
     * @param partsId 
     * @param body 
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param updateAllReferences Update affected documents owned by current user
     * @param updateMyReferences Update affected documents owned by any user
     * @param notHead Do not set this version as the head version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateModuleSourceFileAssemblySubassembliesPartsGeometry(moduleId: string, versionId: string, subassembliesId: string, partsId: string, body?: PartGeometry, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedPartGeometryPatch>;
    public updateModuleSourceFileAssemblySubassembliesPartsGeometry(moduleId: string, versionId: string, subassembliesId: string, partsId: string, body?: PartGeometry, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedPartGeometryPatch>>;
    public updateModuleSourceFileAssemblySubassembliesPartsGeometry(moduleId: string, versionId: string, subassembliesId: string, partsId: string, body?: PartGeometry, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedPartGeometryPatch>>;
    public updateModuleSourceFileAssemblySubassembliesPartsGeometry(moduleId: string, versionId: string, subassembliesId: string, partsId: string, body?: PartGeometry, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (moduleId === null || moduleId === undefined) {
            throw new Error('Required parameter moduleId was null or undefined when calling updateModuleSourceFileAssemblySubassembliesPartsGeometry.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling updateModuleSourceFileAssemblySubassembliesPartsGeometry.');
        }

        if (subassembliesId === null || subassembliesId === undefined) {
            throw new Error('Required parameter subassembliesId was null or undefined when calling updateModuleSourceFileAssemblySubassembliesPartsGeometry.');
        }

        if (partsId === null || partsId === undefined) {
            throw new Error('Required parameter partsId was null or undefined when calling updateModuleSourceFileAssemblySubassembliesPartsGeometry.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }
        if (notHead !== undefined && notHead !== null) {
            queryParameters = queryParameters.set('notHead', <any>notHead);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedPartGeometryPatch>('put',`${this.basePath}/modules/${encodeURIComponent(String(moduleId))}/v/${encodeURIComponent(String(versionId))}/source/file/assembly/subassemblies/${encodeURIComponent(String(subassembliesId))}/parts/${encodeURIComponent(String(partsId))}/geometry`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param moduleId ID of Module
     * @param versionId Version of Module
     * @param body 
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param updateAllReferences Update affected documents owned by current user
     * @param updateMyReferences Update affected documents owned by any user
     * @param notHead Do not set this version as the head version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateModuleSourceFileFile(moduleId: string, versionId: string, body?: Artefact, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedArtefactPatch>;
    public updateModuleSourceFileFile(moduleId: string, versionId: string, body?: Artefact, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedArtefactPatch>>;
    public updateModuleSourceFileFile(moduleId: string, versionId: string, body?: Artefact, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedArtefactPatch>>;
    public updateModuleSourceFileFile(moduleId: string, versionId: string, body?: Artefact, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (moduleId === null || moduleId === undefined) {
            throw new Error('Required parameter moduleId was null or undefined when calling updateModuleSourceFileFile.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling updateModuleSourceFileFile.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }
        if (notHead !== undefined && notHead !== null) {
            queryParameters = queryParameters.set('notHead', <any>notHead);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedArtefactPatch>('put',`${this.basePath}/modules/${encodeURIComponent(String(moduleId))}/v/${encodeURIComponent(String(versionId))}/source/file/file`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param moduleId ID of Module
     * @param versionId Version of Module
     * @param body 
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param updateAllReferences Update affected documents owned by current user
     * @param updateMyReferences Update affected documents owned by any user
     * @param notHead Do not set this version as the head version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateModuleSourceImage(moduleId: string, versionId: string, body?: Artefact, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedArtefactPatch>;
    public updateModuleSourceImage(moduleId: string, versionId: string, body?: Artefact, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedArtefactPatch>>;
    public updateModuleSourceImage(moduleId: string, versionId: string, body?: Artefact, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedArtefactPatch>>;
    public updateModuleSourceImage(moduleId: string, versionId: string, body?: Artefact, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (moduleId === null || moduleId === undefined) {
            throw new Error('Required parameter moduleId was null or undefined when calling updateModuleSourceImage.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling updateModuleSourceImage.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }
        if (notHead !== undefined && notHead !== null) {
            queryParameters = queryParameters.set('notHead', <any>notHead);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedArtefactPatch>('put',`${this.basePath}/modules/${encodeURIComponent(String(moduleId))}/v/${encodeURIComponent(String(versionId))}/source/image`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param moduleId ID of Module
     * @param versionId Version of Module
     * @param body 
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param updateAllReferences Update affected documents owned by current user
     * @param updateMyReferences Update affected documents owned by any user
     * @param notHead Do not set this version as the head version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateModuleSourceMaterial(moduleId: string, versionId: string, body?: MaterialStyling, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedMaterialStylingPatch>;
    public updateModuleSourceMaterial(moduleId: string, versionId: string, body?: MaterialStyling, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedMaterialStylingPatch>>;
    public updateModuleSourceMaterial(moduleId: string, versionId: string, body?: MaterialStyling, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedMaterialStylingPatch>>;
    public updateModuleSourceMaterial(moduleId: string, versionId: string, body?: MaterialStyling, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (moduleId === null || moduleId === undefined) {
            throw new Error('Required parameter moduleId was null or undefined when calling updateModuleSourceMaterial.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling updateModuleSourceMaterial.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }
        if (notHead !== undefined && notHead !== null) {
            queryParameters = queryParameters.set('notHead', <any>notHead);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedMaterialStylingPatch>('put',`${this.basePath}/modules/${encodeURIComponent(String(moduleId))}/v/${encodeURIComponent(String(versionId))}/source/material`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update version summary of specified Module
     * @param moduleId ID of Module to return
     * @param versionId Version to return
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateModuleVersionSummary(moduleId: string, versionId: string, body?: VersionLogVersion, observe?: 'body', reportProgress?: boolean): Observable<VersionLogVersion>;
    public updateModuleVersionSummary(moduleId: string, versionId: string, body?: VersionLogVersion, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLogVersion>>;
    public updateModuleVersionSummary(moduleId: string, versionId: string, body?: VersionLogVersion, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLogVersion>>;
    public updateModuleVersionSummary(moduleId: string, versionId: string, body?: VersionLogVersion, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (moduleId === null || moduleId === undefined) {
            throw new Error('Required parameter moduleId was null or undefined when calling updateModuleVersionSummary.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling updateModuleVersionSummary.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionLogVersion>('put',`${this.basePath}/modules/${encodeURIComponent(String(moduleId))}/log/${encodeURIComponent(String(versionId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update specific version of a OfflineAction
     * @param offlineactionId ID of OfflineAction to update
     * @param versionId Version of OfflineAction to update
     * @param body 
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param updateAllReferences Update affected documents owned by current user
     * @param updateMyReferences Update affected documents owned by any user
     * @param notHead Do not set this version as the head version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateOfflineAction(offlineactionId: string, versionId: string, body?: OfflineAction, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedOfflineActionPatch>;
    public updateOfflineAction(offlineactionId: string, versionId: string, body?: OfflineAction, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedOfflineActionPatch>>;
    public updateOfflineAction(offlineactionId: string, versionId: string, body?: OfflineAction, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedOfflineActionPatch>>;
    public updateOfflineAction(offlineactionId: string, versionId: string, body?: OfflineAction, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (offlineactionId === null || offlineactionId === undefined) {
            throw new Error('Required parameter offlineactionId was null or undefined when calling updateOfflineAction.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling updateOfflineAction.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }
        if (notHead !== undefined && notHead !== null) {
            queryParameters = queryParameters.set('notHead', <any>notHead);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedOfflineActionPatch>('put',`${this.basePath}/offlineactions/${encodeURIComponent(String(offlineactionId))}/v/${encodeURIComponent(String(versionId))}`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update head version of OfflineAction
     * @param offlineactionId ID of OfflineAction to update
     * @param body 
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param updateAllReferences Update affected documents owned by current user
     * @param updateMyReferences Update affected documents owned by any user
     * @param notHead Do not set this version as the head version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateOfflineActionHead(offlineactionId: string, body?: OfflineAction, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedOfflineActionPatch>;
    public updateOfflineActionHead(offlineactionId: string, body?: OfflineAction, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedOfflineActionPatch>>;
    public updateOfflineActionHead(offlineactionId: string, body?: OfflineAction, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedOfflineActionPatch>>;
    public updateOfflineActionHead(offlineactionId: string, body?: OfflineAction, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (offlineactionId === null || offlineactionId === undefined) {
            throw new Error('Required parameter offlineactionId was null or undefined when calling updateOfflineActionHead.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }
        if (notHead !== undefined && notHead !== null) {
            queryParameters = queryParameters.set('notHead', <any>notHead);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedOfflineActionPatch>('put',`${this.basePath}/offlineactions/${encodeURIComponent(String(offlineactionId))}`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update sharing details of specified OfflineAction
     * @param offlineactionId ID of OfflineAction to update
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateOfflineActionSharing(offlineactionId: string, body?: Sharing, observe?: 'body', reportProgress?: boolean): Observable<Sharing>;
    public updateOfflineActionSharing(offlineactionId: string, body?: Sharing, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Sharing>>;
    public updateOfflineActionSharing(offlineactionId: string, body?: Sharing, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Sharing>>;
    public updateOfflineActionSharing(offlineactionId: string, body?: Sharing, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (offlineactionId === null || offlineactionId === undefined) {
            throw new Error('Required parameter offlineactionId was null or undefined when calling updateOfflineActionSharing.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Sharing>('put',`${this.basePath}/offlineactions/${encodeURIComponent(String(offlineactionId))}/sharing`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update version summary of specified OfflineAction
     * @param offlineactionId ID of OfflineAction to return
     * @param versionId Version to return
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateOfflineActionVersionSummary(offlineactionId: string, versionId: string, body?: VersionLogVersion, observe?: 'body', reportProgress?: boolean): Observable<VersionLogVersion>;
    public updateOfflineActionVersionSummary(offlineactionId: string, versionId: string, body?: VersionLogVersion, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLogVersion>>;
    public updateOfflineActionVersionSummary(offlineactionId: string, versionId: string, body?: VersionLogVersion, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLogVersion>>;
    public updateOfflineActionVersionSummary(offlineactionId: string, versionId: string, body?: VersionLogVersion, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (offlineactionId === null || offlineactionId === undefined) {
            throw new Error('Required parameter offlineactionId was null or undefined when calling updateOfflineActionVersionSummary.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling updateOfflineActionVersionSummary.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionLogVersion>('put',`${this.basePath}/offlineactions/${encodeURIComponent(String(offlineactionId))}/log/${encodeURIComponent(String(versionId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update specific version of a Order
     * @param orderId ID of Order to update
     * @param versionId Version of Order to update
     * @param body 
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param updateAllReferences Update affected documents owned by current user
     * @param updateMyReferences Update affected documents owned by any user
     * @param notHead Do not set this version as the head version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateOrder(orderId: string, versionId: string, body?: Order, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedOrderPatch>;
    public updateOrder(orderId: string, versionId: string, body?: Order, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedOrderPatch>>;
    public updateOrder(orderId: string, versionId: string, body?: Order, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedOrderPatch>>;
    public updateOrder(orderId: string, versionId: string, body?: Order, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (orderId === null || orderId === undefined) {
            throw new Error('Required parameter orderId was null or undefined when calling updateOrder.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling updateOrder.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }
        if (notHead !== undefined && notHead !== null) {
            queryParameters = queryParameters.set('notHead', <any>notHead);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedOrderPatch>('put',`${this.basePath}/orders/${encodeURIComponent(String(orderId))}/v/${encodeURIComponent(String(versionId))}`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update head version of Order
     * @param orderId ID of Order to update
     * @param body 
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param updateAllReferences Update affected documents owned by current user
     * @param updateMyReferences Update affected documents owned by any user
     * @param notHead Do not set this version as the head version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateOrderHead(orderId: string, body?: Order, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedOrderPatch>;
    public updateOrderHead(orderId: string, body?: Order, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedOrderPatch>>;
    public updateOrderHead(orderId: string, body?: Order, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedOrderPatch>>;
    public updateOrderHead(orderId: string, body?: Order, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (orderId === null || orderId === undefined) {
            throw new Error('Required parameter orderId was null or undefined when calling updateOrderHead.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }
        if (notHead !== undefined && notHead !== null) {
            queryParameters = queryParameters.set('notHead', <any>notHead);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedOrderPatch>('put',`${this.basePath}/orders/${encodeURIComponent(String(orderId))}`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update sharing details of specified Order
     * @param orderId ID of Order to update
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateOrderSharing(orderId: string, body?: Sharing, observe?: 'body', reportProgress?: boolean): Observable<Sharing>;
    public updateOrderSharing(orderId: string, body?: Sharing, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Sharing>>;
    public updateOrderSharing(orderId: string, body?: Sharing, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Sharing>>;
    public updateOrderSharing(orderId: string, body?: Sharing, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (orderId === null || orderId === undefined) {
            throw new Error('Required parameter orderId was null or undefined when calling updateOrderSharing.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Sharing>('put',`${this.basePath}/orders/${encodeURIComponent(String(orderId))}/sharing`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update version summary of specified Order
     * @param orderId ID of Order to return
     * @param versionId Version to return
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateOrderVersionSummary(orderId: string, versionId: string, body?: VersionLogVersion, observe?: 'body', reportProgress?: boolean): Observable<VersionLogVersion>;
    public updateOrderVersionSummary(orderId: string, versionId: string, body?: VersionLogVersion, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLogVersion>>;
    public updateOrderVersionSummary(orderId: string, versionId: string, body?: VersionLogVersion, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLogVersion>>;
    public updateOrderVersionSummary(orderId: string, versionId: string, body?: VersionLogVersion, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (orderId === null || orderId === undefined) {
            throw new Error('Required parameter orderId was null or undefined when calling updateOrderVersionSummary.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling updateOrderVersionSummary.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionLogVersion>('put',`${this.basePath}/orders/${encodeURIComponent(String(orderId))}/log/${encodeURIComponent(String(versionId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update specific version of a Population
     * @param populationId ID of Population to update
     * @param versionId Version of Population to update
     * @param body 
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param updateAllReferences Update affected documents owned by current user
     * @param updateMyReferences Update affected documents owned by any user
     * @param notHead Do not set this version as the head version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updatePopulation(populationId: string, versionId: string, body?: Population, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedPopulationPatch>;
    public updatePopulation(populationId: string, versionId: string, body?: Population, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedPopulationPatch>>;
    public updatePopulation(populationId: string, versionId: string, body?: Population, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedPopulationPatch>>;
    public updatePopulation(populationId: string, versionId: string, body?: Population, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (populationId === null || populationId === undefined) {
            throw new Error('Required parameter populationId was null or undefined when calling updatePopulation.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling updatePopulation.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }
        if (notHead !== undefined && notHead !== null) {
            queryParameters = queryParameters.set('notHead', <any>notHead);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedPopulationPatch>('put',`${this.basePath}/populations/${encodeURIComponent(String(populationId))}/v/${encodeURIComponent(String(versionId))}`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update head version of Population
     * @param populationId ID of Population to update
     * @param body 
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param updateAllReferences Update affected documents owned by current user
     * @param updateMyReferences Update affected documents owned by any user
     * @param notHead Do not set this version as the head version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updatePopulationHead(populationId: string, body?: Population, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedPopulationPatch>;
    public updatePopulationHead(populationId: string, body?: Population, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedPopulationPatch>>;
    public updatePopulationHead(populationId: string, body?: Population, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedPopulationPatch>>;
    public updatePopulationHead(populationId: string, body?: Population, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (populationId === null || populationId === undefined) {
            throw new Error('Required parameter populationId was null or undefined when calling updatePopulationHead.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }
        if (notHead !== undefined && notHead !== null) {
            queryParameters = queryParameters.set('notHead', <any>notHead);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedPopulationPatch>('put',`${this.basePath}/populations/${encodeURIComponent(String(populationId))}`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update sharing details of specified Population
     * @param populationId ID of Population to update
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updatePopulationSharing(populationId: string, body?: Sharing, observe?: 'body', reportProgress?: boolean): Observable<Sharing>;
    public updatePopulationSharing(populationId: string, body?: Sharing, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Sharing>>;
    public updatePopulationSharing(populationId: string, body?: Sharing, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Sharing>>;
    public updatePopulationSharing(populationId: string, body?: Sharing, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (populationId === null || populationId === undefined) {
            throw new Error('Required parameter populationId was null or undefined when calling updatePopulationSharing.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Sharing>('put',`${this.basePath}/populations/${encodeURIComponent(String(populationId))}/sharing`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update version summary of specified Population
     * @param populationId ID of Population to return
     * @param versionId Version to return
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updatePopulationVersionSummary(populationId: string, versionId: string, body?: VersionLogVersion, observe?: 'body', reportProgress?: boolean): Observable<VersionLogVersion>;
    public updatePopulationVersionSummary(populationId: string, versionId: string, body?: VersionLogVersion, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLogVersion>>;
    public updatePopulationVersionSummary(populationId: string, versionId: string, body?: VersionLogVersion, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLogVersion>>;
    public updatePopulationVersionSummary(populationId: string, versionId: string, body?: VersionLogVersion, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (populationId === null || populationId === undefined) {
            throw new Error('Required parameter populationId was null or undefined when calling updatePopulationVersionSummary.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling updatePopulationVersionSummary.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionLogVersion>('put',`${this.basePath}/populations/${encodeURIComponent(String(populationId))}/log/${encodeURIComponent(String(versionId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update specific version of a Product
     * @param productId ID of Product to update
     * @param versionId Version of Product to update
     * @param body 
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param updateAllReferences Update affected documents owned by current user
     * @param updateMyReferences Update affected documents owned by any user
     * @param notHead Do not set this version as the head version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProduct(productId: string, versionId: string, body?: Product, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedProductPatch>;
    public updateProduct(productId: string, versionId: string, body?: Product, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedProductPatch>>;
    public updateProduct(productId: string, versionId: string, body?: Product, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedProductPatch>>;
    public updateProduct(productId: string, versionId: string, body?: Product, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productId === null || productId === undefined) {
            throw new Error('Required parameter productId was null or undefined when calling updateProduct.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling updateProduct.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }
        if (notHead !== undefined && notHead !== null) {
            queryParameters = queryParameters.set('notHead', <any>notHead);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedProductPatch>('put',`${this.basePath}/products/${encodeURIComponent(String(productId))}/v/${encodeURIComponent(String(versionId))}`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update specific version of a ProductConfiguration
     * @param productconfigurationId ID of ProductConfiguration to update
     * @param versionId Version of ProductConfiguration to update
     * @param body 
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param updateAllReferences Update affected documents owned by current user
     * @param updateMyReferences Update affected documents owned by any user
     * @param notHead Do not set this version as the head version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProductConfiguration(productconfigurationId: string, versionId: string, body?: ProductConfiguration, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedProductConfigurationPatch>;
    public updateProductConfiguration(productconfigurationId: string, versionId: string, body?: ProductConfiguration, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedProductConfigurationPatch>>;
    public updateProductConfiguration(productconfigurationId: string, versionId: string, body?: ProductConfiguration, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedProductConfigurationPatch>>;
    public updateProductConfiguration(productconfigurationId: string, versionId: string, body?: ProductConfiguration, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productconfigurationId === null || productconfigurationId === undefined) {
            throw new Error('Required parameter productconfigurationId was null or undefined when calling updateProductConfiguration.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling updateProductConfiguration.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }
        if (notHead !== undefined && notHead !== null) {
            queryParameters = queryParameters.set('notHead', <any>notHead);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedProductConfigurationPatch>('put',`${this.basePath}/productconfigurations/${encodeURIComponent(String(productconfigurationId))}/v/${encodeURIComponent(String(versionId))}`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update head version of ProductConfiguration
     * @param productconfigurationId ID of ProductConfiguration to update
     * @param body 
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param updateAllReferences Update affected documents owned by current user
     * @param updateMyReferences Update affected documents owned by any user
     * @param notHead Do not set this version as the head version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProductConfigurationHead(productconfigurationId: string, body?: ProductConfiguration, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedProductConfigurationPatch>;
    public updateProductConfigurationHead(productconfigurationId: string, body?: ProductConfiguration, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedProductConfigurationPatch>>;
    public updateProductConfigurationHead(productconfigurationId: string, body?: ProductConfiguration, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedProductConfigurationPatch>>;
    public updateProductConfigurationHead(productconfigurationId: string, body?: ProductConfiguration, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productconfigurationId === null || productconfigurationId === undefined) {
            throw new Error('Required parameter productconfigurationId was null or undefined when calling updateProductConfigurationHead.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }
        if (notHead !== undefined && notHead !== null) {
            queryParameters = queryParameters.set('notHead', <any>notHead);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedProductConfigurationPatch>('put',`${this.basePath}/productconfigurations/${encodeURIComponent(String(productconfigurationId))}`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update sharing details of specified ProductConfiguration
     * @param productconfigurationId ID of ProductConfiguration to update
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProductConfigurationSharing(productconfigurationId: string, body?: Sharing, observe?: 'body', reportProgress?: boolean): Observable<Sharing>;
    public updateProductConfigurationSharing(productconfigurationId: string, body?: Sharing, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Sharing>>;
    public updateProductConfigurationSharing(productconfigurationId: string, body?: Sharing, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Sharing>>;
    public updateProductConfigurationSharing(productconfigurationId: string, body?: Sharing, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productconfigurationId === null || productconfigurationId === undefined) {
            throw new Error('Required parameter productconfigurationId was null or undefined when calling updateProductConfigurationSharing.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Sharing>('put',`${this.basePath}/productconfigurations/${encodeURIComponent(String(productconfigurationId))}/sharing`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update version summary of specified ProductConfiguration
     * @param productconfigurationId ID of ProductConfiguration to return
     * @param versionId Version to return
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProductConfigurationVersionSummary(productconfigurationId: string, versionId: string, body?: VersionLogVersion, observe?: 'body', reportProgress?: boolean): Observable<VersionLogVersion>;
    public updateProductConfigurationVersionSummary(productconfigurationId: string, versionId: string, body?: VersionLogVersion, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLogVersion>>;
    public updateProductConfigurationVersionSummary(productconfigurationId: string, versionId: string, body?: VersionLogVersion, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLogVersion>>;
    public updateProductConfigurationVersionSummary(productconfigurationId: string, versionId: string, body?: VersionLogVersion, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productconfigurationId === null || productconfigurationId === undefined) {
            throw new Error('Required parameter productconfigurationId was null or undefined when calling updateProductConfigurationVersionSummary.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling updateProductConfigurationVersionSummary.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionLogVersion>('put',`${this.basePath}/productconfigurations/${encodeURIComponent(String(productconfigurationId))}/log/${encodeURIComponent(String(versionId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update head version of Product
     * @param productId ID of Product to update
     * @param body 
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param updateAllReferences Update affected documents owned by current user
     * @param updateMyReferences Update affected documents owned by any user
     * @param notHead Do not set this version as the head version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProductHead(productId: string, body?: Product, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedProductPatch>;
    public updateProductHead(productId: string, body?: Product, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedProductPatch>>;
    public updateProductHead(productId: string, body?: Product, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedProductPatch>>;
    public updateProductHead(productId: string, body?: Product, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productId === null || productId === undefined) {
            throw new Error('Required parameter productId was null or undefined when calling updateProductHead.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }
        if (notHead !== undefined && notHead !== null) {
            queryParameters = queryParameters.set('notHead', <any>notHead);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedProductPatch>('put',`${this.basePath}/products/${encodeURIComponent(String(productId))}`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param productId ID of Product
     * @param groupsId 
     * @param body 
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param updateAllReferences Update affected documents owned by current user
     * @param updateMyReferences Update affected documents owned by any user
     * @param notHead Do not set this version as the head version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProductHeadProductionDataGroupsSheetResultPlacementCandidates(productId: string, groupsId: string, body?: LargeObject, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedLargeObjectPatch>;
    public updateProductHeadProductionDataGroupsSheetResultPlacementCandidates(productId: string, groupsId: string, body?: LargeObject, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedLargeObjectPatch>>;
    public updateProductHeadProductionDataGroupsSheetResultPlacementCandidates(productId: string, groupsId: string, body?: LargeObject, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedLargeObjectPatch>>;
    public updateProductHeadProductionDataGroupsSheetResultPlacementCandidates(productId: string, groupsId: string, body?: LargeObject, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productId === null || productId === undefined) {
            throw new Error('Required parameter productId was null or undefined when calling updateProductHeadProductionDataGroupsSheetResultPlacementCandidates.');
        }

        if (groupsId === null || groupsId === undefined) {
            throw new Error('Required parameter groupsId was null or undefined when calling updateProductHeadProductionDataGroupsSheetResultPlacementCandidates.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }
        if (notHead !== undefined && notHead !== null) {
            queryParameters = queryParameters.set('notHead', <any>notHead);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedLargeObjectPatch>('put',`${this.basePath}/products/${encodeURIComponent(String(productId))}/productionData/groups/${encodeURIComponent(String(groupsId))}/sheetResult/placementCandidates`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param productId ID of Product
     * @param versionId Version of Product
     * @param groupsId 
     * @param body 
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param updateAllReferences Update affected documents owned by current user
     * @param updateMyReferences Update affected documents owned by any user
     * @param notHead Do not set this version as the head version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProductProductionDataGroupsSheetResultPlacementCandidates(productId: string, versionId: string, groupsId: string, body?: LargeObject, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedLargeObjectPatch>;
    public updateProductProductionDataGroupsSheetResultPlacementCandidates(productId: string, versionId: string, groupsId: string, body?: LargeObject, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedLargeObjectPatch>>;
    public updateProductProductionDataGroupsSheetResultPlacementCandidates(productId: string, versionId: string, groupsId: string, body?: LargeObject, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedLargeObjectPatch>>;
    public updateProductProductionDataGroupsSheetResultPlacementCandidates(productId: string, versionId: string, groupsId: string, body?: LargeObject, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productId === null || productId === undefined) {
            throw new Error('Required parameter productId was null or undefined when calling updateProductProductionDataGroupsSheetResultPlacementCandidates.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling updateProductProductionDataGroupsSheetResultPlacementCandidates.');
        }

        if (groupsId === null || groupsId === undefined) {
            throw new Error('Required parameter groupsId was null or undefined when calling updateProductProductionDataGroupsSheetResultPlacementCandidates.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }
        if (notHead !== undefined && notHead !== null) {
            queryParameters = queryParameters.set('notHead', <any>notHead);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedLargeObjectPatch>('put',`${this.basePath}/products/${encodeURIComponent(String(productId))}/v/${encodeURIComponent(String(versionId))}/productionData/groups/${encodeURIComponent(String(groupsId))}/sheetResult/placementCandidates`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update sharing details of specified Product
     * @param productId ID of Product to update
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProductSharing(productId: string, body?: Sharing, observe?: 'body', reportProgress?: boolean): Observable<Sharing>;
    public updateProductSharing(productId: string, body?: Sharing, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Sharing>>;
    public updateProductSharing(productId: string, body?: Sharing, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Sharing>>;
    public updateProductSharing(productId: string, body?: Sharing, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productId === null || productId === undefined) {
            throw new Error('Required parameter productId was null or undefined when calling updateProductSharing.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Sharing>('put',`${this.basePath}/products/${encodeURIComponent(String(productId))}/sharing`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update version summary of specified Product
     * @param productId ID of Product to return
     * @param versionId Version to return
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProductVersionSummary(productId: string, versionId: string, body?: VersionLogVersion, observe?: 'body', reportProgress?: boolean): Observable<VersionLogVersion>;
    public updateProductVersionSummary(productId: string, versionId: string, body?: VersionLogVersion, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLogVersion>>;
    public updateProductVersionSummary(productId: string, versionId: string, body?: VersionLogVersion, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLogVersion>>;
    public updateProductVersionSummary(productId: string, versionId: string, body?: VersionLogVersion, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productId === null || productId === undefined) {
            throw new Error('Required parameter productId was null or undefined when calling updateProductVersionSummary.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling updateProductVersionSummary.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionLogVersion>('put',`${this.basePath}/products/${encodeURIComponent(String(productId))}/log/${encodeURIComponent(String(versionId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update specific version of a ProductionMaterial
     * @param productionmaterialId ID of ProductionMaterial to update
     * @param versionId Version of ProductionMaterial to update
     * @param body 
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param updateAllReferences Update affected documents owned by current user
     * @param updateMyReferences Update affected documents owned by any user
     * @param notHead Do not set this version as the head version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProductionMaterial(productionmaterialId: string, versionId: string, body?: ProductionMaterial, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedProductionMaterialPatch>;
    public updateProductionMaterial(productionmaterialId: string, versionId: string, body?: ProductionMaterial, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedProductionMaterialPatch>>;
    public updateProductionMaterial(productionmaterialId: string, versionId: string, body?: ProductionMaterial, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedProductionMaterialPatch>>;
    public updateProductionMaterial(productionmaterialId: string, versionId: string, body?: ProductionMaterial, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productionmaterialId === null || productionmaterialId === undefined) {
            throw new Error('Required parameter productionmaterialId was null or undefined when calling updateProductionMaterial.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling updateProductionMaterial.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }
        if (notHead !== undefined && notHead !== null) {
            queryParameters = queryParameters.set('notHead', <any>notHead);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedProductionMaterialPatch>('put',`${this.basePath}/productionmaterials/${encodeURIComponent(String(productionmaterialId))}/v/${encodeURIComponent(String(versionId))}`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update head version of ProductionMaterial
     * @param productionmaterialId ID of ProductionMaterial to update
     * @param body 
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param updateAllReferences Update affected documents owned by current user
     * @param updateMyReferences Update affected documents owned by any user
     * @param notHead Do not set this version as the head version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProductionMaterialHead(productionmaterialId: string, body?: ProductionMaterial, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedProductionMaterialPatch>;
    public updateProductionMaterialHead(productionmaterialId: string, body?: ProductionMaterial, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedProductionMaterialPatch>>;
    public updateProductionMaterialHead(productionmaterialId: string, body?: ProductionMaterial, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedProductionMaterialPatch>>;
    public updateProductionMaterialHead(productionmaterialId: string, body?: ProductionMaterial, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productionmaterialId === null || productionmaterialId === undefined) {
            throw new Error('Required parameter productionmaterialId was null or undefined when calling updateProductionMaterialHead.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }
        if (notHead !== undefined && notHead !== null) {
            queryParameters = queryParameters.set('notHead', <any>notHead);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedProductionMaterialPatch>('put',`${this.basePath}/productionmaterials/${encodeURIComponent(String(productionmaterialId))}`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update sharing details of specified ProductionMaterial
     * @param productionmaterialId ID of ProductionMaterial to update
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProductionMaterialSharing(productionmaterialId: string, body?: Sharing, observe?: 'body', reportProgress?: boolean): Observable<Sharing>;
    public updateProductionMaterialSharing(productionmaterialId: string, body?: Sharing, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Sharing>>;
    public updateProductionMaterialSharing(productionmaterialId: string, body?: Sharing, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Sharing>>;
    public updateProductionMaterialSharing(productionmaterialId: string, body?: Sharing, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productionmaterialId === null || productionmaterialId === undefined) {
            throw new Error('Required parameter productionmaterialId was null or undefined when calling updateProductionMaterialSharing.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Sharing>('put',`${this.basePath}/productionmaterials/${encodeURIComponent(String(productionmaterialId))}/sharing`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update version summary of specified ProductionMaterial
     * @param productionmaterialId ID of ProductionMaterial to return
     * @param versionId Version to return
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProductionMaterialVersionSummary(productionmaterialId: string, versionId: string, body?: VersionLogVersion, observe?: 'body', reportProgress?: boolean): Observable<VersionLogVersion>;
    public updateProductionMaterialVersionSummary(productionmaterialId: string, versionId: string, body?: VersionLogVersion, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLogVersion>>;
    public updateProductionMaterialVersionSummary(productionmaterialId: string, versionId: string, body?: VersionLogVersion, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLogVersion>>;
    public updateProductionMaterialVersionSummary(productionmaterialId: string, versionId: string, body?: VersionLogVersion, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productionmaterialId === null || productionmaterialId === undefined) {
            throw new Error('Required parameter productionmaterialId was null or undefined when calling updateProductionMaterialVersionSummary.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling updateProductionMaterialVersionSummary.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionLogVersion>('put',`${this.basePath}/productionmaterials/${encodeURIComponent(String(productionmaterialId))}/log/${encodeURIComponent(String(versionId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update specific version of a PublishedConfigurator
     * @param publishedconfiguratorId ID of PublishedConfigurator to update
     * @param versionId Version of PublishedConfigurator to update
     * @param body 
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param updateAllReferences Update affected documents owned by current user
     * @param updateMyReferences Update affected documents owned by any user
     * @param notHead Do not set this version as the head version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updatePublishedConfigurator(publishedconfiguratorId: string, versionId: string, body?: PublishedConfigurator, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedPublishedConfiguratorPatch>;
    public updatePublishedConfigurator(publishedconfiguratorId: string, versionId: string, body?: PublishedConfigurator, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedPublishedConfiguratorPatch>>;
    public updatePublishedConfigurator(publishedconfiguratorId: string, versionId: string, body?: PublishedConfigurator, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedPublishedConfiguratorPatch>>;
    public updatePublishedConfigurator(publishedconfiguratorId: string, versionId: string, body?: PublishedConfigurator, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (publishedconfiguratorId === null || publishedconfiguratorId === undefined) {
            throw new Error('Required parameter publishedconfiguratorId was null or undefined when calling updatePublishedConfigurator.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling updatePublishedConfigurator.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }
        if (notHead !== undefined && notHead !== null) {
            queryParameters = queryParameters.set('notHead', <any>notHead);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedPublishedConfiguratorPatch>('put',`${this.basePath}/publishedconfigurators/${encodeURIComponent(String(publishedconfiguratorId))}/v/${encodeURIComponent(String(versionId))}`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update head version of PublishedConfigurator
     * @param publishedconfiguratorId ID of PublishedConfigurator to update
     * @param body 
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param updateAllReferences Update affected documents owned by current user
     * @param updateMyReferences Update affected documents owned by any user
     * @param notHead Do not set this version as the head version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updatePublishedConfiguratorHead(publishedconfiguratorId: string, body?: PublishedConfigurator, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedPublishedConfiguratorPatch>;
    public updatePublishedConfiguratorHead(publishedconfiguratorId: string, body?: PublishedConfigurator, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedPublishedConfiguratorPatch>>;
    public updatePublishedConfiguratorHead(publishedconfiguratorId: string, body?: PublishedConfigurator, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedPublishedConfiguratorPatch>>;
    public updatePublishedConfiguratorHead(publishedconfiguratorId: string, body?: PublishedConfigurator, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (publishedconfiguratorId === null || publishedconfiguratorId === undefined) {
            throw new Error('Required parameter publishedconfiguratorId was null or undefined when calling updatePublishedConfiguratorHead.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }
        if (notHead !== undefined && notHead !== null) {
            queryParameters = queryParameters.set('notHead', <any>notHead);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedPublishedConfiguratorPatch>('put',`${this.basePath}/publishedconfigurators/${encodeURIComponent(String(publishedconfiguratorId))}`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update sharing details of specified PublishedConfigurator
     * @param publishedconfiguratorId ID of PublishedConfigurator to update
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updatePublishedConfiguratorSharing(publishedconfiguratorId: string, body?: Sharing, observe?: 'body', reportProgress?: boolean): Observable<Sharing>;
    public updatePublishedConfiguratorSharing(publishedconfiguratorId: string, body?: Sharing, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Sharing>>;
    public updatePublishedConfiguratorSharing(publishedconfiguratorId: string, body?: Sharing, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Sharing>>;
    public updatePublishedConfiguratorSharing(publishedconfiguratorId: string, body?: Sharing, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (publishedconfiguratorId === null || publishedconfiguratorId === undefined) {
            throw new Error('Required parameter publishedconfiguratorId was null or undefined when calling updatePublishedConfiguratorSharing.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Sharing>('put',`${this.basePath}/publishedconfigurators/${encodeURIComponent(String(publishedconfiguratorId))}/sharing`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update version summary of specified PublishedConfigurator
     * @param publishedconfiguratorId ID of PublishedConfigurator to return
     * @param versionId Version to return
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updatePublishedConfiguratorVersionSummary(publishedconfiguratorId: string, versionId: string, body?: VersionLogVersion, observe?: 'body', reportProgress?: boolean): Observable<VersionLogVersion>;
    public updatePublishedConfiguratorVersionSummary(publishedconfiguratorId: string, versionId: string, body?: VersionLogVersion, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLogVersion>>;
    public updatePublishedConfiguratorVersionSummary(publishedconfiguratorId: string, versionId: string, body?: VersionLogVersion, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLogVersion>>;
    public updatePublishedConfiguratorVersionSummary(publishedconfiguratorId: string, versionId: string, body?: VersionLogVersion, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (publishedconfiguratorId === null || publishedconfiguratorId === undefined) {
            throw new Error('Required parameter publishedconfiguratorId was null or undefined when calling updatePublishedConfiguratorVersionSummary.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling updatePublishedConfiguratorVersionSummary.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionLogVersion>('put',`${this.basePath}/publishedconfigurators/${encodeURIComponent(String(publishedconfiguratorId))}/log/${encodeURIComponent(String(versionId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param productconfigurationId ID of ProductConfiguration to return
     * @param versionId Version of ProductConfiguration
     * @param extension File extension requested
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateRenderContentMultipartForm(productconfigurationId: string, versionId: string, extension: string, file?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updateRenderContentMultipartForm(productconfigurationId: string, versionId: string, extension: string, file?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updateRenderContentMultipartForm(productconfigurationId: string, versionId: string, extension: string, file?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updateRenderContentMultipartForm(productconfigurationId: string, versionId: string, extension: string, file?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productconfigurationId === null || productconfigurationId === undefined) {
            throw new Error('Required parameter productconfigurationId was null or undefined when calling updateRenderContentMultipart.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling updateRenderContentMultipart.');
        }

        if (extension === null || extension === undefined) {
            throw new Error('Required parameter extension was null or undefined when calling updateRenderContentMultipart.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }

        return this.httpClient.request<any>('put',`${this.basePath}/productconfigurations/${encodeURIComponent(String(productconfigurationId))}/v/${encodeURIComponent(String(versionId))}/render.${encodeURIComponent(String(extension))}`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param offlineactionId ID of OfflineAction to return
     * @param versionId Version of OfflineAction
     * @param extension File extension requested
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateResultContentMultipartForm(offlineactionId: string, versionId: string, extension: string, file?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updateResultContentMultipartForm(offlineactionId: string, versionId: string, extension: string, file?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updateResultContentMultipartForm(offlineactionId: string, versionId: string, extension: string, file?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updateResultContentMultipartForm(offlineactionId: string, versionId: string, extension: string, file?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (offlineactionId === null || offlineactionId === undefined) {
            throw new Error('Required parameter offlineactionId was null or undefined when calling updateResultContentMultipart.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling updateResultContentMultipart.');
        }

        if (extension === null || extension === undefined) {
            throw new Error('Required parameter extension was null or undefined when calling updateResultContentMultipart.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }

        return this.httpClient.request<any>('put',`${this.basePath}/offlineactions/${encodeURIComponent(String(offlineactionId))}/v/${encodeURIComponent(String(versionId))}/result.${encodeURIComponent(String(extension))}`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param moduleId ID of Module to return
     * @param versionId Version of Module
     * @param extension File extension requested
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateSourceFileFileContentMultipartForm(moduleId: string, versionId: string, extension: string, file?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updateSourceFileFileContentMultipartForm(moduleId: string, versionId: string, extension: string, file?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updateSourceFileFileContentMultipartForm(moduleId: string, versionId: string, extension: string, file?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updateSourceFileFileContentMultipartForm(moduleId: string, versionId: string, extension: string, file?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (moduleId === null || moduleId === undefined) {
            throw new Error('Required parameter moduleId was null or undefined when calling updateSourceFileFileContentMultipart.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling updateSourceFileFileContentMultipart.');
        }

        if (extension === null || extension === undefined) {
            throw new Error('Required parameter extension was null or undefined when calling updateSourceFileFileContentMultipart.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }

        return this.httpClient.request<any>('put',`${this.basePath}/modules/${encodeURIComponent(String(moduleId))}/v/${encodeURIComponent(String(versionId))}/source/file/file.${encodeURIComponent(String(extension))}`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param moduleId ID of Module to return
     * @param versionId Version of Module
     * @param extension File extension requested
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateSourceImageContentMultipartForm(moduleId: string, versionId: string, extension: string, file?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updateSourceImageContentMultipartForm(moduleId: string, versionId: string, extension: string, file?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updateSourceImageContentMultipartForm(moduleId: string, versionId: string, extension: string, file?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updateSourceImageContentMultipartForm(moduleId: string, versionId: string, extension: string, file?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (moduleId === null || moduleId === undefined) {
            throw new Error('Required parameter moduleId was null or undefined when calling updateSourceImageContentMultipart.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling updateSourceImageContentMultipart.');
        }

        if (extension === null || extension === undefined) {
            throw new Error('Required parameter extension was null or undefined when calling updateSourceImageContentMultipart.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }

        return this.httpClient.request<any>('put',`${this.basePath}/modules/${encodeURIComponent(String(moduleId))}/v/${encodeURIComponent(String(versionId))}/source/image.${encodeURIComponent(String(extension))}`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param moduleId ID of Module to return
     * @param versionId Version of Module
     * @param extension File extension requested
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateSourceRenderContentMultipartForm(moduleId: string, versionId: string, extension: string, file?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updateSourceRenderContentMultipartForm(moduleId: string, versionId: string, extension: string, file?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updateSourceRenderContentMultipartForm(moduleId: string, versionId: string, extension: string, file?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updateSourceRenderContentMultipartForm(moduleId: string, versionId: string, extension: string, file?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (moduleId === null || moduleId === undefined) {
            throw new Error('Required parameter moduleId was null or undefined when calling updateSourceRenderContentMultipart.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling updateSourceRenderContentMultipart.');
        }

        if (extension === null || extension === undefined) {
            throw new Error('Required parameter extension was null or undefined when calling updateSourceRenderContentMultipart.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }

        return this.httpClient.request<any>('put',`${this.basePath}/modules/${encodeURIComponent(String(moduleId))}/v/${encodeURIComponent(String(versionId))}/source/render.${encodeURIComponent(String(extension))}`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update specific version of a Theme
     * @param themeId ID of Theme to update
     * @param versionId Version of Theme to update
     * @param body 
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param updateAllReferences Update affected documents owned by current user
     * @param updateMyReferences Update affected documents owned by any user
     * @param notHead Do not set this version as the head version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateTheme(themeId: string, versionId: string, body?: Theme, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedThemePatch>;
    public updateTheme(themeId: string, versionId: string, body?: Theme, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedThemePatch>>;
    public updateTheme(themeId: string, versionId: string, body?: Theme, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedThemePatch>>;
    public updateTheme(themeId: string, versionId: string, body?: Theme, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (themeId === null || themeId === undefined) {
            throw new Error('Required parameter themeId was null or undefined when calling updateTheme.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling updateTheme.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }
        if (notHead !== undefined && notHead !== null) {
            queryParameters = queryParameters.set('notHead', <any>notHead);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedThemePatch>('put',`${this.basePath}/themes/${encodeURIComponent(String(themeId))}/v/${encodeURIComponent(String(versionId))}`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update head version of Theme
     * @param themeId ID of Theme to update
     * @param body 
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param updateAllReferences Update affected documents owned by current user
     * @param updateMyReferences Update affected documents owned by any user
     * @param notHead Do not set this version as the head version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateThemeHead(themeId: string, body?: Theme, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedThemePatch>;
    public updateThemeHead(themeId: string, body?: Theme, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedThemePatch>>;
    public updateThemeHead(themeId: string, body?: Theme, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedThemePatch>>;
    public updateThemeHead(themeId: string, body?: Theme, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (themeId === null || themeId === undefined) {
            throw new Error('Required parameter themeId was null or undefined when calling updateThemeHead.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }
        if (notHead !== undefined && notHead !== null) {
            queryParameters = queryParameters.set('notHead', <any>notHead);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedThemePatch>('put',`${this.basePath}/themes/${encodeURIComponent(String(themeId))}`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update sharing details of specified Theme
     * @param themeId ID of Theme to update
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateThemeSharing(themeId: string, body?: Sharing, observe?: 'body', reportProgress?: boolean): Observable<Sharing>;
    public updateThemeSharing(themeId: string, body?: Sharing, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Sharing>>;
    public updateThemeSharing(themeId: string, body?: Sharing, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Sharing>>;
    public updateThemeSharing(themeId: string, body?: Sharing, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (themeId === null || themeId === undefined) {
            throw new Error('Required parameter themeId was null or undefined when calling updateThemeSharing.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Sharing>('put',`${this.basePath}/themes/${encodeURIComponent(String(themeId))}/sharing`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update version summary of specified Theme
     * @param themeId ID of Theme to return
     * @param versionId Version to return
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateThemeVersionSummary(themeId: string, versionId: string, body?: VersionLogVersion, observe?: 'body', reportProgress?: boolean): Observable<VersionLogVersion>;
    public updateThemeVersionSummary(themeId: string, versionId: string, body?: VersionLogVersion, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLogVersion>>;
    public updateThemeVersionSummary(themeId: string, versionId: string, body?: VersionLogVersion, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLogVersion>>;
    public updateThemeVersionSummary(themeId: string, versionId: string, body?: VersionLogVersion, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (themeId === null || themeId === undefined) {
            throw new Error('Required parameter themeId was null or undefined when calling updateThemeVersionSummary.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling updateThemeVersionSummary.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionLogVersion>('put',`${this.basePath}/themes/${encodeURIComponent(String(themeId))}/log/${encodeURIComponent(String(versionId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update details of specific user
     * @param email Email of user
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateUser(email: string, body?: User, observe?: 'body', reportProgress?: boolean): Observable<User>;
    public updateUser(email: string, body?: User, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<User>>;
    public updateUser(email: string, body?: User, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<User>>;
    public updateUser(email: string, body?: User, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (email === null || email === undefined) {
            throw new Error('Required parameter email was null or undefined when calling updateUser.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<User>('put',`${this.basePath}/users/${encodeURIComponent(String(email))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update specific version of a WorkBatch
     * @param workbatchId ID of WorkBatch to update
     * @param versionId Version of WorkBatch to update
     * @param body 
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param updateAllReferences Update affected documents owned by current user
     * @param updateMyReferences Update affected documents owned by any user
     * @param notHead Do not set this version as the head version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateWorkBatch(workbatchId: string, versionId: string, body?: WorkBatch, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedWorkBatchPatch>;
    public updateWorkBatch(workbatchId: string, versionId: string, body?: WorkBatch, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedWorkBatchPatch>>;
    public updateWorkBatch(workbatchId: string, versionId: string, body?: WorkBatch, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedWorkBatchPatch>>;
    public updateWorkBatch(workbatchId: string, versionId: string, body?: WorkBatch, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workbatchId === null || workbatchId === undefined) {
            throw new Error('Required parameter workbatchId was null or undefined when calling updateWorkBatch.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling updateWorkBatch.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }
        if (notHead !== undefined && notHead !== null) {
            queryParameters = queryParameters.set('notHead', <any>notHead);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedWorkBatchPatch>('put',`${this.basePath}/workbatches/${encodeURIComponent(String(workbatchId))}/v/${encodeURIComponent(String(versionId))}`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update head version of WorkBatch
     * @param workbatchId ID of WorkBatch to update
     * @param body 
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param updateAllReferences Update affected documents owned by current user
     * @param updateMyReferences Update affected documents owned by any user
     * @param notHead Do not set this version as the head version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateWorkBatchHead(workbatchId: string, body?: WorkBatch, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedWorkBatchPatch>;
    public updateWorkBatchHead(workbatchId: string, body?: WorkBatch, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedWorkBatchPatch>>;
    public updateWorkBatchHead(workbatchId: string, body?: WorkBatch, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedWorkBatchPatch>>;
    public updateWorkBatchHead(workbatchId: string, body?: WorkBatch, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workbatchId === null || workbatchId === undefined) {
            throw new Error('Required parameter workbatchId was null or undefined when calling updateWorkBatchHead.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }
        if (notHead !== undefined && notHead !== null) {
            queryParameters = queryParameters.set('notHead', <any>notHead);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedWorkBatchPatch>('put',`${this.basePath}/workbatches/${encodeURIComponent(String(workbatchId))}`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update sharing details of specified WorkBatch
     * @param workbatchId ID of WorkBatch to update
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateWorkBatchSharing(workbatchId: string, body?: Sharing, observe?: 'body', reportProgress?: boolean): Observable<Sharing>;
    public updateWorkBatchSharing(workbatchId: string, body?: Sharing, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Sharing>>;
    public updateWorkBatchSharing(workbatchId: string, body?: Sharing, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Sharing>>;
    public updateWorkBatchSharing(workbatchId: string, body?: Sharing, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workbatchId === null || workbatchId === undefined) {
            throw new Error('Required parameter workbatchId was null or undefined when calling updateWorkBatchSharing.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Sharing>('put',`${this.basePath}/workbatches/${encodeURIComponent(String(workbatchId))}/sharing`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update version summary of specified WorkBatch
     * @param workbatchId ID of WorkBatch to return
     * @param versionId Version to return
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateWorkBatchVersionSummary(workbatchId: string, versionId: string, body?: VersionLogVersion, observe?: 'body', reportProgress?: boolean): Observable<VersionLogVersion>;
    public updateWorkBatchVersionSummary(workbatchId: string, versionId: string, body?: VersionLogVersion, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLogVersion>>;
    public updateWorkBatchVersionSummary(workbatchId: string, versionId: string, body?: VersionLogVersion, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLogVersion>>;
    public updateWorkBatchVersionSummary(workbatchId: string, versionId: string, body?: VersionLogVersion, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workbatchId === null || workbatchId === undefined) {
            throw new Error('Required parameter workbatchId was null or undefined when calling updateWorkBatchVersionSummary.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling updateWorkBatchVersionSummary.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionLogVersion>('put',`${this.basePath}/workbatches/${encodeURIComponent(String(workbatchId))}/log/${encodeURIComponent(String(versionId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update specific version of a Workflow
     * @param workflowId ID of Workflow to update
     * @param versionId Version of Workflow to update
     * @param body 
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param updateAllReferences Update affected documents owned by current user
     * @param updateMyReferences Update affected documents owned by any user
     * @param notHead Do not set this version as the head version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateWorkflow(workflowId: string, versionId: string, body?: Workflow, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedWorkflowPatch>;
    public updateWorkflow(workflowId: string, versionId: string, body?: Workflow, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedWorkflowPatch>>;
    public updateWorkflow(workflowId: string, versionId: string, body?: Workflow, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedWorkflowPatch>>;
    public updateWorkflow(workflowId: string, versionId: string, body?: Workflow, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workflowId === null || workflowId === undefined) {
            throw new Error('Required parameter workflowId was null or undefined when calling updateWorkflow.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling updateWorkflow.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }
        if (notHead !== undefined && notHead !== null) {
            queryParameters = queryParameters.set('notHead', <any>notHead);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedWorkflowPatch>('put',`${this.basePath}/workflows/${encodeURIComponent(String(workflowId))}/v/${encodeURIComponent(String(versionId))}`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update head version of Workflow
     * @param workflowId ID of Workflow to update
     * @param body 
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param updateAllReferences Update affected documents owned by current user
     * @param updateMyReferences Update affected documents owned by any user
     * @param notHead Do not set this version as the head version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateWorkflowHead(workflowId: string, body?: Workflow, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedWorkflowPatch>;
    public updateWorkflowHead(workflowId: string, body?: Workflow, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedWorkflowPatch>>;
    public updateWorkflowHead(workflowId: string, body?: Workflow, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedWorkflowPatch>>;
    public updateWorkflowHead(workflowId: string, body?: Workflow, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, notHead?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workflowId === null || workflowId === undefined) {
            throw new Error('Required parameter workflowId was null or undefined when calling updateWorkflowHead.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }
        if (notHead !== undefined && notHead !== null) {
            queryParameters = queryParameters.set('notHead', <any>notHead);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedWorkflowPatch>('put',`${this.basePath}/workflows/${encodeURIComponent(String(workflowId))}`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update sharing details of specified Workflow
     * @param workflowId ID of Workflow to update
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateWorkflowSharing(workflowId: string, body?: Sharing, observe?: 'body', reportProgress?: boolean): Observable<Sharing>;
    public updateWorkflowSharing(workflowId: string, body?: Sharing, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Sharing>>;
    public updateWorkflowSharing(workflowId: string, body?: Sharing, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Sharing>>;
    public updateWorkflowSharing(workflowId: string, body?: Sharing, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workflowId === null || workflowId === undefined) {
            throw new Error('Required parameter workflowId was null or undefined when calling updateWorkflowSharing.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Sharing>('put',`${this.basePath}/workflows/${encodeURIComponent(String(workflowId))}/sharing`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update version summary of specified Workflow
     * @param workflowId ID of Workflow to return
     * @param versionId Version to return
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateWorkflowVersionSummary(workflowId: string, versionId: string, body?: VersionLogVersion, observe?: 'body', reportProgress?: boolean): Observable<VersionLogVersion>;
    public updateWorkflowVersionSummary(workflowId: string, versionId: string, body?: VersionLogVersion, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLogVersion>>;
    public updateWorkflowVersionSummary(workflowId: string, versionId: string, body?: VersionLogVersion, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLogVersion>>;
    public updateWorkflowVersionSummary(workflowId: string, versionId: string, body?: VersionLogVersion, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workflowId === null || workflowId === undefined) {
            throw new Error('Required parameter workflowId was null or undefined when calling updateWorkflowVersionSummary.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling updateWorkflowVersionSummary.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionLogVersion>('put',`${this.basePath}/workflows/${encodeURIComponent(String(workflowId))}/log/${encodeURIComponent(String(versionId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
