import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'split',
  standalone: true,
})
export class SplitPipe implements PipeTransform {
  transform(value: string, delimiter: string): string[] {
    if (!value || !delimiter) return [];
    return value.split(delimiter);
  }
}
