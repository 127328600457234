<div class="fill-v">
  <div class="align-items-center flex justify-content-between">
    <h2 class="font-medium mb-2">Customers</h2>
  </div>
  <div class="font-medium subtitle mb-6">CONFIGURATOR CUSTOMERS</div>

  <!-- <div class="flex justify-content-between align-items-center mb-3">
    <div *ngIf="loading">Loading customers...</div>
  </div> -->

  <div class="card flex flex-column">
    <!-- <inf-search-system
      class="mb-4"
      [filters]="filters"></inf-search-system> -->

    <p-table
      #dt1
      *ngIf="!loading"
      class="mb-5 flex-1"
      dataKey="id"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      [paginator]="customers.length != 0"
      [rowsPerPageOptions]="[10, 25, 50]"
      [rows]="rowsNumber"
      [scrollable]="false"
      [showCurrentPageReport]="true"
      [value]="filteredCustomers">
      <ng-template pTemplate="caption" *ngIf="includeSearch">
        <inf-search-system
          class="mb-4"
          [data]="customers"
          [paths]="['user.name', 'configurations.length']"
          (dataChange)="filterData($event)">
        </inf-search-system>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 4rem" *ngIf="selectCustomers">
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </th>
          <th>Customer</th>
          <th>Since</th>
          <th>Num. Configurations</th>
          <th>Num. Orders</th>
          <th>Support Requests</th>
          <!-- <th>Delivery Status</th> -->
          <th>Details</th>
        </tr>
      </ng-template>
      <ng-template let-value pTemplate="body">
        <tr>
          <td *ngIf="selectCustomers">
            <p-tableCheckbox [value]="value"></p-tableCheckbox>
          </td>
          <td>
            <inf-avatar
              [user]="value.user.email"
              [size]="'small'"
              [pTooltip]="value.user.email"
              tooltipPosition="top"
              [label]="value.user.name"></inf-avatar>
          </td>
          <td>{{ value.user.signup_date | date: 'dd MMMM yyyy' }}</td>
          <td>{{ value.configurations.length }}</td>
          <td>{{ value.orders.length }}</td>
          <td></td>

          <!-- <td>{{ value.data.deliveryStatus }}</td> -->
          <td>
            <div class="flex">
              <button
                class="flex-shrink-0 p-button-text p-button-rounded"
                icon="pi pi-file"
                pButton
                pTooltip="View Activity"
                tooltipPosition="top"
                [routerLink]="['/customers', value.user.email]"></button>
              <!-- <button class="flex-shrink-0 p-button-text p-button-rounded" icon="pi pi-ellipsis-v" pButton></button> -->
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>

    <inf-empty-state
      *ngIf="!customers.length"
      src="../../assets/images/empty-states/orders.svg"
      subtitle="No Active Customers"
      title="Active customers linked to your account will be listed here"></inf-empty-state>
  </div>
</div>
