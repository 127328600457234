/**
 * InfinitivePlatform API
 * Modular Configurator
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface QuantityDefinition { 
    /**
     * Dimension of parameter
     */
    dimension?: QuantityDefinition.DimensionEnum;
    /**
     * Units of parameter
     */
    unit?: string;
    /**
     * Minimum value of parameter
     */
    min?: string;
    /**
     * Maximum value of parameter
     */
    max?: string;
    /**
     * Step or increment amount of parameter
     */
    step?: string;
}
export namespace QuantityDefinition {
    export type DimensionEnum = 'None' | 'Length' | 'Area' | 'Volume' | 'Mass' | 'Weight';
    export const DimensionEnum = {
        None: 'None' as DimensionEnum,
        Length: 'Length' as DimensionEnum,
        Area: 'Area' as DimensionEnum,
        Volume: 'Volume' as DimensionEnum,
        Mass: 'Mass' as DimensionEnum,
        Weight: 'Weight' as DimensionEnum
    };
}
