<div class="order fill-v">
  <div class="align-items-center flex justify-content-between">
    <h2 class="font-medium text-4xl mb-2">Order</h2>
  </div>
  <div class="font-medium subtitle mb-6">OVERVIEW OF ORDER</div>

  <div class="mb-4">
    <button
      class="p-button-sm"
      icon="pi pi-arrow-left"
      iconPos="left"
      label="Orders"
      pButton
      pTooltip="View orders"
      tooltipPosition="bottom"
      [routerLink]="['/orders']"></button>
  </div>

  <div class="card flex min-h-0 p-0">
    <div class="card-header mb-3 align-items-stretch border-round-lg">
      <div class="col-4 py-0">
        <p class="body-small mb-1 ml-2">Order ID</p>
        <p class="body-small ml-2">
          {{ vo.id }}
        </p>
      </div>
      <div class="col-4 py-0 border-x-1">
        <p class="body-small mb-1">Last Updated</p>
        <p class="body-small">
          {{ vo.versionDate | since }}
        </p>
      </div>
      <div class="col-4 py-0 flex justify-content-between">
        <div class="align-items-center">
          <p class="body-small mb-1">Customer</p>
          <div class="p-0 flex align-items-center published-header-content">
            <a class="body-small" [routerLink]="['/customers', summary.owner]" *ngIf="summary">
              {{ summary.owner | truncate }}
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="fill-v p-4">
      <div class="pb-4 grid">
        <div class="flex-col col-6">
          <div class="flex flex-1 align-items-center justify-content-between mb-3">
            <p class="label-normal font-bold text-lg">Summary:</p>
            <ng-container *ngFor="let productOrder of vo.document?.products || []">
              <button
                class="p-button-sm ml-2"
                icon="pi pi-box"
                iconPos="left"
                [label]="'Configuration: Quantity ' + productOrder.quantity"
                pButton
                pTooltip="View Configuration"
                (click)="openPublishConfig(productOrder)"
                tooltipPosition="bottom"></button>
            </ng-container>
          </div>
          <div class="flex flex-1 align-items-center justify-content-between mb-3">
            <a class="label-normal text-primary font-bold" [routerLink]="['/customers', customerName]">
              Customer:
              <span class="label-normal font-regular"> {{ customerName }}</span>
            </a>
          </div>
        </div>
        <div class="flex-col col-6 flex align-items-center justify-content-end">
          <p *ngIf="totalPrice" class="label-normal align-items-center justify-content-center p-6">
            <span class="align-items-center">Order Total:</span>
            <span class="align-items-center font-bold text-2xl"> {{ totalPrice | currency: 'GBP' }}</span>
          </p>
        </div>
      </div>
      <inf-order-product-card
        class="mb-2"
        *ngFor="let lineItem of vo.document?.lineItems"
        [lineItem]="lineItem"></inf-order-product-card>
    </div>
  </div>
</div>
