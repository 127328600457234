<div class="fill-v">
  <div class="align-items-center flex justify-content-between">
    <h2 class="font-medium mb-2">Workflow</h2>
  </div>
  <div class="font-medium subtitle mb-6">ORDER WORKFLOW</div>
  <!-- <div class="mb-4">
    <button
      class="p-button-sm"
      icon="pi pi-folder-open"
      iconPos="left"
      label="Orders"
      pButton
      pTooltip="View orders"
      tooltipPosition="bottom"
      [routerLink]="['/orders']"></button>
    <button
      class="p-button-sm ml-2"
      icon="pi pi-users"
      iconPos="left"
      label="Customers"
      pButton
      pTooltip="View activity by customers"
      tooltipPosition="bottom"
      [routerLink]="['/customers']"></button>
    <button
      class="p-button-sm ml-2"
      icon="pi pi-cog"
      iconPos="left"
      label="Production"
      pButton
      pTooltip="View production data"
      tooltipPosition="bottom"
      [routerLink]="['/production']"></button>
  </div> -->
  <div class="mb-4">
    <button
      class="p-button-sm"
      icon="pi pi-plus"
      iconPos="left"
      label="Add state"
      pButton
      pTooltip="Add new state to the workflow"
      tooltipPosition="bottom"></button>
  </div>

  <div class="card flex flex-column">
    <cae-mermaid class="flex-1" [spec]="spec"></cae-mermaid>
    <!-- <p-table [columns]="cols" [value]="products" [tableStyle]="{ 'min-width': '50rem' }">
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th *ngFor="let col of columns">
                        {{ col.header }}
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr>
                    <td *ngFor="let col of columns">
                        {{ rowData[col.field] }}
                    </td>
                </tr>
            </ng-template>
        </p-table> -->
  </div>
</div>
