import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';

// Services
import { Title } from '@angular/platform-browser';

// Components
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { Table, TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { AvatarComponent } from 'src/app/shared/components/avatar/avatar.component';
import { LettersAvatarComponent } from 'src/app/shared/components/letters-avatar/letters-avatar.component';
import { TagComponent } from 'src/app/shared/components/tag/tag.component';
import { Customer, DefaultService, ProductConfiguration } from '../../../../generated-src';
import { DocumentSummary, Workspace, WorkspaceService } from '@harmanpa/ng-cae';
import { ConfigurationService } from '../../shared/services/configuration.service';
import { SplitPipe } from 'src/app/shared/pipes/split.pipe';

@Component({
  selector: 'inf-customer',
  templateUrl: './customer.component.html',
  standalone: true,
  imports: [
    CommonModule,
    LettersAvatarComponent,
    DividerModule,
    AvatarComponent,
    TooltipModule,
    TableModule,
    RouterLink,
    ButtonModule,
    TagComponent,
    SplitPipe,
  ],
})
export class CustomerComponent implements OnInit {
  inProgress: boolean;
  customerName: string = '';
  customer: any = {};
  user: any = {};
  userEmail?: string = '';
  userName?: string = '';
  currentWorkspace: Workspace = {};
  city?: string = '';
  country?: string = '';
  ip?: string = '';
  browser?: string = '';
  browserVersion?: string = '';
  os?: string = '';
  osVersion?: string = '';
  userRoles?: string[] = [];
  userOs?: string = '';
  // Table Data
  rowsNumber: number = 10;
  first: number = 0;
  totalCount: any; //LineItem[] = [];

  // Configurations
  @ViewChild('dtcustomerconfigurations') table: Table;
  customerConfigurations: any = [];
  configuratorLinkRoot: string = '';
  @Output() configuratorSelection = new EventEmitter<any>();

  // Orders
  @ViewChild('dtcustomerorders') ordertable: Table;
  customerOrders: any = [];

  constructor(
    private configurationService: ConfigurationService,
    private route: ActivatedRoute,
    private http: HttpClient,
    private titleService: Title,
    private workspaceService: WorkspaceService
  ) {}

  ngOnInit(): void {
    //this.inProgress = true;
    const customerId = this.route.snapshot.params['customer_id'];
    this.customerName = customerId;
    this.configuratorLinkRoot = this.customerName.split('@')[1];

    this.http.get<Customer>(`/api/customers/${customerId}`).subscribe(
      res => {
        this.customer = res;
        this.customerConfigurations = res.configurations;
        this.customerOrders = res.orders;
        const session =
          res.user && res.user.sessions && res.user.sessions.length > 0 ? res.user.sessions[0] : undefined;
        this.city = session?.city;
        this.country = session?.region;
        this.ip = session?.ip;
        this.browser = session?.browser?.family;
        this.browserVersion = session?.browser?.major;
        this.os = session?.os?.family;
        this.osVersion = session?.os?.major;
        this.userOs = `${this.browser} ${this.browserVersion} / ${this.os} ${this.osVersion}`;
        this.user = res.user; // Check if 'orders' data is present in the response
        this.userEmail = res.user?.email;
        this.userRoles = res.user?.roles;
        this.workspaceService.getWorkspace(res.user?.workspace).subscribe(ws => (this.currentWorkspace = ws));

        this.titleService.setTitle(`Infinitive: Customer ${res.user?.name ?? ''}`);
        //this.inProgress = false;
      }
      // error: err => {
      //   this.alertService.error(err);
      //   this.inProgress = false;
      // },
    );
  }

  // ngAfterViewInit(): void {
  //   //this.inProgress = false;
  // }

  getOwnerName(email: string): string {
    return email.split('@')[0];
  }

  ////////////////////////////
  // Table Functions
  ////////////////////////////

  // onCustomerConfigurationsSelectionChange(): void {
  //   this.configuratorSelection.emit(this.selectedCustomerConfigurations);
  // }

  ////////////////////////////
  // External Link to Configurator
  ////////////////////////////

  openPublishConfig(item: DocumentSummary): void {
    this.configurationService.openConfiguration(item);
  }
}
