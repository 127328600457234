import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MetricValues } from '../../../../../generated-src';
import { TableModule } from 'primeng/table';
import { MultiSelectModule } from 'primeng/multiselect';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

interface MetricRow {
  label: string;
  value?: string;
  sum?: string;
  product?: string;
  count?: string;
  min?: string;
  max?: string;
}

interface MetricColumn {
  label: string;
  field: string;
  suffix: string;
}

@Component({
  selector: 'inf-metric-table',
  templateUrl: './metric-table.component.html',
  standalone: true,
  imports: [CommonModule, TableModule, MultiSelectModule, FormsModule],
})
export class MetricTableComponent implements OnChanges {
  @Input() metrics: MetricValues;
  @Input() defaultColumns = ['Min', 'Mean', 'Max', 'Value'];
  statisticsColumns: MetricColumn[] = [
    { label: 'Sum', field: 'sum', suffix: '_Sum' },
    { label: 'Product', field: 'product', suffix: '_Product' },
    { label: 'Min', field: 'min', suffix: '_Min' },
    { label: 'Max', field: 'max', suffix: '_Max' },
    { label: 'Count', field: 'count', suffix: '_Count' },
    { label: 'Mean', field: 'mean', suffix: '_Mean' },
    { label: 'Median', field: 'median', suffix: '_Median' },
  ];
  valuesColumns: MetricColumn[] = [{ label: 'Value', field: 'value', suffix: '' }];
  view: 'Statistics' | 'Values' = 'Values';
  rows: MetricRow[] = [];
  columns: MetricColumn[] = [];
  selectedColumns: MetricColumn[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    // Determine if these are calculated, e.g. _Sum, _Count etc
    const suffixes = this.statisticsColumns.map(col => col.suffix);
    const values = Object.keys(this.metrics || {}).some(key => !suffixes.some(suffix => key.endsWith(suffix)));
    const stats = Object.keys(this.metrics || {}).some(key => suffixes.some(suffix => key.endsWith(suffix)));
    // Get the unique set of names
    const names = Object.keys(this.metrics || {})
      .map(key => {
        for (let suffix of suffixes) {
          if (key.endsWith(suffix)) {
            return key.substring(0, key.length - suffix.length);
          }
        }
        return key;
      })
      .filter((name, index, names) => names.indexOf(name) === index);
    // Build a map and populate with data
    const rowMap: { [key: string]: MetricRow } = Object.fromEntries(
      names.map(name => [name, { label: name.replaceAll('_', ' ') }])
    );
    Object.keys(rowMap).forEach(key => {
      rowMap[key].value = this.metrics[key];
      if (stats) {
        this.statisticsColumns.forEach(col => {
          // @ts-ignore
          rowMap[key][col.field] = this.metrics[key + col.suffix];
        });
      }
    });
    // Set the row and column data
    this.rows = Object.values(rowMap);
    this.columns = (stats ? this.statisticsColumns : []).concat(values ? this.valuesColumns : []);
    this.selectedColumns = this.columns.filter(
      column => this.defaultColumns.length === 0 || this.defaultColumns.includes(column.label)
    );
  }

  selectionChanged(): void {
    this.selectedColumns = [...this.selectedColumns];
  }
}
