/**
 * InfinitivePlatform API
 * Modular Configurator
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ReferenceProductionMaterial } from './referenceProductionMaterial';

/**
 * Production setup for parts in this module
 */
export interface ProductionRule { 
    /**
     * References to parts this rule is for
     */
    parts?: Array<Array<string>>;
    /**
     * Action: Make to order or stock item?
     */
    action?: ProductionRule.ActionEnum;
    material?: ReferenceProductionMaterial;
    /**
     * SKU if stock item
     */
    sku?: string;
}
export namespace ProductionRule {
    export type ActionEnum = 'StockItem' | 'MakeToOrder';
    export const ActionEnum = {
        StockItem: 'StockItem' as ActionEnum,
        MakeToOrder: 'MakeToOrder' as ActionEnum
    };
}
