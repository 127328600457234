<div class="status-box">
  <div class="flex px-3 pt-2 justify-content-between">
    <a class="flex-1 text-primary" [routerLink]="['/orders']" [state]="{ orderStatus }">
      <div class="body-xtra-small text-secondary" [ngStyle]="{ 'margin-bottom': '2px' }">
        {{ status | titlecase }}
      </div>
      <h4 class="body-large-semibold mb-1">
        {{ totalStatsThisMonth }} {{ info }}
        <!-- <span class="count body-small-semibold">this month</span> -->
      </h4>
    </a>
    <a [routerLink]="['/orders']" [state]="{ orderStatus }" class="w-4rem pr-2">
      <p-chart type="line" [width]="30" [height]="42" [data]="basicData" [options]="basicOptions" />
    </a>
    <!-- </a> -->
    <a [routerLink]="['/orders']" [state]="{ orderStatus }">
      <inf-icon class="mt-2" [type]="status" [icon]="icon"></inf-icon>
    </a>
  </div>
  <div class="flex px-3 pb-2 justify-content-between">
    <div class="body-small text-secondary">
      <span class="count body-small-semibold">{{ statsThisWeek }} new </span>this week
      <span class="text-secondary body-xtra-small" [class]="getChange().class">
        {{ getChange().value }}

        <i [class]="getChange().iconClass" style="font-size: 0.6rem"></i>
      </span>
    </div>
  </div>
</div>
