<div class="fill-v">
  <div class="align-items-center flex justify-content-between">
    <h2 class="font-medium text-4xl mb-2">Work Batch</h2>
  </div>
  <div class="font-medium subtitle mb-6">READY FOR PRODUCTION</div>

  <div class="mb-4">
    <button
      class="p-button-sm"
      label="Production"
      icon="pi pi-arrow-left"
      iconPos="left"
      pButton
      [pTooltip]="'View List'"
      tooltipPosition="bottom"
      [routerLink]="['/production']"></button>
  </div>

  <div class="card flex flex-column">
    <div class="pb-4 grid">
      <div class="flex-col col-7">
        <div class="flex flex-column mb-3">
          <p class="body-medium font-bold pb-4">Work Batch Summary</p>
          <div class="flex flex-column row-gap-2">
            <p class="label-normal font-bold">
              Batch ID:<span class="label-normal font-regular">
                {{ workBatch.id }}
              </span>
            </p>
            <p class="label-normal font-bold">
              Combined Orders:<span class="label-normal font-regular">
                {{ workBatch.document?.orders.length }}
              </span>
            </p>
            <p class="label-normal font-bold">
              Work Batch Groups:
              <span class="label-normal font-regular">
                {{ workBatchGroups.length }}
              </span>
            </p>
          </div>
        </div>
      </div>
      <div class="flex-col col-5 flex align-items-start justify-content-start">
        <div class="flex flex-column w-6 align-items-start">
          <p class="body-medium font-bold pb-4">Orders</p>
          <p-table [value]="workBatch.document?.orders">
            <ng-template pTemplate="header">
              <tr>
                <th>Order ID</th>
                <th>Version</th>
                <th></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-order>
              <tr>
                <td>{{ order.id }}</td>
                <td>{{ order.version }}</td>
                <td>
                  <button
                    class="p-button-text p-button-rounded"
                    icon="pi pi-file"
                    pButton
                    [routerLink]="['/orders', order.id]"
                    [pTooltip]="'View Order Details'"
                    tooltipPosition="top"></button>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>
    <div class="py-4">
      <p class="body-medium font-bold">Work Batch Groups:</p>
    </div>
    <p-accordion class="w-full">
      <inf-work-batch-group
        *ngFor="let group of workBatchGroups; index as index"
        [workBatch]="workBatch"
        [group]="group"
        [index]="index">
      </inf-work-batch-group>
    </p-accordion>
  </div>
</div>
