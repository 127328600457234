<div class="align-items-center flex">
  <img *ngIf="src" [alt]="alt" class="avatar img-object-contain" [class]="size" [src]="src" width="32" height="32" />
  <div
    *ngIf="!src"
    class="align-items-center avatar avatar-text body-large-semibold flex justify-content-center m-0"
    [class]="size">
    {{ initials }}
  </div>
  <div class="flex flex-column mx-2">
    <span
      *ngIf="label"
      class="avatar-text"
      [ngClass]="{
        'body-large': size == 'large',
        'body-small': size == 'medium' || 'small',
      }">
      {{ label }}
    </span>
    <p *ngIf="details" class="body-xtra-small">{{ details }}</p>
  </div>
</div>
