<div class="fill-v" *ngIf="wsName">
  <div class="align-items-center flex justify-content-between">
    <!-- title-dashboard-test : class for theme testing -->
    <h2 class="font-medium mb-2">Hey {{ user.name }}! 👋</h2>
    <!-- <p-button (click)="toggleTheme()">Toggle Theme</p-button>     -->
  </div>
  <div class="font-medium subtitle mb-6 align-items-center">
    {{ wsName }} OVERVIEW
    <inf-tag *ngIf="subscription" [label]="subscription" [status]="'subscription'" class="ml-3"></inf-tag>
  </div>
  <!-- <inf-banner [banner]="banner"></inf-banner> -->
  <!-- <inf-zeigarnik class="flex mb-6"></inf-zeigarnik> -->
  <!-- <div class="flex gap-2 py-4"> -->
  <!-- <inf-temp-card
    class="flex-1 md:flex-none"
      [title]="'First Step'"
      [message]="
        'Add your first product in Products page'
      "
      [icon]="'pi pi-bell'"
      [closeBtn]="true"
      [actionBtn]="false">
    </inf-temp-card> -->
  <!-- <inf-temp-card
    class="flex-1 md:flex-none"
      [title]="'Well done!'"
      [message]="
        'You now can create a configuration in Products page'
      "
      [icon]="'pi pi-bell'"
      [closeBtn]="true"
      [actionBtn]="true"
      [actionBtnLabel]="'Create'">
    </inf-temp-card> -->
  <!-- <inf-temp-card
    class="flex-1 md:flex-none"
      [title]="'Well done!'"
      [message]="
        'You now can create a configuration in Products page'
      "
      [icon]="'pi pi-bell'"
      [closeBtn]="true"
      [actionBtn]="true"
      [actionBtnLabel]="'Create'">
    </inf-temp-card> -->
  <!-- </div> -->

  <div class="grid grid-nogutter">
    <div class="col-12 grid-nogutter">
      <!-- <div class="col-4 grid-nogutter flex gap-5 mb-5 align-items-center"> -->
      <!-- <inf-pricing></inf-pricing> -->
      <!-- <inf-temp-card
          class="flex-1 md:flex-none"
          [title]="'Well done!'"
          [message]="'You now can create a configuration in Products page'"
          [icon]="'pi pi-bell'"
          [closeBtn]="true"
          [actionBtn]="true"
          [actionBtnLabel]="'Create'">
        </inf-temp-card> -->
      <!-- </div> -->
      <div class="col-12 flex mb-5 gap-5">
        <inf-integrations></inf-integrations>
        <inf-orders-status [dashboard]="true" class="flex-1"></inf-orders-status>
      </div>
      <div class="grid flex mb-5">
        <div class="col-12 md:col-8 lg:col-8">
          <inf-products-overview
            [isOverview]="true"
            *ngIf="products"
            [products]="products"
            [rowsNumber]="8"
            [includeSearch]="false"></inf-products-overview>
        </div>
        <div class="col-12 md:col-4 lg:col-4 flex flex-column gap-3">
          <inf-configurators-overview></inf-configurators-overview>
          <inf-orders-overview
            *ngIf="orders"
            [orders]="orders"
            [isOverview]="true"
            [rowsNumber]="4"
            [includeSearch]="false"></inf-orders-overview>
        </div>
      </div>
    </div>
  </div>
</div>
<inf-push-notification
  [(isModalOpen)]="welcomePopup"
  (workspaceChange)="updateWorkspace($event)"></inf-push-notification>
