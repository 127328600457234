/**
 * InfinitivePlatform API
 * Modular Configurator
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Connection } from './connection';
import { ModuleOption } from './moduleOption';
import { ModuleOptionChange } from './moduleOptionChange';

export interface ConfigurationChange { 
    operation?: ConfigurationChange.OperationEnum;
    valueFrom?: ModuleOption;
    valueTo?: ModuleOption;
    changes?: Array<ModuleOptionChange>;
    connection?: Connection;
}
export namespace ConfigurationChange {
    export type OperationEnum = 'Added' | 'Removed' | 'Changed' | 'Disconnected' | 'Connected';
    export const OperationEnum = {
        Added: 'Added' as OperationEnum,
        Removed: 'Removed' as OperationEnum,
        Changed: 'Changed' as OperationEnum,
        Disconnected: 'Disconnected' as OperationEnum,
        Connected: 'Connected' as OperationEnum
    };
}
