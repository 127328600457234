import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuItem } from 'primeng/api';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { AlertService, UserService, BugReportService } from '@harmanpa/ng-cae';
import { mergeMap } from 'rxjs';
import { TruncatePipe } from '../../pipes/truncate.pipe';

@Component({
  selector: 'inf-menu-item',
  standalone: true,
  imports: [CommonModule, RouterLink, RouterLinkActive, TruncatePipe],
  templateUrl: './menu-item.component.html',
})
export class MenuItemComponent {
  @Input() item: MenuItem = {};
  @Input() markActiveRoute: boolean = true;
  @Input() childClass: string;

  constructor(
    private userService: UserService,
    private bugReportService: BugReportService,
    private alertService: AlertService
  ) {}

  onClick(): void {
    this.item.expanded = true;
    switch (this.item.label) {
      case 'Log out':
        this.logout();
        break;
      case 'Help':
        this.openGleap();
        break;
      case 'Support':
        //TODO: redirect to support template on Gleap
        this.openGleap();
        break;
      default:
        if (this.item.url) {
          this.openLanding();
        }
    }
  }

  logout(): void {
    this.userService
      .logout()
      .pipe(mergeMap(done => this.userService.forceReload('/', '/wait')))
      .subscribe({
        next: newMe => {
          // this.alertService.info('log out successfully', 'Logout');
        },
        error: err => this.alertService.error("can't log out", 'Logout Failed'),
      });
  }

  openGleap(): void {
    this.bugReportService.openHelpCenter(true);
  }

  openLanding(): void {
    if (!this.item.url?.startsWith('http://') && !this.item.url?.startsWith('https://')) {
      // If URL doesnt start with http:// or https://, add it to the URL
      window.open(`https://${this.item.url}`, '_blank');
    } else {
      window.open(this.item.url, '_blank');
    }
  }

  childItemTrackBy(index: number, item: MenuItem): string {
    return item.label as string;
  }

  toggleExpand(event: Event): void {
    event.stopPropagation();
    this.item.expanded = !this.item.expanded;
  }
}
