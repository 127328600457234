import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DocumentSummary } from '@harmanpa/ng-cae';
import { TooltipModule } from 'primeng/tooltip';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { OrderService } from '../../orders.service';
import { Router } from '@angular/router';

@Component({
  selector: 'inf-toolbar-batch-order-editor',
  standalone: true,
  imports: [CommonModule, FormsModule, ButtonModule, TooltipModule, InputTextModule, OverlayPanelModule],
  templateUrl: './toolbar-batch-order-editor.component.html',
})
export class ToolbarBatchOrderEditorComponent implements OnInit {
  /** Selected products */
  @Input() selectedOrders: DocumentSummary[] = [];
  /** Boolean to know if its to show the batch actions or only the search bar */
  // @Input() showBatchAction: boolean = true;
  /** On products search change  */
  @Output() searchChange = new EventEmitter<DocumentSummary[]>();

  constructor(
    private orderService: OrderService,
    private router: Router
  ) {}

  ngOnInit(): void {}

  sendToProduction(data: DocumentSummary[]): void {
    this.orderService.sendOrdersToProduction(data.map(ds => ({ id: ds.id, version: ds.head }))).subscribe(workBatch => {
      this.router.navigate(['/production', workBatch.id]);
    });
  }

  searchChanged(data: DocumentSummary[]): void {
    this.searchChange.emit(data);
  }
}
