import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { ChartModule } from 'primeng/chart';
import { IconComponent } from '../../shared/components/icon/icon.component';
import { DateRangeCount, StatisticsService } from '../../shared/services/statistics.service';
import { OrderService } from '../orders.service';

@Component({
  selector: 'inf-order-status-box',
  standalone: true,
  imports: [CommonModule, IconComponent, ChartModule, ButtonModule, RouterModule],
  templateUrl: './order-status-box.component.html',
})
export class OrderStatusBoxComponent implements OnInit {
  //@Input() count: number = 0;
  @Input() dashboard: boolean = false;
  @Input() icon: string = '';
  @Input() info: string = '';
  @Input() status: string = '';
  @Input() orderStatus: string = '';
  @Input() customColour: string = '#006775';
  // @Input() stats: CountStatistics;
  basicData: any;
  basicOptions: any;
  statsThisWeek: number = 0;
  statsLastWeek: number = 0;
  statsThisMonth: DateRangeCount[] = [];
  statsLastMonth: DateRangeCount[] = [];
  totalStatsThisMonth: number = 0;
  customBorderColour: string = '#006775';

  @Output() setNewFilter = new EventEmitter<string>();

  constructor(
    private statistics: StatisticsService,
    private orderService: OrderService
  ) {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
    this.basicData = {};
    this.basicOptions = {
      plugins: {
        datalabels: {
          display: false,
        },
        legend: {
          display: false,
        },
      },
      //plotOptions: { line: { dataLabels: { enabled: false } } },
      // elements: {
      //   point: {
      //     radius: 0,
      //   },
      // },
      scales: {
        x: {
          display: false,
          ticks: { display: false },
        },
        y: {
          display: false,
        },
      },
    };
  }

  ngOnInit(): void {
    this.getOrderStats();
  }

  ngAfterViewInit(): void {}

  getOrderStats(): void {
    this.statistics.getOrderWeekOnWeekCount(this.orderStatus).subscribe({
      next: stats => {
        this.statsThisWeek = stats.byDate[stats.byDate.length - 1].count;
        this.statsLastWeek = stats.byDate[stats.byDate.length - 2].count;
        this.totalStatsThisMonth = stats.total;
        this.statsLastMonth = stats.byDate.slice(0, stats.byDate.length / 2);
        this.statsThisMonth = stats.byDate.slice(stats.byDate.length / 2, stats.byDate.length);

        this.customBorderColour = `#${this.addAlpha(this.customColour, 0.8)}`;

        this.customColour = `#${this.addAlpha(this.customColour, 0.5)}`;
      },
      complete: () =>
        (this.basicData = {
          labels: ['w1', 'w2', 'w3', 'w4'],
          datasets: [
            {
              label: '',
              data: this.statsThisMonth.map(a => a.count),
              marker: false,
              fill: true,
              backgroundColor: this.customColour,
              tension: 0.4,
              borderColor: this.customBorderColour,
              pointRadius: 0,
            },
            {
              label: 'Second Dataset',
              data: this.statsLastMonth.map(a => a.count),
              fill: true,
              marker: false,
              borderColor: '#DDDDDD',
              tension: 0.4,
              backgroundColor: 'rgb(221, 221, 221)',
              pointRadius: 0,
            },
          ],
        }),
    });
  }

  getChange(): { value: string; class: string; iconClass: string } {
    if (this.statsThisWeek === 0 || this.statsLastWeek === 0) {
      return { value: '(N/A %)', class: 'body-xtra-small', iconClass: '' };
    }

    const change = this.statsThisWeek - this.statsLastWeek;
    const percentageChange = (change / this.statsLastWeek) * 100;

    return {
      value: `(${change > 0 ? '+' : ''}${percentageChange.toFixed(2)}%)`,
      class: change > 0 ? 'text-green-500 body-xtra-small' : 'text-red-500 body-xtra-small',
      iconClass:
        change > 0 ? 'text-green-500 pi pi-arrow-up body-xtra-small' : 'text-red-500 pi pi-arrow-down body-xtra-small',
    };
  }

  // Set the colour and opacity for the line chart
  addAlpha(colour: string, opacity: number): string {
    let newColour;
    if (colour.indexOf('#') == 0) {
      newColour = colour.replace('#', '');
    }

    const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
    return newColour + _opacity.toString(16).toUpperCase();
  }

  ////////////////////////////
  // Filter States On Page
  ////////////////////////////

  setSearchToOrderStatus(): void {
    this.orderService.filterOrderStatusData(this.orderStatus);
  }
}
