<p-accordionTab>
  <ng-template pTemplate="header">
    <div class="flex align-items-center">
      <i class="pi pi-cog mr-2"></i>
      <span class="vertical-align-middle">{{ count }} {{ type | titlecase }} Parts</span>
      <inf-metric-button [metrics]="group.metrics" [inTable]="true"></inf-metric-button>
    </div>
  </ng-template>
  <ng-template pTemplate="content">
    <p-table class="mb-5 flex-1 border-round-xl" [value]="group.items" scrollable="false" selectionMode="multiple">
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 4rem">
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </th>
          <th>Product</th>
          <th>Part</th>
          <th>Configuration</th>
          <th>Actions</th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-item>
        <tr [pContextMenuRow]="item">
          <td>
            <p-tableCheckbox [value]="item"></p-tableCheckbox>
          </td>
          <td>{{ item.part.module.id }}:{{ item.part.module.version }}:{{ item.instance }}</td>
          <td>{{ item.part.part.join('/') }}</td>
          <td>{{ item.configuration }}:{{ item.orderInstance }}</td>
          <td><inf-metric-button [metrics]="item.part.metrics" [inTable]="true"></inf-metric-button></td>
        </tr>
      </ng-template>
    </p-table>
    <inf-metric-button *ngIf="data" [metrics]="data.metrics" label="Nesting Data"></inf-metric-button>
  </ng-template>
</p-accordionTab>
