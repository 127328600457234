<div
  [class.disabled]="item.disabled"
  class="menu-item align-items-center flex p-2 border-round-sm"
  [ngClass]="childClass ? childClass : ''"
  (click)="onClick()"
  [routerLink]="item.routerLink"
  [routerLinkActive]="markActiveRoute ? 'active' : ''">
  <i class="mr-2 menu-item-icon" [class]="item.icon"></i>
  <div class="flex-1 menu-item-label text-sm" *ngIf="item.label">
    {{ item.label | truncate: [30, '...'] }}
  </div>
  <i
    *ngIf="item.items"
    class="ml-2 menu-item-icon"
    [ngClass]="item.expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
    (click)="toggleExpand($event)"></i>
</div>
<ng-container class="mt-2 ml-2" *ngIf="item.expanded">
  <!-- <div class="mt-2 ml-2 flex flex-column gap-2"> -->
  <inf-menu-item
    *ngFor="let childItem of item.items; trackBy: childItemTrackBy"
    [childClass]="'mt-2 ml-2 row-gap-2'"
    [item]="childItem"></inf-menu-item>
  <!-- </div> -->
</ng-container>
