import { MenuItem } from 'primeng/api';
import { MenuGroup } from '../model/menu-group.model';

export class MenuVariants {
  static readonly MAIN_MENU: MenuGroup[] = [
    {
      items: [{ label: 'Dashboard', icon: 'pi pi-home', routerLink: '/dashboard' }],
    },
    {
      label: 'COMPANY SPACE',
      items: [
        {
          label: 'Configurators',
          icon: 'pi pi-wrench',
          routerLink: '/configurators',
        },
        {
          label: 'Integrations',
          icon: 'pi pi-table',
          routerLink: '/integrations',
        },
        {
          label: 'Orders',
          icon: 'pi pi-folder-open',
          routerLink: '/orders',
          items: [
            {
              label: 'Customers',
              icon: 'pi pi-users',
              routerLink: '/customers',
            },
            {
              label: 'Production',
              icon: 'pi pi-cog',
              routerLink: '/production',
            },
            {
              label: 'Workflow',
              icon: 'pi pi-sliders-v',
              routerLink: '/workflow',
            },
          ],
        },
        {
          label: 'Products',
          icon: 'pi pi-tags',
          routerLink: '/products',
        },
      ],
    },
    {
      label: 'RESOURCES',
      items: [
        { label: 'About', icon: 'pi pi-external-link', routerLink: '/landing' },
        {
          label: 'Subscription Plan',
          icon: 'pi pi-credit-card',
          routerLink: '/subscription',
        },
        { label: 'Support', icon: 'pi pi-info-circle' },
        { label: 'T&Cs', icon: 'pi pi-book', routerLink: '/legal' },
      ],
    },
  ];

  static readonly ADMIN_MENU: MenuGroup[] = [
    {
      items: [
        {
          label: 'Preferences',
          icon: 'pi pi-cog',
          routerLink: '/admin-space/preferences',
        },
        {
          label: 'Profile',
          icon: 'pi pi-user',
          routerLink: '/admin-space/profile',
        },
      ],
    },
    {
      label: 'ADMIN SPACE',
      items: [
        {
          label: 'Billing',
          icon: 'pi pi-credit-card',
          routerLink: '/admin-space/billing',
        },
        {
          label: 'Company Settings',
          icon: 'pi pi-id-card',
          routerLink: '/admin-space/company-settings',
        },
        {
          label: 'Users',
          icon: 'pi pi-users',
          routerLink: '/admin-space/users',
        },
      ],
    },
    {
      label: 'RESOURCES',
      items: [
        { label: 'About', icon: 'pi pi-external-link', routerLink: '/landing' },
        {
          label: 'Subscription Plan',
          icon: 'pi pi-credit-card',
          routerLink: '/subscription',
        },
        { label: 'Support', icon: 'pi pi-info-circle' },
        { label: 'T&Cs', icon: 'pi pi-book', routerLink: '/legal' },
      ],
    },
  ];

  static readonly USER_MENU: MenuGroup = {
    items: [
      {
        label: 'Company Space',
        icon: 'pi pi-users',
        routerLink: '/dashboard',
      },
      {
        label: 'Settings and Preferences',
        icon: 'pi pi-cog',
        routerLink: '/admin-space/profile',
      },
    ],
  };

  static readonly GENERAL_USER_MENU: MenuGroup = {
    items: [
      {
        label: 'About',
        routerLink: '/landing',
      },
      {
        label: 'Help',
      },
      {
        label: 'Log out',
      },
      // {
      //   label: 'T&Cs',
      //   routerLink: '/legal'
      // },
    ],
  };

  static readonly PREFERENCES_MENU: MenuGroup = {
    items: [
      {
        label: 'Settings',
        icon: 'pi pi-cog',
        routerLink: 'general',
      },
      {
        label: 'Security',
        icon: 'pi pi-shield',
        routerLink: 'security',
      },
      // {
      //   label: 'Notifications',
      //   icon: 'pi pi-comment',
      //   routerLink: 'notifications',
      // },
    ],
  };

  static readonly CONFIGURATOR_GLOBAL_SETTINGS_MENU: MenuGroup = {
    items: [
      {
        label: 'Theme',
        icon: 'pi pi-palette',
        routerLink: 'theme',
      },
      {
        label: 'Images',
        icon: 'pi pi-images',
        routerLink: 'images',
      },
      {
        label: 'Features',
        icon: 'pi pi-compass',
        routerLink: 'features',
      },
    ],
  };

  static readonly COMPANY_SETTINGS_MENU: MenuGroup = {
    items: [
      {
        label: 'Details',
        icon: 'pi pi-home',
        routerLink: 'details',
      },
      // {
      //   label: 'General',
      //   icon: 'pi pi-home',
      //   routerLink: 'general',
      // },
      // {
      //   label: 'Integrations',
      //   icon: 'pi pi-home',
      //   routerLink: 'integrations',
      // },
      // {
      //   label: 'My plan',
      //   icon: 'pi pi-home',
      //   routerLink: 'my-plan',
      // },
    ],
  };

  // static readonly TABLE_PRODUCTS_ROW_OPTIONS: MenuItem[] = [
  //   {
  //     label: 'Edit',
  //     icon: 'pi pi-pencil',
  //     command: () => {
  //       // this.duplicate();
  //     },
  //   },
  //   {
  //     label: 'Duplicate',
  //     icon: 'pi pi-clone',
  //     command: () => {
  //       // this.duplicate();
  //     },
  //   },
  //   {
  //     label: 'Delete',
  //     icon: 'pi pi-trash',
  //     command: () => {
  //       // this.delete();
  //     },
  //   },
  // ];
}
export function tableRowOptions(editMethod: Function, duplicateMethod: Function, deleteMethod: Function): MenuItem[] {
  return [
    {
      label: 'Edit',
      icon: 'pi pi-pencil',
      command: () => {
        editMethod;
      },
    },
    {
      label: 'Duplicate',
      icon: 'pi pi-clone',
      command: () => {
        duplicateMethod;
      },
    },
    {
      label: 'Delete',
      icon: 'pi pi-trash',
      command: () => {
        deleteMethod;
      },
    },
  ];
}
