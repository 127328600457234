<div class="theme-generator card no-top-radius flex flex-column">
  <h4 class="font-semibold mb-5">Infinitive Themes</h4>
  <div class="flex flex-wrap themes">
    <inf-theme-preset
      *ngFor="let theme of themes; let i = index"
      [themeNumber]="i + 1"
      [themeName]="theme.document.name"
      [primaryColor]="theme.document?.properties?.light.primary"
      [secondaryColor]="theme.document?.properties?.light.secondary"
      [tertiaryColor]="theme.document?.properties?.light.tertiary"
      [isSelected]="selectedPreset === i"
      (click)="selectPreset(i)">
    </inf-theme-preset>
  </div>

  <button class="ml-auto p-button-sm" label="Select" (click)="setPreset()" pButton></button>
  <hr />
  <h4 class="font-semibold mb-5">Custom Theme</h4>
  <div class="flex" *ngIf="!loading">
    <div class="flex-column">
      <div class="flex mb-4">
        <inf-color-picker [(ngModel)]="seed" class="main-color mt-auto" (ngModelChange)="themeModelChanged($event)" />
        <div class="mr-5">
          <p class="body-small-semibold mb-2">Seed</p>
          <input type="text" pInputText [(ngModel)]="seed" (ngModelChange)="themeModelChanged($event)" />
        </div>
      </div>
      <div class="flex gap-1 my-4">
        <p-checkbox [(ngModel)]="trueColors" [binary]="true" inputId="binary" (onChange)="themeModelChanged(seed)" />
        <label class="body-small">Stay true to color inputs</label>
      </div>
      <div class="flex flex-column mb-6">
        <div class="mr-5">
          <p class="body-small-semibold mb-2">Primary</p>
          <div class="flex align-items-center">
            <div
              class="w-2rem h-2rem border-circle mr-2 align-items-center"
              [style.background-color]="keyMainColours.primary"></div>
            <span *ngIf="!togglePrimary" class="body-small w-6-75rem">{{ keyMainColours.primary }}</span>
            <input
              *ngIf="togglePrimary"
              type="text"
              class="w-6-75rem"
              pInputText
              [(ngModel)]="keyMainColours.primary"
              (ngModelChange)="advancedGenerator($event, 'primary')" />
            <button
              class="ml-1 p-button-rounded p-button-text"
              icon="pi pi-pencil"
              (click)="toggleAdvanced('primary')"
              pButton></button>
          </div>
        </div>
        <div class="mr-5">
          <p class="body-small-semibold my-2">Secondary</p>
          <div class="flex align-items-center">
            <div
              class="w-2rem h-2rem border-circle mr-2 align-items-center"
              [style.background-color]="keyMainColours.secondary"></div>
            <span *ngIf="!toggleSecondary" class="body-small w-6-75rem">{{ keyMainColours.secondary }}</span>
            <input
              *ngIf="toggleSecondary"
              type="text"
              class="w-6-75rem"
              pInputText
              [(ngModel)]="keyMainColours.secondary"
              (ngModelChange)="advancedGenerator($event, 'secondary')" />
            <button
              class="ml-1 p-button-rounded p-button-text"
              icon="pi pi-pencil"
              (click)="toggleAdvanced('secondary')"
              pButton></button>
          </div>
        </div>
        <div class="mr-5">
          <p class="body-small-semibold my-2">Tertiary</p>
          <div class="flex align-items-center">
            <div
              class="w-2rem h-2rem border-circle mr-2 align-items-center"
              [style.background-color]="keyMainColours.tertiary"></div>
            <span *ngIf="!toggleTertiary" class="body-small w-6-75rem">{{ keyMainColours.tertiary }}</span>
            <input
              *ngIf="toggleTertiary"
              type="text"
              class="w-6-75rem"
              pInputText
              [(ngModel)]="keyMainColours.tertiary"
              (ngModelChange)="advancedGenerator($event, 'tertiary')" />
            <button
              class="ml-1 p-button-rounded p-button-text"
              icon="pi pi-pencil"
              (click)="toggleAdvanced('tertiary')"
              pButton></button>
          </div>
        </div>
        <!-- <button class="mt-3 p-button-sm" label="Advanced" (click)="toggleAdvanced()" pButton></button> -->
        <button class="p-button-sm" label="Register" (click)="showDialog()" *ngIf="management" pButton></button>
      </div>
    </div>
    <inf-theme-keycolors *ngIf="selectedTheme" [theme]="selectedTheme" class="my-3"></inf-theme-keycolors>
    <p-dialog header="Edit Theme" [modal]="true" [(visible)]="visible" [style]="{ width: '25rem' }" *ngIf="management">
      <span class="p-text-secondary block mb-5">Update theme information.</span>
      <div class="flex align-items-center gap-3 mb-3">
        <label for="themeName" class="font-semibold w-6rem">Theme name</label>
        <input pInputText id="themeName" [(ngModel)]="themeName" class="flex-auto" autocomplete="off" />
      </div>
      <div class="flex justify-content-end gap-2">
        <p-button label="Cancel" severity="secondary" (click)="visible = false" />
        <p-button label="Save" (click)="addNewTheme()" />
      </div>
    </p-dialog>
  </div>
  <button class="ml-auto p-button-sm" label="Apply" (click)="changeSelectedTheme()" pButton></button>
</div>
