/**
 * InfinitivePlatform API
 * Modular Configurator
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ModuleOptionChange { 
    operation?: ModuleOptionChange.OperationEnum;
    parameter?: string;
    valueFrom?: string;
    valueTo?: string;
}
export namespace ModuleOptionChange {
    export type OperationEnum = 'ModuleType' | 'Instance' | 'Location' | 'Parameter';
    export const OperationEnum = {
        ModuleType: 'ModuleType' as OperationEnum,
        Instance: 'Instance' as OperationEnum,
        Location: 'Location' as OperationEnum,
        Parameter: 'Parameter' as OperationEnum
    };
}
