import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { AlertService } from '@harmanpa/ng-cae';
import { ButtonModule } from 'primeng/button';

@Component({
  selector: 'inf-sync-orders',
  standalone: true,
  imports: [CommonModule, ButtonModule],
  templateUrl: './sync-orders.component.html',
})
export class SyncOrdersComponent {
  @Input() inProgress: boolean = false;
  ordersSyncing: boolean = false;
  syncSpin: boolean = false;

  constructor(
    private alertService: AlertService,
    private http: HttpClient
  ) {}

  public syncOrders(): void {
    this.inProgress = true;
    this.syncSpin = true;
    this.http.get('/api/external/orders').subscribe({
      next: orders => {
        console.log(orders, 'syncing new orders from order page');
      },
      error: err => this.alertService.error(err),
      complete: () => {
        setTimeout(() => {
          this.syncSpin = false;
        }, 1000);
        this.inProgress = false;
      },
    });
  }
}
