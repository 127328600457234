import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MetricValues } from '../../../../../generated-src';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { MetricTableComponent } from '../metric-table/metric-table.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'inf-metric-button',
  templateUrl: './metric-button.component.html',
  standalone: true,
  imports: [CommonModule, OverlayPanelModule, ButtonModule, TooltipModule, MetricTableComponent],
})
export class MetricButtonComponent implements OnChanges {
  @Input() metrics: MetricValues;
  @Input() defaultColumns = ['Min', 'Mean', 'Max', 'Value'];
  @Input() label = 'Data';
  @Input() tooltip = 'Show all data';
  @Input() inTable = false;
  count = 0;

  ngOnChanges(changes: SimpleChanges): void {
    this.count = Object.keys(this.metrics || {}).length;
  }
}
