<nav class="fixed flex flex-column sidenav">
  <div class="flex justify-content-center logo-img" [routerLink]="['/']">
    <img
      alt="Infinitive"
      class="my-4 img-object-contain"
      width="96"
      height="72"
      ngSrc="/assets/images/logos/Infinitive logo-01.svg"
      priority />
  </div>
  <div class="overflow-scroll px-3">
    <div class="flex flex-column gap-2 mb-2 py-3" *ngFor="let group of menu; trackBy: groupTrackBy">
      <div *ngIf="group.label" class="p-2">
        {{ group.label }}
      </div>
      <inf-menu-item *ngFor="let item of group.items; trackBy: itemTrackBy" [item]="item"></inf-menu-item>
    </div>
  </div>

  <inf-workspace-menu class="mt-auto px-3"></inf-workspace-menu>
</nav>
