import { Component, OnInit } from '@angular/core';

import { ActivatedRoute, RouterLink, RouterModule } from '@angular/router';
import { DefaultService, VersionedWorkBatch, WorkBatchGroup } from '../../../../generated-src';
import { CommonModule } from '@angular/common';
import { SyncOrdersComponent } from '../sync-orders/sync-orders.component';
import { AlertService, PatchworkService } from '@harmanpa/ng-cae';
import { MetricTableComponent } from '../../shared/components/metric-table/metric-table.component';
import { WorkBatchGroupComponent } from './work-batch-group/work-batch-group.component';

import { AccordionModule } from 'primeng/accordion';
import { MetricButtonComponent } from '../../shared/components/metric-button/metric-button.component';

import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { TableModule } from 'primeng/table';
import { DividerModule } from 'primeng/divider';

@Component({
  selector: 'inf-work-batch',
  templateUrl: './work-batch.component.html',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    SyncOrdersComponent,
    MetricTableComponent,
    WorkBatchGroupComponent,
    AccordionModule,
    MetricButtonComponent,
    ButtonModule,
    TooltipModule,
    RouterLink,
    TableModule,
    DividerModule,
  ],
})
export class WorkBatchComponent implements OnInit {
  workBatch: VersionedWorkBatch;
  workBatchGroups: WorkBatchGroup[] = [];
  loading = false;

  constructor(
    private api: DefaultService,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private patchworkService: PatchworkService
  ) {}

  ngOnInit(): void {
    this.workBatch = this.route.snapshot.data['workbatch'];
    this.patchworkService.setVersionQuery(this.route, this.workBatch);
    this.loading = true;
    this.api.getWorkBatchGroups(this.workBatch.id as string, this.workBatch.version as string).subscribe({
      next: groups => {
        this.workBatchGroups = groups.document || [];
        this.loading = false;
      },
      error: err => {
        this.alertService.error(err);
        this.loading = false;
      },
    });
  }
}
