<div class="fill-v">
  <div class="align-items-center flex justify-content-between">
    <h2 class="font-medium text-4xl mb-2">Customer Information</h2>
  </div>
  <div class="font-medium subtitle mb-6">OVERVIEW OF ACTIVITIES</div>

  <div class="mb-4">
    <button
      class="p-button-sm"
      label="Customers"
      icon="pi pi-arrow-left"
      iconPos="left"
      pButton
      pTooltip="View Customers"
      tooltipPosition="bottom"
      [routerLink]="['/customers']"></button>
  </div>

  <div class="card flex flex-column">
    <!-- <p *ngIf="inProgress">Loading...</p> -->

    <div class="pb-4 grid">
      <div class="flex-col col-8">
        <div *ngIf="customer?.user" class="flex flex-1 align-items-center justify-content-between mb-3">
          <p class="label-normal font-bold text-lg">Customer:</p>
        </div>
        <div class="flex flex-1 align-items-center mb-3">
          <div class="flex w-6 align-items-start">
            <div class="field" *ngIf="customer?.user?.email.length > 0">
              <inf-avatar
                [user]="customer?.user?.email"
                [size]="'large'"
                [details]="getOwnerName(customer?.user?.email ?? '')"
                [label]="getOwnerName(customer?.user?.name ?? '')"></inf-avatar>
            </div>
          </div>
        </div>
      </div>

      <div class="flex-col col-4 flex align-items-start justify-content-start">
        <div class="flex flex-column w-6 align-items-start" *ngIf="currentWorkspace && currentWorkspace.alias">
          <p class="body-medium mb-2 font-bold">Workspace:</p>
          <inf-letters-avatar
            *ngIf="currentWorkspace && currentWorkspace.alias"
            class="w-fit active"
            tooltipPosition="right"
            [text]="currentWorkspace.name"
            [color]="currentWorkspace.color"
            [pTooltip]="'' + currentWorkspace.name" />
        </div>
      </div>
      <div class="grid p-3 mb-3" style="background-color: #fafafa; border-radius: 8px">
        <div class="flex col-12" *ngIf="customer?.user">
          <div class="flex flex-1 align-items-center justify-content-between mb-1" *ngIf="customer?.user?.sessions[0]">
            <p class="label-normal font-bold">
              Last Login:
              <span class="label-normal font-regular">
                {{ city || 'Unknown' }} ({{ country || 'Unknown' }})
                <span *ngIf="country" [class]="'fi fi-' + country.toLowerCase()"></span>
              </span>

              <span class="label-normal font-regular ml-2">{{ customer?.user?.sessions[0].date | date }}</span>
            </p>
          </div>
          <div class="flex flex-1 align-items-center justify-content-between mb-1">
            <p class="label-normal font-bold">
              Sign Up Date:
              <span class="label-normal font-regular">
                {{ customer?.user?.signup_date | date }}
              </span>
            </p>
          </div>

          <div class="flex flex-1 align-items-center justify-content-between mb-1" *ngIf="customer?.user?.sessions[0]">
            <p class="label-normal font-bold">
              IP:
              <!-- <span class="label-normal font-regular"> {{ ip }}</span> -->
              <inf-tag
                [label]="ip || 'Unknown'"
                [isSmall]="true"
                rounded="true"
                class="mr-2 bg-gray-300 border-round-3xl">
              </inf-tag>
            </p>
          </div>
        </div>
        <div class="flex col-12" *ngIf="customer?.user">
          <div class="flex flex-1 align-items-center justify-content-between">
            <p class="label-normal font-bold">
              Roles:
              <!-- <span class="label-normal font-regular">
                {{ customer?.user?.roles?.join(', ') }}</span
              > -->

              <ng-container *ngFor="let role of userRoles">
                <inf-tag
                  [label]="role"
                  [isSmall]="true"
                  [status]="
                    role == 'admin'
                      ? 'info'
                      : role == 'user'
                        ? 'warning'
                        : role == 'editor'
                          ? 'success'
                          : role == 'guest'
                            ? 'danger'
                            : ''
                  "
                  rounded="true"
                  class="mr-2">
                </inf-tag>
              </ng-container>
            </p>
          </div>
          <div class="flex flex-1 align-items-center justify-content-between">
            <p class="label-normal font-bold">
              Signup Method:
              <span class="label-normal font-regular">
                {{ customer?.user?.signup_method }}
              </span>
            </p>
          </div>
          <div class="flex flex-1 align-items-center justify-content-between" *ngIf="customer?.user?.sessions[0]">
            <p class="label-normal font-bold">
              Browser / OS:
              <span class="label-normal font-regular">
                <inf-tag [label]="userOs" [isSmall]="true" rounded="true" class="mr-2 bg-gray-300 border-round-3xl">
                </inf-tag>
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- 
    ///////////
    CONFIGURATIONS
    //////////// 
    -->
    <ng-container *ngIf="customerConfigurations && customerConfigurations.length > 0">
      <div class="pb-2">
        <p class="label-normal font-bold text-lg">Customer Configurations:</p>
      </div>
      <p-table
        #dtcustomerconfigurations
        class="mb-5 flex-1"
        [value]="customerConfigurations"
        [rows]="rowsNumber"
        [showCurrentPageReport]="true"
        [paginator]="true"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [rowsPerPageOptions]="[10, 25, 50]"
        [scrollable]="false">
        <ng-template pTemplate="header">
          <tr>
            <th [pSortableColumn]="'name'" class="body-medium">
              Configuration Name<span> <p-sortIcon field="name" class="ml-2" /></span>
            </th>
            <th class="body-medium" [pSortableColumn]="'lastEdited'">
              Last Updated <span><p-sortIcon field="lastEdited" /></span>
            </th>
            <th class="body-medium">Description</th>
            <th class="body-medium">Product</th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-value let-rowIndex="rowIndex">
          <tr [pContextMenuRow]="value">
            <td class="">
              <a class="label-normal text-primary" (click)="openPublishConfig(value)">
                <span class="body-small"> {{ value.data.name }}</span>
              </a>
            </td>
            <td class="">
              <span class="body-small"> {{ value.lastEdited | date }}</span>
            </td>
            <td class="">
              <span class="body-small"> {{ value.data.description }}</span>
            </td>
            <td class="">
              <div>
                <p class="body-small">
                  <span class="font-bold body-small">productID: </span>
                  {{ (value.data.product | split: ':')[0] }}
                </p>
                <p class="body-small">
                  <span class="font-bold body-small">version: </span>
                  {{ (value.data.product | split: ':')[1] }}
                </p>
              </div>
            </td>
            <td>
              <div class="flex gap-2 justify-content-end">
                <p-button
                  pTooltip="Open Configurator"
                  tooltipPosition="top"
                  icon="pi pi-external-link"
                  styleClass="p-button-text p-button-rounded"
                  (click)="openPublishConfig(value)"></p-button>
              </div>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="5">No configurations found.</td>
          </tr>
        </ng-template>
      </p-table>
      <p-divider></p-divider>
    </ng-container>

    <!-- 
    ///////////
    ORDERS
    //////////// 
    -->

    <ng-container *ngIf="customerOrders && customerOrders.length > 0">
      <div class="pb-2 mt-6">
        <p class="label-normal font-bold text-lg">Customer Orders:</p>
      </div>
      <p-table
        #dtcustomerorders
        class="mb-5 flex-1"
        [value]="customerOrders"
        [rows]="rowsNumber"
        [showCurrentPageReport]="true"
        [paginator]="true"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [rowsPerPageOptions]="[10, 25, 50]"
        [scrollable]="false">
        <ng-template pTemplate="header">
          <tr>
            <th [pSortableColumn]="'id'">
              Order ID<span> <p-sortIcon field="id" class="ml-2" /></span>
            </th>
            <th class="" [pSortableColumn]="'lastEdited'">
              Last Updated <span><p-sortIcon field="lastEdited" /></span>
            </th>
            <th class="">Status</th>
            <th class="">Total</th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-value let-rowIndex="rowIndex">
          <tr [pContextMenuRow]="value">
            <td class="">
              <span class="body-small"> {{ value.id }}</span>
              <!-- <a class="label-normal text-primary" [routerLink]="['/orders', value.id]">
                <span class="flex justify-content-start align-items-center"> {{ value.id }}</span>
              </a> -->
            </td>
            <td class="">
              <span class="body-small"> {{ value.lastEdited | date }}</span>
            </td>
            <td class="">
              <span class="body-small"> {{ value.status }}</span>
            </td>
            <td class="">
              <span class="body-small"> {{ value.total | currency }}</span>
            </td>
            <td>
              <div class="flex gap-2 justify-content-end">
                <button
                  class="p-button-text p-button-rounded"
                  icon="pi pi-file"
                  pButton
                  [routerLink]="['/orders', value.id]"
                  pTooltip="View Details"
                  tooltipPosition="top"></button>
              </div>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="5">No orders found.</td>
          </tr>
        </ng-template>
      </p-table>
    </ng-container>
  </div>
</div>
