import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { OrderStatusBoxComponent } from 'src/app/orders/order-status-box/order-status-box.component';

export interface CountObject {
  received: number;
  delivered: number;
  processing: number;
  needsAction: number;
}

@Component({
  selector: 'inf-orders-status',
  standalone: true,
  imports: [CommonModule, OrderStatusBoxComponent],
  templateUrl: './orders-status.component.html',
})
export class OrdersStatusComponent {
  @Input() dashboard: boolean = false;
}
