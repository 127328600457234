import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { RouterModule } from '@angular/router';
import { map } from 'rxjs';

// Services
import { AlertService } from '@harmanpa/ng-cae';

// Types
import { DefaultService, DocumentSummary } from 'generated-src';

// Components
import { ButtonModule } from 'primeng/button';
import { Table } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { OrdersOverviewComponent } from './orders-overview/orders-overview.component';
import { OrdersStatusComponent } from './orders-status/orders-status.component';
import { SyncOrdersComponent } from './sync-orders/sync-orders.component';
import { HttpClient } from '@angular/common/http';

/**
 *To Do
 * Get Status and Requires Action from the APIs
 */
////////////////////////////
// Temporary Type
////////////////////////////

export interface TemporaryDocumentSummary extends DocumentSummary {
  orderStatus: 'received' | 'in-progress' | 'processing' | 'delivered' | 'needs-action';
  requiresAction: boolean;
  madeToOrder: boolean;
}
export const ordersStatuses = ['received', 'processing', 'delivered'];

////////////////////////////
// End Temporary Type
////////////////////////////

@Component({
  selector: 'inf-orders',
  standalone: true,
  imports: [
    ButtonModule,
    CommonModule,
    TooltipModule,
    RouterModule,
    OrdersOverviewComponent,
    SyncOrdersComponent,
    OrdersStatusComponent,
  ],
  templateUrl: './orders.component.html',
})
export class OrdersComponent implements OnInit {
  @Input() rowsNumber: number = 10;
  orders: DocumentSummary[] = [];
  orderWorkBatches: OrderWorkBatches = {};

  // Loading
  inProgress: boolean;
  ordersSyncing: boolean = false;

  // Table
  includeSearch: boolean = true;
  selectOrders: boolean = false;

  // State
  presetSearch: string;

  ////////////////////////////
  // Table Data on init
  ////////////////////////////
  @ViewChild('dtorders') table: Table;

  constructor(
    private api: DefaultService,
    private alertService: AlertService,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    this.inProgress = false;

    ////////////////////////////
    // Pull Order Status State from Dashboard
    ////////////////////////////

    this.presetSearch = history.state?.['orderStatus'] ?? '';

    this.getOrdersData();
  }

  ////////////////////////////
  // Get Orders
  ////////////////////////////

  public getOrdersData(): void {
    this.inProgress = true;
    this.api.findOrders().subscribe({
      next: (orders: DocumentSummary[]) => {
        this.http.get<OrderWorkBatches>('/api/production/orders').subscribe({
          next: orderworkbatches => {
            this.orders = orders;
            this.orderWorkBatches = orderworkbatches;
            this.inProgress = false;
          },
          error: err => {
            this.alertService.error(err);
            this.inProgress = false;
          },
        });
      },
      error: err => {
        this.alertService.error(err);
        this.inProgress = false;
      },
    });
  }
}

export type OrderWorkBatches = { [key: string]: ReferenceWorkBatch[] };

export interface ReferenceWorkBatch {
  type?: string;
  id: string;
  version: string;
}
