<div class="fill-v">
  <div class="align-items-center flex justify-content-between">
    <h2 class="font-medium mb-2">Production</h2>
  </div>
  <div class="font-medium subtitle mb-6">PICKING AND MANUFACTURING DATA</div>

  <div class="card flex flex-column">
    <p-table
      #dt1
      class="mb-5 flex-1 border-round-xl"
      [value]="filteredWorkBatches"
      [rows]="rowsNumber"
      [showCurrentPageReport]="true"
      [paginator]="!isOverview"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      [rowsPerPageOptions]="[10, 25, 50]"
      scrollable="false"
      selectionMode="multiple">
      <ng-template pTemplate="caption">
        <inf-search-system
          [data]="workBatches"
          class="mb-4"
          [paths]="['id', 'owner']"
          (dataChange)="filterData($event)">
        </inf-search-system>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <!-- <th style="width: 4rem" *ngIf="!isOverview">
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </th> -->
          <th pSortableColumn="owner">
            Owner<span> <p-sortIcon field="owner" /></span>
          </th>
          <th>Created</th>
          <th pSortableColumn="lastEdited">
            Last Edited<span> <p-sortIcon field="lastEdited" /></span>
          </th>
          <th>Batch ID</th>
          <th>Combined Orders</th>
          <th>Actions</th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-batch>
        <tr [pContextMenuRow]="batch">
          <!-- <td *ngIf="!isOverview">
            <p-tableCheckbox [value]="batch"></p-tableCheckbox>
          </td> -->
          <td>
            <inf-avatar
              [user]="batch.owner"
              [size]="'small'"
              [label]="getOwnerName(batch.owner)"
              [pTooltip]="batch.owner"
              tooltipPosition="top"></inf-avatar>
          </td>
          <td>
            {{ batch.created | date: 'dd MMMM yyyy' }}
          </td>
          <td *ngIf="!isOverview">
            {{ batch.lastEdited | date: 'dd MMMM yyyy' }}
          </td>
          <td>{{ batch.id }}</td>
          <td>
            {{ batch.data['orders'].length }}
          </td>
          <td>
            <div class="flex">
              <button
                class="p-button-text p-button-rounded"
                icon="pi pi-file"
                pButton
                [routerLink]="['/production', batch.id]"
                [pTooltip]="'View Details'"
                tooltipPosition="top"></button>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
