/**
 * InfinitivePlatform API
 * Modular Configurator
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Values of scores for optimisation rules
 */
export interface Score { 
    /**
     * State of score
     */
    state?: Score.StateEnum;
    /**
     * How this score should be combined with others
     */
    merge?: Score.MergeEnum;
    /**
     * Weight of the score
     */
    weight?: number;
}
export namespace Score {
    export type StateEnum = 'Always' | 'Sometimes' | 'Never';
    export const StateEnum = {
        Always: 'Always' as StateEnum,
        Sometimes: 'Sometimes' as StateEnum,
        Never: 'Never' as StateEnum
    };
    export type MergeEnum = 'Mean' | 'Min' | 'Max' | 'Sum' | 'RMS';
    export const MergeEnum = {
        Mean: 'Mean' as MergeEnum,
        Min: 'Min' as MergeEnum,
        Max: 'Max' as MergeEnum,
        Sum: 'Sum' as MergeEnum,
        RMS: 'RMS' as MergeEnum
    };
}
