<div [ngClass]="dashboard ? 'flex' : 'flex'" class="gap-4 orders-status">
  <inf-order-status-box
    [dashboard]="dashboard"
    class="flex-1"
    [class]="'received'"
    [status]="'incoming'"
    [orderStatus]="'received'"
    icon="pi pi-inbox"
    info="Received"></inf-order-status-box>
  <inf-order-status-box
    [dashboard]="dashboard"
    class="flex-1"
    [class]="'ongoing'"
    [customColour]="'#ffa000'"
    [orderStatus]="'processing'"
    icon="pi pi-reply"
    info="Processing"
    [status]="'ongoing'"></inf-order-status-box>
  <inf-order-status-box
    [dashboard]="dashboard"
    class="flex-1"
    [class]="'delivered'"
    [customColour]="'#2a602c'"
    [orderStatus]="'delivered'"
    icon="pi pi-check-circle"
    info="Delivered"
    [status]="'completed'"></inf-order-status-box>
</div>
