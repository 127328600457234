import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import {
  Artefact,
  DefaultService,
  LineItem,
  ParameterDefinition,
  ProductionParts,
  VersionedArtefact,
} from '../../../../../generated-src';
import { AuthService } from '@harmanpa/ng-cae';
import { BadgeModule } from 'primeng/badge';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ChipsModule } from 'primeng/chips';
import { DropdownModule } from 'primeng/dropdown';
import { FieldComponent } from '../../../shared/components/field/field.component';
import { FileUploadModule } from 'primeng/fileupload';
import { FormsModule } from '@angular/forms';
import { TooltipModule } from 'primeng/tooltip';
import { map, Observable } from 'rxjs';

@Component({
  selector: 'inf-order-product-card',
  standalone: true,
  templateUrl: './order-product-card.component.html',
  imports: [
    BadgeModule,
    CommonModule,
    ChipsModule,
    DropdownModule,
    FieldComponent,
    FileUploadModule,
    FormsModule,
    TooltipModule,
    NgOptimizedImage,
  ],
})
export class OrderProductCardComponent implements OnInit {
  @Input() lineItem: LineItem;
  @Input() docLink: string;
  id: string;
  version: string;
  url: string = '';
  price: string = '';
  productionParts: ProductionParts = { groups: [] };
  nProductionParts = 0;

  constructor(
    private api: DefaultService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.id = this.lineItem.module?.split(':')[0] as string;
    this.version = this.lineItem.module?.split(':')[1] as string;
    this.api.getModuleSourceImage(this.id, this.version).subscribe((vi: VersionedArtefact) => {
      console.log(vi);
      if (vi.document && (vi.document as Artefact).uri) {
        this.url = '/api/modules/' + this.id + '/v/' + this.version + '/source/image.png';
      } else {
        this.url = '/api/modules/' + this.id + '/v/' + this.version + '/source/render.png';
      }
    });
    this.api.getModuleProductionParts(this.id, this.version).subscribe(vpp => {
      this.productionParts = vpp.document || { groups: [] };
      this.nProductionParts = (this.productionParts.groups || []).flatMap(group => group.parts).length;
    });
  }

  toggleExpand(event: Event): void {
    // this.expanded = !this.expanded;
    // this.expandedChange.emit(this.expanded);
    event.stopPropagation();
  }

  stopP(event: Event): void {
    event.stopPropagation();
  }

  parameterTrackBy(index: number, parameter: ParameterDefinition): string {
    return parameter.name as string;
  }
}
