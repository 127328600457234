<ng-container *ngIf="count > 0">
  <p-overlayPanel #op>
    <inf-metric-table [metrics]="metrics"></inf-metric-table>
  </p-overlayPanel>
  <p-button
    [styleClass]="inTable ? 'p-button-text p-button-rounded' : 'p-button-sm'"
    (click)="op.toggle($event)"
    icon="pi pi-table"
    [label]="inTable ? undefined : label"
    [pTooltip]="tooltip"></p-button>
</ng-container>
